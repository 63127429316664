import { Box } from '@mui/material'
import React from 'react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { ReactComponent as PinSvg } from 'assets/LabelProofDesignPin.svg'
import { ReactComponent as HighlightedPinSvg } from 'assets/LabelProofDesignActivatedPin.svg'
import { AvatarInitials } from 'components/AvatarInitials/AvatarInitials'
import { ActivePin } from 'state/labelproof/proofer/ProoferPinsSlice'
import { on } from 'events'

export interface PreviewPin {
  id: string
  x: number
  y: number
  hidden: boolean
  draggable: boolean
  highlighted: boolean
  user?: {
    name: string
    avatarSrc: string
  }
}

interface PinProps extends PreviewPin {
  scale: number
  repositionPin: (x: number, y: number, id: string) => void
  onClick: (pin: ActivePin) => void
}

// Compensate for the difference between the tip of the pin and the top left corner of the pin container
enum compensation {
  X = 42,
  Y = 63
}

export const Pin: React.FC<PinProps> = ({
  id,
  x,
  y,
  scale,
  repositionPin,
  onClick,
  hidden,
  user,
  draggable,
  highlighted
}) => {
  if (hidden) {
    return null
  }
  const pinPosition = React.useMemo(() => {
    return { x: x - compensation.X, y: y - compensation.Y }
  }, [x, y])
  return (
    <Draggable
      scale={scale}
      position={pinPosition}
      onStop={(e, data) =>
        repositionPin(data.x + compensation.X, data.y + compensation.Y, id)
      }
      onDrag={(e: DraggableEvent, { x, y }: DraggableData) => {
        e.stopPropagation()
      }}
      disabled={!draggable}
    >
      <Box
        id={`pin-${id}`}
        sx={{
          position: 'absolute',
          height: '60px',
          width: '52px',
          top: '2px',
          left: '12px'
        }}
        onContextMenu={(e) => e.stopPropagation()}
      >
        <Box
          style={{
            position: 'relative',
            height: '100%',
            width: '100%',
            top: '0',
            left: '0'
          }}
          onClick={(e) => {
            e.stopPropagation()
            onClick({ id  })
          }}
        >
          {highlighted ? <HighlightedPinSvg /> : <PinSvg />}
          <Box
            sx={{
              position: 'absolute',
              top: -5,
              left: -1.5,
              transform: 'translate(37%, 35%)'
            }}
          >
            {user && (
              <AvatarInitials
                variant="circle"
                name={user.name}
                avatarSrc={user.avatarSrc}
                size={36}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Draggable>
  )
}
