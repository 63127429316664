import { ListCell } from 'components/Cells/ListCell'
import { NameCell } from 'components/Cells/NameCell'
import { SortOrder } from 'components/common'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import { SimpleIngredientsSortField } from 'models/SimpleIngredient'
import React from 'react'

export interface IngredientsTableRow {
  id: string
  name: string
  friendlyId?: string
  suppliers?: string[]
  tags?: string[]
}

export interface IngredientsTableProps {
  rows: IngredientsTableRow[]
  allPagesLoaded: boolean
  nextPageCallback: () => void
  onRowClick: (ingredientId: string) => void
  sortConfig: {
    sortField: SimpleIngredientsSortField
    sortOrder: SortOrder
    onSort: (
      field: string,
      currentOrder: SortOrder | undefined
    ) => void
  }
}

export const IngredientsTable: React.FC<IngredientsTableProps> = ({
  rows,
  allPagesLoaded,
  onRowClick,
  nextPageCallback,
  sortConfig
}) => {
  const columnConfigs: ColumnConfiguration<IngredientsTableRow>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '35%' },
      field: (ingredient) => ingredient.name,
      customRender: (ingredient) => <NameCell name={ingredient.name} />,
      sortConfig: {
        active: sortConfig.sortField === SimpleIngredientsSortField.NAME,
        order: sortConfig.sortField === SimpleIngredientsSortField.NAME ? sortConfig.sortOrder : undefined,
        fieldSortId: SimpleIngredientsSortField.NAME,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Friendly ID',
      field: (ingredient) => ingredient.friendlyId ?? '',
      sortConfig: {
        active: sortConfig.sortField === SimpleIngredientsSortField.FRIENDLY_ID,
        order: sortConfig.sortField === SimpleIngredientsSortField.FRIENDLY_ID ? sortConfig.sortOrder : undefined,
        fieldSortId: SimpleIngredientsSortField.FRIENDLY_ID,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Suppliers',
      field: (ingredient) => ingredient.suppliers?.join(',') ?? '',
      customRender: (ingredient) => (
        <ListCell list={ingredient.suppliers ?? []} />
      )
    },
    {
      title: 'Tags',
      field: (ingredient) => ingredient.tags?.join(',') ?? '',
      customRender: (ingredient) => <ListCell list={ingredient.tags ?? []} />
    }
  ]

  return (
    <EntrTable
      data={rows}
      allPagesLoaded={allPagesLoaded}
      columnConfigs={columnConfigs}
      onRowClick={(row) => onRowClick(row.id)}
      onLoadNextPage={nextPageCallback}
      noDataMessage="No ingredients found"
    />
  )
}
