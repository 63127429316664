import React, { useRef } from 'react'
import { NumberField, NumberFieldProps } from './NumberField'
import { BoxRowBasic } from 'components/common'
import { ClickAwayListener, Fade } from '@mui/material'
import { Button } from 'components/Button/Button'

export interface SumbitNumberFieldProps
  extends Omit<NumberFieldProps, 'onChange' | 'onSubmit'> {
  onSubmit: (value: number) => void
  submitText?: string
}

/**
 * An extension of the NumberField that formats the input as a percentage.
 */
export const SubmitNumberField: React.FC<SumbitNumberFieldProps> = ({
  value,
  onSubmit,
  submitText = 'Submit',
  ...props
}) => {
  const [showButton, setShowButton] = React.useState<boolean>(false)
  const [submissionValue, setSubmissionValue] = React.useState<number | null>(
    null
  )
  const inputRef = useRef<HTMLInputElement>(null)

  const handleOnBlur = () => {
    if (inputRef.current && inputRef.current.contains(document.activeElement)) {
      return
    }
    setSubmissionValue(null)
    setShowButton(false)
  }

  const submitable = React.useMemo(() => {
    return submissionValue !== null && submissionValue !== value
  }, [submissionValue, value])

  const handleSubmit = () => {
    if (submitable && submissionValue !== null) {
      onSubmit(submissionValue)
    }
    setShowButton(false)
  }

  const handleOnFocus = () => {
    setShowButton(true)
  }

  const handleChange = (value: number) => {
    setSubmissionValue(value)
    setShowButton(true)
  }

  const handleInternalChange = (
    internalValue: string,
    incomplete: boolean,
    invalid: boolean
  ) => {
    // In the event the internal value is similar to the original value, clear the submission value.
    if (
      incomplete ||
      invalid ||
      Number(internalValue) === value ||
      internalValue === ''
    ) {
      setSubmissionValue(null)
    }
  }

  return (
    <ClickAwayListener onClickAway={handleOnBlur}>
      <BoxRowBasic ref={inputRef}>
        <NumberField
          {...props}
          value={submissionValue || value}
          onFocus={handleOnFocus}
          onChange={handleChange}
          onInternalValueChange={handleInternalChange}
          validation=">0"
          sx={{
            borderBottomRightRadius: showButton ? '0px' : undefined,
            borderTopRightRadius: showButton ? '0px' : undefined,
            ...props.sx
          }}
        />
        {showButton && (
          <Fade in={showButton}>
            <Button
              color="secondary"
              size="small"
              disabled={!submitable}
              onClick={handleSubmit}
              style={{
                height: '34px',
                borderTopLeftRadius: '0px',
                borderBottomLeftRadius: '0px',
                border: '1px solid rgba(0, 0, 0, 0.1)',
                boxShadow: 'none'
              }}
            >
              {submitText}
            </Button>
          </Fade>
        )}
      </BoxRowBasic>
    </ClickAwayListener>
  )
}
