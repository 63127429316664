import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LabelProofApiResponse } from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import {
  DeleteProofDocumentRequest,
  GetProofDesignFileUrlRequest,
  GetProofDocumentsRequest,
  GetProofDocumentUrlRequest as GetProofDocumentRequest,
  GetProofRequest,
  UpdateProofRequest,
  UploadProofDesignFileRequest,
  UploadProofDocumentRequest
} from './ProoferRequest'
import { LabelProoferTab } from 'pages/LabelProofs/LabelProofer/components/Panel/PanelContainer'
import { BasicDocument } from 'models/Document'

export interface ProoferState {
  loadingDesignFileUrl: boolean
  designImageLoaded: boolean
  loadingProof: boolean
  loadingDocuments: boolean
  savingProof: boolean
  proof?: LabelProofApiResponse
  designFileUrl?: string
  deletingProof: boolean
  deletingDocument: boolean
  activeTab: LabelProoferTab
  documents: BasicDocument[]
}

const initialState: ProoferState = {
  loadingDesignFileUrl: false,
  designImageLoaded: false,
  loadingProof: false,
  loadingDocuments: false,
  savingProof: false,
  deletingProof: false,
  deletingDocument: false,
  activeTab: LabelProoferTab.REVIEW,
  documents: []
}

export const getProof = createAsyncThunk(
  'labelproof/proofer/get',
  async ({ companyId, proofId: proofId }: GetProofRequest) => {
    return await LabelProofsApi.getLabelProof(companyId, proofId)
  }
)

export const updateProof = createAsyncThunk(
  'labelproof/proofer/update',
  async ({ companyId, proofId: proofId, name }: UpdateProofRequest) => {
    return await LabelProofsApi.updateLabelProof(companyId, proofId, {
      name: name
    })
  }
)

export const deleteProof = createAsyncThunk(
  'labelproof/proofer/delete',
  async ({ companyId, proofId: proofId }: GetProofRequest) => {
    return await LabelProofsApi.deleteLabelProof(companyId, proofId)
  }
)

export const uploadProofDesignFile = createAsyncThunk(
  'labelproof/proofer/uploadDesignFile',
  async ({
    companyId,
    proofId,
    file,
    uploadProgressListener
  }: UploadProofDesignFileRequest) => {
    return await LabelProofsApi.uploadLabelProofDesignFile(
      companyId,
      proofId,
      file,
      uploadProgressListener
    )
  }
)

export const getProofDesignFileUrl = createAsyncThunk(
  'labelproof/proofer/getDesignFileUrl',
  async ({ companyId, proofId }: GetProofDesignFileUrlRequest) => {
    return await LabelProofsApi.getLabelProofDesignFile(companyId, proofId)
  }
)

export const getProofDocuments = createAsyncThunk(
  'labelproof/proofer/getDocuments',
  async ({ companyId, proofId }: GetProofDocumentsRequest) => {
    return await LabelProofsApi.getLabelProofDocuments(companyId, proofId)
  }
)

export const getProofDocument = createAsyncThunk(
  'labelproof/proofer/getDocumentUrl',
  async ({ companyId, proofId, fileName, download }: GetProofDocumentRequest) => {
    return await LabelProofsApi.getProofDocument(
      companyId,
      proofId,
      fileName,
      download
    )
  }
)

export const uploadProofDocument = createAsyncThunk(
  'labelproof/proofer/uploadDocument',
  async ({
    companyId,
    proofId,
    file,
    uploadProgressListener
  }: UploadProofDocumentRequest) => {
    return await LabelProofsApi.uploadLabelProofDocument(
      companyId,
      proofId,
      file,
      uploadProgressListener
    )
  }
)

export const deleteProofDocument = createAsyncThunk(
  'labelproof/proofer/deleteDocument',
  async ({ companyId, proofId, fileName }: DeleteProofDocumentRequest) => {
    return await LabelProofsApi.deleteLabelProofDocument(
      companyId,
      proofId,
      fileName
    )
  }
)

const prooferSlice = createSlice({
  name: 'prooferSlice',
  initialState,
  reducers: {
    resetProoferState: () => initialState,
    setDesignImageLoaded: (state, action: PayloadAction<boolean>) => {
      state.designImageLoaded = action.payload
    },
    setActiveTab: (state, action: PayloadAction<LabelProoferTab>) => {
      state.activeTab = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(getProof.pending, (state) => {
      state.loadingProof = true
    })
    builder.addCase(
      getProof.fulfilled,
      (state, action: PayloadAction<LabelProofApiResponse>) => {
        state.loadingProof = false
        state.proof = action.payload
      }
    )
    builder.addCase(getProof.rejected, (state) => {
      state.loadingProof = false
    })

    builder.addCase(updateProof.pending, (state) => {
      state.savingProof = true
    })
    builder.addCase(
      updateProof.fulfilled,
      (state, action: PayloadAction<LabelProofApiResponse>) => {
        state.savingProof = false
        state.proof = action.payload
      }
    )
    builder.addCase(updateProof.rejected, (state) => {
      state.savingProof = false
    })

    builder.addCase(deleteProof.pending, (state) => {
      state.deletingProof = true
    })
    builder.addCase(deleteProof.fulfilled, (state) => {
      state.deletingProof = false
      state.proof = undefined
    })
    builder.addCase(deleteProof.rejected, (state) => {
      state.deletingProof = false
    })

    builder.addCase(getProofDesignFileUrl.pending, (state) => {
      state.loadingDesignFileUrl = true
    })
    builder.addCase(
      getProofDesignFileUrl.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.loadingDesignFileUrl = false
        state.designFileUrl = action.payload
      }
    )
    builder.addCase(getProofDesignFileUrl.rejected, (state) => {
      state.designFileUrl = undefined
      state.loadingDesignFileUrl = false
      state.designImageLoaded = false
    })
    builder.addCase(getProofDocuments.pending, (state) => {
      state.loadingDocuments = true
    })
    builder.addCase(
      getProofDocuments.fulfilled,
      (state, action: PayloadAction<BasicDocument[]>) => {
        state.loadingDocuments = false
        state.documents = action.payload
      }
    )
    builder.addCase(getProofDocuments.rejected, (state) => {
      state.loadingDocuments = false
    })
    builder.addCase(deleteProofDocument.pending, (state) => {
      state.deletingDocument = true
    })
    builder.addCase(deleteProofDocument.fulfilled, (state) => {
      state.deletingDocument = false
    })
    builder.addCase(deleteProofDocument.rejected, (state) => {
      state.deletingDocument = false
    })
  }
})

export const { resetProoferState, setDesignImageLoaded, setActiveTab } =
  prooferSlice.actions
export default prooferSlice.reducer
