import { BasicFormula, SubFormulaStatementFormat } from './Formula'
import { BasicIngredient, IngredientNutrient } from './Ingredient'
import { Measurement } from './Measurement'
import { BasicSupplier } from './Supplier'

export enum ActiveIngredientDisplaySection {
  NUTRIENTS = 'Top',
  ACTIVE_INGREDIENTS = 'Bottom',
  BOTH = 'Both',
  HIDDEN = 'Hidden'
}

export interface FormulaIngredient {
  id: string
  formula: BasicFormula
  ingredient: BasicIngredient
  supplier: BasicSupplier
  amount: number
  wastePercentage: number
  amountInPercentage: number
  amountInMeasurement: number
  measurement: Measurement
  totalCostForGrams: number | null
  isActive: boolean
  formatInStatement: SubFormulaStatementFormat
  activeIngredientDisplaySection: ActiveIngredientDisplaySection
  children?: FormulaIngredient[]
}

export interface FormulaIngredientNutrient {
  ingredientNutrient: IngredientNutrient
}
