import { ComponentPreview } from 'components/ComponentPreview/ComponentPreview'
import React from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  ActivePin,
  repositionProofPin,
  setActivePin,
  setCurrentDesignViewingState,
  setStagedPin,
  STAGED_PIN_ID
} from 'state/labelproof/proofer/ProoferPinsSlice'
import { setDesignImageLoaded } from 'state/labelproof/proofer/ProoferSlice'
import { PreviewPin } from './Pins/Pin'
import { Pins } from './Pins/Pins'


export interface PreviewProps {
  fileUrl?: string
  pins?: PreviewPin[]
}

export const PreviewContainer: React.FC<PreviewProps> = ({ fileUrl, pins }) => {
  const dispatch = useAppDispatch()
  const [currentScale, setCurrentScale] = React.useState<number>(1)
  const [imageDimensions, setImageDimensions] = React.useState({
    width: 0,
    height: 0,
    naturalWidth: 0,
    naturalHeight: 0
  })

  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)
  const designImageLoaded = useAppSelector(
    (state) => state.proofer.designImageLoaded
  )

  const activePin = useAppSelector((state) => state.prooferPins.activePin)

  // Refs for precise positioning
  const containerRef = React.useRef<HTMLDivElement>(null)
  const imgRef = React.useRef<HTMLImageElement>(null)
  const pinsContainerRef = React.useRef<HTMLDivElement>(null)

  const [pinsContainerStyle, setPinsContainerStyle] = React.useState({})

  const [isDragging, setIsDragging] = React.useState(false)

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(false)
  }

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    setIsDragging(true)
  }

  React.useEffect(() => {
    if (!imgRef.current || !containerRef.current) return

    const updateImageDimensions = (entries: ResizeObserverEntry[]) => {
      const entry = entries[0] // First entry is our image
      if (entry) {
        const { width, height } = entry.contentRect
        const img = imgRef.current

        if (img) {
          setImageDimensions({
            width: width,
            height: height,
            naturalWidth: img.naturalWidth,
            naturalHeight: img.naturalHeight
          })

          // Use the exact dimensions from the ResizeObserver entry
          // i.e. the actual dimensions of the image
          setPinsContainerStyle({
            position: 'absolute',
            width: `${width}px`,
            height: `${height}px`,
            top: '0px',
            left: '0px'
          })
        }
      }
    }

    const resizeObserver = new ResizeObserver(updateImageDimensions)

    if (designImageLoaded && imgRef.current) {
      resizeObserver.observe(imgRef.current)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [designImageLoaded])

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.button === 2) {
      return
    }

    if (
      e.target !== pinsContainerRef.current ||
      isDragging ||
      !designImageLoaded ||
      !proofId ||
      !pinsContainerRef.current
    ) {
      setIsDragging(false)
      return
    }

    const widthRatio = e.nativeEvent.offsetX / imageDimensions.width
    const heightRatio = e.nativeEvent.offsetY / imageDimensions.height
    void dispatch(
      setStagedPin({
        widthRatio,
        heightRatio
      })
    )
    dispatch(setActivePin({ id: STAGED_PIN_ID }))

    setIsDragging(false)
  }

  const repositionPin = (newX: number, newY: number, pinId: string) => {
    if (!proofId) {
      return
    }

    const newWidthRatio = newX / imageDimensions.width
    const newHeightRatio = newY / imageDimensions.height
    const pin = pins?.find((pin) => pin.id === pinId)
    if (pin && pin.x === newWidthRatio && pin.y === newHeightRatio) {
      return
    }

    if (pinId === STAGED_PIN_ID) {
      dispatch(setStagedPin({ widthRatio: newWidthRatio, heightRatio: newHeightRatio }))
      return
    }

    void dispatch(
      repositionProofPin({
        companyId,
        proofId,
        pinId,
        widthRatio: newWidthRatio,
        heightRatio: newHeightRatio
      })
    )
  }

  const handleTransformChange = (ref: ReactZoomPanPinchRef) => {
    setCurrentScale(ref.state.scale)
    dispatch(
      setCurrentDesignViewingState({
        scale: ref.state.scale
      })
    )
  }

  const handleImageLoad = () => {
    dispatch(setDesignImageLoaded(true))
  }

  const positionedPins = React.useMemo(() => {
    if (!pins) {
      return []
    }

    return pins.map((pin) => ({
      ...pin,
      x: pin.x * imageDimensions.width,
      y: pin.y * imageDimensions.height
    }))
  }, [pins, imageDimensions])

  const handlePinClick = React.useCallback(
    (pin: ActivePin | null) => {
      if (pin?.id !== activePin?.id) {
        dispatch(setActivePin(pin))
      }
    },
    [dispatch, activePin]
  )

  return (
    <ComponentPreview
      loading={false}
      loadingText="Loading design"
      onTransformChange={handleTransformChange}
    >
      <div
        ref={containerRef}
        style={{
          position: 'relative',
          display: 'inline-block',
          width: '100%',
          height: '100%',
          backgroundColor: 'white'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <img
          ref={imgRef}
          src={fileUrl}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
          onLoad={handleImageLoad}
        />
        {designImageLoaded && (
          <div ref={pinsContainerRef} style={pinsContainerStyle}>
            <Pins
              pins={positionedPins}
              scale={currentScale}
              repositionPin={repositionPin}
              onClick={handlePinClick}
            />
          </div>
        )}
      </div>
    </ComponentPreview>
  )
}
