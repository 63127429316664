import { RegulationApiResponse } from 'services/apis/regulation/RegulationApiResponse'
import { BasicCompany } from './Company'
import { NutrientType, NutrientUnit } from './Nutrient'
import { SortedListing } from 'services/apis/config/Sorting'
import { TargetsSortField } from 'services/apis/target/TargetApiResponse'

export enum LogicalOperator {
  AND = 'and',
  OR = 'or'
}

export enum ComparisonOperator {
  EQUAL = 'is equal to',
  LESS_THAN = 'is less than',
  BIGGER_THAN = 'is more than',
  LESS_THAN_OR_EQUAL = 'is less than or equal to',
  BIGGER_THAN_OR_EQUAL = 'is more than or equal to',
  NOT_EQUAL = 'is not equal to'
}

export enum OperationType {
  LOGICAL = 'logical',
  COMPARISON = 'comparison',
  TARGET = 'target',
  CONDITIONAL = 'conditional'
}

export enum ComparisonType {
  RACC = 'RACC',
  PER_RACC = 'per RACC',
  PER_TARGET_VALUE = 'per target value',
  PER_LABELED_SERVING = 'per labeled serving',
  CALORIES_FROM_NUTRIENT = 'calories from nutrient',
  TAGS_IN_FORMULA = 'tags in formula',
  TEXT_IN_FORMULA = 'text in formula'
}

export enum ConditionalType {
  TARGET = 'target',
  MEAL_AND_MAIN_DISH = 'meal and main dish',
  SMALL_RACC = 'small RACC',
  ALLERGEN_FREE = "allergen free"
}

export enum ComparisonUnit {
  GRAM = 'g',
  MILLIGRAM = 'mg',
  TABLESPOON = 'tbsp',
  PERCENTAGE = '%',
  CALORIE = 'kcal',
  TAG = 'tag'
}

export enum ExtendedNutrientUnitForTargets {
  PERCENTAGE = '%',
  TAG = 'tag'
}

export type NutrientTargetUnit = NutrientUnit | ExtendedNutrientUnitForTargets

export enum TagsQuantifier {
  ALL = 'all',
  NONE = 'none'
}

export enum CreateOperationActionType {
  LOGICAL_AND = 'and',
  LOGICAL_OR = 'or',
  COMPARISON_PER_RACC = 'per_RACC',
  COMPARISON_RDI_PER_RACC = 'RDI_per_RACC',
  COMPARISON_SERVING = 'serving',
  COMPARISON_PER_LABELED_SERVING = 'per_labeled_serving',
  COMPARISON_CAL_NUTRIENT = 'Cal_nutrient',
  COMPARISON_TAG_IN_FORMULA = 'tag_in_formula',
  COMPARISON_TEXT_IN_FORMULA = 'text_in_formula',
  TARGET = 'target',
  CONDITIONAL_TARGET = 'conditional_target',
  CONDITIONAL_MEAL_AND_MAIN_DISH = 'conditional_meal_and_main_dish',
  CONDITIONAL_SMALL_RACC = 'conditional_small_RACC',
  CONDITIONAL_ALLERGEN_FREE = 'conditional_allergen_free'
}

export interface BasicOperation {
  id: string
  operationType: OperationType
}

export interface TargetOperation extends BasicOperation {
  targetId: string
  targetName: string
  expectedExecutionReturn?: boolean
  executeOnReferenceFormula?: boolean
}

export interface ConditionalOperation extends BasicOperation {
  conditionalType: ConditionalType
  targetId?: string // only for Target Conditional Operation
  isMealOrMainDish?: boolean // only for Meal And Main Dish Conditional Operation
  isSmallRacc?: boolean // only for Small RACC Conditional Operation
  allergenType?: string // only for Allergen Free Conditional Operation
}

export interface ComparisonOperation extends BasicOperation {
  operator: ComparisonOperator
  comparisonUnit: NutrientTargetUnit
  value: number
  comparisonType: ComparisonType
  comparedToReferenceFormula: boolean
  comparedToRdi?: boolean
  nutrient?: NutrientType
  secondNutrient?: NutrientType
  targetValue?: number // only for Target Value Comparison Type
  targetUnit?: ComparisonUnit // only for Target Value Comparison Type
  tagId?: string // only for Tags in Formula Comparison Type
  text?: string // only for text in ingredients Comparison Type
}

export interface LogicalOperation extends BasicOperation {
  operator: LogicalOperator
  operations: Array<Operation>
  level?: number
}

export type Operation =
  | LogicalOperation
  | ComparisonOperation
  | TargetOperation
  | ConditionalOperation

export interface NormalizedBasicOperation {
  id: string
  operationType: OperationType
  parentOperationId: string
  parentTargetId: string
}

export interface NormalizedLogicalOperation extends NormalizedBasicOperation {
  operator: LogicalOperator
  level?: number
  childOperationsIds: Array<string>
}

export interface NormalizedTargetOperation extends NormalizedBasicOperation {
  targetId: string
  targetName: string
  expectedExecutionReturn?: boolean
  executeOnReferenceFormula?: boolean
}

export interface NormalizedConditionalOperation
  extends NormalizedBasicOperation {
  conditionalType: ConditionalType
  targetId?: string // only for Target Conditional Operation
  isMealOrMainDish?: boolean // only for Meal And Main Dish Conditional Operation
  isSmallRacc?: boolean // only for Small RACC Conditional Operation
  allergenType?: string // only for Allergen Free Conditional Operation
}

export interface NormalizedComparisonOperation
  extends NormalizedBasicOperation {
  operator: ComparisonOperator
  comparisonUnit: NutrientTargetUnit
  value: number
  comparisonType: ComparisonType
  comparedToReferenceFormula: boolean
  comparedToRdi?: boolean
  nutrient?: NutrientType
  secondNutrient?: NutrientType
  targetValue?: number // only for Target Value Comparison Type
  targetUnit?: ComparisonUnit // only for Target Value Comparison Type
  tagId?: string // only for Tags in Formula Comparison Type
  text?: string // only for text in ingredients Comparison Type
}

export type NormalizedOperation =
  | NormalizedLogicalOperation
  | NormalizedComparisonOperation
  | NormalizedTargetOperation
  | NormalizedConditionalOperation

export interface NormalizedOperations {
  [id: string]: NormalizedOperation
}

export interface BasicTargetCategory {
  id: string
  name: string
}

export interface BasicTarget {
  id: string
  name: string
  updatedAt: string
  formulaCount: number
  isEditable: boolean
  company?: BasicCompany
  targetDefinitionCategory?: BasicTargetCategory
  description?: string
  regulation?: RegulationApiResponse
}

export interface Target extends BasicTarget {
  operation?: Operation
}

export interface SortedBasicTargets
  extends SortedListing<BasicTarget, TargetsSortField> { }

export interface NormalizedTarget extends BasicTarget {
  operationId?: string
}

export interface NormalizedTargets {
  [id: string]: NormalizedTarget
}
