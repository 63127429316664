import { Box, Skeleton, TableCell, TableRow } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import React from 'react'
import { calculateLevelPx } from '../common/helper'
import {
  IngredientActive,
  IngredientActiveProps
} from './components/IngredientActive'
import {
  IngredientCost,
  IngredientCostProps
} from './components/IngredientCost'
import {
  IngredientInputQuantity,
  IngredientInputQuantityProps
} from './components/IngredientInputQuantity'
import { IngredientMoistureLossProps } from './components/IngredientMoistureLoss'
import {
  IngredientName,
  IngredientNameProps
} from './components/IngredientName'
import {
  IngredientSupplierDropdown,
  IngredientSupplierDropdownProps
} from './components/IngredientSupplierDropdown'
import {
  IngredientWastePercentage,
  IngredientWastePercentageProps
} from './components/IngredientWastePercentage'
import {
  IngredientYieldQuantity,
  IngredientYieldQuantityProps
} from './components/IngredientYieldQuantity'

export type IngredientCellProps = Omit<IngredientNameProps, 'disabled'>
export type SupplierCellProps = IngredientSupplierDropdownProps
export type ActiveCellProps = Omit<IngredientActiveProps, 'disabled'>
export type CostCellProps = IngredientCostProps
export type InputQuantityCellProps = Omit<
  IngredientInputQuantityProps,
  'disabled'
>
export type WastePercentageCellProps = Omit<
  IngredientWastePercentageProps,
  'disabled'
>
export type YieldQuantityCellProps = IngredientYieldQuantityProps
export type MoistureLossCellProps = IngredientMoistureLossProps

export interface Visiblity {
  supplier: boolean
  cost: boolean
  wastePercentage: boolean
  yield: boolean
  active: boolean
}

export interface IngredientRowProps {
  formulaIngredientId: string
  cells: {
    ingredient: IngredientCellProps
    supplier: SupplierCellProps
    active: ActiveCellProps
    cost: CostCellProps
    inputQuantity: InputQuantityCellProps
    wastePercentage: WastePercentageCellProps
    moistureLoss: MoistureLossCellProps
    yieldQuantity: YieldQuantityCellProps
  }
  onRowCheckedChange: (checked: boolean) => void
  checked: boolean
  level?: number
  visibility: Visiblity
  disabled?: boolean
}

export const IngredientRowSkeleton: React.FC<{
  level?: number
  colSpan?: number
}> = ({ level = 0, colSpan = 3 }) => {
  return (
    <TableRow>
      <TableCell sx={{ padding: '4px 4px' }}></TableCell>
      <TableCell sx={{ padding: '4px 4px' }}>
        <Box marginLeft={`${calculateLevelPx(level)}px`}>
          <Skeleton variant="rectangular" height={'32px'} />
        </Box>
      </TableCell>
      {Array.from({ length: colSpan - 1 }).map((_, cellIdx) => (
        <TableCell key={cellIdx} sx={{ padding: '4px 4px' }}>
          <Skeleton variant="rectangular" height={'32px'} />
        </TableCell>
      ))}
    </TableRow>
  )
}

export const IngredientRow: React.FC<IngredientRowProps> = ({
  cells,
  formulaIngredientId,
  onRowCheckedChange,
  checked,
  visibility,
  level = 0,
  disabled = false
}) => {
  const tableCellPadding = { padding: '4px 4px' }

  return (
    <TableRow key={formulaIngredientId}>
      <TableCell
        sx={{
          ...tableCellPadding,
          position: 'sticky',
          left: 0,
          zIndex: 1,
          background: 'white'
        }}
      >
        {!disabled && (
          <Checkbox
            size="small"
            checked={checked}
            onChange={(_, checked) => onRowCheckedChange(checked)}
            sx={{ padding: 0 }}
          />
        )}
      </TableCell>
      <TableCell
        sx={{
          ...tableCellPadding,
          position: 'sticky',
          left: '28px',
          zIndex: 1,
          background: 'white'
        }}
      >
        <Box marginLeft={`${Math.max(level - 1, 0) * 24}px`}>
          <IngredientName {...cells.ingredient} disabled={disabled} />
        </Box>
      </TableCell>
      {visibility.active && (
        <TableCell sx={{ ...tableCellPadding }} align="center">
          {level == 0 && (
            <IngredientActive {...cells.active} disabled={disabled} />
          )}
        </TableCell>
      )}
      {visibility.supplier && (
        <TableCell sx={{ ...tableCellPadding }}>
          {!cells.supplier.hidden && (
            <IngredientSupplierDropdown
              {...cells.supplier}
              disabled={disabled || cells.supplier.disabled}
            />
          )}
        </TableCell>
      )}
      {visibility.cost && (
        <TableCell sx={{ ...tableCellPadding }}>
          <IngredientCost totalCost={cells.cost.totalCost} />
        </TableCell>
      )}
      <TableCell sx={{ ...tableCellPadding }}>
        {
          <IngredientInputQuantity
            {...cells.inputQuantity}
            disabled={disabled}
          />
        }
      </TableCell>
      {visibility.wastePercentage && (
        <TableCell sx={{ ...tableCellPadding }}>
          {
            <IngredientWastePercentage
              {...cells.wastePercentage}
              disabled={disabled}
            />
          }
        </TableCell>
      )}

      {visibility.yield && (
        <TableCell sx={{ ...tableCellPadding }}>
          <IngredientYieldQuantity {...cells.yieldQuantity} />
        </TableCell>
      )}
    </TableRow>
  )
}
