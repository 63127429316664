import { Checkbox } from '@mui/material'
import { BoxRow } from 'components/common'
import { EditableText } from 'components/EditableText/EditableText'
import React from 'react'
import { Disabled } from 'stories/Button.stories'

export interface ReviewItemAdderProps {
  onAdd: (name: string) => Promise<void>
  disabled?: boolean
}

export const ReviewItemCreator: React.FC<ReviewItemAdderProps> = ({
  onAdd,
  disabled = false
}) => {
  const placeholder = 'Add another task...'
  const [lastValue, setLastValue] = React.useState<string>(placeholder)

  const onSave = (name: string) => {
    setLastValue(name)
    if (name !== placeholder && name.trim() !== '') {
      onAdd(name)
        .then(() => {
          setLastValue(placeholder)
        })
        .catch(() => {
          setLastValue(name)
        })
    }
  }

  return (
    <BoxRow gap={'5px'}>
      <Checkbox sx={{ padding: '0px' }} disabled={true} />
      <EditableText
        initialValue={lastValue}
        placeholder={placeholder}
        saveAction={onSave}
        disabled={disabled}
      />
    </BoxRow>
  )
}
