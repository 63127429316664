import Box from '@mui/material/Box'
import { BoxColumnBasic } from 'components/common'
import { Tab } from 'components/Tabs/Tab/Tab'
import { TabPanel } from 'components/Tabs/TabPanel/TabPanel'
import { Tabs } from 'components/Tabs/Tabs'
import React from 'react'
import { CommentsContainer } from './Comments/CommentsContainer'
import { FormulaHistory } from './FormulaHistory/FormulaHistory'
import { TargetsContainer } from './FormulaTargets/TargetsContainer'
import { LabelContainer } from './Label/LabelContainer'
import { NutritionFactsEditorContainer } from './Label/NutritionFactsEditor/NutritionFactsEditorContainer'
import { Nutrients } from './Nutrients/Nutrients'
import { TabContent } from './TabContent'

export const FormulatorPanel = () => {
  const [activeTab, setActiveTab] = React.useState(0)
  const [editLabel, setEditLabel] = React.useState(false)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const toggleEditLabelPanel = () => {
    setEditLabel(!editLabel)
  }

  return (
    <>
      <Box height="100%">
        <BoxColumnBasic height="100%" width="100%" minWidth="36.8vw">
          <Tabs
            sx={{
              padding: '20px',
              alignItems: 'center',
              overflow: 'hidden',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
              flex: '0 0 auto',
              zIndex: '1',
              borderBottom: '2px solid rgba(0, 0, 0, 0.06)'
            }}
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label="Nutrients" />
            <Tab label="Label" />
            <Tab label="Targets" />
            <Tab label="History" />
            <Tab label="Comments" />
          </Tabs>
          <Box flex="1 1 auto" zIndex={1} overflow="hidden">
            <TabPanel value={activeTab} index={0}>
              <Nutrients />
            </TabPanel>
            <TabPanel value={activeTab} index={1}>
              <TabContent fixed={true} padding="20px 20px 0px 20px">
                <LabelContainer
                  onEditClicked={toggleEditLabelPanel}
                  editorOpen={editLabel}
                />
              </TabContent>
            </TabPanel>
            <TabPanel value={activeTab} index={2}>
              <TargetsContainer />
            </TabPanel>
            <TabPanel value={activeTab} index={3}>
              <FormulaHistory />
            </TabPanel>
            <TabPanel value={activeTab} index={4}>
              <CommentsContainer />
            </TabPanel>
          </Box>
        </BoxColumnBasic>
      </Box>
      <NutritionFactsEditorContainer
        show={editLabel}
        onClose={toggleEditLabelPanel}
      />
    </>
  )
}
