import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Formula, FormulaAllergens } from 'models/Formula'
import {
  FormulaIngredientStatement,
  FormulaNutritionFact,
  FormulaNutritionFactLabelType
} from 'models/FormulaLabel'
import { SupplementFacts } from 'models/SupplementFacts'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { LoadingState } from 'state/CommonState'
import { GetFormulaAllergensRequest } from 'state/formulator/allergens/FormulaAllergensRequest'
import {
  GetFormulaNutritionFactLabelRequest,
  GetSupplementFactsRequest
} from 'state/labels/nutritions/NutritionFactLabelsRequest'
import { GetFormulaIngredientStatementRequest } from 'state/labels/statements/IngredientStatementsRequest'

export interface ProoferLabelsState {
  facts?: FormulaNutritionFact | SupplementFacts
  ingredientStatement?: FormulaIngredientStatement
  allergenStatement?: FormulaAllergens
  formula?: Formula
  loadingFacts: boolean
  loadingFormula: boolean
}

const initialState: ProoferLabelsState = {
  loadingFacts: false,
  loadingFormula: false
}

export const getFormula = createAsyncThunk(
  'labelproof/formula/get',
  async ({
    companyId,
    formulaId
  }: {
    companyId: string
    formulaId: string
  }) => {
    return await FormulaApi.getFormula(companyId, formulaId)
  }
)

export const getFactsAllergens = createAsyncThunk(
  'labelproof/allergens/get',
  async ({ companyId, formulaId }: GetFormulaAllergensRequest) => {
    return await FormulaApi.getFormulaAllergens(companyId, formulaId, {
      withSuggestions: true
    })
  }
)

export const getFactsIngredientStatement = createAsyncThunk(
  'formulas/ingredientStatement/get',
  async ({
    companyId,
    formulaId,
    isSupplement
  }: GetFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.getIngredientStatement(
      companyId,
      formulaId,
      true,
      undefined,
      undefined,
      undefined,
      isSupplement
    )
  }
)

export const getSupplementFacts = createAsyncThunk(
  'labelproof/supplementFacts/get',
  async ({
    companyId,
    formulaId
  }: GetSupplementFactsRequest): Promise<SupplementFacts> => {
    return await FormulaApi.getSupplementFacts(companyId, formulaId)
  }
)

export const getNutritionFacts = createAsyncThunk(
  'labelproof/nutritionFacts/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaNutritionFactLabelRequest): Promise<FormulaNutritionFact> => {
    const perServingRes = await FormulaApi.getNutritionFacts(
      companyId,
      formulaId,
      {}
    )

    // If it's a dual, get the container weight for the formula and get the container nutrients.
    if (perServingRes.type === FormulaNutritionFactLabelType.DUAL) {
      const containerWeight = perServingRes.containerWeight || 0

      const perContainerRes = await FormulaApi.getNutritionFacts(
        companyId,
        formulaId,
        {
          servingWeight: containerWeight
        }
      )

      return {
        ...perServingRes,
        perContainer: {
          calories: perContainerRes.calories,
          containerWeight: perContainerRes.servingWeight,
          nutrients: perContainerRes.nutrients
        }
      }
    }

    return perServingRes
  }
)

const prooferLabelSlice = createSlice({
  name: 'prooferLabelSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    {
      builder.addCase(getNutritionFacts.pending, (state) => {
        state.loadingFacts = true
      })
      builder.addCase(
        getNutritionFacts.fulfilled,
        (state, action: PayloadAction<FormulaNutritionFact>) => {
          state.loadingFacts = false
          state.facts = action.payload
        }
      )
      builder.addCase(getNutritionFacts.rejected, (state) => {
        state.loadingFacts = false
      })
      builder.addCase(getSupplementFacts.pending, (state) => {
        state.loadingFacts = true
      })
      builder.addCase(
        getSupplementFacts.fulfilled,
        (state, action: PayloadAction<SupplementFacts>) => {
          state.loadingFacts = false
          state.facts = action.payload
        }
      )
      builder.addCase(getSupplementFacts.rejected, (state) => {
        state.loadingFacts = false
      })
      builder.addCase(getFactsIngredientStatement.pending, (state) => {
        state.loadingFacts = true
      })
      builder.addCase(
        getFactsIngredientStatement.fulfilled,
        (state, action: PayloadAction<FormulaIngredientStatement>) => {
          state.loadingFacts = false
          state.ingredientStatement = action.payload
        }
      )
      builder.addCase(getFactsIngredientStatement.rejected, (state) => {
        state.loadingFacts = false
      })
      builder.addCase(getFactsAllergens.pending, (state) => {
        state.loadingFacts = true
      })
      builder.addCase(
        getFactsAllergens.fulfilled,
        (state, action: PayloadAction<FormulaAllergens>) => {
          state.loadingFacts = false
          state.allergenStatement = action.payload
        }
      )
      builder.addCase(getFactsAllergens.rejected, (state) => {
        state.loadingFacts = false
      })
      builder.addCase(getFormula.pending, (state) => {
        state.loadingFormula = true
      })
      builder.addCase(
        getFormula.fulfilled,
        (state, action: PayloadAction<Formula>) => {
          state.loadingFormula = false
          state.formula = action.payload
        }
      )
      builder.addCase(getFormula.rejected, (state) => {
        state.loadingFormula = false
      })
    }
  }
})

export default prooferLabelSlice.reducer
