import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  createTarget,
  getTargets,
  getTarget,
  resetTargetsState,
  filterTargets,
  clearActiveTarget,
  getAvailableNutrients
} from 'state/targets'

import {
  BasicTarget,
  BasicTargetCategory,
  LogicalOperation,
  LogicalOperator,
  OperationType,
  Target
} from 'models/Target'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { TableFilter, TableOptions } from 'components/EntrTable/types'
import { NameCell } from 'components/Cells/NameCell'
import { Typography } from '@mui/material'
import {
  EntrTableContainer,
  PageEntrTableContainer,
  PageEntrTableInnerContainer,
  SortOrder
} from 'components/common'
import { EntrTableFilters } from 'components/EntrTable/EntrTableFilters'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import { TargetViewer } from './components/TargetViewer'
import { TitleActionsBar } from 'components/TitleActionsBar/TitleActionsBar'
import { ModalContext } from 'components/Modal/ModalContext'
import { getCompanyTags } from 'state/tags/TagsSlice'
import { getNextSortOrder } from 'common/utils'
import {
  customTargetCategoryTextStyles,
  targetCategoryTextStyles
} from './TargetsStyles'
import { Button } from 'components/Button/Button'
import { getAllergens } from 'state/allergens/AllergensSlice'
import { TargetsSortField } from 'services/apis/target/TargetApiResponse'

const options: TableOptions = {
  sorting: false,
  filtering: true
}

enum TargetsFilterField {
  CATEGORY = 'Category'
}

export const Targets: React.FC = () => {
  const dispatch = useAppDispatch()

  const { showConfirmationModal } = React.useContext(ModalContext)

  const filteredTargets = useAppSelector(
    (state) => state.targets.filteredTargets.items
  )
  const sortOrder = useAppSelector((state) => state.targets.targets.sortOrder)
  const sortField = useAppSelector((state) => state.targets.targets.sortField)
  const activeTarget = useAppSelector((state) => state.targets.activeTarget)
  const isActiveTargetUpdated = useAppSelector(
    (state) => state.targets.isActiveTargetUpdated
  )

  const deleting = useAppSelector((state) => state.targets.deleting)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const [showViewer, setShowViewer] = React.useState(false)
  const [categories, setCategories] = React.useState<BasicTargetCategory[]>([])
  const { showError, showSuccess } = React.useContext(SnackbarContext)

  const onSort = (field: string, order: SortOrder | undefined) => {
    void dispatch(
      getTargets({
        companyId: currentCompany.id,
        categoriesIds: activeFilters[TargetsFilterField.CATEGORY],
        sortBy : order ? field as TargetsSortField : undefined,
        sortOrder : order
      })
    )
  }

  const columnConfigs: ColumnConfiguration<Target>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '20%' },
      field: () => '',
      customRender: (target) => <NameCell name={target.name} />,
      sortConfig: {
        active: sortField === TargetsSortField.NAME,
        order: sortField === TargetsSortField.NAME ? sortOrder : undefined,
        fieldSortId: TargetsSortField.NAME,
        onSort
      }
    },
    {
      title: 'Category',
      fieldStyles: { width: '15%' },
      field: () => '',
      customRender: (target) => {
        return (
          <Typography
            style={
              target.targetDefinitionCategory?.name
                ? customTargetCategoryTextStyles
                : targetCategoryTextStyles
            }
          >
            {target.targetDefinitionCategory?.name ?? 'Custom Targets'}
          </Typography>
        )
      },
      sortConfig: {
        active: sortField === TargetsSortField.CATEGORY_NAME,
        order: sortField === TargetsSortField.CATEGORY_NAME ? sortOrder : undefined,
        fieldSortId: TargetsSortField.CATEGORY_NAME,
        onSort
      }
    }
    // {
    //   title: 'Used in',
    //   fieldStyles: { width: '10%' },
    //   field: () => '',
    //   customRender: (target) => {
    //     const formulasCountText =
    //       target.formulaCount > 0
    //         ? `${target.formulaCount} ${pluralize(
    //             target.formulaCount,
    //             'formula'
    //           )}`
    //         : 'No formulas'
    //     const style =
    //       target.formulaCount > 0 ? formulaCountTextStyles : noFormulasTextStyles
    //     return <Typography style={style}>{formulasCountText}</Typography>
    //   }
    // },
  ]

  React.useEffect(() => {
    const addedCategoriesIds = new Set<string>()
    const newCategories: BasicTargetCategory[] = [
      {
        id: 'customTargets',
        name: 'Custom Targets'
      } as BasicTargetCategory
    ]
    filteredTargets.forEach((target) => {
      if (
        target.targetDefinitionCategory?.id &&
        !addedCategoriesIds.has(target.targetDefinitionCategory.id)
      ) {
        addedCategoriesIds.add(target.targetDefinitionCategory.id)
        newCategories.push(target.targetDefinitionCategory)
      }
    })
    if (newCategories.length > categories.length) {
      setCategories(newCategories)
    }
  }, [filteredTargets])

  React.useEffect(() => {
    void dispatch(
      getTargets({
        companyId: currentCompany.id
      })
    ).then((action) => {
      if (getTargets.fulfilled.match(action)) {
        dispatch(getAvailableNutrients())
        dispatch(getCompanyTags({ companyId: currentCompany.id }))
        dispatch(getAllergens())
      }
    })

    return () => {
      dispatch(resetTargetsState())
    }
  }, [])

  React.useEffect(() => {
    // when a target is selected, show the viewer
    if (activeTarget.id) {
      setShowViewer(true)
    }
  }, [activeTarget])

  React.useEffect(() => {
    // when the viewer is closed and there is an active target clear the active target
    if (!showViewer && activeTarget.id) {
      dispatch(clearActiveTarget())
    }
  }, [showViewer])

  React.useEffect(() => {
    if (deleting) {
      setShowViewer(false)
    }
  }, [deleting])

  const handleNewTarget = () => {
    dispatch(
      createTarget({
        name: 'Untitled Target',
        companyId: currentCompany.id,
        rootOperation: {
          id: Math.random().toString(),
          operationType: OperationType.LOGICAL,
          operator: LogicalOperator.AND,
          operations: []
        } as LogicalOperation
      })
    ).then((action) => {
      if (createTarget.fulfilled.match(action)) {
        dispatch(
          getTarget({
            companyId: currentCompany.id,
            targetId: action.payload.id
          })
        )
        showSuccess('New target created')
      } else {
        showError('Failed to create new target')
      }
    })
  }

  const handleRowClicked = (target: BasicTarget) => {
    if (target.id === activeTarget.id && showViewer) {
      return
    }
    if (isActiveTargetUpdated) {
      showConfirmationModal({
        title: 'Unsaved Changes',
        message: `You have unsaved changes in target "${activeTarget.name}". Are you sure you want to leave?`,
        danger: true,
        yesText: 'Yes',
        noText: 'Cancel',
        onYesClicked: () => {
          dispatch(clearActiveTarget())
          dispatch(
            getTarget({ companyId: currentCompany.id, targetId: target.id })
          )
        }
      })
      return
    }
    dispatch(clearActiveTarget())
    dispatch(getTarget({ companyId: currentCompany.id, targetId: target.id }))
  }

  const handleClickAway = () => {
    if (!showViewer) {
      return
    }
    if (isActiveTargetUpdated) {
      showConfirmationModal({
        title: 'Unsaved Changes',
        message: `You have unsaved changes in target "${activeTarget.name}". Are you sure you want to leave?`,
        danger: true,
        yesText: 'Yes',
        noText: 'Cancel',
        onYesClicked: () => setShowViewer(false)
      })
      return
    }
    setShowViewer(false)
  }

  const handleSearch = (searchInput: string) => {
    void dispatch(filterTargets(searchInput))
  }

  const action = (
    <Button onClick={handleNewTarget} color="primary">
      New Target
    </Button>
  )

  const filters: TableFilter[] = [
    {
      field: TargetsFilterField.CATEGORY,
      paramName: 'Category',
      options: categories.map((category) => {
        return {
          label: category.name,
          value: category.id
        }
      }),
      onUpdateSelectionCallback: (values: string[]) => {
        setActiveFilters((prev) => {
          return {
            ...prev,
            [TargetsFilterField.CATEGORY]: values
          }
        })
        void dispatch(
          getTargets({
            companyId: currentCompany.id,
            categoriesIds: values,
            sortBy: sortField,
            sortOrder
          })
        )
      }
    }
  ]

  const [activeFilters, setActiveFilters] = React.useState<Record<TargetsFilterField, string[]>>(
    filters.reduce((acc, filter) => {
      acc[filter.field as TargetsFilterField] = []
      return acc
    }, {} as Record<TargetsFilterField, string[]>)
  )

  return (
    <PageEntrTableContainer>
      <TargetViewer show={showViewer} setShow={setShowViewer} />
      <PageEntrTableInnerContainer>
        <EntrTableContainer onClick={handleClickAway}>
          <TitleActionsBar title={'Targets'} action={action} />
          <EntrTableFilters
            searchPlaceholder="Search all targets"
            searchable
            filters={filters}
            searchParamName="search"
            onUpdateSearchCallback={(searchInput) => handleSearch(searchInput)}
          />
          <EntrTable
            data={filteredTargets}
            columnConfigs={columnConfigs}
            onRowClick={handleRowClicked}
            allPagesLoaded={true}
            onLoadNextPage={() => { }}
            sx={{ width: '100%', marginBottom: '20px' }}
          />
        </EntrTableContainer>
      </PageEntrTableInnerContainer>
    </PageEntrTableContainer>
  )
}
