import styled from 'styled-components'
import { pointToPixel } from '../helpers'

export const NutritionLabelBox = styled.div`
  background-color: transparent;
  padding: ${pointToPixel(3)};
  border: ${pointToPixel(0.25)} solid black;
`

export const Container = styled.div``

export const ServingContainer = styled.div``

export const FlexContainerRowSpaced = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`

export const FlexContainerRowSpacedTop = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: top;
`

export const FlexContainerItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
`

export const FlexContainerColumnSpaced = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexContainerRow = styled.div`
  display: flex;
  flex-direction: row;
`

export const FlexContainerRowCentered = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FlexContainerColumnCentered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
