import React from 'react'
import { EditorSection } from '../../../common/EditorSection'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import {
  ActiveIngredientRow,
  ActiveIngredientRowProps,
  ActiveIngredientRowSkeleton
} from './components/ActiveIngredientRow'

export interface ActiveIngredientsProps {
  activeIngredientsTableRows?: ActiveIngredientRowProps[]
  loading?: boolean
}

const ActiveIngredientsSkeleton: React.FC = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <ActiveIngredientRowSkeleton key={`ai-skeleton-${index}`} />
      ))}
    </>
  )
}

export const ActiveIngredients: React.FC<ActiveIngredientsProps> = ({
  activeIngredientsTableRows = [],
  loading
}) => {
  const tableContainerRef = React.useRef<HTMLDivElement>(null)
  return (
    <EditorSection title="Active Ingredients" lastSection={true}>
      <Box
        ref={tableContainerRef}
        sx={{ overflowX: 'auto', height: '100%', width: '100%' }}
      >
        {loading ? (
          <ActiveIngredientsSkeleton />
        ) : activeIngredientsTableRows.length > 0 ? (
          <TableContainer sx={{ padding: '8px 0px', width: '100%' }}>
            <Table style={{ tableLayout: 'fixed' }}>
              <colgroup>
                <col style={{ minWidth: '200px' }} />
                <col style={{ width: '150px' }} />
                <col style={{ minWidth: '300px' }} />
                <col style={{ minWidth: '300px' }} />
              </colgroup>
              <TableHead>
                <TableRow key="active-ingredients-table-header">
                  <TableCell
                    align="left"
                    sx={{
                      position: 'sticky',
                      left: 0,
                      zIndex: 2,
                      background:
                        'linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(255,255,255,0) 100%)'
                    }}
                  >
                    <Typography color="secondary" variant="body1">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      Amount
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      Name
                    </Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography color="secondary" variant="body1">
                      Amount
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activeIngredientsTableRows.map((ai, index) => (
                  <React.Fragment key={`ai-frag-${index}`}>
                    <ActiveIngredientRow
                      key={`ai-${index}`}
                      activeIngredient={ai.activeIngredient}
                      overrides={ai.overrides}
                    />
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="subtitle2">
            <i>No active ingredients visible to override.</i>
          </Typography>
        )}
      </Box>
    </EditorSection>
  )
}
