import React from 'react'
import { PreviewPin } from './Pin'
import { Pin } from './Pin'
import { ActivePin } from 'state/labelproof/proofer/ProoferPinsSlice'

interface PinsProps {
  pins: PreviewPin[]
  scale: number
  repositionPin: (x: number, y: number, id: string) => void
  onClick: (pin: ActivePin) => void
}

export const Pins: React.FC<PinsProps> = ({ 
  pins,
   scale,
    repositionPin ,
    onClick
  }) => {
  return (
    <>
      {pins.map((pin) => (
        <Pin
          key={pin.id}
          id={pin.id}
          x={pin.x}
          y={pin.y}
          hidden={pin.hidden}
          draggable={pin.draggable}
          highlighted={pin.highlighted}
          user={pin.user}
          scale={scale}
          repositionPin={repositionPin}
          onClick={onClick}
        />
      ))}
    </>
  )
}
