import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { FormulasPaginated, FormulasSortingField } from 'models/Formula'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { fromFormulaStatus } from 'services/apis/formula/FormulaApiMapper'
import { CreatingState, LoadingState } from '../CommonState'
import { CreateFormulaRequest, GetFormulasRequest } from './FormulasRequest'
import { SortOrder } from 'components/common'

interface FormulasState extends LoadingState, CreatingState {
  formulas: FormulasPaginated
}

const initialState: FormulasState = {
  formulas: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 1,
    sortField: FormulasSortingField.LAST_ACCESSED,
    sortOrder: SortOrder.DESC
  },
  loading: false,
  creating: false,
  error: false
}

export const getFormulas = createAsyncThunk(
  'formulas/all/get',
  async ({
    companyId,
    page = 1,
    size = 50,
    fuzzyName,
    createdBy,
    status,
    withArchived,
    sortBy,
    sortOrder
  }: GetFormulasRequest) => {
    return await FormulaApi.getFormulas(companyId, {
      page: page,
      size: size,
      simpleFormulaFuzzyName: fuzzyName,
      createdBy: createdBy,
      status: status?.map((s) => fromFormulaStatus(s)),
      withArchive: withArchived,
      sortBy,
      sortOrder
    })
  }
)

export const createFormula = createAsyncThunk(
  'formulas/create',
  async ({ companyId }: CreateFormulaRequest) => {
    return await FormulaApi.createFormula(companyId, {})
  }
)

const formulasSlice = createSlice({
  name: 'formulasSlice',
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.formulas.page = action.payload
    },
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    builder.addCase(getFormulas.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getFormulas.fulfilled,
      (state, action: PayloadAction<FormulasPaginated>) => {
        if (action.payload.page === 1) {
          state.formulas = {
            ...action.payload
          }
        } else {
          state.formulas = {
            ...action.payload,
            items: action.payload.items.reduce((acc, currentItem) => {
              // Check if the item already exists in the current state
              const existingItem = state.formulas.items.find(
                (item) => item.id === currentItem.id
              )

              // If the item already exists, replace it; otherwise, add it to the accumulator
              if (existingItem) {
                return acc.map((item) =>
                  item.id === currentItem.id ? currentItem : item
                )
              } else {
                return [...acc, currentItem]
              }
            }, state.formulas.items)
          }
        }
        state.loading = false
      }
    )
    builder.addCase(getFormulas.rejected, (state) => {
      state.formulas = initialState.formulas
      state.loading = false
      state.error = true
    })

    builder.addCase(createFormula.pending, (state) => {
      state.creating = true
      state.error = false
    })
    builder.addCase(createFormula.fulfilled, (state) => {
      state.creating = false
    })
    builder.addCase(createFormula.rejected, (state) => {
      state.creating = false
      state.error = true
    })
  }
})

export const { setCurrentPage, resetState } = formulasSlice.actions
export default formulasSlice.reducer
