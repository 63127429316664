import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  DetailedSupplier,
  SimpleSupplier,
  DetailedSupplierPaginated,
  SuppliersSortField
} from 'models/Supplier'
import { SupplierApi } from 'services/apis/supplier/SupplierApi'
import {
  CreateSupplierRequest,
  DeleteSupplierRequest,
  GetSupplierAllergensRequest,
  GetSuppliersRequest,
  AddSupplierAllergenRequest,
  UpdateSupplierRequest,
  AddSupplierTagRequest
} from './SupplierRequest'
import {
  AddingState,
  CreatingState,
  DeletingState,
  LoadingState,
  UpdatingState
} from '../CommonState'
import { SupplierAllergen } from 'models/Allergen'
import { SupplierTag } from 'models/Tags'
import { SortOrder } from 'components/common'

interface SuppliersState
  extends LoadingState,
  CreatingState,
  UpdatingState,
  DeletingState,
  AddingState {
  suppliers: DetailedSupplierPaginated
}

const initialState: SuppliersState = {
  suppliers: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 1,
    sortField: SuppliersSortField.LAST_ACCESSED,
    sortOrder: SortOrder.DESC
  },
  loading: false,
  creating: false,
  deleting: false,
  updating: false,
  adding: false,
  error: false
}

export const getSuppliers = createAsyncThunk(
  'suppliers/all/get',
  async ({
    companyId,
    page = 1,
    size = 50,
    fuzzyName,
    tags,
    sortBy,
    sortOrder,
    withPublicSuppliers = false
  }: GetSuppliersRequest) => {
    return await SupplierApi.getSuppliers(companyId, {
      page,
      size,
      simpleSupplierFuzzyName: fuzzyName,
      simpleSupplierTags: tags,
      withPublicSuppliers: withPublicSuppliers,
      sortBy,
      sortOrder,
    })
  }
)

export const createSupplier = createAsyncThunk(
  'suppliers/create',
  async ({ companyId, name, description }: CreateSupplierRequest) => {
    return await SupplierApi.createSupplier(companyId, { name, description })
  }
)

export const deleteSupplier = createAsyncThunk(
  'suppliers/delete',
  async ({ companyId, supplierId }: DeleteSupplierRequest) => {
    return await SupplierApi.deleteSupplier(companyId, supplierId)
  }
)

export const updateSupplier = createAsyncThunk(
  'suppliers/update',
  async ({
    companyId,
    supplierId,
    name,
    verified,
    description
  }: UpdateSupplierRequest) => {
    return await SupplierApi.updateSupplier(companyId, supplierId, {
      name,
      verified,
      description
    })
  }
)

export const addSupplierTag = createAsyncThunk(
  'suppliers/tags/add',
  async ({ companyId, supplierId, name }: AddSupplierTagRequest) => {
    return await SupplierApi.addTag(companyId, supplierId, name)
  }
)

export const addSupplierAllergen = createAsyncThunk(
  'suppliers/allergens/add',
  async ({
    companyId,
    supplierId,
    allergenType
  }: AddSupplierAllergenRequest) => {
    return await SupplierApi.addAllergen(companyId, supplierId, allergenType)
  }
)

export const getSupplierAllergens = createAsyncThunk(
  'suppliers/allergens/get',
  async ({ companyId, supplierId }: GetSupplierAllergensRequest) => {
    return await SupplierApi.getAllergens(companyId, supplierId)
  }
)

export const suppliersSlice = createSlice({
  name: 'suppliersSlice',
  initialState,
  reducers: {},
  extraReducers(builder) {
    // get suppliers
    builder.addCase(getSuppliers.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getSuppliers.fulfilled,
      (state, action: PayloadAction<DetailedSupplierPaginated>) => {
        if (action.payload.page === 1) {
          state.suppliers = {
            ...action.payload
          }
        } else {
          const arr = action.payload.page > 1 ? state.suppliers.items : []
          const all = [...arr, ...action.payload.items]
          const ids = [...new Set([...all.map((o) => o.id)])]
          const updatedItems = ids.map(
            (id) => all.reverse().find((o) => o.id === id) as DetailedSupplier
          )
          state.suppliers = {
            ...action.payload,
            items: updatedItems
          }
          state.loading = false
        }
      }
    )
    builder.addCase(getSuppliers.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    // create supplier
    builder.addCase(createSupplier.pending, (state) => {
      state.creating = true
      state.error = false
    })
    builder.addCase(
      createSupplier.fulfilled,
      (state, action: PayloadAction<SimpleSupplier>) => {
        state.suppliers.items = [
          ...state.suppliers.items,
          { ...action.payload, tags: [], totalFormulas: 0, totalIngredients: 0 }
        ]
        state.creating = false
      }
    )
    builder.addCase(createSupplier.rejected, (state) => {
      state.creating = false
      state.error = true
    })
    // delete supplier
    builder.addCase(deleteSupplier.pending, (state) => {
      state.deleting = true
      state.error = false
    })
    builder.addCase(
      deleteSupplier.fulfilled,
      (state, action: PayloadAction<string>) => {
        state.suppliers.items = state.suppliers.items.filter(
          (s) => s.id !== action.payload
        )
        state.deleting = false
      }
    )
    builder.addCase(deleteSupplier.rejected, (state) => {
      state.deleting = false
      state.error = true
    })
    // update supplier
    builder.addCase(updateSupplier.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateSupplier.fulfilled,
      (state, action: PayloadAction<SimpleSupplier>) => {
        const suppliers = state.suppliers.items.map((s) => {
          if (s.id === action.payload.id) {
            return { ...s, ...action.payload }
          } else {
            return s
          }
        })
        state.suppliers.items = suppliers
        state.updating = false
      }
    )
    builder.addCase(updateSupplier.rejected, (state) => {
      state.updating = false
      state.error = true
    })
    // add tag
    builder.addCase(addSupplierTag.pending, (state) => {
      state.adding = true
      state.error = false
    })
    builder.addCase(
      addSupplierTag.fulfilled,
      (state, action: PayloadAction<SupplierTag>) => {
        state.suppliers.items = state.suppliers.items.map((s) => {
          if (s.id === action.payload.supplier.id) {
            const supplier = s
            supplier.tags = [...supplier.tags, action.payload.tag]
            return supplier
          } else {
            return s
          }
        })
        state.adding = false
      }
    )
    builder.addCase(addSupplierTag.rejected, (state) => {
      state.adding = false
      state.error = true
    })
    // add allergen
    builder.addCase(addSupplierAllergen.pending, (state) => {
      state.adding = true
      state.error = false
    })
    builder.addCase(
      addSupplierAllergen.fulfilled,
      (state, action: PayloadAction<SupplierAllergen>) => {
        state.suppliers.items = state.suppliers.items.map((s) => {
          if (s.id === action.payload.supplier.id) {
            const supplier: DetailedSupplier = s
            supplier.allergens = supplier.allergens
              ? [...supplier.allergens, action.payload]
              : [action.payload]
            return supplier
          } else {
            return s
          }
        })
        state.adding = false
      }
    )
    builder.addCase(addSupplierAllergen.rejected, (state) => {
      state.adding = false
      state.error = true
    })
    // get allergens
    builder.addCase(getSupplierAllergens.pending, (state) => {
      state.error = false
    })
    builder.addCase(
      getSupplierAllergens.fulfilled,
      (state, action: PayloadAction<SupplierAllergen[]>) => {
        state.suppliers.items = state.suppliers.items.map((s) => {
          if (action.payload.length && s.id === action.payload[0].supplier.id) {
            const supplier: DetailedSupplier = s
            supplier.allergens = [...action.payload]
            return supplier
          } else {
            return s
          }
        })
      }
    )
    builder.addCase(getSupplierAllergens.rejected, (state) => {
      state.error = true
    })
  }
})

export default suppliersSlice.reducer
