import React from 'react'
import { FormulatorIngredients } from './FormulatorIngredients'
import { FormulaDetailsContainer } from './components/FormulaDetails/FormulaDetailsContainer'
import { IngredientsTableContainer } from './components/Ingredients/IngredientsTableContainer'
import { StatusBarContainer } from './components/StatusBar/StatusBarContainer'
import { TotalsBarContainer } from './components/TotalsBar/TotalsBarContainer'
import { YieldAdjustmentsContainer } from './components/YieldAdjustments/YieldAdjustmentsContainer'

export const FormulatorIngredientsContainer: React.FC = () => {
  // How much of the formula ingredient container is visilbe without scrolling.
  const [visibleWidth, setVisibleWidth] = React.useState<string>('100%')

  return (
    <FormulatorIngredients
      statusBar={<StatusBarContainer />}
      formulaDetails={<FormulaDetailsContainer />}
      ingredientsTable={
        <IngredientsTableContainer visibileWidth={visibleWidth} />
      }
      totalsBar={<TotalsBarContainer />}
      yieldAdjustments={
        <YieldAdjustmentsContainer visibleWidth={visibleWidth} />
      }
      setVisibleWidth={setVisibleWidth}
    />
  )
}
