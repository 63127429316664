import { Option, ViewerRow } from 'components/common'
import { Select } from 'components/Select/Select'
import React from 'react'

import {
  Autocomplete,
  AutocompleteProps
} from 'components/Autocomplete/Autocomplete'
import { TextField } from 'components/InputFields/TextField'
import { Botanicals } from './components/Botanicals/Botanicals'
import {
  IngredientCost,
  IngredientCostProps
} from './components/IngredientCost/IngredientCost'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { infoMessages } from 'common/constants'
import { NumberField } from 'components/InputFields/NumberField'
import { validateRange } from 'components/InputFields/validation'

interface IngredientFormProps {
  disabled?: boolean
  ingredient?: Ingredient
  onChange: (field: Field, value: string | number | boolean | null) => void
  costsProps: Omit<IngredientCostProps, 'disabled'>
  suppliers: Omit<AutocompleteProps, 'label' | 'labelIcon'>
  allergens: Omit<AutocompleteProps, 'label' | 'labelIcon'>
  tags: Omit<AutocompleteProps, 'label' | 'labelIcon'>
}

export interface Ingredient {
  id?: string
  name?: string
  ingredientStatement?: string
  verified?: boolean
  friendlyId?: string
  manufacturer?: string
  description?: string
  isSugar?: boolean
  suppliers?: Option<boolean>[]
  assay?: string
  botanicalName?: string
  source?: string
  proteinDigestibility?: number
}

export const enum Field {
  name = 'name',
  statement = 'statement',
  verified = 'verified',
  friendlyId = 'friendlyId',
  manufacturer = 'manufacturer',
  description = 'description',
  isSugar = 'isSugar',
  botanicalName = 'botanicalName',
  assay = 'assay',
  source = 'source',
  proteinDigestibility = 'proteinDigestibility',
}

export const IngredientForm: React.FC<IngredientFormProps> = ({
  disabled = false,
  ingredient,
  onChange,
  costsProps,
  suppliers,
  allergens,
  tags
}) => {
  const DEBOUNCE_TIME = 1000

  const botanicalInfoExpanded = React.useMemo(() => {
    return (
      !!ingredient?.botanicalName || !!ingredient?.source || !!ingredient?.assay
    )
  }, [ingredient?.id])

  return (
    <>
      <TextField
        label="Name"
        name="name"
        value={ingredient?.name || ''}
        placeholder="Ingredient Name"
        scale="large"
        onChange={(v) => onChange(Field.name, v)}
        disabled={disabled}
        debounceTime={DEBOUNCE_TIME}
      />

      <ViewerRow sx={{ '&&': { alignItems: 'start' } }}>
        <TextField
          label="Friendly ID"
          name="friendlyId"
          value={ingredient?.friendlyId || ''}
          onChange={(v) => onChange(Field.friendlyId, v)}
          disabled={disabled}
          debounceTime={DEBOUNCE_TIME}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.ingredients.FRIENDLY_ID}
                />
              }
            />
          }
        />
        <Select
          label="Status"
          name="verified"
          options={[
            { value: 1, label: 'Approved' },
            { value: 0, label: 'Not Approved' }
          ]}
          value={ingredient?.verified ? 1 : 0}
          onChange={(v) => onChange(Field.verified, v)}
          disabled={disabled}
        />
      </ViewerRow>

      <TextField
        label="Ingredient Statement Name"
        name="statement"
        rows={3}
        value={ingredient?.ingredientStatement || ''}
        onChange={(v) => onChange(Field.statement, v)}
        disabled={disabled}
        debounceTime={DEBOUNCE_TIME}
        labelIcon={
          <InfoBubble
            text={
              <MarkdownRenderer
                markdownText={
                  infoMessages.ingredients.INGREDIENT_STATEMENT_NAME
                }
              />
            }
          />
        }
      />

      <ViewerRow sx={{ '&&': { alignItems: 'start', gridTemplateColumns: 'repeat(3, 1fr)' } }}>
        <TextField
          label="Manufacturer"
          name="manufacturer"
          value={ingredient?.manufacturer || ''}
          onChange={(v) => onChange(Field.manufacturer, v)}
          disabled={disabled}
          debounceTime={DEBOUNCE_TIME}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.ingredients.MANUFACTURER}
                />
              }
            />
          }
        />

        <Select
          label="Considered Added Sugar?"
          name="isSugar"
          options={[
            { value: 1, label: 'Yes' },
            { value: 0, label: 'No' }
          ]}
          value={ingredient?.isSugar ? 1 : 0}
          onChange={(v) => onChange(Field.isSugar, v)}
          disabled={disabled}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.ingredients.ADDED_SUGAR}
                />
              }
            />
          }
        />

        <NumberField
          label="Protein Digestibility"
          name="proteinDigestibility"
          value={ingredient?.proteinDigestibility}
          onChange={(v) => onChange(Field.proteinDigestibility, v)}
          allowEmptyValue={true}
          disabled={disabled}
          debounceTime={DEBOUNCE_TIME}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.ingredients.PROTEIN_DIGESTIBILITY}
                />
              }
            />
          }
          maxDecimals={6}
          validation='>=0'
          additionalValidators={[(value) => validateRange(value, 0, 1)]}
        />
      </ViewerRow>
      <TextField
        label="Description"
        name="description"
        value={ingredient?.description || ''}
        onChange={(v) => onChange(Field.description, v)}
        multiline
        rows={5}
        disabled={disabled}
        debounceTime={DEBOUNCE_TIME}
      />

      <Autocomplete
        label="Suppliers"
        {...suppliers}
        maxTagsDisplay={3}
        disabled={disabled}
      />
      <Autocomplete
        label="Allergens"
        {...allergens}
        maxTagsDisplay={3}
        disabled={disabled}
      />
      <Autocomplete
        label="Tags"
        {...tags}
        freeSolo
        maxTagsDisplay={3}
        disabled={disabled}
      />

      <Botanicals
        assay={{
          value: ingredient?.assay,
          onChange: (v) => onChange(Field.assay, v),
          disabled: disabled
        }}
        botanicalName={{
          value: ingredient?.botanicalName,
          onChange: (v) => onChange(Field.botanicalName, v),
          disabled: disabled
        }}
        source={{
          value: ingredient?.source,
          onChange: (v) => onChange(Field.source, v),
          disabled: disabled
        }}
        debounceTime={DEBOUNCE_TIME}
        expanded={botanicalInfoExpanded}
      />

      <IngredientCost disabled={disabled} {...costsProps} />
    </>
  )
}
