import { PayloadAction } from '@reduxjs/toolkit'
import { TargetsState, initialState } from './TargetsSlice'
import {
  NormalizedOperation,
  NormalizedOperations,
  NormalizedTarget
} from 'models/Target'
import {
  addOperationToTargetRequest,
  deleteOperationFromTargetRequest
} from './TargetRequest'
import { isNormalizedLogicalOperation } from 'services/apis/target/TargetApiMapper'
import { createDefaultOperation } from './helpers'
import isEqual from 'lodash.isequal'
import { RegulationApiResponse } from 'services/apis/regulation/RegulationApiResponse'

export const setActiveTargetReducer = (
  state: TargetsState,
  action: PayloadAction<NormalizedTarget>
) => {
  state.activeTarget = action.payload
  const operationsArr = Object.entries(state.normalizedOperations)
  const activeOperationsArr = operationsArr.filter(
    ([_, operation]) => operation.parentTargetId === action.payload.id
  )
  state.activeOperations = Object.fromEntries(activeOperationsArr)
  state.isActiveTargetUpdated = false
}

export const clearActiveTargetReducer = (state: TargetsState) => {
  state.activeTarget = {} as NormalizedTarget
  state.activeOperations = {} as NormalizedOperations
  state.normalizedTarget = {} as NormalizedTarget
  state.normalizedOperations = {} as NormalizedOperations
  state.isActiveTargetUpdated = false
}

export const updateActiveTargetNameReducer = (
  state: TargetsState,
  action: PayloadAction<string>
) => {
  state.activeTarget = {
    ...state.activeTarget,
    name: action.payload
  }
  state.isActiveTargetUpdated =
    state.activeTarget.name !== state.normalizedTarget.name
}

export const updateActiveTargetRegulationReducer = (
  state: TargetsState,
  action: PayloadAction<RegulationApiResponse>
) => {
  state.activeTarget = {
    ...state.activeTarget,
    regulation: action.payload
  }
  state.isActiveTargetUpdated =
    state.activeTarget.regulation?.id !== state.normalizedTarget.regulation?.id
}

export const deleteActiveOperationReducer = (
  state: TargetsState,
  action: PayloadAction<deleteOperationFromTargetRequest>
) => {
  const parentOperation =
    state.activeOperations[action.payload.parentOperationId]
  if (isNormalizedLogicalOperation(parentOperation)) {
    parentOperation.childOperationsIds =
      parentOperation.childOperationsIds.filter(
        (operationId) => operationId !== action.payload.operationId
      )
    if (
      !parentOperation.childOperationsIds.includes(action.payload.operationId)
    ) {
      const { [action.payload.operationId]: _, ...updated } =
        state.activeOperations
      state.activeOperations = { ...updated }
      state.isActiveTargetUpdated = !isEqual(
        state.activeOperations,
        state.normalizedOperations
      )
    }
  }
}

export const addActiveOperationReducer = (
  state: TargetsState,
  action: PayloadAction<addOperationToTargetRequest>
) => {
  const newOperation = createDefaultOperation(
    action.payload.operationType,
    action.payload.operationId,
    state.activeTarget.id,
    action.payload.level
  )

  const parentOperation = state.activeOperations[action.payload.operationId]
  if (isNormalizedLogicalOperation(parentOperation)) {
    parentOperation.childOperationsIds = [
      ...parentOperation.childOperationsIds,
      newOperation.id
    ]
    if (parentOperation.childOperationsIds.includes(newOperation.id)) {
      state.activeOperations[newOperation.id] = { ...newOperation }
      state.isActiveTargetUpdated = !isEqual(
        state.activeOperations,
        state.normalizedOperations
      )
    }
  }
}

export const updateActiveOperationReducer = (
  state: TargetsState,
  action: PayloadAction<NormalizedOperation>
) => {
  state.activeOperations[action.payload.id] = action.payload
  state.isActiveTargetUpdated = !isEqual(
    state.activeOperations,
    state.normalizedOperations
  )
}

export const resetTargetsStateReducer = (state: TargetsState) => {
  return initialState
}

export const filterTargetsReducer = (
  state: TargetsState,
  action: PayloadAction<string>
) => {
  state.filteredTargets.items = state.targets.items.filter((target) =>
    target.name.toLowerCase().includes(action.payload.toLowerCase())
  )
}
