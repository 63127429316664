import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import React from 'react'
import {
  ProofComment,
  ProofCommentProps
} from '../../../../../../Panel/tabs/Activities/components/ProofComment/ProofComment'
import {
  ProofCommentForm,
  ProofCommentFormProps
} from '../../../../../../Panel/tabs/Activities/components/ProofCommentForm/ProofCommentForm'

interface PinThreadProps {
  comments: ProofCommentProps[]
  formProps: ProofCommentFormProps
}

export const PinThread: React.FC<PinThreadProps> = ({
  comments,
  formProps
}) => {
  const commentsContainerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (commentsContainerRef.current) {
      commentsContainerRef.current.scrollTop =
        commentsContainerRef.current.scrollHeight
    }
  }, [comments])

  return (
    <Box>
      <BoxColumnBasic
        ref={commentsContainerRef}
        sx={{ overflowY: 'auto', maxHeight: '250px' }}
      >
        {comments.map((comment, index) => (
          <ProofComment key={`proof-comment-${index}`} {...comment} />
        ))}
      </BoxColumnBasic>
      <ProofCommentForm {...formProps} />
    </Box>
  )
}
