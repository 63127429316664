import {
  LabelProofActivityApiResponse,
  LabelProofPinCommentApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import {
  Activity,
  isComment,
  isLog
} from 'state/labelproof/proofer/ProoferActivitiesSlice'
import {
  CreateProofPinCommentRequestData,
  UpdateProofPinCommentRequestData
} from 'state/labelproof/proofer/ProoferRequest'
import { ProofCommentProps } from './components/ProofComment/ProofComment'
import { ProofLogProps } from './components/ProofLog/ProofLog'
import { addPinToText, getMentionedUsersMap } from './helpers'
import { StagedPinComment, StagedPinCommentReplyTo } from 'state/labelproof/proofer/ProoferPinsSlice'

export const mapCommentReplyToStagedCommentReply = (
  replyTo?: LabelProofPinCommentApiResponse
): StagedPinCommentReplyTo | undefined => {
  if (!replyTo) {
    return undefined
  }
  return {
    id: replyTo.id,
    comment: replyTo.comment,
    mentionsMap: getMentionedUsersMap(replyTo.mentions),
    pinId: replyTo.pinId
  }
}

export const mapCommentToStagedComment = (
  comment: LabelProofPinCommentApiResponse
): StagedPinComment => {
  return {
    id: comment.id,
    comment: comment.comment,
    replyTo: mapCommentReplyToStagedCommentReply(comment.replyTo),
    pinId: comment.pinId,
    mentionedUserIds: comment.mentions.map(
      (mention) => mention.mentionedUser.id
    )
  }
}

export const mapStagedCommentToCreateProofCommentRequestData = (
  stagedComment: StagedPinComment
): CreateProofPinCommentRequestData => {
  return {
    comment: stagedComment.comment,
    reply_to: stagedComment.replyTo?.id,
    mentionedUserIds: stagedComment.mentionedUserIds,
    pinId: stagedComment.pinId
  }
}

export const mapStagedCommentToUpdateProofCommentRequestData = (
  stagedComment: StagedPinComment
): UpdateProofPinCommentRequestData => {
  return {
    comment: stagedComment.comment,
    mentionedUserIds: stagedComment.mentionedUserIds
  }
}

interface MapCommentToPropsArgs {
  comment: LabelProofPinCommentApiResponse
  currentUserId: string
  onDeleteClick?: (id: string) => void
  onEditClick?: (id: string) => void
  onReplyClick?: ((id: string) => void) | null
  onPinClick?: (id: string) => void
  withPins?: boolean
}
export const mapCommentToProps = ({
  comment,
  currentUserId,
  onDeleteClick,
  onEditClick,
  onReplyClick,
  onPinClick,
  withPins = true
}: MapCommentToPropsArgs): ProofCommentProps => {
  return {
    id: comment.id,
    text:
      withPins && !comment.isDeleted
        ? addPinToText(comment.comment, comment.pinId)
        : comment.comment,
    createdBy: comment.createdBy && {
      name: comment.createdBy.firstName + ' ' + comment.createdBy.lastName,
      isOwner: comment.createdBy.id === currentUserId,
      avatarSrc: comment.createdBy.imagePath
    },
    repliedComment: comment.replyTo && {
      id: comment.replyTo.id,
      text:
        withPins && !comment.isDeleted
          ? addPinToText(comment.replyTo.comment, comment.replyTo.pinId)
          : comment.replyTo.comment,
      createdByName:
        comment.replyTo.createdBy &&
        comment.replyTo.createdBy.firstName +
          ' ' +
          comment.replyTo.createdBy.lastName,
      deleted: comment.replyTo.isDeleted
    },
    timestamp: comment.createdAt,
    edited: !!comment.lastEditedAt,
    deleted: comment.isDeleted,
    editable: comment.permissions.editComment,
    deletable: comment.permissions.deleteComment,
    mentionedUsersMap: getMentionedUsersMap([
      ...comment.mentions,
      ...(comment.replyTo?.mentions || [])
    ]),
    onPinClick,
    onDeleteClick,
    onEditClick,
    onReplyClick
  }
}

export const mapLogToProps = (
  log: LabelProofActivityApiResponse
): ProofLogProps => {
  return {
    occurredAt: log.occurredAt,
    user: log.linkedUser && {
      name: log.linkedUser.firstName + ' ' + log.linkedUser.lastName,
      avatarSrc: log.linkedUser.imagePath
    },
    details: log.details
  }
}

interface mapActivitiesToCommentsAndLogsPropsArgs {
  activities: Activity[]
  currentUserId: string
  onPinClick: (id: string) => void
  config?: {
    filterOutComments: boolean
    filterOutLogs: boolean
  }
  onDeleteClick?: (id: string) => void
  onEditClick?: (id: string) => void
  onReplyClick?: ((id: string) => void) | null
}
export const mapActivitiesToCommentsAndLogsProps = ({
  activities,
  currentUserId,
  onPinClick,
  config = {
    filterOutComments: false,
    filterOutLogs: false
  },
  onDeleteClick,
  onEditClick,
  onReplyClick
}: mapActivitiesToCommentsAndLogsPropsArgs): (
  | ProofCommentProps
  | ProofLogProps
)[] => {
  return activities
    .filter((activity) =>
      config.filterOutComments ? !isComment(activity) : true
    )
    .filter((activity) => (config.filterOutLogs ? !isLog(activity) : true))
    .map((activity) =>
      isComment(activity)
        ? mapCommentToProps({
            comment: activity,
            currentUserId,
            onDeleteClick,
            onEditClick,
            onReplyClick,
            onPinClick
          })
        : mapLogToProps(activity)
    )
}
