import EventIcon from '@mui/icons-material/Event'
import { Box, Popover, Typography } from '@mui/material'
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { displayDate } from 'common/utils'
import { Button } from 'components/Button/Button'
import { BoxColumnBasic, BoxRow } from 'components/common'
import dayjs from 'dayjs'
import React from 'react'
import { ButtonTextColors, MainColors } from 'styles/colors'

export interface DateProps {
  date: string | null
  onChange: (date: string | null) => void
  disabled?: boolean
  due?: boolean
  completed?: boolean
  type?: 'text' | 'button'
}

export const Date: React.FC<DateProps> = ({
  date,
  onChange,
  disabled,
  due = false,
  completed = false,
  type = 'text'
}) => {
  const pastDue = React.useMemo(() => {
    return dayjs().startOf('day').diff(dayjs(date).startOf('day'), 'days') > 0
  }, [date])

  const color = React.useMemo(() => {
    if (completed) {
      return MainColors.success
    } else if (pastDue) {
      return MainColors.danger
    } else if (type === 'text' && !date) {
      return '#233C586E'
    } else {
      return type === 'button' ? ButtonTextColors.secondary : 'inherit'
    }
  }, [completed, pastDue, due, date, type])

  const displayedDate = React.useMemo(() => {
    if (date) {
      return `${due ? 'Due ' : ''} ${displayDate(date)}`
    }
    return due ? 'Due Date' : 'Date'
  }, [due, date])

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'date-picker-popover' : undefined

  const buttonDate = (
    <Button
      color="secondary"
      onClick={handleClick}
      size="small"
      disabled={disabled}
      sx={{
        color: color,
        '&:hover': {
          color: color
        }
      }}
    >
      <EventIcon sx={{ height: '15px', width: 'auto', marginRight: '2px' }} />
      {displayedDate}
    </Button>
  )

  const textDate = (
    <BoxRow sx={{ gap: '2px' }}>
      <EventIcon sx={{ height: '15px', width: 'auto', color: color }} />

      <Typography
        onClick={(event) => {
          !disabled && handleClick(event)
        }}
        sx={{
          fontStyle: date ? 'italic' : 'normal',
          color: color,
          cursor: disabled ? 'default' : 'pointer'
        }}
      >
        {date ? displayedDate : 'add due date'}
      </Typography>
    </BoxRow>
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ padding: '1px', width: 'fit-content' }}>
        {type === 'text' ? textDate : buttonDate}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Box sx={{ p: 2 }}>
            <DateCalendar
              value={date ? dayjs(date) : null}
              onChange={(newValue: dayjs.Dayjs | null) => {
                if (newValue) {
                  onChange(newValue?.format('YYYY-MM-DD') || '')
                  handleClose()
                }
              }}
            />
            <Button
              variant="text"
              color="danger"
              onClick={() => {
                onChange(null)
                handleClose()
              }}
            >
              Clear
            </Button>
          </Box>
        </Popover>
      </Box>
    </LocalizationProvider>
  )
}
