import { ClearRounded } from '@mui/icons-material'
import { Input, TableCell, TableRow } from '@mui/material'
import { infoMessages } from 'common/constants'
import { Button } from 'components/Button/Button'
import { BoxRowBasic, Option } from 'components/common'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { Select } from 'components/Select/Select'
import React from 'react'
import { YieldAdjustmentType } from 'services/apis/formula/FormulaApiResponse'
import { YieldQuantityEditor } from './components/YieldQuantityEditor'

export interface YieldAdjustmentProps {
  adjustment: {
    id: string
    name: string
  }
  adjustmentTypeOptions: Option<boolean>[]
  adjustmentPercentage: number
  adjustmentAmount: number
  onAdjustmentTypeChange: (adjustmentType: YieldAdjustmentType) => void
  onPercentageChange: (percentage: number) => void
  onAmountChange: (amount: number) => void
  onRemove: () => void
}

export const YieldAdjustment: React.FC<YieldAdjustmentProps> = ({
  adjustment,
  adjustmentTypeOptions,
  adjustmentPercentage,
  adjustmentAmount,
  onAdjustmentTypeChange,
  onPercentageChange,
  onAmountChange,
  onRemove
}) => {
  return (
    <TableRow key={adjustment.id}>
      <TableCell>
        <Input
          fullWidth
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontFamily: 'Inter',
            fontSize: 16,
            '& .MuiInputBase-input': {
              padding: '0px'
            },
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000'
            }
          }}
          startAdornment={
            <BoxRowBasic
              sx={{
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '5px',
                color: '#000000'
              }}
            >
              {adjustment.name}
              <InfoBubble
                text={
                  <MarkdownRenderer
                    markdownText={
                      infoMessages.formula.yieldAdjustments.MOISTURE
                    }
                  />
                }
              />
            </BoxRowBasic>
          }
          disabled
        />
      </TableCell>
      <TableCell>
        <Select
          value={adjustmentTypeOptions.find((a) => a.value)?.id || ''}
          onChange={(v) => onAdjustmentTypeChange(v as YieldAdjustmentType)}
          options={adjustmentTypeOptions.map((option) => ({
            value: option.id,
            label: option.label
          }))}
        ></Select>
      </TableCell>

      <TableCell>
        <YieldQuantityEditor
          amount={{
            value: adjustmentAmount,
            onUpdate: onAmountChange
          }}
          percentage={{
            value: adjustmentPercentage,
            onUpdate: onPercentageChange
          }}
        />
      </TableCell>

      <TableCell>
        <Button
          variant="circle"
          size="small"
          color="tertiary"
          onClick={onRemove}
        >
          <ClearRounded sx={{ width: '15px', height: '15px' }} />
        </Button>
      </TableCell>
    </TableRow>
  )
}
