import { ListCell } from 'components/Cells/ListCell'
import { NameCell } from 'components/Cells/NameCell'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import React from 'react'

export interface AdvancedIngredientsSearchTableRow {
  id: string
  name: string
  friendlyId?: string
  suppliers?: string[]
}

export interface AdvancedIngredientsSearchTableProps {
  rows: AdvancedIngredientsSearchTableRow[]
  allPagesLoaded: boolean
  nextPageCallback: () => void
  onRowClick: (ingredientId: string) => void
}

export const AdvancedIngredientsSearchTable: React.FC<
  AdvancedIngredientsSearchTableProps
> = ({ rows, allPagesLoaded, onRowClick, nextPageCallback }) => {
  const columnConfigs: ColumnConfiguration<AdvancedIngredientsSearchTableRow>[] =
    [
      {
        title: 'Name',
        field: (ingredient) => ingredient.name,
        customRender: (ingredient) => <NameCell name={ingredient.name} />
      },
      {
        title: 'Friendly ID',
        field: (ingredient) => ingredient.friendlyId ?? '',
        fieldStyles: { width: '15ch' }
      },
      {
        title: 'Suppliers',
        fieldStyles: { width: '25ch' },
        field: (ingredient) => ingredient.suppliers?.join(',') ?? '',
        customRender: (ingredient) => (
          <ListCell list={ingredient.suppliers ?? []} />
        )
      }
    ]

  return (
    <EntrTable
      data={rows}
      allPagesLoaded={allPagesLoaded}
      columnConfigs={columnConfigs}
      onRowClick={(row) => onRowClick(row.id)}
      onLoadNextPage={nextPageCallback}
      sx={{ padding: '0px 24px 24px 24px', tableLayout: 'fixed' }}
    />
  )
}
