import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { Uploader, UploaderProps } from './components/Uploader'
import { PreviewContainer, PreviewProps } from './components/PreviewContainer'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { Alert, Box } from '@mui/material'

interface DesignProps {
  uploader: UploaderProps
  preview: PreviewProps
  loadingDesignFileUrl: boolean
  showInfoBanner?: boolean
}

export const Design: React.FC<DesignProps> = ({
  uploader,
  preview,
  loadingDesignFileUrl,
  showInfoBanner
}) => {
  return (
    <BoxColumnBasic
      sx={{
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative'
      }}
    >
      {loadingDesignFileUrl || preview.fileUrl === undefined ? (
        <LoadingAnimation text={'Loading'} />
      ) : preview.fileUrl ? (
        <PreviewContainer {...preview} />
      ) : (
        <Uploader {...uploader} />
      )}
      {showInfoBanner && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: '12px',
            transform: 'translateX(-50%)'
          }}
        >
          <Alert severity="info" sx={{ fontSize: '13px' }}>
            Click on the proof to add your first pin
          </Alert>
        </Box>
      )}
    </BoxColumnBasic>
  )
}
