export const POINT_TO_PIXEL_RATIO = 1.8

export function extractServingPerContainerNumber(
  servingsPerContainer: string
): number | null {
  const matches = servingsPerContainer.match(/(\d+(\.\d+)?)(?!.*\d)/)
  return matches ? parseFloat(matches[0]) : null
}

export const pointToPixel = (
  points: number,
  ratio: number = POINT_TO_PIXEL_RATIO
): string => {
  return `${Math.ceil(points * ratio)}px`
}
