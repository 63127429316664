import React from 'react'
import {
  IngredientRow,
  IngredientRowProps,
  IngredientRowSkeleton
} from './IngredientRow'
import { TableCell, TableRow, Typography } from '@mui/material'
import { YieldAdjustmentRow } from '../yieldAdjustment/YieldAdjustmentRow'
import {
  YieldAdjustment,
  YieldAdjustmentProps
} from '../yieldAdjustment/YieldAdjustment'

export interface IngredientRowGroupProps
  extends Omit<
    IngredientRowProps,
    | 'cells.ingredient.expanded'
    | 'cells.ingredient.onExpandClick'
    | 'cells.ingredient.expandable'
  > {
  children?: IngredientRowGroupProps[]
  expandable?: boolean
  visibleWidth?: string
  yieldAdjustments?: YieldAdjustmentProps[]
  onExpandClick?: () => Promise<void>
}

const NoIngredientsRow: React.FC<{
  level?: number
  colSpan?: number
}> = ({ level = 0, colSpan = 2 }) => {
  return (
    <TableRow>
      <TableCell sx={{ padding: '4px 4px' }}></TableCell>
      <TableCell
        sx={{ marginLeft: Math.max(level - 1, 0) * 3 }}
        colSpan={colSpan - 1}
      >
        <Typography variant="subtitle2">
          This formula doesn't contain any ingredients.
        </Typography>
      </TableCell>
    </TableRow>
  )
}

export const IngredientRowGroup: React.FC<IngredientRowGroupProps> = ({
  children,
  level = 0,
  expandable,
  onExpandClick,
  visibleWidth = '100%',
  yieldAdjustments,
  ...props
}) => {
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const { cells, ...restProps } = props
  const { ingredient, ...restCells } = cells

  const cellCount =
    3 +
    Object.values(restProps.visibility).reduce(
      (acc, val) => acc + (val ? 1 : 0),
      0
    )

  const handleExpandClick = () => {
    const newExpanded = !expanded
    setExpanded(newExpanded)
    if (onExpandClick && newExpanded) {
      void onExpandClick()
    }
  }

  const getPlaceholderRows = (rows: number, level = 0) => {
    return (
      <>
        {Array.from({ length: rows }).map((_, rowIdx) => (
          <IngredientRowSkeleton
            key={rowIdx}
            level={level}
            colSpan={cellCount}
          />
        ))}
      </>
    )
  }

  return (
    <>
      <IngredientRow
        cells={{
          ingredient: {
            ...ingredient,
            expanded: expanded,
            expandable: expandable,
            onExpandClick: handleExpandClick
          },
          ...restCells
        }}
        {...restProps}
        level={level}
      />

      {expanded && children === undefined && (
        <>{getPlaceholderRows(3, level + 1)}</>
      )}

      {expanded && children !== undefined && children.length === 0 && (
        <NoIngredientsRow level={level + 1} colSpan={cellCount} />
      )}

      {expanded &&
        children &&
        children.length > 0 &&
        children.map((child, index) => (
          <IngredientRowGroup
            key={index}
            {...child}
            level={level + 1}
            disabled={true}
            visibleWidth={visibleWidth}
          />
        ))}

      {expanded &&
        yieldAdjustments?.map((props, index) => (
          <YieldAdjustmentRow
            key={index}
            span={cellCount}
            visibleWidth={visibleWidth}
            level={level + 1}
            yieldAdjustment={<YieldAdjustment {...props} />}
          />
        ))}
    </>
  )
}
