import { deepCompareMemo } from 'common/utils'
import { ModalContext } from 'components/Modal/ModalContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { YieldAdjustmentType } from 'services/apis/formula/FormulaApiResponse'
import { getFormulaIngredientBreakdown } from 'state/formulator/breakdown/BreakdownSlice'
import {
  getFormulaYieldAdjustments,
  removeFormulaYieldAdjustment,
  updateFormulaYieldAdjustment
} from 'state/formulator/yieldAdjustments/FormulaYieldAdjustmentsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { YieldAdjustments } from './YieldAdjustments'
import { YieldAdjustmentProps } from './components/YieldAdjustment/YieldAdjustment'

interface AdjustmentLimits {
  minAmount: number
  maxAmount: number
  minPercentage: number
  maxPercentage: number
}

interface AdjustmentValues {
  amount: number
  percentage: number
  limits: AdjustmentLimits
}

interface Adjustment {
  [YieldAdjustmentType.LOSS]: AdjustmentValues
  [YieldAdjustmentType.TARGET]: AdjustmentValues
  adjustmentType: YieldAdjustmentType
  adjustmentId: string
}

export interface YieldAdjustmentsContainerProps {
  visibleWidth: string
}

// Note: At the current moment, this handles only Moisutre Loss.
// In the future, this would require a minor refactor to support other type of adjustments.

export const YieldAdjustmentsContainer: React.FC<
  YieldAdjustmentsContainerProps
> = ({ visibleWidth }) => {
  const formulaYieldAdjustments = useAppSelector(
    (state) => state.formulaYieldAdjustmentsSlice.formulaYieldAdjustments
  )
  const currentCompanyId = useAppSelector(
    (state) => state.companies.currentCompany.id
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaBreakdown = useAppSelector((state) => state.breakdown.breakdown)
  const { showInfoModal, showConfirmationModal } =
    React.useContext(ModalContext)

  const dispatch = useAppDispatch()
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const refreshIngredientBreakdown = React.useCallback(() => {
    if (formulaId) {
      void dispatch(
        getFormulaIngredientBreakdown({
          companyId: currentCompanyId,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, currentCompanyId])

  const breakdownDependency = deepCompareMemo(
    formulaBreakdown?.root.inputAdjustments.moistureLoss
  )

  const moistureLoss: Adjustment | undefined = React.useMemo(() => {
    if (formulaBreakdown?.root.inputAdjustments.moistureLoss) {
      return {
        [YieldAdjustmentType.LOSS]: {
          amount:
            formulaBreakdown.root.inputAdjustments.moistureLoss
              .moistureLossQuantity.rounded,
          percentage:
            formulaBreakdown.root.inputAdjustments.moistureLoss.moistureLossPct
              .rounded,
          limits: {
            minAmount:
              formulaBreakdown.limits.moistureLoss.minMoistureLossQuantity
                .rounded,
            maxAmount:
              formulaBreakdown.limits.moistureLoss.maxMoistureLossQuantity
                .rounded,
            minPercentage:
              formulaBreakdown.limits.moistureLoss.minMoistureLossPct.rounded,
            maxPercentage:
              formulaBreakdown.limits.moistureLoss.maxMoistureLossPct.rounded
          }
        },
        [YieldAdjustmentType.TARGET]: {
          amount:
            formulaBreakdown.root.inputAdjustments.moistureLoss
              .targetYieldQuantity.rounded,
          percentage:
            formulaBreakdown.root.inputAdjustments.moistureLoss
              .targetMoisturePct.rounded,
          limits: {
            minAmount:
              formulaBreakdown.limits.moistureLoss.minTargetYieldQuantity
                .rounded,
            maxAmount:
              formulaBreakdown.limits.moistureLoss.maxTargetYieldQuantity
                .rounded,
            minPercentage:
              formulaBreakdown.limits.moistureLoss.minTargetMoisturePct.rounded,
            maxPercentage:
              formulaBreakdown.limits.moistureLoss.maxTargetMoisturePct.rounded
          }
        },
        adjustmentType:
          formulaBreakdown.root.inputAdjustments.moistureLoss.adjustmentType,
        adjustmentId:
          formulaBreakdown.root.inputAdjustments.moistureLoss.adjustmentId
      }
    }
  }, [breakdownDependency])

  const handleYieldAdjustmentRemove = React.useCallback(
    (id: string) => {
      // Find the yield name.
      const yieldName = formulaYieldAdjustments.find(
        (fya) => fya.yieldAdjustment.id === id
      )?.yieldAdjustment.name

      showConfirmationModal({
        title: 'Remove Yield Adjustment',
        message: (
          <>
            Are you sure you want to remove {<b>{yieldName}</b>} from this
            formula?
          </>
        ),
        yesText: 'Remove',
        noText: 'Cancel',
        onYesClicked: () => {
          void dispatch(
            removeFormulaYieldAdjustment({
              companyId: currentCompanyId,
              formulaId: formulaId,
              yieldAdjustmentId: id
            })
          ).then(() => {
            refreshIngredientBreakdown()
          })
          formulatorAnalytics.ingredients.removedYieldAdjustment(formulaId)
        },
        danger: true
      })
    },
    [currentCompanyId, formulaId]
  )

  const verifyMoistureLossLimit = React.useCallback(
    (value: number, type: 'amount' | 'percentage'): React.ReactNode | null => {
      if (moistureLoss) {
        const limits = moistureLoss[moistureLoss.adjustmentType].limits
        const minValue =
          type === 'amount' ? limits.minAmount : limits.minPercentage * 100
        const maxValue =
          type === 'amount' ? limits.maxAmount : limits.maxPercentage * 100
        const unit = type === 'amount' ? 'g' : '%'

        if (value < minValue || value > maxValue) {
          return (
            <>
              The {type} you entered is outside the range of{' '}
              <b>
                {minValue}
                {unit} to {maxValue}
                {unit}
              </b>
              . Please enter a value within this range.
            </>
          )
        }
      }
      return null
    },
    [moistureLoss]
  )

  const handleAdjustmentTypeChange = React.useCallback(
    (adjustmentId: string, adjustmentType: YieldAdjustmentType) => {
      // When the type changes, pull the new values from the breakdown and submit them.
      if (moistureLoss) {
        void dispatch(
          updateFormulaYieldAdjustment({
            companyId: currentCompanyId,
            formulaId: formulaId,
            yieldAdjustmentId: adjustmentId,
            yieldAmount: moistureLoss[adjustmentType].amount,
            adjustmentType
          })
        ).then(() => {
          refreshIngredientBreakdown()
        })
      }
    },
    [formulaBreakdown?.root.inputAdjustments.moistureLoss]
  )

  const handlePercentageChange = React.useCallback(
    (adjustmentId: string, percentage: number) => {
      if (moistureLoss) {
        const limitMessage = verifyMoistureLossLimit(percentage, 'percentage')

        if (limitMessage !== null) {
          showInfoModal({
            title: 'Yield Out of Range',
            message: limitMessage,
            yesText: 'OK'
          })
        } else {
          void dispatch(
            updateFormulaYieldAdjustment({
              companyId: currentCompanyId,
              formulaId: formulaId,
              yieldAdjustmentId: adjustmentId,
              yieldPercentage: percentage,
              adjustmentType: moistureLoss.adjustmentType
            })
          ).then(() => {
            refreshIngredientBreakdown()
          })
          formulatorAnalytics.ingredients.modifiedYieldAdjustment(formulaId)
        }
      }
    },
    [moistureLoss]
  )

  const handleAmountChange = React.useCallback(
    (adjustmentId: string, amount: number) => {
      if (moistureLoss) {
        const limitMessage = verifyMoistureLossLimit(amount, 'amount')

        if (limitMessage !== null) {
          showInfoModal({
            title: 'Yield Out of Range',
            message: limitMessage,
            yesText: 'OK'
          })
        } else {
          void dispatch(
            updateFormulaYieldAdjustment({
              companyId: currentCompanyId,
              formulaId: formulaId,
              yieldAdjustmentId: adjustmentId,
              yieldAmount: amount,
              adjustmentType: moistureLoss.adjustmentType
            })
          ).then(() => {
            refreshIngredientBreakdown()
          })
          formulatorAnalytics.ingredients.modifiedYieldAdjustment(formulaId)
        }
      }
    },
    [moistureLoss]
  )

  const adjustments: YieldAdjustmentProps[] = React.useMemo(() => {
    if (!moistureLoss) {
      return []
    }
    return [
      {
        adjustment: {
          id: moistureLoss.adjustmentId,
          name: 'Moisture'
        },
        adjustmentTypeOptions: [
          {
            id: YieldAdjustmentType.LOSS,
            label: 'Loss',
            value: moistureLoss.adjustmentType === YieldAdjustmentType.LOSS
          },
          {
            id: YieldAdjustmentType.TARGET,
            label: 'Target',
            value: moistureLoss.adjustmentType === YieldAdjustmentType.TARGET
          }
        ],
        onAdjustmentTypeChange: (type: YieldAdjustmentType) =>
          handleAdjustmentTypeChange(moistureLoss.adjustmentId, type),
        onPercentageChange: (percentage: number) =>
          handlePercentageChange(moistureLoss.adjustmentId, percentage),
        onAmountChange: (amount: number) =>
          handleAmountChange(moistureLoss.adjustmentId, amount),
        onRemove: () => handleYieldAdjustmentRemove(moistureLoss.adjustmentId),
        adjustmentAmount: moistureLoss[moistureLoss.adjustmentType].amount,
        adjustmentPercentage:
          moistureLoss[moistureLoss.adjustmentType].percentage
      }
    ]
  }, [
    moistureLoss,
    handleYieldAdjustmentRemove,
    handleAmountChange,
    handlePercentageChange,
    handleAdjustmentTypeChange
  ])

  // ---------------------- START OF TEMPORARY FIX ----------------------
  // The below is required to update the yield adjustment whenever the breakdown changes.
  // This scenario is needed in the event where an ingredient amount is updated and the yield adjustment needs to be re-submitted.
  // Ideally, it should be done on the backend on ingredient update.
  React.useEffect(() => {
    if (currentCompanyId && formulaId) {
      void dispatch(
        getFormulaYieldAdjustments({
          companyId: currentCompanyId,
          formulaId: formulaId
        })
      )
    }
  }, [moistureLoss, currentCompanyId, formulaId])
  React.useEffect(() => {
    // When we get new yield adjustments, check if they are different from the ones in the breakdown.
    // If they are, update the yield adjustment %.
    if (moistureLoss) {
      const breakdownPercentage =
        moistureLoss[moistureLoss.adjustmentType].percentage
      const yieldAdjustmentMoistureLoss = formulaYieldAdjustments.find((fya) =>
        fya.yieldAdjustment.name.includes('Moisture')
      )
      if (
        breakdownPercentage !== yieldAdjustmentMoistureLoss?.yieldPercentage
      ) {
        handlePercentageChange(moistureLoss.adjustmentId, breakdownPercentage)
      }
    }
  }, [formulaYieldAdjustments, handlePercentageChange])
  // ---------------------- END OF TEMPORARY FIX ----------------------

  return (
    <YieldAdjustments adjustments={adjustments} visibleWidth={visibleWidth} />
  )
}
