import styled from 'styled-components'
import {
  Box,
  FormControl,
  FormControlLabel,
  MenuItem,
  Tab,
  Typography
} from '@mui/material'
import Switch from '@mui/material/Switch'
import { entrTheme } from 'entrTheme'
import React from 'react'
import { ZIndex } from 'styles/zIndex'

export const BoxRow = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center'
})

export const BoxRowBasic = styled(Box)({
  display: 'flex',
  flexDirection: 'row'
})

export const BoxColumnBasic = styled(Box)({
  display: 'flex',
  flexDirection: 'column'
})

export const LabelTypography = styled(Typography)({
  fontWeight: '400',
  fontStyle: 'normal',
  marginBottom: '6px',
  fontSize: '12px',
  '&:has(svg)': {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
    svg: {
      height: '16px',
      width: '16px'
    }
  }
})

export const FormControlLabelStyled = styled(FormControlLabel)({
  fontWeight: '400',
  fontStyle: 'normal',
  fontSize: '13px',
  lineHeight: 'normal',
  marginLeft: 0,
  color: entrTheme.palette.secondary.main
})

export const FormControlStyled = styled(FormControl)`
  fieldset {
    border: none;
  }
`

export const MenuItemDataAttrStyled = styled(MenuItem)`
  &[aria-selected='true'][data-allow-removal='false'] {
    pointer-events: none;
  }
`

export const EntrTableContainer = styled(Box)`
  display: grid;
  grid-template-rows: auto auto 1fr;
  position: relative;
  margin: 1.5rem 2.875rem 0 2.5rem;
`

export const PageEntrTableContainer = styled(Box)({
  position: 'relative',
  overflow: 'hidden'
})

export const PageEntrTableInnerContainer = styled(Box)({
  display: 'grid',
  gridTemplateRows: '100vh',
  overflow: 'hidden'
})

export const ViewerLayout = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed; /* or absolute */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
`

export const ViewerContainer = styled(Box)`
  display: grid;
  grid-template-rows: auto 1fr;
  width: 45%;
  height: 96vh;
  padding: 24px;
  gap: 14px;
  border-radius: 12px;
  border: 0.5px solid rgba(185, 185, 185, 0.41);
  background: #fff;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
    3px 7px 16px 0px rgba(0, 0, 0, 0.1), 12px 27px 29px 0px rgba(0, 0, 0, 0.09),
    27px 60px 40px 0px rgba(0, 0, 0, 0.05),
    47px 107px 47px 0px rgba(0, 0, 0, 0.01),
    74px 168px 51px 0px rgba(0, 0, 0, 0);
  position: absolute;
  z-index: ${ZIndex.panel};
  top: 12px;
  right: 12px;
  transform: translateX(110%);
  transition: all 0.3s ease-out;

  &[data-show='true'] {
    transform: translateX(0%);
  }
`

export const ViewerTopControl = styled(Box)`
  padding: 24px 24px 0px 24px;
`

export const ViewerContent = styled(Box)`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 14px;
  overflow: hidden auto;
  position: relative;
  /* below: to cover up 1 to 2px bottom content leaking above bottom border  */
  &::before {
    content: '';
    position: fixed;
    width: 100%;
    height: 2px;
    background-color: #fff;
    top: 66px;
    left: 0px;
    z-index: 2;
  }
`

export const ViewerRow = styled(Box)({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  alignItems: 'center',
  columnGap: '16px',
  rowGap: '14px',
  '& > *': {
    minWidth: 0
  }
})

export const ViewerBottomContentContainer = styled(Box)`
  position: relative;
  background-color: #fff;
  flex-grow: 1;
  display: flex;
`

export const ViewerBottomContentWrap = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  flex-grow: 1;
  position: relative;
`

export const ViewerBottomContent = styled(Box)`
  flex-grow: 1;
`

export const ViewerBottomHeaderFooter = styled(Box)`
  padding: 16px;
  background-color: #fff;
  flex-grow: 0;
  position: sticky;
  & + * {
    z-index: 0;
    border-left: 1px solid rgba(185, 185, 185, 0.41);
    border-right: 1px solid rgba(185, 185, 185, 0.41);
  }

  &[data-header] {
    z-index: 2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    top: 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border: 1px solid rgba(185, 185, 185, 0.41);
      border-bottom: none;
    }
  }

  &[data-footer] {
    z-index: 1;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    bottom: 0;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-bottom-left-radius: 12px;
      border-bottom-right-radius: 12px;
      border: 1px solid rgba(185, 185, 185, 0.41);
      border-top: none;
    }
  }
`

export const ViewerBottomTab = styled(Tab)`
  padding: 6px 16px;
`

export const SwitchControlLabelStyled = styled(FormControlLabelStyled)({
  width: '100%',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: 0,
  gap: '8px',
  padding: '2px 0'
})

export const SwitchStyled = styled(Switch)({
  width: 33,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    '&.Mui-checked': {
      transform: 'translateX(15px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: entrTheme.palette.formulaColors.green.main,
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: entrTheme.palette.tertiary.main
  }
})

export interface Option<T> extends SelectOption<T> {
  id: string
}

export interface OptionGroup<T> {
  label: string
  options: Option<T>[]
}

export interface SelectOption<T> {
  label: string | React.ReactNode
  value: T
  disabled?: boolean
  hidden?: boolean
}

export enum SortOrder {
  DESC = 'desc',
  ASC = 'asc'
}
