import { Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxRowBasic } from 'components/common'
import React from 'react'
import { MainColors } from 'styles/colors'

const buttonTextStyles = {
  fontFamily: 'Inter',
  fontWeight: 600,
  fontSize: '12px',
  lineHeight: '145%',
  letterSpacing: '0%',
  color: 'rgba(35, 60, 88, 0.43)'
}

export interface PinActionsProps {
  resolvable: boolean
  deletable: boolean
  onMarkComplete: () => void
  onDelete: () => void
}

export const PinActions: React.FC<PinActionsProps> = ({
  resolvable,
  deletable,
  onMarkComplete,
  onDelete
}) => {
  return (
    <BoxRowBasic
      sx={{
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '5px'
      }}
    >
      <Button variant="text" onClick={onMarkComplete} disabled={!resolvable}>
        <Typography
          sx={{
            ...buttonTextStyles,
            color: resolvable ? MainColors.primary : MainColors.tertiary
          }}
        >
          Mark Complete
        </Typography>
      </Button>
      <Button variant="text" onClick={onDelete} disabled={!deletable}>
        <Typography
          sx={{
            ...buttonTextStyles,
            color: deletable ? MainColors.danger : MainColors.tertiary
          }}
        >
          Delete
        </Typography>
      </Button>
    </BoxRowBasic>
  )
}
