import { SwitchStyled } from 'components/common'
import React from 'react'

export interface IngredientActiveProps {
  active: boolean
  onToggle: (value: boolean) => void
  disabled?: boolean
}

export const IngredientActive: React.FC<IngredientActiveProps> = ({
  active,
  onToggle,
  disabled = false
}) => {
  return (
    <SwitchStyled
      checked={active}
      onChange={(_, checked) => onToggle(checked)}
      disabled={disabled}
    />
  )
}
