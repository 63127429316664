import { PayloadAction } from '@reduxjs/toolkit'
import { TargetsState, initialState } from '../TargetsSlice'
import { BasicTarget, SortedBasicTargets, Target } from 'models/Target'
import { normalizeOperations, normalizeTarget } from '../helpers'
import { Nutrient } from 'models/Nutrient'

// Get all targets handlers
export const handleGetTargetsPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleGetTargetsFulfilled = (
  state: TargetsState,
  action: PayloadAction<SortedBasicTargets>
) => {
  state.targets = action.payload
  state.filteredTargets = action.payload
  state.loading = false
  state.error = false
}

export const handleGetTargetsRejected = (state: TargetsState) => {
  state.targets = initialState.targets
  state.normalizedOperations = initialState.normalizedOperations
  state.loading = false
  state.error = true
}

// Get target handlers
export const handleGetTargetPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleGetTargetFulfilled = (
  state: TargetsState,
  action: PayloadAction<Target>
) => {
  state.normalizedTarget = normalizeTarget(action.payload)
  state.normalizedOperations = normalizeOperations(action.payload)
  state.activeTarget = normalizeTarget(action.payload)
  state.activeOperations = normalizeOperations(action.payload)

  state.loading = false
  state.error = false
}

export const handleGetTargetRejected = (state: TargetsState) => {
  state.loading = false
  state.error = true
}

// Create target handlers
export const handleCreateTargetPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleCreateTargetFulfilled = (
  state: TargetsState,
  action: PayloadAction<BasicTarget>
) => {
  state.targets.items = [...state.targets.items, action.payload]
  state.filteredTargets.items = [...state.filteredTargets.items, action.payload]
  state.loading = false
  state.error = false
}

export const handleCreateTargetRejected = (state: TargetsState) => {
  state.loading = false
  state.error = true
}

// Update target handlers
export const handleUpdateTargetPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleUpdateTargetFulfilled = (
  state: TargetsState,
  action: PayloadAction<Target>
) => {
  state.normalizedTarget = normalizeTarget(action.payload)
  state.normalizedOperations = normalizeOperations(action.payload)
  state.activeTarget = normalizeTarget(action.payload)
  state.activeOperations = normalizeOperations(action.payload)
  state.loading = false
  state.error = false
  state.isActiveTargetUpdated = false
}

export const handleUpdateTargetRejected = (state: TargetsState) => {
  state.loading = false
  state.error = true
}

// Delete target handlers
export const handleDeleteTargetPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleDeleteTargetFulfilled = (
  state: TargetsState,
  action: PayloadAction<string>
) => {
  state.targets.items = state.targets.items.filter((target) => target.id !== action.payload)
  state.loading = false
  state.error = false
}

export const handleDeleteTargetRejected = (state: TargetsState) => {
  state.loading = false
  state.error = true
}

// Get available nutrients handlers
export const handleGetAvailableNutrientsPending = (state: TargetsState) => {
  state.loading = true
  state.error = false
}

export const handleGetAvailableNutrientsFulfilled = (
  state: TargetsState,
  action: PayloadAction<Nutrient[]>
) => {
  state.availableNutrients = action.payload.sort((a, b) => a.rank - b.rank)
  state.loading = false
  state.error = false
}

export const handleGetAvailableNutrientsRejected = (state: TargetsState) => {
  state.loading = false
  state.error = true
}
