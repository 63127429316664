export interface ValidationResult {
  newInput?: string
  status?: 'pass' | 'incomplete'
  invalid?: boolean
}


/**
 * Checks if the input ends with a dot or a trailing zero after a dot.
 * @param inputValue The input value to check.
 * @returns A partial validation result.
 */
const checkIncompleteDecimal = (inputValue: string): ValidationResult => {
  if (
    inputValue.endsWith('.') ||
    (inputValue.includes('.') && inputValue.endsWith('0'))
  ) {
    return {
      newInput: inputValue,
      status: 'incomplete',
      invalid: inputValue === '.'
    }
  }

  return {
    status: 'pass'
  }
}

/**
 * Validates the minus sign in the input.
 * @param inputValue The input value to validate.
 * @param positive If the input should be positive.
 * @returns The validation result.
 */
export const validateMinusSign = (
  inputValue: string,
  positive: boolean
): ValidationResult => {
  // Make sure it contains only one minus sign if it's not positive.
  const allowedMinusSign = positive && inputValue.startsWith('-')
  // If it's not allowed to be negative, make sure it's not negative.
  const moreThanOneMinusSign = inputValue.split('-').length > 1
  if (allowedMinusSign || moreThanOneMinusSign) {
    return {
      invalid: true
    }
  }

  return {
    newInput: inputValue,
    status: 'pass'
  }
}

/**
 * Validates the zeros in the input.
 * @param inputValue The input value to validate.
 * @param nonZero If the input should be non-zero.
 * @returns The validation result.
 */
export const validateZeros = (
  inputValue: string,
  nonZero: boolean
): ValidationResult => {
  let transformedInput = inputValue
  // If 2+ consecutive zeros are typed at the beginning of the number, only keep one.
  if (
    inputValue.startsWith('0') &&
    inputValue.length > 1 &&
    inputValue[1] !== '.'
  ) {
    transformedInput = inputValue.slice(1)
  }

  // If it's not allowed to be zero, allow the user to type the zero as it's a possible decimal but don't submit it.
  if (nonZero && Number(transformedInput) === 0) {
    return {
      newInput: transformedInput,
      status: 'incomplete',
      invalid: true
    }
  }

  return {
    newInput: transformedInput,
    status: transformedInput !== '0' || !nonZero ? 'pass' : 'incomplete',
    invalid: transformedInput === '0' && nonZero
  }
}

/**
 * Validates the characters in the input.
 * @param inputValue The input value to validate.
 * @returns The validation result.
 */
export const validateCharacters = (inputValue: string): ValidationResult => {
  // Prevent anything except numbers, dots, emtpy string and - through regex.
  // If it does not match the regex, return.
  const regex = new RegExp(`[^0-9\\.-]`, 'g')
  if (regex.test(inputValue)) {
    return {
      invalid: true
    }
  }
  return {
    newInput: inputValue,
    status: 'pass'
  }
}

/**
 * Validates that a number belongs to a certain range.
 * @param inputValue The input value to validate.
 * @param min The minimum value allowed.
 * @param max The maximum value allowed.
 * @param excludeMin If the minimum value is excluded. Defaults to false.
 * @param excludeMax If the maximum value is excluded. Defaults to false.
 * @returns The validation result.
 */
export const validateRange = (
  inputValue: string,
  min: number,
  max: number,
  excludeMin = false,
  excludeMax = false
): ValidationResult => {
  const value = parseFloat(inputValue)
  
  const minCondition = excludeMin ? value > min : value >= min
  const maxCondition = excludeMax ? value < max : value <= max
  const validRange = minCondition && maxCondition

  if (isNaN(value) || !validRange) {
    return {
      invalid: true
    }
  }

  // Use the shared utility function for incomplete decimal validation.
  const incompleteResult = checkIncompleteDecimal(inputValue)
  if (incompleteResult.status === 'incomplete') {
    return incompleteResult
  }

  return {
    newInput: inputValue,
    status: 'pass'
  }
}

/**
 * Validates if the input is a percentage.
 * @param inputValue The input value to validate.
 * @returns The validation result.
 */
export const validatePercentage = (inputValue: string): ValidationResult => {
  const value = parseFloat(inputValue)

  // The input should be a number between 0 and 100.
  // It's the responsibility of other validators to make sure it's a number.
  if ((!isNaN(value) && (value < 0 || value > 100)) || inputValue === '100.') {
    return {
      invalid: true
    }
  }
  return {
    newInput: inputValue,
    status: 'pass'
  }
}

/**
 * Validates the decimal part of the input.
 * @param inputValue The input value to validate.
 * @param maxDecimals The maximum number of decimals allowed.
 * @returns The validation result.
 */
export const validateDecimal = (
  inputValue: string,
  maxDecimals: number
): ValidationResult => {
  let newInput = inputValue
  // If there are no maxDecimals prevent the user from entering a .
  if (maxDecimals === 0 && inputValue.includes('.')) {
    return {
      invalid: true
    }
  }

  // Not allowed to have more than 1 dot.
  if (inputValue.split('.').length > 2) {
    return {
      invalid: true
    }
  }

  // Limit decimals to maxDecimals by truncating the extra decimals.
  const [integerPart, decimalPart] = inputValue.split('.')
  if (decimalPart && decimalPart.length > maxDecimals) {
    newInput = `${integerPart}.${decimalPart.slice(0, maxDecimals)}`
  }

  // Use the shared utility function for incomplete decimal validation.
  const incompleteResult = checkIncompleteDecimal(newInput)
  if (incompleteResult.status === 'incomplete') {
    return incompleteResult
  }

  // Otherwise, format it properly and mark it ready for submission.
  return {
    newInput: newInput,
    status: 'pass'
  }
}

/**
 * Runs all the validations on the input.
 * @param inputValue The input value to validate.
 * @param validators The list of validation functions to run.
 * @returns The validation result.
 */
export const runValidations = (
  inputValue: string,
  validators: ((inputValue: string) => ValidationResult)[]
): ValidationResult => {
  let incompleteInput = false
  let invalidInput = false

  let result: ValidationResult = {
    newInput: inputValue,
    status: 'pass',
    invalid: false
  }

  for (const validator of validators) {
    result = validator(result.newInput || '')
    // If the input is invalid with no status, return the invalid result.
    if (result.invalid && !result.status) {
      return result
    }
    // If only one case is incomplete, the whole input is incomplete.
    if (result.status === 'incomplete') {
      incompleteInput = true
    }
    // If any case is invalid, the whole input is invalid.
    if (result.invalid) {
      invalidInput = true
    }
  }

  return {
    newInput: result.newInput,
    status: incompleteInput ? 'incomplete' : 'pass',
    invalid: invalidInput
  }
}
