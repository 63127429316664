import { OpenInNew } from '@mui/icons-material'
import { Skeleton, Typography } from '@mui/material'
import { pluralize } from 'common/utils'
import { BoxColumnBasic, BoxRow } from 'components/common'
import React from 'react'

export interface Usage {
  url: string
  name: string
}

interface UsageMessageProps {
  /**
   * The function that checks where the source is being used.
   * @returns A promise that resolves to an array of usages.
   */
  checkUsage: () => Promise<Usage[]>
  /**
   * The target type where the source is being used.
   */
  target: string
  /**
   * The source that is being used.
   */
  source: string
  /**
   * The message to be displayed after showing the usage.
   */
  message: React.ReactNode
}

export const UsageMessage: React.FC<UsageMessageProps> = ({
  checkUsage,
  target,
  source,
  message
}) => {
  const [usages, setUsages] = React.useState<Usage[] | undefined>(undefined)

  React.useEffect(() => {
    void checkUsage().then(setUsages)
  }, [checkUsage])

  const getMessage = () => {
    if (usages === undefined) {
      return (
        <>
          Checking in which {pluralize(2, target)} <b>{source}</b> is being
          used.
          <Skeleton variant="text" width={'100%'} />
          <Skeleton variant="text" width={'100%'} />
          <Skeleton variant="text" width={'100%'} />
        </>
      )
    }

    if (usages.length === 0) {
      return <>{message}</>
    }

    return (
      <>
        <b>{source}</b> is used in the below <b>{usages.length}</b>{' '}
        {pluralize(usages.length, target)}. {message}
        <BoxColumnBasic sx={{ marginTop: '5px', maxHeight: '20vh' }}>
          {usages.map((usage) => (
            <BoxRow key={usage.url}>
              <Typography
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => window.open(usage.url, '_blank')}
              >
                {usage.name}
              </Typography>
              <OpenInNew style={{ fontSize: '14' }} />
            </BoxRow>
          ))}
        </BoxColumnBasic>
      </>
    )
  }

  return <>{getMessage()}</>
}
