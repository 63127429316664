import { BasicTarget, SortedBasicTargets, Target } from 'models/Target'
import {
  fromOperation,
  toBasicTarget,
  toSortedBasicTargets,
  toTarget
} from './TargetApiMapper'
import { BasicTargetApiResponse, SortedBasicTargetsApiResponse, TargetApiResponse } from './TargetApiResponse'
import { api } from '../config/AxiosConfig'
import { CreateTargetApiData, UpdateTargetApiData } from './TargetApiRequest'

export const TargetApi = {
  createTarget: (
    companyId: string,
    data: CreateTargetApiData
  ): Promise<BasicTarget> => {
    return api
      .post<BasicTargetApiResponse>(
        `/companies/${companyId}/target_definitions`,
        data
      )
      .then((res) => toBasicTarget(res.data))
  },
  getTargets: (
    companyId: string,
    withPublic: boolean | undefined,
    categoriesIds: string[] | undefined,
    sortBy?: string,
    sortOrder?: string
  ): Promise<SortedBasicTargets> => {
    return api
      .get<SortedBasicTargetsApiResponse>(
        `/companies/${companyId}/target_definitions`,
        {
          params: {
            withPublic,
            categoriesIds,
            sortBy,
            sortOrder
          },
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => toSortedBasicTargets(res.data))
  },
  getTarget: (companyId: string, targetId: string): Promise<Target> => {
    return api
      .get<TargetApiResponse>(
        `/companies/${companyId}/target_definitions/${targetId}`
      )
      .then((res) => toTarget(res.data))
  },
  deleteTarget: (companyId: string, targetId: string): Promise<string> => {
    return api
      .delete(`/companies/${companyId}/target_definitions/${targetId}`)
      .then(() => targetId)
  },
  updateTarget: (
    companyId: string,
    targetId: string,
    data: UpdateTargetApiData
  ): Promise<Target> => {
    return api
      .put<TargetApiResponse>(
        `/companies/${companyId}/target_definitions/${targetId}`,
        {
          ...data,
          operation: data.operation ? fromOperation(data.operation) : undefined
        }
      )
      .then((res) => toTarget(res.data))
  }
}
