import { IconButton } from '@mui/material'
import styled from 'styled-components'

export const ZoomButton = styled(IconButton)({
  borderRadius: '6px',
  width: '35px',
  height: '35px',
  backgroundColor: '#ededed',
  '&:hover': {
    backgroundColor: '#f8f8f8'
  },
  '&[disabled]': {
    backgroundColor: '#f8f8f8',
    '.MuiSvgIcon-root': {
      opacity: 0.3
    }
  },
  '.MuiSvgIcon-root': {
    fill: '#000'
  }
})
