import { Button } from 'components/Button/Button'
import { BoxColumnBasic } from 'components/common'
import { TextField, TextFieldProps } from 'components/InputFields/TextField'
import React from 'react'
import { MainColors } from 'styles/colors'

export interface OverrideTextFieldProps extends TextFieldProps {
  originalValue?: string
  onResetClick?: () => void
}

/**
 * Shows a text field that can be overridden by the user.
 * If focused, user would start with original value.
 */
export const OverrideTextField: React.FC<OverrideTextFieldProps> = ({
  originalValue,
  onResetClick,
  ...props
}) => {
  const [currentValue, setCurrentValue] = React.useState<string | undefined>(
    props.value
  )

  React.useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const handleFocus = () => {
    if (props.value === '') {
      setCurrentValue(originalValue)
    }
  }

  const handleBlur = () => {
    if (props.value === '') {
      setCurrentValue('')
    }
  }

  return (
    <TextField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={currentValue}
      placeholder={originalValue}
      sx={{
        backgroundColor: props.value ? MainColors.lightWarning : 'inherit',
        alignItems: 'flex-end'
      }}
      endAdornment={
        props.value &&
        onResetClick && (
          <BoxColumnBasic alignItems={'flex-end'}>
            <Button variant="text" size="small" onClick={onResetClick}>
              Reset
            </Button>
          </BoxColumnBasic>
        )
      }
    />
  )
}
