import {
  FormulaAllergen,
  FormulaAllergens,
  FormulasSortingField
} from 'models/Formula'
import {
  BaseApiPaginatedResponse,
  SortedApiPaginatedResponse
} from '../config/BaseApiPaginatedResponse'
import {
  BasicIngredientApiResponse,
  IngredientApiResponse,
  IngredientNutrientApiResponse
} from '../ingredient/IngredientApiResponse'
import { MeasurementApiResponse } from '../measurements/MeasurementApiResponse'
import { NutrientApiResponse } from '../nutrient/NutrientApiResponse'
import { BasicSupplierApiResponse } from '../supplier/SupplierApiResponse'
import {
  BasicTargetApiResponse,
  OperationApiResponse
} from '../target/TargetApiResponse'
import { BasicUserApiResponse, UserApiResponse } from '../user/UserApiResponse'
import { YieldAdjustment } from '../yield_adjustments/YieldAdjustmentsApiResponse'
import { FilterApiResponse } from '../filter/FilterApiResponse'
import { BasicSimpleIngredientApiResponse } from '../simple_ingredient/SimpleIngredientApiResponse'
import { SortOrder } from 'components/common'

export enum FormulaApiStatus {
  IN_REVIEW = 'in_review',
  PRIVATE_DRAFT = 'private_draft',
  APPROVED = 'approved',
  IN_MARKET = 'in_market'
}

export enum NutritionFactLabelApiAgeGroup {
  INFANT = 'infant',
  CHILDREN_LESS_THAN_FOUR = 'children_less_than_four',
  PREGNANT_WOMEN = 'pregnant_women',
  LACTACTING_WOMEN = 'lactating_women',
  ADULT = 'adult'
}

export enum NutritionFactLabelApiType {
  VERTICAL = 'vertical',
  TABULAR = 'tabular',
  LINEAR = 'linear',
  DUAL = 'as_packaged_as_prepared'
}

export enum FormulaLabelStatementApiCaseType {
  ALL_CAPS = 'all_caps',
  ALL_LOWERCASE = 'all_lowercase',
  TITLE_CASE = 'title_case',
  SENTENCE_CASE = 'sentence_case',
  NOT_SET = 'not_set'
}

export enum SubFormulaStatementApiFormat {
  EXPANDED = 'expanded',
  SINGLE_ITEM = 'single_item'
  // MERGED = 'merged'
}

export enum ActiveIngredientDisplaySectionApiResponse {
  NUTRIENTS = 'nutrients',
  ACTIVE_INGREDIENTS = 'active_ingredients',
  BOTH = 'both',
  HIDDEN = 'hidden'
}

export interface BasicFormulaApiResponse {
  id: string
  name: string
  friendlyId?: string
}

export interface FormulaRegulationApiResponse {
  formulaId: string
  regulationId: string
  isMealOrMainDish?: boolean
  racc?: number
  raccUnit?: string
  referenceFormula?: BasicFormulaApiResponse
  statementCase?: FormulaLabelStatementApiCaseType
  subIngredientStatementCase?: FormulaLabelStatementApiCaseType
}

export interface FormulaApiResponse {
  id: string
  name: string
  status: FormulaApiStatus
  imagePath?: string
  servingWeight?: number
  containerWeight?: number
  note: string
  createdAt: string
  updatedAt: string
  createdBy: UserApiResponse
  isArchived: boolean
  friendlyId?: string
  formulaRegulations: FormulaRegulationApiResponse[]
  isSupplement: boolean
}

export interface FormulaCostApiResponse {
  totalCostPerServing: number | null
  totalCostPerWeight: number | null
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaApiPaginatedResponse
  extends SortedApiPaginatedResponse<
    FormulaApiResponse,
    FormulasSortingField
  > {}

export interface FormulaCommentApiResponse {
  id: string
  comment: string
  formula: BasicFormulaApiResponse
  createdBy: BasicUserApiResponse
  createdAt: string
  updatedAt: string
}

export interface FormulaIngredientApiResponse {
  id: string
  formula: BasicFormulaApiResponse
  ingredient: IngredientApiResponse
  supplier: BasicSupplierApiResponse
  amount: number
  wastePercentage: number
  amountInMeasurement: number
  measurement: MeasurementApiResponse
  totalCostForGrams: number
  isActive: boolean
  formatInStatement: SubFormulaStatementApiFormat
  activeIngredientDisplaySection: ActiveIngredientDisplaySectionApiResponse
  children?: FormulaIngredientApiResponse[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaIngredientApiPaginatedResponse
  extends BaseApiPaginatedResponse<FormulaIngredientApiResponse> {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaCommentApiPaginatedResponse
  extends BaseApiPaginatedResponse<FormulaCommentApiResponse> {}

export interface FormulaIngredientStatementApiResponse {
  id: string
  name: string
  ingredientStatement: string
  suggestedIngredientStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  ingredientStatementPrefix: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
}

export interface FormulaNutrientModifierApiResponse {
  name: string
  amount: number
}
export interface FormulaNutrientApiResponse {
  formula: BasicFormulaApiResponse
  nutrient: NutrientApiResponse
  ingredients: FormulaIngredientNutrientApiResponse[]
  total: number
  dvPercentage?: number
  children: FormulaNutrientApiResponse[]
  modifiers: FormulaNutrientModifierApiResponse[]
}

export interface FormulaNutrientsApiResponse {
  items: FormulaNutrientApiResponse[][]
  scale: number
}

export interface FormulaIngredientNutrientApiResponse {
  formulaIngredient: FormulaIngredientApiResponse
  nutrient: IngredientNutrientApiResponse
}

export interface DisplayAmountOptionApiResponse {
  amount: string
}

export interface DisplayDvOptionApiResponse {
  amount: string
  notSignifcantSource?: boolean
  asteriskText?: string
}

export interface OverrideValuesApiResponse {
  amount?: string
  dv?: string
  nutrientDisplayName?: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  perContainerAmount?: string
  perContainerDv?: string
}

export interface NutritionFactNutrientApiResponse {
  nutrient: NutrientApiResponse
  displayAmountOptions: DisplayAmountOptionApiResponse[]
  displayDvOptions: DisplayDvOptionApiResponse[]
  displayNameOptions: string[]
  overrideValues?: OverrideValuesApiResponse
}

export enum FormulaNutritionFactLanguageApiResponse {
  ENGLISH = 'english',
  FRENCH = 'french',
  SPANISH = 'spanish'
}

export type FormulaNutritionFactLabelLangSpecificFieldsApiResponse = {
  [key in FormulaNutritionFactLanguageApiResponse]?: string
}

export interface FormulaNutritionFactApiResponse {
  // TODO: Objects here are temporary.
  servingsPerContainer: string
  servingSize: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  servingWeight: number
  servingWeightOverride: string
  containerWeight: number
  calories: string
  nutrients: NutritionFactNutrientApiResponse[]
  optionalNutrients: NutrientApiResponse[]
  type: NutritionFactLabelApiType
  ageGroup: NutritionFactLabelApiAgeGroup
  showProteinPercentage: boolean
  forceShowProteinPercentage: boolean
  pdcaas: number | null
  suggestedPdcaas: number | null
  invalidProteinDigestibilityIngredients: BasicSimpleIngredientApiResponse[]
  applyPdcaas: boolean
  description: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  ingredientStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  allergenStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  suggestedAllergenStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  allergenStatementPrefix: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  regulationId: string
  dvBasedOnRounded: boolean
}

export interface SupplementIngredientOverrideApiResponse {
  name?: string
  amount?: string
}

export interface SupplementIngredientApiResponse {
  id: string
  name: string
  ingredientStatement: string
  amount: number
  amountInMeasurement: number
  measurement: MeasurementApiResponse
  active: boolean
  dv: string
  displaySection: ActiveIngredientDisplaySectionApiResponse
  overrideValue?: SupplementIngredientOverrideApiResponse
}

export interface SupplementFactsNutrientApiResponse
  extends NutritionFactNutrientApiResponse {
  source: BasicIngredientApiResponse[]
}

export interface FormulaSupplementFactsApiResponse
  extends FormulaNutritionFactApiResponse {
  activeIngredients: SupplementIngredientApiResponse[]
  nutrients: SupplementFactsNutrientApiResponse[]
}

export interface FormulaNutritionFactLabelHeaderApiResponse {
  servingsPerContainer: string
  servingSize: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  type: NutritionFactLabelApiType
  ageGroup: NutritionFactLabelApiAgeGroup
  showProteinPercentage: boolean
  forceShowProteinPercentage: boolean
  pdcaas: number
  applyPdcaas: boolean
  description: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  ingredientStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  allergenStatement: FormulaNutritionFactLabelLangSpecificFieldsApiResponse
  regulationId: string
  servingWeightOverride: string
}

export interface FormulaNutritionFactsOptionalNutrientsApiResponse {
  formula: BasicFormulaApiResponse
  nutrients: NutrientApiResponse[]
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaAllergensApiResponse extends FormulaAllergens {}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface FormulaAllergenApiResponse extends FormulaAllergen {}

export interface BasicFormulaTargetCategoryApiResponse {
  id: string
  name: string
  rank?: number
}

export enum OperationExecutionErrorTypeApiResponse {
  COMPARISON_FAILURE = 'Comparison Failure',
  DATA_NOT_FOUND = 'Data Not Found',
  LOGIC_ERROR = 'Logic Error',
  OTHER = 'Other'
}

export interface OperationExecutionErrorApiResponse {
  errorType: OperationExecutionErrorTypeApiResponse
  errorMessage: string
}

export interface OperationExecutionResultApiResponse {
  operation: OperationApiResponse
  matched: boolean
  error?: OperationExecutionErrorApiResponse
  actualValue?: number
  expectedValue?: number
  percentScore?: number
  suggestionMessage?: string
}

export interface TruthSetExecutionResultApiResponse {
  truthSetMatched: boolean
  truthSetPercentScore?: number
  truthSetOperationsExecutionResults: OperationExecutionResultApiResponse[]
}

export interface FormulaTargetApiResponse {
  formula: BasicFormulaApiResponse
  targetDefinition: BasicTargetApiResponse
  targetDefinitionCategory?: BasicFormulaTargetCategoryApiResponse
  isTargetSelectedForFormula?: boolean
  matched?: boolean
  averagePercentScore?: number
  targetExecutionTracePerTruthSet?: TruthSetExecutionResultApiResponse[]
}

export enum YieldAdjustmentType {
  LOSS = 'loss',
  TARGET = 'target'
}

export interface FormulaYieldAdjustmentApiResponse {
  formula: BasicFormulaApiResponse
  yieldPercentage: number
  yieldAmount: number
  yieldAdjustment: YieldAdjustment
  adjustmentType: YieldAdjustmentType
  maxYieldAmount: number
}

export interface FormulaNutrientOverrideApiResponse {
  formulaId: string
  nutrientId: string
  regulationId: string
  amount: string
  dv: string
  nutrientName: string
}

export interface SupplementActiveIngredientOverrideApiResponse {
  formulaId: string
  ingredientId: string
  ingredientName?: string
  ingredientAmount?: string
}

export interface FormulaCreatedByFilterApiResponse
  extends FilterApiResponse<BasicUserApiResponse> {}
