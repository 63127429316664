import { IngredientType } from 'models/Ingredient'
import {
  FormulaBreakdownNode,
  IngredientBreakdownNode,
  SimpleIngredientBreakdownNode
} from 'services/apis/formula/breakdown/BreakdownResponse'
import { MeasurementProps } from './components/row/components/IngredientInputQuantity'
import {
  ActiveCellProps,
  CostCellProps,
  IngredientCellProps,
  InputQuantityCellProps,
  MoistureLossCellProps,
  SupplierCellProps,
  Visiblity,
  WastePercentageCellProps,
  YieldQuantityCellProps
} from './components/row/IngredientRow'
import { IngredientRowGroupProps } from './components/row/IngredientRowGroup'
import { YieldAdjustmentProps } from './components/yieldAdjustment/YieldAdjustment'
import { YieldAdjustmentType } from 'services/apis/formula/FormulaApiResponse'

const mapIngredientCell = (
  node: IngredientBreakdownNode,
  navigationClick: (id: string, type: IngredientType) => void
): IngredientCellProps => {
  return {
    name: node.name,
    friendlyId: node.friendlyId,
    detailsNavigation: {
      hoverMessage: `Go to ${
        isFormula(node) ? 'formula' : 'ingredient'
      } details`,
      onClick: () => navigationClick(node.id, node.ingredientType)
    },
    verified: !node.isPublic
  }
}

const mapSupplierCell = (
  node: IngredientBreakdownNode,
  getIngredientSuppliers: (ingredientId: string) => Promise<
    {
      id: string
      name: string
    }[]
  >,
  onSelect: (formulaIngredientId: string, supplierId: string) => void
): SupplierCellProps => {
  const supplierName = isFormula(node)
    ? 'No supplier'
    : (node as SimpleIngredientBreakdownNode).supplier?.name ?? 'No supplier'
  return {
    currentSupplierName: supplierName,
    getIngredientSuppliers: () => getIngredientSuppliers(node.id),
    onSelect: (supplierId: string) =>
      onSelect(node.formulaIngredientId, supplierId),
    hidden: isFormula(node)
  }
}

const mapActiveCell = (
  node: IngredientBreakdownNode,
  onToggle: (formulaIngredientId: string, value: boolean) => void
): ActiveCellProps => {
  return {
    active: node.isActive,
    onToggle: (value) => onToggle(node.formulaIngredientId, value)
  }
}

const mapCostCell = (node: IngredientBreakdownNode): CostCellProps => {
  return {
    totalCost: node.cost.rounded
  }
}

const mapWastePercentageCell = (
  node: IngredientBreakdownNode,
  onChange: (formulaIngredientId: string, percentage: number) => void
): WastePercentageCellProps => {
  return {
    value: node.proportions.wastePct.rounded,
    onChange: (percentage) => onChange(node.formulaIngredientId, percentage)
  }
}

const mapMoistureLossCell = (
  node: IngredientBreakdownNode
): MoistureLossCellProps => {
  return {
    value: node.quantities.inheritedMoistureLoss.rounded
  }
}

const mapYieldQuantityCell = (
  node: IngredientBreakdownNode,
  showPercentages: boolean
): YieldQuantityCellProps => {
  return {
    amount:
      node.quantities.yieldQuantity.convertedValues[node.inputMeasurement.type!]
        ?.rounded || 0,
    amountInGrams: node.quantities.yieldQuantity.rounded,
    percentage: node.proportions.pctInRootPostMoistureLoss.rounded,
    showPercentages: showPercentages,
    unit: node.inputMeasurement.unit
  }
}

const mapInputQuantityCell = (
  node: IngredientBreakdownNode,
  showPercentages: boolean,
  onAmountChange: (
    formulaIngredientId: string,
    amount: number,
    measurementId: string
  ) => void,
  onMeasurementChange: (
    formulaIngredientId: string,
    measurementId: string
  ) => void,
  getAvailableMeasurements: (
    ingredientId: string
  ) => Promise<MeasurementProps[]>
): InputQuantityCellProps => {
  return {
    amount: {
      value:
        node.quantities.inputQuantity.convertedValues[
          node.inputMeasurement.type!
        ]?.rounded || 0,
      valueInGrams: node.quantities.inputQuantity.rounded,
      percentage: node.proportions.pctInParentPreWasteRemoval.rounded,
      onUpdate: (amount) =>
        onAmountChange(
          node.formulaIngredientId,
          amount,
          node.inputMeasurement.id
        )
    },
    showPercentages: showPercentages,
    measurement: {
      id: node.inputMeasurement.id,
      unit: node.inputMeasurement.unit,
      onUpdate: (measurementId) =>
        onMeasurementChange(node.formulaIngredientId, measurementId),
      getAvailableMeasurements: () => getAvailableMeasurements(node.id)
    }
  }
}

const isFormula = (node: IngredientBreakdownNode): boolean => {
  return node.ingredientType === IngredientType.FORMULA
}

const isExpandable = (node: IngredientBreakdownNode): boolean => {
  return (
    isFormula(node) &&
    (node as unknown as FormulaBreakdownNode).children.length > 0
  )
}

export interface MapNodeToRowArgs {
  node: IngredientBreakdownNode
  showPercentages: boolean
  navigationClick: (id: string, type: IngredientType) => void
  getIngredientSuppliers: (ingredientId: string) => Promise<
    {
      id: string
      name: string
    }[]
  >
  onSelectSupplier: (formulaIngredientId: string, supplierId: string) => void
  toggleActive: (formulaIngredientId: string, value: boolean) => void
  onWastePercentageChange: (
    formulaIngredientId: string,
    percentage: number
  ) => void
  onAmountChange: (
    formulaIngredientId: string,
    amount: number,
    measurementId: string
  ) => void
  onMeasurementChange: (
    formulaIngredientId: string,
    measurementId: string
  ) => void
  getAvailableMeasurements: (
    ingredientId: string
  ) => Promise<MeasurementProps[]>
  checked: boolean
  onRowCheckedChange: (checked: boolean, formulaIngredientId: string) => void
  visibility: Visiblity
  disabled: boolean
  level: number
  visibleWidth?: string
}

const mapYieldAdjustments = (
  node: IngredientBreakdownNode
): YieldAdjustmentProps[] | undefined => {
  if (isFormula(node)) {
    const formulaNode = node as unknown as FormulaBreakdownNode
    if (formulaNode.inputAdjustments.moistureLoss) {
      return [
        {
          name: 'moisture',
          type: formulaNode.inputAdjustments.moistureLoss.adjustmentType,
          amount:
            formulaNode.inputAdjustments.moistureLoss.adjustmentType ===
            YieldAdjustmentType.LOSS
              ? formulaNode.inputAdjustments.moistureLoss.moistureLossQuantity
                  .rounded
              : formulaNode.inputAdjustments.moistureLoss.targetYieldQuantity
                  .rounded,
          percentage:
            formulaNode.inputAdjustments.moistureLoss.adjustmentType ===
            YieldAdjustmentType.LOSS
              ? formulaNode.inputAdjustments.moistureLoss.moistureLossPct
                  .rounded
              : formulaNode.inputAdjustments.moistureLoss.targetMoisturePct
                  .rounded
        }
      ]
    }
  }
}

export const mapNodeToRow = ({
  node,
  showPercentages,
  navigationClick,
  getIngredientSuppliers,
  onSelectSupplier,
  toggleActive,
  onWastePercentageChange,
  onAmountChange,
  onMeasurementChange,
  getAvailableMeasurements,
  checked,
  onRowCheckedChange,
  visibility,
  disabled,
  level,
  visibleWidth
}: MapNodeToRowArgs): IngredientRowGroupProps => {
  return {
    formulaIngredientId: node.formulaIngredientId || '',
    cells: {
      ingredient: mapIngredientCell(node, navigationClick),
      supplier: mapSupplierCell(node, getIngredientSuppliers, onSelectSupplier),
      active: mapActiveCell(node, toggleActive),
      cost: mapCostCell(node),
      wastePercentage: mapWastePercentageCell(node, onWastePercentageChange),
      moistureLoss: mapMoistureLossCell(node),
      yieldQuantity: mapYieldQuantityCell(node, showPercentages),
      inputQuantity: mapInputQuantityCell(
        node,
        showPercentages,
        onAmountChange,
        onMeasurementChange,
        getAvailableMeasurements
      )
    },
    checked: checked,
    onRowCheckedChange: (checked) =>
      onRowCheckedChange(checked, node.formulaIngredientId),
    visibility: visibility,
    disabled: disabled,
    expandable: isExpandable(node),
    level: level,
    visibleWidth: visibleWidth,
    yieldAdjustments: mapYieldAdjustments(node),
    children: isFormula(node)
      ? (node as unknown as FormulaBreakdownNode).children.map((child) =>
          mapNodeToRow({
            node: child,
            showPercentages,
            navigationClick,
            getIngredientSuppliers,
            onSelectSupplier,
            toggleActive,
            onWastePercentageChange,
            onAmountChange,
            onMeasurementChange,
            getAvailableMeasurements,
            checked,
            onRowCheckedChange,
            visibility,
            disabled,
            level: level + 1,
            visibleWidth
          })
        )
      : []
  }
}
