import { FlexContainerItemRow } from 'components/NutritionLabel/components/NutritionLabelStyles'
import { NutritionLabelTextCanadian } from 'components/NutritionLabel/components/NutritionLabelTextCanadian'
import { pointToPixel } from 'components/NutritionLabel/helpers'

export const INDENTATION_POINTS = 6

export interface ItemRowSettings {
  level?: number
  boldName?: boolean
  boldPercentage?: boolean
  topLine?: boolean
}

export interface ItemRowValues {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export interface ItemRowProps extends ItemRowSettings {
  name: string
  amount?: string | number
  unit?: string
  dvPercentage?: string
  override?: React.ReactNode
}

export const ItemRow: React.FC<ItemRowProps> = ({
  name,
  amount,
  unit,
  dvPercentage,
  level = 0,
  boldName = false,
  boldPercentage = false,
  override
}) => {
  return (
    <>
      <FlexContainerItemRow
        style={{
          paddingLeft: pointToPixel(level * INDENTATION_POINTS)
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'baseline'
          }}
        >
          {!override && (
            <>
              <NutritionLabelTextCanadian
                bold={boldName}
                points={8}
                additionalLineHeight={1}
              >
                {name}
              </NutritionLabelTextCanadian>
              <NutritionLabelTextCanadian points={8} additionalLineHeight={1}>
                {'\u00A0'}
                {amount}
                {'\u00A0'}
                {unit}
              </NutritionLabelTextCanadian>
            </>
          )}
          {override}
        </div>
        <div>
          <NutritionLabelTextCanadian
            bold={boldPercentage}
            points={8}
            additionalLineHeight={1}
          >
            {dvPercentage && `${dvPercentage} %`}
          </NutritionLabelTextCanadian>
        </div>
      </FlexContainerItemRow>
    </>
  )
}
