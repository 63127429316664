import { displayDate } from 'common/utils'
import ArchivedChip from 'components/ArchivedChip/ArchivedChip'
import { NameAvatarCell } from 'components/Cells/NameAvatarCell'
import { BoxRow, SortOrder } from 'components/common'
import { ColumnConfiguration, EntrTable } from 'components/EntrTable/EntrTable'
import { FormulasSortingField, FormulaStatus } from 'models/Formula'
import React from 'react'
import { StatusChip } from '../StatusChip'
import { UserCell } from './UserCell'

export interface FormulasTableRow {
  id: string
  name: string
  imagePath?: string
  friendlyId?: string
  createdBy?: {
    fullName?: string
    imagePath?: string
  }
  status: FormulaStatus
  createdAt: string
  updatedAt?: string
  archived: boolean
}

export interface FormulasTableProps {
  rows: FormulasTableRow[]
  allPagesLoaded: boolean
  nextPageCallback: () => void
  onRowClick: (formulaId: string) => void
  sortConfig: {
    sortField: string
    sortOrder: SortOrder
    onSort: (field: string, currentOrder: SortOrder | undefined) => void
  }
}

export const FormulasTable: React.FC<FormulasTableProps> = ({
  rows,
  allPagesLoaded,
  onRowClick,
  nextPageCallback,
  sortConfig
}) => {
  const columnConfigs: ColumnConfiguration<FormulasTableRow>[] = [
    {
      title: 'Name',
      fieldStyles: { width: '36%' },
      field: (formula) => formula.name,
      customRender: (formula) => (
        <BoxRow gap={'5px'}>
          <NameAvatarCell
            name={formula.name}
            avatarSrc={formula.imagePath || ''}
          />
          {formula.archived ? <ArchivedChip iconOnly /> : <></>}
        </BoxRow>
      ),
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.NAME,
        order: sortConfig.sortField === FormulasSortingField.NAME ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.NAME,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Friendly ID',
      field: (formula) => formula.friendlyId ?? '',
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.FRIENDLY_ID,
        order: sortConfig.sortField === FormulasSortingField.FRIENDLY_ID ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.FRIENDLY_ID,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Created By',
      field: (formula) => formula.createdBy?.fullName || '',
      customRender: (formula) => (
        <UserCell
          name={formula.createdBy?.fullName || ''}
          avatarSrc={formula.createdBy?.imagePath || ''}
        />
      ),
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.CREATED_BY,
        order: sortConfig.sortField === FormulasSortingField.CREATED_BY ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.CREATED_BY,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Status',
      field: (formula) => formula.status,
      customRender: (formula) => <StatusChip status={formula.status} />,
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.STATUS,
        order: sortConfig.sortField === FormulasSortingField.STATUS ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.STATUS,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Created On',
      field: (formula) =>
        formula.createdAt ? displayDate(formula.createdAt || '') : '',
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.CREATED_AT,
        order: sortConfig.sortField === FormulasSortingField.CREATED_AT ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.CREATED_AT,
        onSort: sortConfig.onSort
      }
    },
    {
      title: 'Last Edited',
      fieldStyles: { width: '130px' },
      field: (formula) =>
        formula.updatedAt ? displayDate(formula.updatedAt) : '',
      sortConfig: {
        active: sortConfig.sortField === FormulasSortingField.UPDATED_AT,
        order: sortConfig.sortField === FormulasSortingField.UPDATED_AT ? sortConfig.sortOrder : undefined,
        fieldSortId: FormulasSortingField.UPDATED_AT,
        onSort: sortConfig.onSort
      }
    }
  ]

  return (
    <EntrTable
      data={rows}
      allPagesLoaded={allPagesLoaded}
      columnConfigs={columnConfigs}
      onRowClick={(row) => onRowClick(row.id)}
      onLoadNextPage={nextPageCallback}
    />
  )
}
