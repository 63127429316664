import { useDocuments } from 'common/documentsHook'
import React from 'react'
import { Design } from './Design'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getProofDesignFileUrl,
  uploadProofDesignFile
} from 'state/labelproof/proofer/ProoferSlice'
import {
  getProofPins,
  STAGED_PIN_ID
} from 'state/labelproof/proofer/ProoferPinsSlice'

interface DesignContainerProps {}

export const DesignContainer: React.FC<DesignContainerProps> = ({}) => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const proof = useAppSelector((state) => state.proofer.proof)

  const designFileUrl = useAppSelector((state) => state.proofer.designFileUrl)
  const loadingDesignFileUrl = useAppSelector(
    (state) => state.proofer.loadingDesignFileUrl
  )
  const permissions = useAppSelector(
    (state) => state.proofer.proof?.permissions
  )

  const pins = useAppSelector((state) => state.prooferPins.pins)
  const activePin = useAppSelector((state) => state.prooferPins.activePin)
  const stagedPin = useAppSelector((state) => state.prooferPins.stagedPin)

  const handleUploadFile = (
    file: File,
    progressListener: (progress: number | undefined) => void
  ): Promise<void> => {
    if (!proof) {
      return Promise.reject('No proof found')
    }
    return dispatch(
      uploadProofDesignFile({
        companyId: companyId,
        proofId: proof.id,
        file: file,
        uploadProgressListener: (progressEvent) => {
          progressListener(progressEvent.progress)
        }
      })
    ).then(() => {})
  }

  const getDesignFileUrl = () => {
    if (!proof) {
      return
    }
    dispatch(
      getProofDesignFileUrl({
        companyId,
        proofId: proof.id
      })
    )
  }

  const getPins = React.useCallback(() => {
    if (!proof) {
      return
    }
    dispatch(getProofPins({ companyId, proofId: proof.id }))
  }, [companyId, proof])

  React.useEffect(() => {
    if (!proof) {
      return
    }
    getDesignFileUrl()
    getPins()
  }, [proof])

  const previewPins = React.useMemo(() => {
    const previewPins = pins.map((pin) => ({
      id: pin.id,
      x: pin.widthRatio,
      y: pin.heightRatio,
      hidden: pin.resolved,
      draggable: pin.permissions.repositionPin,
      highlighted: activePin?.id === pin.id,
      user: pin.createdBy && {
        name: pin.createdBy.firstName + ' ' + pin.createdBy.lastName,
        avatarSrc: pin.createdBy.imagePath
      }
    }))

    if (stagedPin) {
      previewPins.push({
        id: STAGED_PIN_ID,
        x: stagedPin.widthRatio,
        y: stagedPin.heightRatio,
        hidden: false,
        draggable: true,
        highlighted: activePin?.id === STAGED_PIN_ID,
        user: {
          name: currentUser.firstName + ' ' + currentUser.lastName,
          avatarSrc: currentUser.imagePath
        }
      })
    }
    return previewPins
  }, [pins, activePin?.id, stagedPin, currentUser])

  const { uploadDocumentsCallback: uploadFiles } = useDocuments({
    onUploadDocument: handleUploadFile,
    refresh: getDesignFileUrl,
    validFileTypes: ['image/png'],
    invalidFileTypeMessage: 'Only PNG is currently supported'
  })

  const showInfoBanner = React.useMemo(
    () => previewPins.length === 0 && !loadingDesignFileUrl && !!designFileUrl,
    [previewPins, loadingDesignFileUrl, designFileUrl]
  )

  return (
    <Design
      uploader={{
        uploadFile: (file) => uploadFiles([file]),
        uploadDisabled: !permissions?.uploadDesignFile
      }}
      preview={{
        fileUrl: designFileUrl,
        pins: previewPins
      }}
      loadingDesignFileUrl={loadingDesignFileUrl}
      showInfoBanner={showInfoBanner}
    />
  )
}
