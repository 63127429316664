import React from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Activities } from './Activities'
import {
  getProofActivities,
  resetProoferActivitiesState
} from 'state/labelproof/proofer/ProoferActivitiesSlice'
import { useLoadNextPage } from 'common/hooks'
import { mapActivitiesToCommentsAndLogsProps } from './ActivitiesMappers'
import { setActivePin } from 'state/labelproof/proofer/ProoferPinsSlice'

export const ActivitiesContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const loading = useAppSelector(
    (state) => state.prooferActivities.loadingActivities
  )
  const activities = useAppSelector(
    (state) => state.prooferActivities.activities.items
  )
  const currentPage = useAppSelector(
    (state) => state.prooferActivities.activities.page
  )
  const pages = useAppSelector(
    (state) => state.prooferActivities.activities.pages
  )
  const permissions = useAppSelector(
    (state) => state.proofer.proof?.permissions
  )
  const pins = useAppSelector((state) => state.prooferPins.pins)

  const activitiesLoading = React.useMemo(() => {
    return loading && activities.length === 0
  }, [loading, activities.length])

  const setCurrentPageCallback = React.useCallback(
    (page: number) => {
      if (proofId) {
        void dispatch(
          getProofActivities({
            companyId,
            proofId,
            page
          })
        )
      }
    },
    [companyId, proofId]
  )

  const { nextPageCallback, allPagesLoaded } = useLoadNextPage({
    page: currentPage,
    pages,
    setCurrentPageCallback
  })

  // Initially get the first page of activities
  React.useEffect(() => {
    setCurrentPageCallback(1)
  }, [pins, setCurrentPageCallback])

  React.useEffect(() => {
    return () => {
      dispatch(resetProoferActivitiesState())
    }
  }, [])

  const onPinClick = React.useCallback(
    (id: string) => {
      void dispatch(setActivePin({ id }))
    },
    [dispatch]
  )

  const activitiesProps = React.useMemo(() => {
    return mapActivitiesToCommentsAndLogsProps({
      activities,
      currentUserId: currentUser.id,
      onPinClick,
      config: {
        filterOutComments: !permissions?.listComments,
        filterOutLogs: !permissions?.listActivity
      }
    })
  }, [activities, permissions, currentUser.id, onPinClick])

  return (
    <Activities
      loading={activitiesLoading}
      activitiesProps={activitiesProps}
      nextPageCallback={nextPageCallback}
      allPagesLoaded={allPagesLoaded}
      page={currentPage}
    />
  )
}
