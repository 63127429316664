import { SortOrder } from 'components/common'
import React from 'react'
import { SetURLSearchParams, useSearchParams } from 'react-router-dom'
import { getNextSortOrder } from './utils'

export function useQuery(): [
  { [key: string]: string | undefined },
  SetURLSearchParams,
  URLSearchParams
] {
  const [searchParams, setSearchParams] = useSearchParams()
  const getValues = () => {
    const o: { [key: string]: string } = {}
    searchParams.forEach((v, k) => (o[k] = v))
    return o
  }
  const [obj, setObj] = React.useState(getValues())
  React.useEffect(() => {
    setObj(getValues())
  }, [searchParams])

  return [obj, setSearchParams, searchParams]
}

interface UseLoadNextPageArgs {
  page: number
  pages: number
  setCurrentPageCallback: (page: number) => void
}

export function useLoadNextPage({
  page,
  pages,
  setCurrentPageCallback
}: UseLoadNextPageArgs): {
  nextPageCallback: () => void
  allPagesLoaded: boolean
} {
  const [allPagesLoaded, setAllPagesLoaded] = React.useState(false)

  const nextPageCallback = React.useCallback(() => {
    if (page + 1 <= pages) {
      setCurrentPageCallback(page + 1)
    }
  }, [page, pages, setCurrentPageCallback])

  React.useEffect(() => {
    // In a case where there is no data, page = 1 and pages = 0.
    setAllPagesLoaded(page >= pages)
  }, [page, pages])

  return { nextPageCallback, allPagesLoaded }
}

// Helper function to get value at a nested path
const getValueAtPath = <T>(item: T, path: string[]): any => {
  if (!path || path.length === 0) {
    return undefined
  }

  return path.reduce((acc: any, key: string) => {
    if (acc && typeof acc === 'object' && key in acc) {
      return acc[key]
    }
    return undefined
  }, item)
}
interface UseSorterByFieldArgs<T> {
  items: T[]
  idProperty: string
  sortingOrderCycle: (SortOrder | undefined)[]
}

interface UseSorterByFieldReturn<T> {
  items: T[]
  sortingFieldId?: string
  sortingOrder: SortOrder | undefined
  updateSortingFieldPath: (sortByPath: string[]) => void
  sortingFieldIdGen: (fieldPath: string[]) => string
}

export function useSorterByField<T>({
  items: originalItems,
  idProperty,
  sortingOrderCycle
}: UseSorterByFieldArgs<T>): UseSorterByFieldReturn<T> {
  const initialOrder = React.useMemo(
    () => (sortingOrderCycle.length > 0 ? sortingOrderCycle[0] : undefined),
    [sortingOrderCycle]
  )
  const [config, setConfig] = React.useState<{
    fieldPath: string[]
    order: SortOrder | undefined
  }>({ fieldPath: [], order: initialOrder })

  const [sortedItems, setSortedItems] = React.useState<T[]>([])

  const [idToRankMap, setIdToRankMap] = React.useState<
    Record<string, number> | undefined
  >()

  React.useEffect(() => {
    setConfig({ fieldPath: [], order: initialOrder })
    if (!idToRankMap) {
      setSortedItems(originalItems)
      return
    }
    setSortedItems(
      [...originalItems].sort((a: any, b: any) => {
        const rankA = idToRankMap[a[idProperty] as string] ?? -1
        const rankB = idToRankMap[b[idProperty] as string] ?? -1
        return rankA - rankB
      })
    )
  }, [originalItems])

  React.useEffect(() => {
    if (config.fieldPath.length === 0) {
      return
    }
    if (config.order === undefined) {
      setSortedItems(originalItems)
    } else {
      setSortedItems(sortItems(originalItems, config.fieldPath, config.order))
    }
  }, [config.fieldPath, config.order])

  React.useEffect(() => {
    const newIdToRankMap: Record<string, number> = {}
    sortedItems.forEach((item: any, index) => {
      const itemId = item[idProperty]
      if (item && itemId) {
        newIdToRankMap[itemId] = index
      }
    })
    setIdToRankMap(newIdToRankMap)
  }, [sortedItems])

  const updateSortingFieldPath = (pathToField: string[]) =>
    setConfig({
      fieldPath: pathToField,
      order:
        pathToField === config.fieldPath
          ? getNextSortOrder(config.order, sortingOrderCycle)
          : initialOrder
    })

  const sortItems = (
    items: T[],
    field: string[],
    order: SortOrder | undefined
  ): T[] => {
    const newItems = [...items].sort((a, b) => {
      const aValue = getValueAtPath(a, field)
      const bValue = getValueAtPath(b, field)

      if (aValue === undefined && bValue === undefined) return 0
      if (aValue === undefined) return order === SortOrder.ASC ? -1 : 1
      if (bValue === undefined) return order === SortOrder.ASC ? 1 : -1

      if (aValue === null && bValue === null) return 0
      if (aValue === null) return order === SortOrder.ASC ? -1 : 1
      if (bValue === null) return order === SortOrder.ASC ? 1 : -1

      if (aValue < bValue) return order === SortOrder.ASC ? -1 : 1
      if (aValue > bValue) return order === SortOrder.ASC ? 1 : -1

      return 0
    })

    newItems.forEach((item) => {
      if (item !== null && typeof item === 'object' && 'children' in item) {
        item.children = sortItems(item.children as T[], field, order)
      }
    })

    return newItems
  }

  const sortingFieldIdGen = (fieldPath: string[]): string => {
    return fieldPath.join('-')
  }

  const sortingFieldId = React.useMemo(() => {
    return config.fieldPath.length > 0
      ? sortingFieldIdGen(config.fieldPath)
      : undefined
  }, [config.fieldPath])

  return {
    items: sortedItems.length > 0 ? sortedItems : originalItems,
    sortingFieldId,
    sortingOrder: config.order,
    updateSortingFieldPath,
    sortingFieldIdGen
  }
}
