import { OpenInNew } from "@mui/icons-material"
import { Typography } from "@mui/material"
import { pluralize } from "common/utils"
import { BoxColumnBasic, BoxRow } from "components/common"


export interface InvalidPDIngredientsProps {
    ingredients: {
        id: string
        name: string
    }[]
    onIngredientClick: (id: string) => void
}


export const InvalidPDIngredients: React.FC<InvalidPDIngredientsProps> = ({
    ingredients,
    onIngredientClick
}) => {

    return (<BoxColumnBasic sx={{ gap: '5px' }}>
        <Typography>
            <b>PDCAAS could not be calculated because of missing data.</b>
        </Typography>
        <Typography>
            {`You can enter a PDCAAS score in the field below, or you can complete the protein digestibility information for the following ${pluralize(ingredients.length, 'ingredient')} and then refresh your page to calculate:`}
        </Typography>
        <BoxRow sx={{ flexWrap: 'wrap' }}>
            {ingredients.map(({ id, name }, index) => (
                <BoxRow
                    key={id}
                >
                    <Typography
                        style={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={
                            () => onIngredientClick && onIngredientClick(id)
                        }>{name}</Typography>
                    <OpenInNew style={{ fontSize: '14' }} />
                    {index < ingredients.length - 1 && <Typography sx={{ marginRight: '4px' }}>,</Typography>}
                </BoxRow>
            ))}
        </BoxRow>
    </BoxColumnBasic>)
}