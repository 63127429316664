import { Popover, TableCell, TableRow, Typography, styled } from '@mui/material'
import { BoxRow, BoxRowBasic } from 'components/common'
import React from 'react'
import IngredientsBreakdown, {
  IngredientBreakdownProps,
  ModifierProps
} from './IngredientsBreakdown'
import { convertToFixedFloat } from 'common/utils'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'

const formatNumber = (value?: number) => {
  if (value === undefined || value === null) {
    return ''
  }
  return convertToFixedFloat(value, 2)
}

const UnitTypography = styled(Typography)`
  margin-left: 3px;
  font-size: 14px;
  color: ${(props) => props.theme.palette.tertiary.main};
`

const StyledPopover = styled(Popover)(() => ({
  '& .MuiPopover-paper': {
    width: '25%'
  }
}))

export interface NutrientRowProps {
  nutrient: {
    id: string
    name: string
    amount: number
    unit: string
    dvPercentage?: number
    ingredientsBreakdown?: IngredientBreakdownProps[]
    modifiers?: ModifierProps[]
    info?: string
  }
  level?: number
}

export const NutrientRow: React.FC<NutrientRowProps> = ({
  nutrient,
  level = 0
}) => {
  const hoverTimer = React.useRef<NodeJS.Timeout | null>(null)
  const [popoverAnchorEl, setPopoverAnchorEl] =
    React.useState<HTMLElement | null>(null)
  const [filteredIngredientsBreakdown, setFilteredIngredientsBreakdown] =
    React.useState<IngredientBreakdownProps[]>([])
  const [filteredModifiers, setFilteredModifiers] = React.useState<
    ModifierProps[]
  >([])

  React.useEffect(() => {
    setFilteredIngredientsBreakdown(
      (nutrient.ingredientsBreakdown || []).filter(
        (nib) => nib.nutrient.amount > 0.01
      )
    )
  }, [nutrient.ingredientsBreakdown])

  React.useEffect(() => {
    setFilteredModifiers(
      (nutrient.modifiers || []).filter(
        (m) => m.amount > 0.01 || m.amount < 0.01
      )
    )
  }, [nutrient.modifiers])

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    if (filteredIngredientsBreakdown.length > 0) {
      handlePopoverOpen(event)
    }
  }

  const handleMouseLeave = () => {
    handlePopoverClose()
  }

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    const currentTarget = event.currentTarget
    hoverTimer.current = setTimeout(() => {
      setPopoverAnchorEl(currentTarget)
    }, 200) // Set timer for 0.2 second
  }

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null)
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current) // Clear the timer if it's still running
      hoverTimer.current = null
    }
  }

  return (
    <>
      <TableRow
        key={nutrient.id + '-nutrient-row'}
        hover
        onMouseEnter={(event) => handleMouseEnter(event)}
        onMouseLeave={() => handleMouseLeave()}
      >
        <TableCell align="left">
          <BoxRow sx={{ gap: '5px' }}>
            <Typography
              sx={{
                fontWeight: level > 0 ? '400' : '500',
                fontSize: level > 0 ? '14px' : '16px',
                marginLeft: level > 0 ? `${level * 12}px` : '0px'
              }}
            >
              {nutrient.name}
            </Typography>
            {nutrient.info && (
              <InfoBubble
                text={<MarkdownRenderer markdownText={nutrient.info} />}
              />
            )}
          </BoxRow>
        </TableCell>
        <TableCell align="left">
          <BoxRowBasic>
            <Typography fontSize={'16px'}>
              {formatNumber(nutrient.amount)}
            </Typography>
            <UnitTypography>{nutrient.unit}</UnitTypography>
          </BoxRowBasic>
        </TableCell>
        <TableCell align="left">
          <BoxRowBasic>
            {nutrient.dvPercentage !== undefined &&
            nutrient.dvPercentage !== null ? (
              <>
                {formatNumber(nutrient.dvPercentage * 100)}
                <UnitTypography>%</UnitTypography>
              </>
            ) : (
              ''
            )}
          </BoxRowBasic>
        </TableCell>
      </TableRow>
      <StyledPopover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        onClose={handleMouseLeave}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        sx={{ pointerEvents: 'none' }}
      >
        <IngredientsBreakdown
          ingredientsBreakdown={filteredIngredientsBreakdown}
          modifiers={filteredModifiers}
        ></IngredientsBreakdown>
      </StyledPopover>
    </>
  )
}

export default NutrientRow
