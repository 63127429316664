import { ModalContext } from 'components/Modal/ModalContext'
import {
  mapCommentToProps,
  mapCommentToStagedComment
} from 'pages/LabelProofs/LabelProofer/components/Panel/tabs/Activities/ActivitiesMappers'
import React from 'react'
import { MentionItem } from 'react-mentions'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  ActivePin,
  createPinWithComment,
  createProofPinComment,
  deleteProofPinComment,
  resetPinsStagedComment,
  setActivePin,
  setEditingPinsComment,
  setPinsStagedComment,
  setStagedPin,
  STAGED_PIN_ID,
  updateProofPinComment
} from 'state/labelproof/proofer/ProoferPinsSlice'
import {
  CreateProofPinCommentRequestData,
  UpdateProofPinCommentRequestData
} from 'state/labelproof/proofer/ProoferRequest'
import { PinThread } from './PinThread'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'

export const PinThreadContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)
  const activePin: ActivePin | null = useAppSelector(
    (state) => state.prooferPins.activePin
  )
  const stagedPin = useAppSelector((state) => state.prooferPins.stagedPin)
  const pins = useAppSelector((state) => state.prooferPins.pins)
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const { showConfirmationModal } = React.useContext(ModalContext)
  const activeTab = useAppSelector((state) => state.proofer.activeTab)
  const pinsStagedComment = useAppSelector(
    (state) => state.prooferPins.stagedPinComment
  )
  const editingPinsComment = useAppSelector(
    (state) => state.prooferPins.editingPinsComment
  )
  const { showError } = React.useContext(SnackbarContext)

  const onPinsCommentDelete = React.useCallback(
    (commentId: string) => {
      if (proofId && activePin) {
        showConfirmationModal({
          title: 'Delete Comment',
          message: <>Are you sure you want to delete this comment?</>,
          yesText: 'Delete',
          noText: 'Cancel',
          onYesClicked: () => {
            void dispatch(
              deleteProofPinComment({
                companyId,
                proofId,
                commentId,
                pinId: activePin?.id
              })
            )
              .unwrap()
              .catch(() => {
                showError('Failed to delete comment')
              })
          },
          danger: true
        })
      }
    },
    [dispatch, companyId, proofId]
  )

  const onEditPinsCommentClick = (id: string) => {
    if (!activePin) {
      return
    }
    const pin = pins.find((pin) => pin.id === activePin.id)
    if (!pin) {
      return
    }
    const comment = pin.comments.find((comment) => comment.id === id)
    if (!comment) {
      return
    }
    dispatch(setPinsStagedComment(mapCommentToStagedComment(comment)))
    dispatch(setEditingPinsComment(true))
  }

  const onSubmitComment = React.useCallback(
    async (comment: CreateProofPinCommentRequestData) => {
      if (proofId && activePin) {
        if (
          activePin.id === STAGED_PIN_ID &&
          stagedPin &&
          comment.comment.trim()
        ) {
          dispatch(
            createPinWithComment({
              companyId,
              proofId,
              widthRatio: stagedPin.widthRatio,
              heightRatio: stagedPin.heightRatio,
              comment: comment.comment,
              mentionedUserIds: comment.mentionedUserIds
            })
          )
            .unwrap()
            .then((pin) => {
              dispatch(setActivePin(null))
              dispatch(setStagedPin(null))
              dispatch(resetPinsStagedComment())
              // timeout to avoid flickering
              setTimeout(() => {
                dispatch(setActivePin({ id: pin.id }))
              }
              , 0)
            })
          return
        }

        await dispatch(
          createProofPinComment({
            companyId,
            proofId,
            data: {
              ...comment,
              pinId: activePin.id
            }
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetPinsStagedComment())
          })
          .catch(() => {
            showError('Failed to create comment')
          })
      }
    },
    [dispatch, companyId, proofId, activePin?.id, activeTab, stagedPin]
  )

  const onUpdateComment = React.useCallback(
    async (commentId: string, comment: UpdateProofPinCommentRequestData) => {
      if (proofId && activePin) {
        await dispatch(
          updateProofPinComment({
            companyId,
            proofId,
            commentId,
            data: comment,
            pinId: activePin?.id
          })
        )
          .unwrap()
          .then(() => {
            dispatch(resetPinsStagedComment())
          })
          .catch(() => {
            showError('Failed to update comment')
          })
      }
    },
    [dispatch, companyId, proofId, activePin?.id]
  )

  const setEditingPinsCommentState = React.useCallback(
    (editing: boolean) => {
      dispatch(setEditingPinsComment(editing))
    },
    [dispatch]
  )

  const resetPinsStagedCommentState = React.useCallback(() => {
    dispatch(resetPinsStagedComment())
  }, [dispatch])

  const onPinsCommentTextChange = React.useCallback(
    (newValue: string, mentions: MentionItem[]) => {
      dispatch(
        setPinsStagedComment({
          ...pinsStagedComment,
          comment: newValue,
          mentionedUserIds: mentions.map((mention) => mention.id)
        })
      )
    },
    [dispatch, pinsStagedComment]
  )

  const activePinCommentsProps = React.useMemo(() => {
    if (!activePin) {
      return []
    }
    const pin = pins.find((pin) => pin.id === activePin.id)
    const comments = pin?.comments || []
    return comments.map((comment) =>
      mapCommentToProps({
        comment,
        currentUserId: currentUser.id,
        onDeleteClick: onPinsCommentDelete,
        onEditClick: onEditPinsCommentClick,
        withPins: false
      })
    )
  }, [activePin, pins, activePin?.id])

  return (
    <PinThread
      comments={activePinCommentsProps}
      formProps={{
        onSubmit: onSubmitComment,
        onUpdate: onUpdateComment,
        onRemoveReply: () => {},
        stagedComment: pinsStagedComment,
        onChange: onPinsCommentTextChange,
        editingComment: editingPinsComment,
        resetStagedComment: resetPinsStagedCommentState,
        setEditingComment: setEditingPinsCommentState
      }}
    />
  )
}
