import { Button } from 'components/Button/Button'
import { BoxColumnBasic } from 'components/common'
import { NumberField, NumberFieldProps } from 'components/InputFields/NumberField'
import React from 'react'
import { MainColors } from 'styles/colors'

export interface OverrideNumberFieldProps extends NumberFieldProps<true> {
  originalValue: number | null
  onResetClick?: () => void
}

/**
 * Shows a number field that can be overridden by the user.
 * If focused, user would start with original value.
 */
export const OverrideNumberField: React.FC<OverrideNumberFieldProps> = ({
  originalValue,
  onResetClick,
  ...props
}) => {
  const [currentValue, setCurrentValue] = React.useState<number | null | undefined>(
    props.value
  )

  React.useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const handleFocus = () => {
    if (props.value === null) {
      setCurrentValue(originalValue)
    }
  }

  const handleBlur = () => {
    if (props.value === null) {
      setCurrentValue(null)
    }
  }

  return (
    <NumberField
      {...props}
      onFocus={handleFocus}
      onBlur={handleBlur}
      value={currentValue}
      defaultValue={originalValue}
      placeholder={originalValue?.toString()}
      sx={{
        backgroundColor: props.value != null && props.value != originalValue
          ? MainColors.lightWarning
          : 'inherit'
      }}
      endAdornment={
        props.value != originalValue &&
        onResetClick && (
          <BoxColumnBasic alignItems={'flex-end'}>
            <Button variant="text" size="small" onClick={onResetClick}>
              Reset
            </Button>
          </BoxColumnBasic>
        )
      }
    />
  )
}
