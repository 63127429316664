import { Delete } from '@mui/icons-material'
import { Box, Collapse, IconButton, Tooltip, Typography } from '@mui/material'
import { ReactComponent as SmallDropdownArrow } from 'assets/SmallDropdownArrow.svg'
import { BoxRow } from 'components/common'
import React from 'react'

export interface FormulaDetailsProps {
  notesContainer: React.ReactNode
  viewControllerContainer: React.ReactNode
  ingredientsDelete: {
    showButton: boolean
    onRemoveSelectedIngredients: () => void
  }
  addControllerContainer: React.ReactNode
}

export const FormulaDetails: React.FC<FormulaDetailsProps> = ({
  notesContainer,
  viewControllerContainer,
  ingredientsDelete,
  addControllerContainer
}) => {
  const [showNotes, setShowNotes] = React.useState(false)
  const [showNotesContent, setShowNotesContent] = React.useState(false)

  const handleShowNotesClick = React.useCallback(() => {
    setShowNotes(!showNotes)
    setShowNotesContent(!showNotesContent)
  }, [showNotes, showNotesContent])

  return (
    <Box width={'100%'}>
      <Collapse in={showNotes}>{notesContainer}</Collapse>
      <BoxRow justifyContent={'space-between'} alignContent={'center'}>
        <BoxRow>
          <Typography variant="subtitle1">Formula Notes</Typography>
          <IconButton disableRipple onClick={handleShowNotesClick}>
            {showNotes ? (
              <SmallDropdownArrow style={{ transform: 'rotate(180deg)' }} />
            ) : (
              <SmallDropdownArrow />
            )}
          </IconButton>

          {ingredientsDelete.showButton && (
            <Tooltip title="Remove Selected" placement="right">
              <IconButton
                onClick={ingredientsDelete.onRemoveSelectedIngredients}
              >
                <Delete color="error" />
              </IconButton>
            </Tooltip>
          )}
        </BoxRow>
        <BoxRow sx={{ gap: '10px' }}>
          {viewControllerContainer}
          {addControllerContainer}
        </BoxRow>
      </BoxRow>
    </Box>
  )
}
