import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'
import { GetTokenSilentlyOptions } from '@auth0/auth0-react';
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';

// Get the necessary domain and clientId from the environment variables.
const baseURL = process.env.REACT_APP_AXIOS_URL

// applyCaseMiddleware transforms our data from snake case to camelcase and vice versa.
const _api = applyCaseMiddleware(
  axios.create({
    withCredentials: false,
    baseURL: baseURL
  })
)

type GetAccessTokenSilentlyFn = {
  (options: GetTokenSilentlyOptions & {
    detailedResponse: true;
  }): Promise<GetTokenSilentlyVerboseResponse>;
  (options?: GetTokenSilentlyOptions): Promise<string>;
};

// Added for integration tests.
export type GetTokenFn = () => string;

// Adds access tokens in all api requests. This has to be called from App.
// this interceptor is only added when the auth0 instance is ready and exports the getAccessTokenSilently method.
export const addAccessTokenInterceptor = (getAccessTokenSilently: GetAccessTokenSilentlyFn | GetTokenFn) => {
  _api.interceptors.request.use(async (config) => {
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};

export const api = _api