import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { useDebounceCallback } from 'usehooks-ts'

export interface FormulatorIngredientsProps {
  statusBar: React.ReactNode
  formulaDetails: React.ReactNode
  ingredientsTable: React.ReactNode
  totalsBar: React.ReactNode
  yieldAdjustments: React.ReactNode
  setVisibleWidth?: (width: string) => void
}

export const FormulatorIngredients: React.FC<FormulatorIngredientsProps> = ({
  statusBar,
  formulaDetails,
  ingredientsTable,
  totalsBar,
  yieldAdjustments,
  setVisibleWidth
}) => {
  const visibleBoxRef = React.useRef<HTMLTableRowElement>(null)

  const handleResize = useDebounceCallback(() => {
    if (visibleBoxRef.current && setVisibleWidth) {
      // Client width is the width of the box in which the scroll is.
      // 36px is the width of the side bar arrow.
      setVisibleWidth(`${visibleBoxRef.current.clientWidth - 36}px`)
    }
  }, 100)

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (entries[0].contentRect) {
        handleResize()
      }
    })
    if (visibleBoxRef.current) {
      resizeObserver.observe(visibleBoxRef.current)
    }

    return () => {
      if (visibleBoxRef.current) {
        resizeObserver.unobserve(visibleBoxRef.current)
      }
    }
  }, [handleResize, visibleBoxRef, setVisibleWidth])

  return (
    <>
      <Box
        paddingLeft={'4px'}
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1
        }}
      >
        {statusBar}
        <Box>{formulaDetails}</Box>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            marginTop: '20px'
          }}
        >
          <Box
            sx={{
              flex: 1,
              position: 'relative',
              overflowY: 'auto',
              marginBottom: '20px'
            }}
          >
            <Box
              ref={visibleBoxRef}
              sx={{
                position: 'absolute',
                paddingBottom: '1rem',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '15px',
                justifyContent: 'space-between'
              }}
            >
              <BoxColumnBasic
                sx={{
                  justifyContent: 'space-between',
                  height: '100%',
                  width: 'fit-content',
                  gap: '15px',
                  minWidth: '100%'
                }}
              >
                {ingredientsTable}
                {yieldAdjustments}
              </BoxColumnBasic>
            </Box>
          </Box>
          {totalsBar}
        </Box>
      </Box>
    </>
  )
}
