import React from 'react'
import { Panel } from './Panel'
import { ReviewContainer } from './tabs/Review/ReviewContainer'
import { LabelContainer } from './tabs/Label/LabelContainer'
import { ActivitiesContainer } from './tabs/Activities/ActivitiesContainer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { setActiveTab } from 'state/labelproof/proofer/ProoferSlice'
import { ProoferDocuments } from './tabs/Documents/ProoferDocuments'

export enum LabelProoferTab {
  REVIEW = 0,
  ACTIVITY = 1,
  LABEL = 2,
  DOCUMENTS = 3
}

export const PanelContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const activeTab = useAppSelector((state) => state.proofer.activeTab)
  const handleTabChange = React.useCallback((newTab: LabelProoferTab) => {
    dispatch(setActiveTab(newTab))
  }, [])
  return (
    <Panel
      reviewTabContent={<ReviewContainer />}
      activityTabContent={<ActivitiesContainer />}
      labelTabContent={<LabelContainer />}
      documentsTabContent={<ProoferDocuments />}
      activeTab={activeTab}
      onTabChange={handleTabChange}
    />
  )
}
