import { CardContent, CardMedia, Tooltip, useTheme } from '@mui/material'
import { ReactComponent as FormulasIcon } from 'assets/FormulasOutline.svg'
import { applyFakeTransparency, displayDate, selectColor } from 'common/utils'
import { BoxColumnBasic, BoxRow } from 'components/common'
import React from 'react'
import { MainColors } from 'styles/colors'
import {
  FooterTypography,
  FormulaTypography,
  TitleTypography
} from './ProofCardContentStyles'

export interface ProofCardProps {
  id: string
  title: string
  formulaName: string
  completedTasks: number
  totalTasks: number
  creationDate: string
  imageUrl?: string
  completed?: boolean
}

export const ProofCardContent: React.FC<ProofCardProps> = ({
  title,
  formulaName,
  completedTasks,
  totalTasks,
  creationDate,
  imageUrl,
  completed = false
}) => {
  const {
    palette: { formulaColors }
  } = useTheme()
  const color = React.useMemo(() => {
    const colors: string[] = Object.entries(formulaColors).map(
      ([, color]) => color.main
    )
    return selectColor(title, colors)
  }, [title])

  const dateText = React.useMemo(
    () => displayDate(creationDate),
    [creationDate]
  )

  return (
    <>
      {imageUrl ? (
        <CardMedia component="img" image={imageUrl} alt={title} />
      ) : (
        <CardMedia
          component="div"
          sx={{
            backgroundColor: applyFakeTransparency(color, 0.15),
            height: '130px'
          }}
        />
      )}
      <CardContent sx={{ height: '120px' }}>
        <BoxColumnBasic
          sx={{ justifyContent: 'space-between', height: '100%' }}
        >
          <BoxColumnBasic sx={{ gap: '10px' }}>
            <BoxRow sx={{ justifyContent: 'space-between' }}>
              <BoxRow sx={{ gap: '5px' }}>
                <FormulasIcon />
                <Tooltip title={formulaName} enterNextDelay={1000}>
                  <FormulaTypography noWrap={true}>
                    {formulaName}
                  </FormulaTypography>
                </Tooltip>
              </BoxRow>
            </BoxRow>
            <Tooltip title={title} enterNextDelay={1000}>
              <TitleTypography noWrap={true}>{title}</TitleTypography>
            </Tooltip>
          </BoxColumnBasic>
          <BoxRow sx={{ justifyContent: 'space-between' }}>
            {totalTasks > 0 ? (
              <FooterTypography
                color={
                  completedTasks === totalTasks ? MainColors.success : undefined
                }
              >
                {completed
                  ? 'Complete'
                  : `${completedTasks}/${totalTasks} complete`}
              </FooterTypography>
            ) : (
              <FooterTypography></FooterTypography>
            )}
            <FooterTypography>{dateText}</FooterTypography>
          </BoxRow>
        </BoxColumnBasic>
      </CardContent>
    </>
  )
}
