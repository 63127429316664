import { Typography } from '@mui/material'
import React from 'react'

import { ReactComponent as CloseCircle } from 'assets/CloseCircle.svg'
import { TextField } from 'components/TextField/TextField'
import { BoxRow } from 'components/common'
import { NumberField } from 'components/InputFields/NumberField'

export interface Measurement {
  amount: number
  unit: string
  onChange: (amount: number) => void
}

export interface ConversionItemProps {
  id: string
  fromMeasurement: Measurement
  toMeasurement: Measurement
  onRemove: (id: string) => void
  disabled?: boolean
}

export const ConversionItem: React.FC<ConversionItemProps> = ({
  id,
  fromMeasurement,
  toMeasurement,
  onRemove,
  disabled
}) => {
  const MAX_DECIMALS = 6

  return (
    <BoxRow sx={{ gap: '5px' }}>
      <BoxRow sx={{ gap: '5px', flex: '1' }}>
        <NumberField
          value={fromMeasurement.amount}
          onChange={(v) => fromMeasurement.onChange(v)}
          disabled={disabled}
          validation=">0"
          endAdornment={
            <Typography whiteSpace={'nowrap'}>
              {fromMeasurement.unit}
            </Typography>
          }
          debounceTime={500}
          maxDecimals={MAX_DECIMALS}
        />
        =
        <NumberField
          value={toMeasurement.amount}
          onChange={(v) => toMeasurement.onChange(v)}
          disabled={disabled}
          validation=">0"
          endAdornment={
            <Typography whiteSpace={'nowrap'}>{toMeasurement.unit}</Typography>
          }
          debounceTime={500}
          maxDecimals={MAX_DECIMALS}
        />
      </BoxRow>
      {!disabled && (
        <CloseCircle
          style={{ cursor: 'pointer' }}
          onClick={() => onRemove(id)}
          role="button"
          title="remove"
          aria-label="remove"
        />
      )}
    </BoxRow>
  )
}
