import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import { BoxColumnBasic, SortOrder } from 'components/common'
import { SortableTableHeaderCell } from 'components/SortableTableHeaderCell/SortableTableHeaderCell'
import { FormulaTableHeaderCell } from 'pages/Formulator/FormulatorStyles'
import React from 'react'
import { IngredientRowSkeleton } from './components/row/IngredientRow'
import {
  IngredientRowGroup,
  IngredientRowGroupProps
} from './components/row/IngredientRowGroup'
import { IngredientSearchRow } from './components/search/IngredientSearchRow'

export interface IngredientTableProps {
  showSupplier?: boolean
  showCost?: boolean
  showActive?: boolean
  showYield?: boolean
  showWastePercentage?: boolean
  showQuantityPercentage?: boolean
  loading?: boolean
  ingredientSearch: React.ReactNode
  ingredientRows?: IngredientRowGroupProps[]
  sortingSettings: {
    sortingFieldId?: string
    sortingOrder: SortOrder | undefined
    updateSortingFieldPath: (sortByPath: string[]) => void
    sortingFieldIdGen: (fieldPath: string[]) => string
  }
  visibleWidth?: string
}

const propertyToSortByPaths = {
  name: ['cells', 'ingredient', 'name'],
  supplier: ['cells', 'supplier', 'currentSupplierName'],
  inputQuantity: ['cells', 'inputQuantity', 'amount', 'valueInGrams'],
  cost: ['cells', 'cost', 'totalCost'],
  wastePercentage: ['cells', 'wastePercentage', 'value'],
  yieldQuantity: ['cells', 'yieldQuantity', 'amountInGrams'],
  active: ['cells', 'active', 'active']
}

export const IngredientsTable: React.FC<IngredientTableProps> = ({
  showSupplier = false,
  showCost = false,
  showYield = false,
  showActive = false,
  showWastePercentage = false,
  loading = false,
  ingredientSearch,
  sortingSettings,
  ingredientRows = [],
  visibleWidth
}) => {
  const allIngredientsChecked = React.useMemo(() => {
    return ingredientRows.length > 0 && ingredientRows.every((ir) => ir.checked)
  }, [ingredientRows])

  const handleCheckAllIngredientsChange = React.useCallback(() => {
    const checked = allIngredientsChecked
    ingredientRows.forEach((ir) => {
      ir.onRowCheckedChange(!checked)
    })
  }, [ingredientRows, allIngredientsChecked])

  const getPlaceholderRows = (rows: number) => {
    const cellCount = getSearchRowSpan()
    return (
      <>
        {Array.from({ length: rows }).map((_, rowIdx) => (
          <IngredientRowSkeleton key={rowIdx} colSpan={cellCount} />
        ))}
      </>
    )
  }

  const getSearchRowSpan = () => {
    return (
      3 +
      [
        showSupplier,
        showCost,
        showWastePercentage,
        showActive
        // showMoistureLoss
      ].reduce((acc, val) => acc + (val ? 1 : 0), 0)
    )
  }

  return (
    <BoxColumnBasic>
      <Table size="small" sx={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 2,
              background: 'white'
            }}
          >
            <FormulaTableHeaderCell
              sx={{
                width: '25px',
                position: 'sticky',
                left: 0,
                zIndex: 1,
                background: 'white'
              }}
              key="check"
            >
              <Checkbox
                size="small"
                checked={allIngredientsChecked}
                onChange={() => handleCheckAllIngredientsChange()}
                sx={{ padding: 0 }}
              />
            </FormulaTableHeaderCell>
            <SortableTableHeaderCell
              id={sortingSettings.sortingFieldIdGen(propertyToSortByPaths.name)}
              sortingSettings={sortingSettings}
              propertyToSortByPath={propertyToSortByPaths.name}
              label="Ingredient"
              containerComponent={FormulaTableHeaderCell}
              containerProps={{
                sx: {
                  minWidth: '300px',
                  width: '100%',
                  position: 'sticky',
                  left: '28px',
                  background: 'white',
                  zIndex: 1
                }
              }}
            />
            {showActive && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.active
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.active}
                label="Active?"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{ sx: { width: '40px' } }}
              />
            )}
            {showSupplier && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.supplier
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.supplier}
                label="Supplier"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{
                  sx: { minWidth: '200px' }
                }}
              />
            )}
            {showCost && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.cost
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.cost}
                label="Cost"
                containerComponent={FormulaTableHeaderCell}
              />
            )}

            <SortableTableHeaderCell
              id={sortingSettings.sortingFieldIdGen(
                propertyToSortByPaths.inputQuantity
              )}
              sortingSettings={sortingSettings}
              propertyToSortByPath={propertyToSortByPaths.inputQuantity}
              label="Input Quantity"
              containerComponent={FormulaTableHeaderCell}
              containerProps={{
                align: 'right'
              }}
            />

            {showWastePercentage && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.wastePercentage
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.wastePercentage}
                label="Waste"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{
                  sx: {
                    width: '100px'
                  }
                }}
              />
            )}

            {showYield && (
              <SortableTableHeaderCell
                id={sortingSettings.sortingFieldIdGen(
                  propertyToSortByPaths.yieldQuantity
                )}
                sortingSettings={sortingSettings}
                propertyToSortByPath={propertyToSortByPaths.yieldQuantity}
                label="Yield"
                containerComponent={FormulaTableHeaderCell}
                containerProps={{
                  align: 'right'
                }}
              />
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <IngredientSearchRow
            ingredientSearch={ingredientSearch}
            span={getSearchRowSpan()}
            width={visibleWidth}
          />
          {loading
            ? getPlaceholderRows(5)
            : ingredientRows.map((ir) => (
                <IngredientRowGroup
                  key={`ir-${ir.formulaIngredientId}`}
                  {...ir}
                  visibleWidth={visibleWidth}
                />
              ))}
        </TableBody>
      </Table>
    </BoxColumnBasic>
  )
}
