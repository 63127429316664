import { IngredientSearchResultsPaginated } from 'models/Ingredient'
import { api } from '../config/AxiosConfig'
import { toIngredientSearchResultsPaginated } from './IngredientApiMapper'
import {
  AdvancedSearchIngredientsApiParams,
  SearchIngredientsApiParams
} from './IngredientApiRequest'
import {
  IngredientFormulaUsageApiResponse,
  IngredientSearchApiPaginatedResponse
} from './IngredientApiResponse'

export const IngredientApi = {
  searchIngredients: (
    companyId: string,
    params: SearchIngredientsApiParams
  ): Promise<IngredientSearchResultsPaginated> => {
    return api
      .get<IngredientSearchApiPaginatedResponse>(
        `/companies/${companyId}/ingredients/search`,
        {
          params: params
        }
      )
      .then((res) => {
        return toIngredientSearchResultsPaginated(res.data)
      })
  },
  advancedSearchIngredients: (
    companyId: string,
    params: AdvancedSearchIngredientsApiParams
  ): Promise<IngredientSearchResultsPaginated> => {
    return api
      .get<IngredientSearchApiPaginatedResponse>(
        `/companies/${companyId}/ingredients/advanced_search`,
        {
          params: params,
          paramsSerializer: {
            indexes: null
          }
        }
      )
      .then((res) => {
        return toIngredientSearchResultsPaginated(res.data)
      })
  },
  getIngredientFormulaUsage: (
    companyId: string,
    ingredientId: string
  ): Promise<IngredientFormulaUsageApiResponse> => {
    return api
      .get<IngredientFormulaUsageApiResponse>(
        `/companies/${companyId}/ingredients/${ingredientId}/usage`
      )
      .then((res) => res.data)
  }
}
