import { AppState, Auth0Provider } from '@auth0/auth0-react'
import React from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { Auth0TokenInterceptor } from './AuthTokenInterceptor'

/**
 * The Auth0ProviderWithNavigate is a wrapper for Auth0Provider.
 * It helps with navigating the user to the last page they were at in the event of
 * a required re-authentication (login).
 */
export const Auth0ProviderWithNavigate = ({
  children
}: {
  children: React.ReactNode
}) => {
  const navigate: NavigateFunction = useNavigate()

  // Get the necessary domain and clientId from the environment variables.
  const domain = process.env.REACT_APP_AUTH0_DOMAIN
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE
  const environment = process.env.REACT_APP_ENVIRONMENT

  const onRedirectCallback = (appState?: AppState) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    navigate(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience
      }}
      cacheLocation={environment === 'local' ? 'localstorage' : 'memory'}
      onRedirectCallback={onRedirectCallback}
    >
      <Auth0TokenInterceptor>{children}</Auth0TokenInterceptor>
    </Auth0Provider>
  )
}
