import React from 'react'
import { pointToPixel } from '../helpers'

export type SeparatorType = 'normal' | 'thick' | 'hairline'
export type SeparatorOrientation = 'vertical' | 'horizontal'
export type SeparatorRegulation = 'canadian' | 'fda'

const separatorCanadian: Record<SeparatorType, number> = {
  hairline: 0.5,
  normal: 1,
  thick: 2.5
}

const separatorFDA: Record<SeparatorType, number> = {
  hairline: 0.25,
  normal: 3,
  thick: 7
}

export interface SeparatorProps {
  type?: SeparatorType
  regulation?: SeparatorRegulation
  short?: number
  orientation?: SeparatorOrientation
  margin?: number
}

export const NutritionFactsRule: React.FC<SeparatorProps> = ({
  type = 'normal',
  short = 0,
  orientation = 'horizontal',
  regulation = 'fda',
  margin = 0
}) => {
  const sizeInPt =
    regulation === 'fda' ? separatorFDA[type] : separatorCanadian[type]
  const sizePx = pointToPixel(sizeInPt)
  const marginPx = pointToPixel(margin)

  const marginTopBottom = orientation === 'horizontal' ? `${marginPx}` : `0px`
  const shortMarginLeft = pointToPixel(10 * short)
  const marginSides = orientation === 'horizontal' ? '0px' : `${marginPx}`

  return (
    <div
      style={{
        backgroundColor: 'black',
        width: orientation === 'horizontal' ? undefined : sizePx,
        height: orientation === 'horizontal' ? sizePx : undefined,
        marginTop: marginTopBottom,
        marginLeft: short > 0 ? shortMarginLeft : marginSides,
        marginBottom: marginTopBottom,
        marginRight: marginSides
      }}
    />
  )
}
