import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import {
  FormulaIngredientStatement,
  FormulaIngredientsFormatPreview
} from 'models/FormulaLabel'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  fromFormulaIngredientsStatementFormatsPreview,
  fromSubFormulaStatementFormat
} from 'services/apis/formula/FormulaApiMapper'
import { LoadingState, UpdatingState } from '../../CommonState'
import {
  GetFormulaIngredientStatementRequest,
  UpdateFormulaIngredientStatementFormatRequest,
  UpdateFormulaIngredientStatementRequest
} from './IngredientStatementsRequest'

export interface IngredientStatementsState extends LoadingState, UpdatingState {
  ingredientStatement?: FormulaIngredientStatement
  ingredientStatementPreview?: FormulaIngredientStatement
  formulaIngredientsStatementFormatPreview?: FormulaIngredientsFormatPreview[]
}

const initialState: IngredientStatementsState = {
  error: false,
  updating: false,
  loading: false
}

export const getFormulaIngredientStatementPreview = createAsyncThunk(
  'formulas/ingredientStatementPreview/get',
  async ({
    companyId,
    formulaId,
    statementCase,
    subIngredientStatementCase,
    regulationId,
    isSupplement,
    formulaIngredientsStatementFormatPreview,
    withSuggestions = false
  }: GetFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.getIngredientStatement(
      companyId,
      formulaId,
      withSuggestions,
      regulationId,
      statementCase,
      subIngredientStatementCase,
      isSupplement,
      {
        formulaIngredientsStatementFormat:
          formulaIngredientsStatementFormatPreview
            ? fromFormulaIngredientsStatementFormatsPreview(
                formulaIngredientsStatementFormatPreview
              )
            : undefined
      }
    )
  }
)

export const updateFormulaIngredientStatementFormat = createAsyncThunk(
  'formulas/ingredients/statementFormat/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    formatInStatement
  }: UpdateFormulaIngredientStatementFormatRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { formatInStatement: fromSubFormulaStatementFormat(formatInStatement) }
    )
  }
)

export const getFormulaIngredientStatement = createAsyncThunk(
  'formulas/ingredientStatement/get',
  async ({
    companyId,
    formulaId,
    statementCase,
    subIngredientStatementCase,
    regulationId,
    isSupplement,
    withSuggestions = false
  }: GetFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.getIngredientStatement(
      companyId,
      formulaId,
      withSuggestions,
      regulationId,
      statementCase,
      subIngredientStatementCase,
      isSupplement
    )
  }
)

export const updateFormulaIngredientStatement = createAsyncThunk(
  'formulas/ingredientStatement/update',
  async ({
    companyId,
    formulaId,
    ingredientStatement
  }: UpdateFormulaIngredientStatementRequest): Promise<FormulaIngredientStatement> => {
    return await FormulaApi.updateIngredientStatement(companyId, formulaId, {
      ingredientStatement
    })
  }
)

const labelSlice = createSlice({
  name: 'labelSlice',
  initialState,
  reducers: {
    setFormulaIngredientsStatementFormatPreview(
      state,
      action: { payload: FormulaIngredientsFormatPreview[] }
    ) {
      state.formulaIngredientsStatementFormatPreview = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(getFormulaIngredientStatement.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getFormulaIngredientStatement.fulfilled,
      (state, action) => {
        state.ingredientStatement = action.payload
        state.loading = false
      }
    )
    builder.addCase(getFormulaIngredientStatement.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(getFormulaIngredientStatementPreview.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getFormulaIngredientStatementPreview.fulfilled,
      (state, action) => {
        state.ingredientStatementPreview = action.payload
        state.loading = false
      }
    )
    builder.addCase(getFormulaIngredientStatementPreview.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    builder.addCase(updateFormulaIngredientStatement.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientStatement.fulfilled,
      (state, action) => {
        state.ingredientStatement = {
          ...state.ingredientStatement,
          ingredientStatement: action.payload.ingredientStatement
        }
        state.loading = false
      }
    )
    builder.addCase(updateFormulaIngredientStatement.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    // Update formula statement Format.
    builder.addCase(updateFormulaIngredientStatementFormat.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(
      updateFormulaIngredientStatementFormat.fulfilled,
      (state, action) => {
        state.updating = false
      }
    )
    builder.addCase(
      updateFormulaIngredientStatementFormat.rejected,
      (state) => {
        state.updating = false
        state.error = true
      }
    )
  }
})

export const { setFormulaIngredientsStatementFormatPreview } =
  labelSlice.actions

export default labelSlice.reducer
