import { convertToFixedFloat } from 'common/utils'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { GrowProps, InputField, InputFieldProps } from './InputField'
import {
  runValidations,
  validateCharacters,
  validateDecimal,
  validateMinusSign,
  validateZeros,
  ValidationResult
} from './validation'
import { toCh } from './helper'

export interface NumberFieldProps<E extends boolean = false>
  extends Omit<InputFieldProps, 'value' | 'onChange'> {
  onChange: (value: E extends true ? number | null : number) => void
  value?: E extends true ? number | null : number
  defaultValue?: E extends true ? number | null : number
  debounceTime?: number
  maxDecimals?: number
  validation?: '>0' | '>=0'
  allowEmptyValue?: E
  additionalValidators?: ((value: string) => ValidationResult)[]
  onInternalValueChange?: (
    value: string,
    incomplete: boolean,
    invalid: boolean
  ) => void
}

export const NumberField = <E extends boolean = false>({
  onChange,
  onInternalValueChange,
  value = null as E extends true ? number | null : number,
  defaultValue,
  debounceTime = 0,
  maxDecimals = 0,
  validation,
  allowEmptyValue,
  additionalValidators,
  grow,
  growProps,
  ...rest
}: NumberFieldProps<E>) => {
  const internalGrowProps: GrowProps = React.useMemo(() => {
    return {
      extraChars: 0,
      mode: 'minimum',
      ...growProps
    }
  }, [growProps])

  const positive = ['>0', '>=0'].includes(validation ?? '')
  const nonZero = validation === '>0'
  const [pendingSubmission, setPendingSubmission] =
    React.useState<boolean>(false)
  const [incompleteInput, setIncompleteInput] = React.useState<boolean>(false)
  const [invalidInput, setInvalidInput] = React.useState<boolean>(false)

  // Conditionally set the default value based on allowEmptyValue
  const computedDefaultValue =
    defaultValue !== undefined ? defaultValue : allowEmptyValue ? null : 0

  // Start initially with a clean number.
  const [internalValue, setInternalValue] = React.useState<string>(
    value === null ? '' : convertToFixedFloat(value, maxDecimals, true)
  )
  // Save the last valid input as a fallback value.
  const [lastValidInput, setLastValidInput] = React.useState<string>(
    value === null ? '' : convertToFixedFloat(value, maxDecimals, true)
  )

  /**
   * Checks if user is still typing an incomplete number.
   * @param v The number to check.
   * @returns True if the user is still typing, false otherwise.
   */
  const isUserStillTyping = (v: string): boolean => {
    return (v === '' && !allowEmptyValue) || incompleteInput
  }

  /**
   * Marks that there is a pending change to be sent and starts the debounce timer.
   * @param newValue The value to be sent.
   */
  const submitChanges = (newValue: string) => {
    if (!areValuesEqual(newValue, value)) {
      setLastValidInput(newValue)
      setPendingSubmission(true)
      handleInputChangeDebounced(newValue)
    }
  }

  const cancelSubmit = () => {
    setPendingSubmission(false)
    handleInputChangeDebounced.cancel()
  }

  /**
   * Checks if 2 values are equal based on the conversion to a fixed float.
   * @param valueA The first value.
   * @param valueB The second value.
   * @returns True if the values are equal, false otherwise.
   */
  const areValuesEqual = (
    valueA?: string | number | null,
    valueB?: string | number | null
  ): boolean => {
    const a = convertToFixedFloat(valueA ?? '', maxDecimals, true)
    const b = convertToFixedFloat(valueB ?? '', maxDecimals, true)
    return a === b
  }

  React.useEffect(() => {
    // Check if the user is still typing and if there are no pending submissions.
    if (!isUserStillTyping(internalValue) && !pendingSubmission) {
      // If the value is different from the internal value, update the internal value with a clean input.
      if (!areValuesEqual(value, internalValue)) {
        const newValue =
          value === null ? '' : convertToFixedFloat(value, maxDecimals, true)
        setInternalValue(newValue)
      }
    }
  }, [value])

  const handleInputChangeDebounced = useDebouncedCallback(
    (inputValue: string) => {
      if (onChange && inputValue === '') {
        onChange(null as E extends true ? number | null : number)
        setPendingSubmission(false)
        return
      }
      // Send the input as a number to the parent component.
      const numberInputValue = Number(inputValue)
      if (onChange) {
        onChange(numberInputValue)
        setPendingSubmission(false)
      }
    },
    debounceTime
  )

  React.useEffect(() => {
    onInternalValueChange &&
      onInternalValueChange(internalValue, incompleteInput, invalidInput)
  }, [internalValue])

  const handleInputChange = (inputValue: string) => {
    if (inputValue === '' && allowEmptyValue) {
      setIncompleteInput(false)
      setInvalidInput(false)
      setInternalValue(inputValue)
      submitChanges(inputValue)
      return
    }
    // If it's an empty string, don't do anything until it's blurred.
    if (inputValue === '') {
      setInternalValue(inputValue)
      cancelSubmit()
      return
    }

    // Prepare the validations.
    const validations = [
      (value: string) => validateCharacters(value),
      (value: string) => validateDecimal(value, maxDecimals),
      (value: string) => validateMinusSign(value, positive),
      (value: string) => validateZeros(value, nonZero),
      ...(additionalValidators ?? [])
    ]

    const result = runValidations(inputValue, validations)
    setIncompleteInput(result.status === 'incomplete')

    // If the validation result has an input.
    if (result.newInput) {
      // Update the internal value and its validity.
      setInvalidInput(result.invalid ?? false)
      setInternalValue(result.newInput)
      // If the validation passed, submit the changes.
      if (result.status === 'pass') {
        submitChanges(result.newInput)
      }
    }
  }

  const handleBlur = () => {
    if (internalValue === '' && allowEmptyValue) {
      // Reset all the flags and submit the changes.
      setIncompleteInput(false)
      setInvalidInput(false)
      setInternalValue(internalValue)
      submitChanges(internalValue)
      return
    }
    // If the user was still typing an invalid number and does not have pending submissions.
    let newInternalValue = internalValue
    if ((internalValue === '' || invalidInput) && !pendingSubmission) {
      // Default to computedDefaultValue if possible or to the last valid input.
      newInternalValue = nonZero
        ? lastValidInput
        : computedDefaultValue === null
        ? ''
        : computedDefaultValue.toString()
    } else {
      // Otherwise, format the internal value properly regardless of submission.
      newInternalValue = convertToFixedFloat(internalValue, maxDecimals, true)
    }
    // Reset all the flags and submit the changes.
    setIncompleteInput(false)
    setInvalidInput(false)
    setInternalValue(newInternalValue)
    submitChanges(newInternalValue)
  }

  return (
    <InputField
      value={internalValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      {...rest}
      sx={{
        ...(grow && {
          [internalGrowProps.mode === 'strict' ? 'width' : 'minWidth']: toCh(
            internalValue,
            3,
            3 + (internalGrowProps.extraChars || 0)
          )
        }),
        ...rest.sx
      }}
    />
  )
}
