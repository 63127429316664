import { EntrTableContainer } from 'components/common'
import React from 'react'

export interface AdvancedIngredientSearchProps {
  filterAndSearch: React.ReactNode
  table: React.ReactNode
}

export const AdvancedIngredientSearch: React.FC<
  AdvancedIngredientSearchProps
> = ({ filterAndSearch, table }) => {
  return (
    <EntrTableContainer
      style={{
        overflow: 'hidden auto',
        height: '70vh',
        width: '90vw',
        margin: '0px'
      }}
    >
      {filterAndSearch}
      {table}
    </EntrTableContainer>
  )
}
