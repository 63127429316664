import { styled } from '@mui/system'
import { ReactComponent as PinIcon } from 'assets/PinIcon.svg'

export const BlueHighlight = styled('span')({
  background: '#057deb1a',
  padding: '3px',
  borderRadius: '4px',
  display: 'inline',
  fontSize: '12px',
  fontWeight: '600',
  color: '#057DEB'
})

export type TextParserFunction = (text: string) => React.ReactNode
type PinParser = (onClick?: (id: string) => void) => TextParserFunction

export type TextParser = (
  map: Record<string, string>,
  onClick?: (id: string) => void
) => TextParserFunction

export const mentionParser: TextParser =
  (map) =>
  (text): React.ReactNode => {
    let result = undefined
    const mentionRegex = /(^|[^@])@\{(.*?)\}/g
    const parts = text.split(mentionRegex)
    parts.forEach((part, index) => {
      if (index % 3 === 2) {
        const userId = part
        const userName = map[userId] || 'Invalid User'
        result = <BlueHighlight key={index}>{userName}</BlueHighlight>
      }
    })
    return result
  }

export const pinParser: PinParser =
  (onClick) =>
  (text): React.ReactNode => {
    let result = undefined
    const pinRegex = /(^|[^@])@@\[(.*?)\]/g
    const parts = text.split(pinRegex)
    parts.forEach((part, index) => {
      if (index % 3 === 2) {
        const pinId = part
        const pinName = 'Pin ' + pinId.substring(0, 4).toUpperCase()
        result = (
          <BlueHighlight
            sx={{ cursor: onClick ? 'pointer' : 'default' }}
            onClick={onClick ? () => onClick(pinId) : undefined}
          >
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                verticalAlign: 'bottom'
              }}
            >
              <PinIcon style={{ marginRight: '4px' }} /> {pinName}
            </span>
          </BlueHighlight>
        )
      }
    })
    return result
  }
