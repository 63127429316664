import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'
import React from 'react'
import {
  addFormulaRegulationStatementCase,
  updateFormulaIsSupplement
} from 'state/formulator/FormulatorSlice'
import {
  addFormulaAllergens,
  getFormulaAllergens,
  removeFormulaAllergens
} from 'state/formulator/allergens/FormulaAllergensSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  addNutritionFactLabelOptionalNutrients,
  removeNutritionFactLabelOptionalNutrients,
  updateActiveIngredientOverride,
  updateFormulaContainerWeight,
  updateFormulaIngredientDisplaySection,
  updateNutritionFactLabelNutrientsOverride,
  updateNutritionFactsLabel,
  updateNutritionFactsLabelLangSpecificFields
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import NutritionFactsEditor from './NutritionFactsEditor'
import { EditorPanelContainer } from './components/EditorPanel/EditorPanelContainer'
import { EditorPreviewContainer } from './components/EditorPreview/EditorPreviewContainer'
import isEqual from 'lodash.isequal'
import {
  mapFormulaIngredientsToActiveIngredientsSectionDisplay,
  mapFormulaIngredientsToStatementFormatsPreview
} from 'services/apis/formula/FormulaApiMapper'
import { getFormulaIngredients } from 'state/formulator/ingredients/FormulatorIngredientsSlice'
import { SupplementFacts } from 'models/SupplementFacts'
import { updateFormulaIngredientStatementFormat } from 'state/labels/statements/IngredientStatementsSlice'

export interface NutritionFactsEditorContainerProps {
  show: boolean
  onClose: () => void
}

export const NutritionFactsEditorContainer: React.FC<
  NutritionFactsEditorContainerProps
> = ({ show, onClose }) => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )

  // Label type.
  const originalIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const editedIsSupplement = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )

  // Container Weight.
  const originalContainerWeight = useAppSelector(
    (state) => state.formulator.formula.containerWeight
  )
  const editedContainerWeight = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.containerWeight
  )

  // Allergen Statements.
  const editedAllergenStatement = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.allergens
  )
  const originalAllergens = useAppSelector(
    (state) => state.formulatorAllergens.formulaAllergens.allergens
  )

  // Optional nutrients.
  const editedOptionalNutrients = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.optionalNutrientsType
  )
  const originalOptionalNutrients =
    useAppSelector(
      (state) =>
        state.nutritionFactLabels.nutritionFactLabel?.optionalNutrientsType
    ) || []

  // Nutrition Facts.
  const editedNutritionFacts = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview
  )
  const originalNutritionFacts = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel
  )

  // statementCase.
  const statementCase = useAppSelector(
    (state) =>
      state.formulator.formula.formulaRegulations.find(
        (regulation) =>
          regulation.regulationId === editedNutritionFacts.regulationId
      )?.statementCase
  )
  const editedStatementCase = useAppSelector(
    (state) =>
      state.formulator.formulaRegulationsSettingsEdits.find(
        (edit) => edit.regulationId === editedNutritionFacts.regulationId
      )?.statementCase
  )

  // formula supplement.
  const formulaIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const formulaIsSupplementEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.isSupplement
  )

  // Sub-formula statement format
  const formulaIngredientsStatementFormatPreview = useAppSelector(
    (state) =>
      state.ingredientStatements.formulaIngredientsStatementFormatPreview
  )
  const originalFormulaIngredientsStatementFormat = React.useMemo(() => {
    return mapFormulaIngredientsToStatementFormatsPreview(
      formulaIsSupplement || formulaIsSupplementEdits,
      formulaIngredients
    )
  }, [formulaIngredients])

  // Formula ingredients label section display.
  const activeIngredientsSectionDisplayPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .activeIngredientsSectionDisplay
  )
  const originalActiveIngredientsSectionDisplay = React.useMemo(() => {
    return mapFormulaIngredientsToActiveIngredientsSectionDisplay(
      formulaIsSupplement || formulaIsSupplementEdits,
      formulaIngredients
    )
  }, [formulaIngredients, formulaIsSupplement, formulaIsSupplementEdits])

  const handleDiscard = React.useCallback(() => {
    onClose()
  }, [onClose])

  // Container Weight.
  const containerWeightChanged = React.useMemo(() => {
    return originalContainerWeight !== editedContainerWeight
  }, [originalContainerWeight, editedContainerWeight])
  const saveContainerWeight = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (containerWeightChanged) {
        void dispatch(
          updateFormulaContainerWeight({
            companyId: currentCompany.id,
            formulaId: formulaId,
            containerWeight: editedContainerWeight
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [
    currentCompany.id,
    formulaId,
    containerWeightChanged,
    editedContainerWeight
  ])

  // Allergens.
  const allergensChanged = React.useMemo(() => {
    return (
      JSON.stringify(originalAllergens.map((a) => a.type)) !==
      JSON.stringify(editedAllergenStatement)
    )
  }, [originalAllergens, editedAllergenStatement])
  const saveAllergens = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (allergensChanged) {
        const addRemoveAllergensPromises: Promise<any>[] = []
        // Get the added and removed allergens.
        const addedAllergens = editedAllergenStatement.filter(
          (a) => !originalAllergens.some((oa) => oa.type === a)
        )
        const removedAllergens = originalAllergens
          .map((oa) => oa.type)
          .filter((a) => !editedAllergenStatement.includes(a))
        if (addedAllergens.length > 0) {
          addRemoveAllergensPromises.push(
            dispatch(
              addFormulaAllergens({
                companyId: currentCompany.id,
                formulaId: formulaId,
                allergenTypes: addedAllergens
              })
            )
          )
        }
        if (removedAllergens.length > 0) {
          addRemoveAllergensPromises.push(
            dispatch(
              removeFormulaAllergens({
                companyId: currentCompany.id,
                formulaId: formulaId,
                allergenTypes: removedAllergens
              })
            )
          )
        }
        Promise.all(addRemoveAllergensPromises)
          .then(() => {
            resolve(true)
            void dispatch(
              getFormulaAllergens({
                companyId: currentCompany.id,
                formulaId: formulaId,
                regulationId: editedNutritionFacts.regulationId
              })
            )
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [
    currentCompany.id,
    formulaId,
    allergensChanged,
    originalAllergens,
    editedAllergenStatement
  ])

  // Override active ingredients.
  const overrideActiveIngredientsChanged = React.useMemo(() => {
    return (
      JSON.stringify(
        (originalNutritionFacts as SupplementFacts)?.activeIngredients
      ) !==
      JSON.stringify(
        (editedNutritionFacts as SupplementFacts)?.activeIngredients
      )
    )
  }, [originalNutritionFacts, editedNutritionFacts])
  const saveOverrideActiveIngredients =
    React.useCallback((): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        if (overrideActiveIngredientsChanged) {
          // Build the override payload.
          // Only get the override values that are different than the original one.
          const activeIngredientOverrides = (
            (editedNutritionFacts as SupplementFacts)?.activeIngredients || []
          )
            .filter((ai) => {
              const originalActiveIngredient = (
                (originalNutritionFacts as SupplementFacts)
                  ?.activeIngredients || []
              ).find((on) => on.id === ai.id)

              return (
                ai.overrideValue?.name !==
                  originalActiveIngredient?.overrideValue?.name ||
                ai.overrideValue?.amount !==
                  originalActiveIngredient?.overrideValue?.amount
              )
            })
            .map((ai) => ({
              ingredientId: ai.id,
              ingredientName: ai.overrideValue?.name,
              ingredientAmount: ai.overrideValue?.amount
            }))

          void dispatch(
            updateActiveIngredientOverride({
              companyId: currentCompany.id,
              formulaId: formulaId,
              overrides: activeIngredientOverrides
            })
          )
            .then(() => {
              resolve(true)
            })
            .catch((e) => {
              console.error(e)
              reject(false)
            })
        } else {
          resolve(true)
        }
      })
    }, [
      editedNutritionFacts,
      currentCompany.id,
      formulaId,
      originalNutritionFacts
    ])

  // Override nutrients.
  const overrideNutrientsChanged = React.useMemo(() => {
    return (
      JSON.stringify(originalNutritionFacts?.nutrients) !==
      JSON.stringify(editedNutritionFacts.nutrients)
    )
  }, [originalNutritionFacts, editedNutritionFacts])
  const saveOverrideNutrients = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (overrideNutrientsChanged) {
        // Build the override payload.
        // Only get the override values that are different than the original one.
        const nutrients = editedNutritionFacts.nutrients
          .filter((n) => {
            const originalNutrient = originalNutritionFacts?.nutrients.find(
              (on) => on.nutrient.id === n.nutrient.id
            )
            return (
              n.overrideValues?.amount !==
                originalNutrient?.overrideValues?.amount ||
              n.overrideValues?.dv !== originalNutrient?.overrideValues?.dv ||
              !isEqual(
                n.overrideValues?.nutrientDisplayName,
                originalNutrient?.overrideValues?.nutrientDisplayName
              ) ||
              n.overrideValues?.perContainerAmount !==
                originalNutrient?.overrideValues?.perContainerAmount ||
              n.overrideValues?.perContainerDv !==
                originalNutrient?.overrideValues?.perContainerDv
            )
          })
          .map((n) => ({
            nutrientId: n.nutrient.id,
            nutrientDisplayName: n.overrideValues?.nutrientDisplayName,
            amount: n.overrideValues?.amount,
            dv: n.overrideValues?.dv,
            perContainerAmount: n.overrideValues?.perContainerAmount,
            perContainerDv: n.overrideValues?.perContainerDv
          }))

        void dispatch(
          updateNutritionFactLabelNutrientsOverride({
            companyId: currentCompany.id,
            formulaId: formulaId,
            regulationId: editedNutritionFacts.regulationId,
            nutrients: nutrients
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [editedNutritionFacts, currentCompany.id, formulaId])

  // Optional nutrients.
  const optionalNutrientsChanged = React.useMemo(() => {
    return (
      JSON.stringify([...originalOptionalNutrients].sort()) !==
      JSON.stringify([...editedOptionalNutrients].sort())
    )
  }, [originalOptionalNutrients, editedOptionalNutrients])
  const saveOptionalNutrients = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (optionalNutrientsChanged) {
        const addRemoveOptionalNutrientsPromises: Promise<any>[] = []
        // Get the added and removed allergens.
        const addedOptionalNutrients = editedOptionalNutrients.filter(
          (a) => !originalOptionalNutrients.some((oa) => oa === a)
        )
        const removedOptionalNutrients = originalOptionalNutrients.filter(
          (a) => !editedOptionalNutrients.includes(a)
        )
        if (addedOptionalNutrients.length > 0) {
          addRemoveOptionalNutrientsPromises.push(
            dispatch(
              addNutritionFactLabelOptionalNutrients({
                companyId: currentCompany.id,
                formulaId: formulaId,
                nutrientTypes: addedOptionalNutrients
              })
            )
          )
        }
        if (removedOptionalNutrients.length > 0) {
          addRemoveOptionalNutrientsPromises.push(
            dispatch(
              removeNutritionFactLabelOptionalNutrients({
                companyId: currentCompany.id,
                formulaId: formulaId,
                nutrientTypes: removedOptionalNutrients
              })
            )
          )
        }
        Promise.all(addRemoveOptionalNutrientsPromises)
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [
    currentCompany.id,
    formulaId,
    optionalNutrientsChanged,
    originalOptionalNutrients,
    editedOptionalNutrients
  ])

  // Nutrition Facts Label.
  const nutritionFactsLabelChanged = React.useMemo(() => {
    return (
      JSON.stringify(originalNutritionFacts) !==
      JSON.stringify(editedNutritionFacts)
    )
  }, [originalNutritionFacts, editedNutritionFacts])
  const saveNutritionFactsLabel = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (nutritionFactsLabelChanged) {
        void dispatch(
          updateNutritionFactsLabel({
            companyId: currentCompany.id,
            formulaId: formulaId,
            ageGroup: editedNutritionFacts.ageGroup,
            regulationId: editedNutritionFacts.regulationId,
            servingsPerContainer: editedNutritionFacts.servingsPerContainer,
            showProteinPercentage: editedNutritionFacts.showProteinPercentage,
            type: editedNutritionFacts.type,
            pdcaas: editedNutritionFacts.pdcaas,
            applyPdcaas: editedNutritionFacts.applyPdcaas,
            servingWeightOverride: editedNutritionFacts.servingWeightOverride,
            dvBasedOnRounded: editedNutritionFacts.dvBasedOnRounded
          })
        )
          .then(() => {
            const regulationLanguageUpdatesPromises: Promise<any>[] = []
            Object.values(FormulaNutritionFactLanguage).forEach(
              (language: FormulaNutritionFactLanguage) => {
                if (
                  typeof editedNutritionFacts.description[language] ===
                    'string' ||
                  typeof editedNutritionFacts.ingredientStatement[language] ===
                    'string' ||
                  typeof editedNutritionFacts.servingSize[language] === 'string'
                ) {
                  regulationLanguageUpdatesPromises.push(
                    dispatch(
                      updateNutritionFactsLabelLangSpecificFields({
                        companyId: currentCompany.id,
                        formulaId: formulaId,
                        language: language,
                        description: editedNutritionFacts.description[language],
                        ingredientStatement:
                          editedNutritionFacts.ingredientStatement[language],
                        allergenStatement:
                          editedNutritionFacts.allergenStatement[language],
                        servingSize: editedNutritionFacts.servingSize[language]
                      })
                    )
                  )
                }
              }
            )

            Promise.all(regulationLanguageUpdatesPromises)
              .then(() => {
                resolve(true)
              })
              .catch((e) => {
                console.error(e)
                reject(false)
              })
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [
    currentCompany.id,
    formulaId,
    nutritionFactsLabelChanged,
    editedNutritionFacts
  ])

  // statementCase.
  const statementCaseChanged = React.useMemo(() => {
    return statementCase !== editedStatementCase
  }, [statementCase, editedStatementCase])
  const saveStatementCase = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (statementCaseChanged && editedStatementCase) {
        // Update the statement case.
        void dispatch(
          addFormulaRegulationStatementCase({
            companyId: currentCompany.id,
            formulaId: formulaId,
            regulationId: editedNutritionFacts.regulationId,
            statementCase: editedStatementCase
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [statementCase, editedStatementCase])

  // Sub-formula statement formats.
  const formulaIngredientsStatementFormatChanged = React.useMemo(() => {
    return !isEqual(
      originalFormulaIngredientsStatementFormat,
      formulaIngredientsStatementFormatPreview
    )
  }, [
    originalFormulaIngredientsStatementFormat,
    formulaIngredientsStatementFormatPreview
  ])
  const saveFormulaIngredientsStatementFormat =
    React.useCallback((): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        if (
          formulaIngredientsStatementFormatChanged &&
          formulaIngredientsStatementFormatPreview
        ) {
          // get the formula ingredients that has their statement format changed.
          const formulaIngredientsWithChangedStatementFormat =
            formulaIngredientsStatementFormatPreview.filter((ingredient) => {
              const originalFormulaIngredient =
                originalFormulaIngredientsStatementFormat.find(
                  (originalFormulaIngredient) =>
                    originalFormulaIngredient.id === ingredient.id
                )
              return (
                originalFormulaIngredient &&
                originalFormulaIngredient.format !== ingredient.format
              )
            })
          // Update formula ingredients statement formats.
          const updateFormulaIngredientsStatementFormatPromises: Promise<any>[] =
            []
          formulaIngredientsWithChangedStatementFormat.forEach(
            (formulaIngredient) => {
              updateFormulaIngredientsStatementFormatPromises.push(
                dispatch(
                  updateFormulaIngredientStatementFormat({
                    companyId: currentCompany.id,
                    formulaId: formulaId,
                    formulaIngredientId: formulaIngredient.id,
                    formatInStatement: formulaIngredient.format
                  })
                )
              )
            }
          )
          Promise.all(updateFormulaIngredientsStatementFormatPromises)
            .then(() => {
              void dispatch(
                getFormulaIngredients({
                  companyId: currentCompany.id,
                  formulaId: formulaId
                })
              )
              resolve(true)
            })
            .catch((e) => {
              console.error(e)
              reject(false)
            })
        } else {
          resolve(true)
        }
      })
    }, [
      formulaIngredientsStatementFormatChanged,
      formulaIngredientsStatementFormatPreview
    ])

  // Active formula ingredients display section.
  const activeIngredientsDisplaySectionChanged = React.useMemo(() => {
    return !isEqual(
      originalActiveIngredientsSectionDisplay,
      activeIngredientsSectionDisplayPreview
    )
  }, [
    originalActiveIngredientsSectionDisplay,
    activeIngredientsSectionDisplayPreview
  ])
  const saveActiveIngredientsDisplaySection =
    React.useCallback((): Promise<boolean> => {
      return new Promise((resolve, reject) => {
        if (
          activeIngredientsDisplaySectionChanged &&
          activeIngredientsSectionDisplayPreview.length > 0
        ) {
          // get the formula ingredients that has their display section changed.
          const formulaIngredientsWithChangedDisplaySection =
            activeIngredientsSectionDisplayPreview.filter((ingredient) => {
              const originalFormulaIngredient =
                originalActiveIngredientsSectionDisplay.find(
                  (originalFormulaIngredient) =>
                    originalFormulaIngredient.id === ingredient.id
                )
              return (
                originalFormulaIngredient &&
                originalFormulaIngredient.displaySection !==
                  ingredient.displaySection
              )
            })
          // Update the active ingredients display section.
          const updateActiveIngredientsDisplaySectionPromises: Promise<any>[] =
            []
          formulaIngredientsWithChangedDisplaySection.forEach(
            (formulaIngredient) => {
              updateActiveIngredientsDisplaySectionPromises.push(
                dispatch(
                  updateFormulaIngredientDisplaySection({
                    companyId: currentCompany.id,
                    formulaId: formulaId,
                    formulaIngredientId: formulaIngredient.id,
                    activeIngredientDisplaySection:
                      formulaIngredient.displaySection
                  })
                )
              )
            }
          )
          Promise.all(updateActiveIngredientsDisplaySectionPromises)
            .then(() => {
              void dispatch(
                getFormulaIngredients({
                  companyId: currentCompany.id,
                  formulaId: formulaId
                })
              )
              resolve(true)
            })
            .catch((e) => {
              console.error(e)
              reject(false)
            })
        } else {
          resolve(true)
        }
      })
    }, [
      activeIngredientsDisplaySectionChanged,
      activeIngredientsSectionDisplayPreview
    ])

  // labelType.
  const isSupplementChanged = React.useMemo(() => {
    return originalIsSupplement !== editedIsSupplement
  }, [originalIsSupplement, editedIsSupplement])
  const saveIsSupplement = React.useCallback((): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      if (isSupplementChanged) {
        void dispatch(
          updateFormulaIsSupplement({
            companyId: currentCompany.id,
            formulaId: formulaId,
            isSupplement: editedIsSupplement
          })
        )
          .then(() => {
            resolve(true)
          })
          .catch((e) => {
            console.error(e)
            reject(false)
          })
      } else {
        resolve(true)
      }
    })
  }, [isSupplementChanged, editedIsSupplement])

  const saveEnabled = React.useMemo(() => {
    return (
      containerWeightChanged ||
      nutritionFactsLabelChanged ||
      allergensChanged ||
      optionalNutrientsChanged ||
      overrideNutrientsChanged ||
      statementCaseChanged ||
      isSupplementChanged ||
      formulaIngredientsStatementFormatChanged ||
      activeIngredientsDisplaySectionChanged ||
      overrideActiveIngredientsChanged
    )
  }, [
    containerWeightChanged,
    nutritionFactsLabelChanged,
    allergensChanged,
    optionalNutrientsChanged,
    overrideNutrientsChanged,
    statementCaseChanged,
    isSupplementChanged,
    formulaIngredientsStatementFormatChanged,
    activeIngredientsDisplaySectionChanged,
    overrideActiveIngredientsChanged
  ])

  const handleSave = React.useCallback(() => {
    // Type of formula should be saved prior to the rest to avoid race conditions.
    void saveIsSupplement()
      .then(() => {
        return Promise.all([
          saveContainerWeight(),
          saveNutritionFactsLabel(),
          saveAllergens(),
          saveOptionalNutrients(),
          saveOverrideNutrients(),
          saveStatementCase(),
          saveFormulaIngredientsStatementFormat(),
          saveActiveIngredientsDisplaySection(),
          saveOverrideActiveIngredients()
        ])
      })
      .finally(() => {
        onClose()
      })
  }, [
    saveContainerWeight,
    saveNutritionFactsLabel,
    saveAllergens,
    saveOptionalNutrients,
    saveOverrideNutrients,
    saveStatementCase,
    saveIsSupplement,
    saveFormulaIngredientsStatementFormat,
    saveActiveIngredientsDisplaySection,
    saveOverrideActiveIngredients
  ])

  return (
    <NutritionFactsEditor
      panel={<EditorPanelContainer visible={show} />}
      preview={<EditorPreviewContainer />}
      show={show}
      topBarProps={{
        onDiscard: handleDiscard,
        onSave: handleSave,
        saveEnabled: saveEnabled
      }}
    />
  )
}
