import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import {
  getFormulaTargets,
  resetFormulaTargetsState
} from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getTargets } from 'state/targets'
import { Targets } from './Targets'
import { TargetsSettingsContainer } from './components/TargetSettings/TargetsSettingsContainer'
import { TargetsResultsContainer } from './components/TargetsResults/TargetsResultsContainer'

export const TargetsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const companyTargets = useAppSelector((state) => state.targets.targets.items)
  const formulaTargets = useAppSelector(
    (state) => state.formulaTargets.formulaTargets
  )
  const executedTargets = useAppSelector(
    (state) => state.formulaTargets.executedCompanyTargets
  )
  const executedTargetsLoading = useAppSelector(
    (state) => state.formulaTargets.loading
  )

  const [selectingTargets, setSelectingTargets] = React.useState(false)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const handleDoneClick = React.useCallback(() => {
    setSelectingTargets(false)
  }, [selectingTargets])

  const handleEditClick = React.useCallback(() => {
    setSelectingTargets(true)
  }, [selectingTargets])

  React.useEffect(() => {
    if (formulaId && companyId) {
      formulatorAnalytics.targets.viewedTargetsTab(formulaId)
      void dispatch(
        getFormulaTargets({
          formulaId,
          companyId
        })
      )
    }
  }, [companyId, formulaId])

  React.useEffect(() => {
    if (companyId) {
      void dispatch(getTargets({ companyId }))
    }
    return () => {
      void dispatch(resetFormulaTargetsState())
    }
  }, [companyId])

  const selectedTargetsTotal = React.useMemo(() => {
    return formulaTargets.length
  }, [formulaTargets])

  const targetsTotal = React.useMemo(() => {
    return companyTargets.length
  }, [companyTargets])

  const loading = React.useMemo(() => {
    return (
      companyTargets.length === 0 ||
      (executedTargetsLoading && executedTargets.length === 0)
    )
  }, [companyTargets, executedTargets, executedTargetsLoading])

  return (
    <Targets
      targetsResults={<TargetsResultsContainer />}
      targetsSettings={<TargetsSettingsContainer />}
      targetsHeaderProps={{
        selected: selectedTargetsTotal,
        total: targetsTotal,
        onDoneClick: handleDoneClick,
        onEditClick: handleEditClick,
        editMode: selectingTargets
      }}
      loading={loading}
    />
  )
}
