import React from 'react'

import { searchIngredients } from 'state/ingredients/search/IngredientSearchSlice'

import { ModalContext } from 'components/Modal/ModalContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { getFormula } from 'state/formulator/FormulatorSlice'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { getFormulaHistory } from 'state/formulator/history/FormulaHistorySlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { IngredientSearch, SearchResult } from './IngredientSearch'
import { AdvancedIngredientSearchContainer } from './advanced/AdvancedIngredientSearchContainer'
import {
  addIngredientToFormula,
  getFormulaIngredientBreakdown
} from 'state/formulator/breakdown/BreakdownSlice'

export const IngredientSearchContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const suggestions = useAppSelector(
    (state) => state.ingredientSearch.ingredientSuggestions
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)
  const loading = useAppSelector((state) => state.ingredientSearch.loading)
  const { showModal, closeModal } = React.useContext(ModalContext)

  const refreshIngredientBreakdown = React.useCallback(() => {
    if (formulaId) {
      void dispatch(
        getFormulaIngredientBreakdown({
          companyId: currentCompany.id,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, currentCompany.id])

  const handleSearchChange = React.useCallback(
    (newSearchTerm: string) => {
      formulatorAnalytics.ingredients.searchedIngredient(formulaId)
      void dispatch(
        searchIngredients({
          companyId: currentCompany.id,
          query: newSearchTerm,
          withPublicIngredients: true,
          requireAllTerms: true,
          fuzziness: 0,
          size: 20
        })
      )
    },
    [currentCompany.id]
  )

  const handleIngredientClick = React.useCallback(
    (ingredientId: string, supplierId?: string) => {
      formulatorAnalytics.ingredients.addedIngredientToFormula(formulaId)
      void dispatch(
        addIngredientToFormula({
          companyId: currentCompany.id,
          formulaId: formulaId,
          ingredientId: ingredientId,
          supplierId: supplierId
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleAdvancedSearchShowClick = React.useCallback(
    (searchTerm: string) => {
      showModal({
        content: (
          <AdvancedIngredientSearchContainer
            initialSearchTerm={searchTerm}
            onIngredientClick={(ingredientId: string) => {
              handleIngredientClick(ingredientId)
              closeModal()
            }}
          />
        )
      })
    },
    [formulaId, currentCompany.id]
  )

  const searchResults: SearchResult[] = React.useMemo(() => {
    return suggestions.items.map((sug) => {
      return {
        ingredient: {
          id: sug.id,
          friendlyId: sug.friendlyId,
          name: sug.name,
          highlightedName: sug.highlightedName
        },
        suppliers: sug.suppliers
      }
    })
  }, [suggestions])

  return (
    <IngredientSearch
      onClick={handleIngredientClick}
      onAdvancedSearchClick={handleAdvancedSearchShowClick}
      onSearchChange={handleSearchChange}
      state={loading ? 'searching' : 'ready'}
      searchResults={searchResults}
    />
  )
}
