import React from 'react'
import { PinActions } from './PinActions'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { ModalContext } from 'components/Modal/ModalContext'
import {
  ActivePin,
  deleteProofPin,
  resolveProofPin,
  setActivePin,
  STAGED_PIN_ID
} from 'state/labelproof/proofer/ProoferPinsSlice'

export const PinActionsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const proofId = useAppSelector((state) => state.proofer.proof?.id)

  const activePin: ActivePin | null = useAppSelector(
    (state) => state.prooferPins.activePin
  )
  const pins = useAppSelector((state) => state.prooferPins.pins)
  const { showConfirmationModal } = React.useContext(ModalContext)

  if (!activePin || !proofId) {
    return null
  }

  const pin = React.useMemo(() => {
    return pins.find((pin) => pin.id === activePin.id)
  }, [activePin, pins])

  const deletable = React.useMemo(() => {
    if (!pin || activePin.id === STAGED_PIN_ID) {
      return false
    }
    return pin.permissions.deletePin
  }, [pin, activePin])

  const resolvable = React.useMemo(() => {
    if (!pin || activePin.id === STAGED_PIN_ID) {
      return false
    }
    return pin.permissions.resolvePin
  }, [pin, activePin])

  const confirmDeleteActivePin = React.useCallback(() => {
    if (!deletable) {
      return
    }
    showConfirmationModal({
      title: 'Delete Pin',
      message: <>Are you sure you want to delete this pin?</>,
      yesText: 'Delete',
      noText: 'Cancel',
      onYesClicked: deletePin,
      danger: true
    })
  }, [activePin, dispatch, proofId, companyId, deletable])

  const resolvePin = React.useCallback(() => {
    if (!resolvable) {
      return
    }
    void dispatch(
      resolveProofPin({
        companyId,
        proofId,
        pinId: activePin.id
      })
    )
      .unwrap()
      .then(() => {
        dispatch(setActivePin(null))
      })
  }, [activePin, dispatch, proofId, companyId, resolvable])

  const deletePin = React.useCallback(() => {
    void dispatch(deleteProofPin({ companyId, proofId, pinId: activePin.id }))
      .unwrap()
      .then(() => {
        dispatch(setActivePin(null))
      })
  }, [activePin, dispatch, proofId, companyId])

  return (
    <PinActions
      onDelete={confirmDeleteActivePin}
      onMarkComplete={resolvePin}
      deletable={deletable}
      resolvable={resolvable}
    />
  )
}
