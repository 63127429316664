import React from 'react'
import { PinDetails } from './PinDetails'
import { PinActionsContainer } from './components/PinActions/PinActionsContainer'
import { PinThreadContainer } from './components/PinThread/PinThreadContainer'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  setStagedPin,
  setActivePin,
  STAGED_PIN_ID
} from 'state/labelproof/proofer/ProoferPinsSlice'

export const PinDetailsContainer: React.FC = () => {
  const dispatch = useAppDispatch()
  const activePin = useAppSelector((state) => state.prooferPins.activePin)
  const currentDesignViewingState = useAppSelector(
    (state) => state.prooferPins.currentDesignViewingState
  )

  const handleDismiss = (event: MouseEvent | TouchEvent) => {
    const pinElement =
      activePin && document.getElementById(`pin-${activePin.id}`)
    if (pinElement && pinElement.contains(event.target as Node)) {
      return
    }
    dispatch(setStagedPin(null))
    dispatch(setActivePin(null))
  }

  const newPin = React.useMemo(() => activePin?.id === STAGED_PIN_ID, [
    activePin
  ])

  return activePin === null ? null : (
    <PinDetails
      actions={<PinActionsContainer />}
      thread={<PinThreadContainer />}
      onDismiss={handleDismiss}
      position={{
        activePinId: activePin ? activePin.id : null,
        currentDesignViewingState: currentDesignViewingState
      }}
      newPin={newPin}
    />
  )
}
