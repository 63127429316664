import { ModalContext } from 'components/Modal/ModalContext'
import React from 'react'
import { getAllergens } from 'state/allergens/AllergensSlice'
import { getFormulaPreviewAllergens } from 'state/formulator/allergens/FormulaAllergensSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  setNutritionFactLabelAllergensEdits,
  setNutritionFactLabelEdits
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { Allergens } from './Allergens'
import { FormulaNutritionFactLanguage } from 'models/FormulaLabel'

export const AllergensContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const allergens = useAppSelector((state) => state.allergens.allergens)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)
  const regulationId = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.regulationId
  )
  const formulaPreviewAllergens = useAppSelector(
    (state) => state.formulatorAllergens.formulaPreviewAllergens
  )
  const formulaIngredients = useAppSelector(
    (state) => state.formulatorIngredients.formulaIngredients
  )
  const selectedAllergens = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits.allergens
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const allergenStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.allergenStatement
  )
  const suggestedAllergenStatementPreview = useAppSelector(
    (state) =>
      state.nutritionFactLabels.nutritionFactLabelEdits
        .nutritionFactLabelPreview.suggestedAllergenStatement
  )
  const { showConfirmationModal } = React.useContext(ModalContext)

  const currentAllergens = React.useMemo(() => {
    return allergens.map((a) => ({
      id: a.type,
      label: a.name,
      value: selectedAllergens.includes(a.type),
      description: a.description,
      ingredientsCount:
        formulaPreviewAllergens.suggestedAllergens.find(
          (sa) => sa.allergen.type === a.type
        )?.ingredients.length || 0
    }))
  }, [allergens, formulaPreviewAllergens, selectedAllergens])

  React.useEffect(() => {
    // Get the new allergens related to the regulation.
    void dispatch(getAllergens(regulationId))
  }, [regulationId])

  React.useEffect(() => {
    if (formulaId) {
      void dispatch(
        getFormulaPreviewAllergens({
          companyId: companyId,
          formulaId: formulaId,
          regulationId: regulationId
        })
      )
        .unwrap()
        .then((fa) => {
          dispatch(
            setNutritionFactLabelAllergensEdits(fa.allergens.map((a) => a.type))
          )
        })
    }
  }, [formulaIngredients, regulationId])

  const handleConfirmChange = React.useCallback(
    (id: string, checked: boolean) => {
      const newSelectedAllergens = checked
        ? [...selectedAllergens, id]
        : selectedAllergens.filter((a) => a !== id)

      void dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          allergens: newSelectedAllergens
        })
      )
    },
    [nutritionFactLabelEdits, selectedAllergens]
  )

  const handleChange = React.useCallback(
    (id: string, checked: boolean) => {
      const allergen = allergens.find((a) => a.type === id)
      const allergenName = allergen?.name || ''
      const ingredientNames =
        formulaPreviewAllergens.suggestedAllergens
          .find((sa) => sa.allergen.type === id)
          ?.ingredients.map((i) => i.name) || []

      if (selectedAllergens.includes(id) && ingredientNames.length > 0) {
        const msg = (
          <>
            The following ingredients may contain <b>{allergenName}</b>. Are you
            sure you want to remove this allergen?
            {ingredientNames.map((a, index) => (
              <li key={index}>{a}</li>
            ))}
          </>
        )

        showConfirmationModal({
          title: 'Removing Allergen',
          message: msg,
          danger: true,
          yesText: 'Remove',
          noText: 'Cancel',
          onYesClicked: () => {
            handleConfirmChange(id, checked)
          }
        })
      } else {
        handleConfirmChange(id, checked)
      }
    },
    [allergens, formulaPreviewAllergens, selectedAllergens, handleConfirmChange]
  )

  const handleStatementChange = React.useCallback(
    (statement: string, language: FormulaNutritionFactLanguage) => {
      const newAllergenStatementPreview = {
        ...allergenStatementPreview,
        [language]: statement
      }
      void dispatch(
        setNutritionFactLabelEdits({
          ...nutritionFactLabelEdits,
          nutritionFactLabelPreview: {
            ...nutritionFactLabelEdits.nutritionFactLabelPreview,
            allergenStatement: newAllergenStatementPreview
          }
        })
      )
    },
    [allergenStatementPreview, nutritionFactLabelEdits]
  )

  return (
    <>
      <Allergens
        allergens={currentAllergens}
        onChange={handleChange}
        allergenStatements={Object.keys(allergenStatementPreview).map(
          (language) => {
            const languageKey = language as FormulaNutritionFactLanguage
            return {
              language: languageKey,
              value: allergenStatementPreview[languageKey] || '',
              suggestedValue:
                suggestedAllergenStatementPreview[languageKey] || '',
              onChange: (v: string) => {
                handleStatementChange(v, languageKey)
              }
            }
          }
        )}
      />
    </>
  )
}
