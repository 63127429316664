import { Box, Checkbox, Typography } from '@mui/material'
import { BoxRowBasic } from 'components/common'
import { EditableText } from 'components/EditableText/EditableText'
import { DeleteHoverableWrapper } from 'components/Wrappers/DeleteHoverableWrapper'
import React from 'react'

export interface TaskProps {
  id: string
  name: string
  checked: boolean
  completed: boolean
  editable?: boolean
  deletable?: boolean
  checkable?: boolean
  onCheckChange: (checked: boolean) => void
  onNameChange: (name: string) => void
  onDeleteClick: () => void
}

export const Task: React.FC<TaskProps> = ({
  name,
  checked,
  completed,
  editable = true,
  deletable = true,
  checkable = true,
  onCheckChange,
  onNameChange,
  onDeleteClick
}) => {
  return (
    <DeleteHoverableWrapper
      onDelete={onDeleteClick}
      position="right"
      disabled={!deletable}
      boxSx={{ alignItems: 'flex-start' }}
      buttonSx={{ paddingTop: '3px' }}
    >
      <BoxRowBasic sx={{ gap: '5px', alignItems: 'flex-start' }}>
        <Checkbox
          checked={checked}
          onChange={(_, c) => onCheckChange(c)}
          color={completed ? 'success' : 'primary'}
          disabled={!checkable}
          sx={{
            '&.Mui-disabled': {
              color: completed
                ? 'success.main'
                : checked
                ? 'success.primary'
                : 'inherit',
              opacity: 0.26
            },
            padding: '0px',
            paddingTop: '2px'
          }}
        />

        <Box>
          <EditableText
            initialValue={name}
            saveAction={onNameChange}
            placeholder="Untitled Task"
            disabled={!editable || checked}
            sx={{
              textDecoration: checked ? 'line-through' : 'none'
            }}
          />
        </Box>
      </BoxRowBasic>
    </DeleteHoverableWrapper>
  )
}
