import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LabelProofPinApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import {
  GetProofActivitiesRequest,
  CreateProofPinRequestData,
  ResolveLabelProofPinRequest,
  RepositionLabelProofPinRequest,
  DeleteProofPinRequest,
  CreateProofPinCommentRequest,
  UpdateProofPinCommentRequest,
  DeleteProofPinCommentRequest
} from './ProoferRequest'
import { LoadingState } from 'state/CommonState'


export interface StagedPinCommentReplyTo {
  id: string
  comment: string
  mentionsMap: Record<string, string>
  createdByName?: string
  pinId: string
}
export interface StagedPinComment {
  id?: string
  comment: string
  replyTo?: StagedPinCommentReplyTo
  pinId: string
  mentionedUserIds?: string[]
}

export const STAGED_PIN_ID = 'staged-pin'
export interface StagedPin {
  widthRatio: number
  heightRatio: number
}

export interface ActivePin {
  id: string
}

export interface DesignViewingState {
  scale: number
}

export interface PinsState extends LoadingState {
  pins: LabelProofPinApiResponse[]
  editingPinsComment: boolean
  pendingRepositioningUpdates: Record<
    string,
    {
      originalWidthRatio: number
      originalHeightRatio: number
    }
  >
  activePin: ActivePin | null
  currentDesignViewingState: DesignViewingState
  stagedPinComment: StagedPinComment
  pinThreadPopperDismissing: boolean
  stagedPin: StagedPin | null
}

const initialState: PinsState = {
  pins: [],
  editingPinsComment: false,
  loading: false,
  error: false,
  pendingRepositioningUpdates: {},
  activePin: null,
  currentDesignViewingState: {
    scale: 1
  },
  stagedPinComment: {
    comment: '',
    replyTo: undefined,
    pinId: '',
    mentionedUserIds: undefined
  },
  pinThreadPopperDismissing: false,
  stagedPin: null
}

export const getProofPins = createAsyncThunk(
  'prooferPinsSlice/getProofPins',
  async ({ companyId, proofId }: GetProofActivitiesRequest) => {
    return LabelProofsApi.getLabelProofPins(companyId, proofId)
  }
)

export const createProofPin = createAsyncThunk(
  'prooferPinsSlice/createProofPin',
  async ({
    companyId,
    proofId,
    widthRatio,
    heightRatio
  }: CreateProofPinRequestData) => {
    return LabelProofsApi.createLabelProofPin(companyId, proofId, {
      widthRatio,
      heightRatio
    })
  }
)

export const createPinWithComment = createAsyncThunk(
  'prooferPinsSlice/createPinWithComment',
  async ({
    companyId,
    proofId,
    widthRatio,
    heightRatio,
    comment,
    mentionedUserIds
  }: {
    companyId: string
    proofId: string
    widthRatio: number
    heightRatio: number
    comment: string
    mentionedUserIds?: string[]
  }) => {
    const pin = await LabelProofsApi.createLabelProofPin(companyId, proofId, {
      widthRatio,
      heightRatio
    })

    const commentResponse = await LabelProofsApi.createLabelProofPinComment(
      companyId,
      proofId,
      {
        comment,
        mentionedUserIds,
        pinId: pin.id
      }
    )

    return { ...pin, comments: [commentResponse] }
  }
)

export const resolveProofPin = createAsyncThunk(
  'prooferPinsSlice/resolveProofPin',
  async ({ companyId, proofId, pinId }: ResolveLabelProofPinRequest) => {
    return LabelProofsApi.resolveLabelProofPin(companyId, proofId, pinId)
  }
)

export const repositionProofPin = createAsyncThunk(
  'prooferPinsSlice/repositionProofPin',
  async ({
    companyId,
    proofId,
    pinId,
    widthRatio,
    heightRatio
  }: RepositionLabelProofPinRequest) => {
    return LabelProofsApi.repositionLabelProofPin(companyId, proofId, pinId, {
      widthRatio,
      heightRatio
    })
  }
)

export const deleteProofPin = createAsyncThunk(
  'prooferPinsSlice/deleteProofPin',
  async ({ companyId, proofId, pinId }: DeleteProofPinRequest) => {
    return LabelProofsApi.deleteLabelProofPin(companyId, proofId, pinId)
  }
)

export const createProofPinComment = createAsyncThunk(
  'labelproof/proofer/createComment',
  async ({ companyId, proofId, data }: CreateProofPinCommentRequest) => {
    return await LabelProofsApi.createLabelProofPinComment(
      companyId,
      proofId,
      data
    )
  }
)

export const updateProofPinComment = createAsyncThunk(
  'labelproof/proofer/updateComment',
  async ({
    companyId,
    proofId,
    commentId,
    data,
    pinId
  }: UpdateProofPinCommentRequest) => {
    return await LabelProofsApi.updateLabelProofPinComment(
      companyId,
      proofId,
      commentId,
      data
    )
  }
)

export const deleteProofPinComment = createAsyncThunk(
  'labelproof/proofer/deleteComment',
  async ({ companyId, proofId, commentId, pinId }: DeleteProofPinCommentRequest) => {
    return await LabelProofsApi.deleteLabelProofPinComment(
      companyId,
      proofId,
      commentId
    )
  }
)

const prooferPinsSlice = createSlice({
  name: 'prooferPinsSlice',
  initialState,
  reducers: {
    setActivePin(state, action: { payload: ActivePin | null }) {
      state.activePin = action.payload
    },
    setCurrentDesignViewingState(
      state,
      action: { payload: DesignViewingState }
    ) {
      state.currentDesignViewingState = action.payload
    },
    setPinsStagedComment: (
      state,
      action: PayloadAction<Partial<StagedPinComment>>
    ) => {
      state.stagedPinComment = {
        ...state.stagedPinComment,
        ...action.payload
      }
    },
    resetPinsStagedComment: (state) => {
      state.stagedPinComment = initialState.stagedPinComment
    },
    setStagedPin: (state, action: PayloadAction<StagedPin | null>) => {
      state.stagedPin = action.payload
    },
    setEditingPinsComment: (state, action: PayloadAction<boolean>) => {
      state.editingPinsComment = action.payload
    }
  },
  extraReducers(builder) {
    builder.addCase(createProofPinComment.fulfilled, (state, action) => {
      const pin = state.pins.find(
        (pin) => pin.id === action.meta.arg.data.pinId
      )
      if (pin) {
        state.pins = state.pins.map((pin) => {
          if (pin.id === action.meta.arg.data.pinId) {
            return {
              ...pin,
              comments: [...pin.comments, action.payload]
            }
          }
          return pin
        })
      }
    })

    builder.addCase(updateProofPinComment.fulfilled, (state, action) => {
      const pin = state.pins.find((pin) => pin.id === action.meta.arg.pinId)
      if (pin) {
        state.pins = state.pins.map((pin) => {
          if (pin.id === action.meta.arg.pinId) {
            return {
              ...pin,
              comments: pin.comments.map((comment) => {
                if (comment.id === action.meta.arg.commentId) {
                  return {
                    ...comment,
                    ...action.payload
                  }
                }
                return comment
              })
            }
          }
          return pin
        })
      }
    })

    builder.addCase(deleteProofPinComment.fulfilled, (state, action) => {
      const pin = state.pins.find((pin) => pin.id === action.meta.arg.pinId)
      if (pin) {
        state.pins = state.pins.map((pin) => {
          if (pin.id === action.meta.arg.pinId) {
            return {
              ...pin,
              comments: pin.comments.map((comment) => {
                if (comment.id === action.meta.arg.commentId) {
                  return {
                    ...comment,
                    ...action.payload
                  }
                }
                return comment
              })
            }
          }
          return pin
        })
      }
    })

    builder
      .addCase(getProofPins.pending, (state) => {
        state.loading = true
      })
      .addCase(getProofPins.fulfilled, (state, action) => {
        state.loading = false
        state.pins = action.payload
      })
      .addCase(getProofPins.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(createProofPin.pending, (state) => {
        state.loading = true
      })
      .addCase(createProofPin.fulfilled, (state, action) => {
        state.loading = false
        state.pins = [...state.pins, action.payload]
      })
      .addCase(createProofPin.rejected, (state) => {
        state.loading = false
        state.error = true
      })
    
    builder
      .addCase(createPinWithComment.fulfilled, (state, action) => {
        state.loading = false
        state.pins = [...state.pins, action.payload]
      })

    builder
      .addCase(resolveProofPin.pending, (state) => {
        state.loading = true
      })
      .addCase(resolveProofPin.fulfilled, (state, action) => {
        state.loading = false
        state.pins = state.pins.map((pin) => {
          if (pin.id === action.payload.id) {
            return action.payload
          }
          return pin
        })
      })
      .addCase(resolveProofPin.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(repositionProofPin.pending, (state, action) => {
        state.loading = true

        const pinId = action.meta.arg.pinId
        const originalPin = state.pins.find((pin) => pin.id === pinId)

        if (originalPin) {
          state.pendingRepositioningUpdates[pinId] = {
            originalWidthRatio: originalPin.widthRatio,
            originalHeightRatio: originalPin.heightRatio
          }
        }

        state.pins = state.pins.map((pin) => {
          if (pin.id === action.meta.arg.pinId) {
            return {
              ...pin,
              widthRatio: action.meta.arg.widthRatio,
              heightRatio: action.meta.arg.heightRatio
            }
          }
          return pin
        })
      })
      .addCase(repositionProofPin.fulfilled, (state, action) => {
        state.loading = false

        const pinId = action.payload.id
        if (state.pendingRepositioningUpdates[pinId]) {
          const { [pinId]: _, ...remaining } = state.pendingRepositioningUpdates
          state.pendingRepositioningUpdates = remaining
        }

        state.pins = state.pins.map((pin) => {
          if (pin.id === action.payload.id) {
            return action.payload
          }
          return pin
        })
      })
      .addCase(repositionProofPin.rejected, (state, action) => {
        state.loading = false
        state.error = true

        const pinId = action.meta.arg.pinId
        const originalPosition = state.pendingRepositioningUpdates[pinId]

        if (originalPosition) {
          state.pins = state.pins.map((pin) => {
            if (pin.id === pinId) {
              return {
                ...pin,
                widthRatio: originalPosition.originalWidthRatio,
                heightRatio: originalPosition.originalHeightRatio
              }
            }
            return pin
          })
          const { [pinId]: _, ...remaining } = state.pendingRepositioningUpdates
          state.pendingRepositioningUpdates = remaining
        }
      })

    builder
      .addCase(deleteProofPin.pending, (state) => {
        state.loading = true
      })
      .addCase(deleteProofPin.fulfilled, (state, action) => {
        state.loading = false
        state.pins = state.pins.filter(
          (pin) => pin.id !== action.meta.arg.pinId
        )
      })
      .addCase(deleteProofPin.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  }
})

export const {
  setActivePin,
  setCurrentDesignViewingState,
  setPinsStagedComment,
  resetPinsStagedComment,
  setStagedPin,
  setEditingPinsComment,
} = prooferPinsSlice.actions
export default prooferPinsSlice.reducer
