import { Typography } from '@mui/material'
import { ModalContext } from 'components/Modal/ModalContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import React from 'react'
import { updateFormulaServingWeight } from 'state/formulator/FormulatorSlice'
import {
  getFormulaIngredientBreakdown,
  scaleFormula
} from 'state/formulator/breakdown/BreakdownSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { FormulatorContext } from '../../FormulatorProvider'
import { TotalsBar } from './TotalsBar'

export const TotalsBarContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const formulaBreakdown = useAppSelector((state) => state.breakdown.breakdown)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const { showConfirmationModal } = React.useContext(ModalContext)
  const { viewOptions } = React.useContext(FormulatorContext)

  const refreshIngredientBreakdown = React.useCallback(() => {
    if (formulaId) {
      void dispatch(
        getFormulaIngredientBreakdown({
          companyId: currentCompany.id,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, currentCompany.id])

  const handleScaleFormula = React.useCallback(
    (newTotal: number) => {
      formulatorAnalytics.ingredients.scaledIngredients(formulaId)
      showConfirmationModal({
        title: 'Scaling Formula',
        message: (
          <>
            Are you sure you want to scale formula to <b>{newTotal}g</b>? This
            will modify all its ingredient amounts proportionally.
          </>
        ),
        yesText: 'Scale',
        noText: 'Cancel',
        onYesClicked: () => {
          void dispatch(
            scaleFormula({
              companyId: currentCompany.id,
              formulaId: formulaId,
              scale: newTotal
            })
          ).then(() => {
            refreshIngredientBreakdown()
          })
        }
      })
    },
    [currentCompany.id, formulaId]
  )

  const costingVisibile: boolean = React.useMemo(() => {
    // Search within viewOptions array for the view option with id as cost.
    return viewOptions.find((option) => option.id === 'cost')?.value || false
  }, [viewOptions])

  const handleServingWeightChange = React.useCallback(
    (newServingWeight: number) => {
      formulatorAnalytics.label.modifiedServingWeight(formulaId)
      void dispatch(
        updateFormulaServingWeight({
          companyId: currentCompany.id,
          formulaId: formulaId,
          servingWeight: newServingWeight
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  return (
    <TotalsBar
      totalIngredients={formulaBreakdown?.root.ingredientCount || 0}
      servingWeight={{
        value: formulaBreakdown?.root.servingSize?.rounded || 0,
        onChange: handleServingWeightChange
      }}
      totalWeight={{
        value: formulaBreakdown?.summary.quantities.finishedTotal.rounded || 0,
        onChange: handleScaleFormula
      }}
      cost={{
        perServing: formulaBreakdown?.summary.servingCost?.rounded || 0,
        perTotal: formulaBreakdown?.summary.totalCost?.rounded || 0,
        visibile: costingVisibile
      }}
    />
  )
}
