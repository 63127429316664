import {
  BasicOperationApiResponse,
  BasicTargetApiResponse,
  BasicTargetCategoryApiResponse,
  ComparisonOperationApiResponse,
  ComparisonOperatorApiResponse,
  ComparisonTypeApiResponse,
  ComparisonUnitApiResponse,
  ConditionalOperationApiResponse,
  ConditionalTypeApiResponse,
  ExtendedNutrientUnitForTargetsApiResponse,
  LogicalOperationApiResponse,
  LogicalOperatorApiResponse,
  NutrientTargetUnitApiResponse,
  OperationApiResponse,
  OperationTypeApiResponse,
  SortedBasicTargetsApiResponse,
  TargetApiResponse,
  TargetOperationApiResponse
} from './TargetApiResponse'
import {
  BasicOperation,
  BasicTarget,
  BasicTargetCategory,
  ComparisonOperation,
  ComparisonOperator,
  ComparisonType,
  ComparisonUnit,
  ConditionalOperation,
  ConditionalType,
  ExtendedNutrientUnitForTargets,
  LogicalOperation,
  LogicalOperator,
  NormalizedComparisonOperation,
  NormalizedLogicalOperation,
  NormalizedOperation,
  NormalizedTargetOperation,
  NutrientTargetUnit,
  Operation,
  OperationType,
  SortedBasicTargets,
  Target,
  TargetOperation
} from 'models/Target'
import { toBasicCompany } from '../company/CompanyApiMapper'
import { fromNutrientType, fromNutrientUnit, toNutrientType, toNutrientUnit } from '../nutrient/NutrientApiMapper'
import { NutrientUnit } from 'models/Nutrient'
import { NutrientUnitApiResponse } from '../nutrient/NutrientApiResponse'

export const toLogicalOperator = (
  operator: LogicalOperatorApiResponse
): LogicalOperator => {
  switch (operator) {
    case LogicalOperatorApiResponse.AND:
      return LogicalOperator.AND
    case LogicalOperatorApiResponse.OR:
      return LogicalOperator.OR
  }
}

export const fromLogicalOperator = (
  operator: LogicalOperator
): LogicalOperatorApiResponse => {
  switch (operator) {
    case LogicalOperator.AND:
      return LogicalOperatorApiResponse.AND
    case LogicalOperator.OR:
      return LogicalOperatorApiResponse.OR
  }
}

export const toComparisonOperator = (
  operator: ComparisonOperatorApiResponse
): ComparisonOperator => {
  switch (operator) {
    case ComparisonOperatorApiResponse.EQUAL:
      return ComparisonOperator.EQUAL
    case ComparisonOperatorApiResponse.LESS_THAN:
      return ComparisonOperator.LESS_THAN
    case ComparisonOperatorApiResponse.BIGGER_THAN:
      return ComparisonOperator.BIGGER_THAN
    case ComparisonOperatorApiResponse.LESS_THAN_OR_EQUAL:
      return ComparisonOperator.LESS_THAN_OR_EQUAL
    case ComparisonOperatorApiResponse.BIGGER_THAN_OR_EQUAL:
      return ComparisonOperator.BIGGER_THAN_OR_EQUAL
    case ComparisonOperatorApiResponse.NOT_EQUAL:
      return ComparisonOperator.NOT_EQUAL
  }
}

export const fromComparisonOperator = (
  operator: ComparisonOperator
): ComparisonOperatorApiResponse => {
  switch (operator) {
    case ComparisonOperator.EQUAL:
      return ComparisonOperatorApiResponse.EQUAL
    case ComparisonOperator.LESS_THAN:
      return ComparisonOperatorApiResponse.LESS_THAN
    case ComparisonOperator.BIGGER_THAN:
      return ComparisonOperatorApiResponse.BIGGER_THAN
    case ComparisonOperator.LESS_THAN_OR_EQUAL:
      return ComparisonOperatorApiResponse.LESS_THAN_OR_EQUAL
    case ComparisonOperator.BIGGER_THAN_OR_EQUAL:
      return ComparisonOperatorApiResponse.BIGGER_THAN_OR_EQUAL
    case ComparisonOperator.NOT_EQUAL:
      return ComparisonOperatorApiResponse.NOT_EQUAL
  }
}

export const toOperationType = (
  operation: OperationTypeApiResponse
): OperationType => {
  switch (operation) {
    case OperationTypeApiResponse.LOGICAL:
      return OperationType.LOGICAL
    case OperationTypeApiResponse.COMPARISON:
      return OperationType.COMPARISON
    case OperationTypeApiResponse.TARGET:
      return OperationType.TARGET
    case OperationTypeApiResponse.CONDITIONAL:
      return OperationType.CONDITIONAL
  }
}

export const fromOperationType = (
  operation: OperationType
): OperationTypeApiResponse => {
  switch (operation) {
    case OperationType.LOGICAL:
      return OperationTypeApiResponse.LOGICAL
    case OperationType.COMPARISON:
      return OperationTypeApiResponse.COMPARISON
    case OperationType.TARGET:
      return OperationTypeApiResponse.TARGET
    case OperationType.CONDITIONAL:
      return OperationTypeApiResponse.CONDITIONAL
  }
}

export const toComparisonType = (
  comparisonType: ComparisonTypeApiResponse
): ComparisonType => {
  switch (comparisonType) {
    case ComparisonTypeApiResponse.RACC:
      return ComparisonType.RACC
    case ComparisonTypeApiResponse.PER_RACC:
      return ComparisonType.PER_RACC
    case ComparisonTypeApiResponse.PER_TARGET_VALUE:
      return ComparisonType.PER_TARGET_VALUE
    case ComparisonTypeApiResponse.PER_LABELED_SERVING:
      return ComparisonType.PER_LABELED_SERVING
    case ComparisonTypeApiResponse.CALORIES_FROM_NUTRIENT:
      return ComparisonType.CALORIES_FROM_NUTRIENT
    case ComparisonTypeApiResponse.TAGS_IN_FORMULA:
      return ComparisonType.TAGS_IN_FORMULA
    case ComparisonTypeApiResponse.TEXT_IN_FORMULA:
      return ComparisonType.TEXT_IN_FORMULA
  }
}

export const fromComparisonType = (
  comparisonType: ComparisonType
): ComparisonTypeApiResponse => {
  switch (comparisonType) {
    case ComparisonType.RACC:
      return ComparisonTypeApiResponse.RACC
    case ComparisonType.PER_RACC:
      return ComparisonTypeApiResponse.PER_RACC
    case ComparisonType.PER_TARGET_VALUE:
      return ComparisonTypeApiResponse.PER_TARGET_VALUE
    case ComparisonType.PER_LABELED_SERVING:
      return ComparisonTypeApiResponse.PER_LABELED_SERVING
    case ComparisonType.CALORIES_FROM_NUTRIENT:
      return ComparisonTypeApiResponse.CALORIES_FROM_NUTRIENT
    case ComparisonType.TAGS_IN_FORMULA:
      return ComparisonTypeApiResponse.TAGS_IN_FORMULA
    case ComparisonType.TEXT_IN_FORMULA:
      return ComparisonTypeApiResponse.TEXT_IN_FORMULA
  }
}

export const toConditionalType = (
  conditionalType: ConditionalTypeApiResponse
): ConditionalType => {
  switch (conditionalType) {
    case ConditionalTypeApiResponse.TARGET:
      return ConditionalType.TARGET
    case ConditionalTypeApiResponse.MEAL_AND_MAIN_DISH:
      return ConditionalType.MEAL_AND_MAIN_DISH
    case ConditionalTypeApiResponse.SMALL_RACC:
      return ConditionalType.SMALL_RACC
    case ConditionalTypeApiResponse.ALLERGEN_FREE:
      return ConditionalType.ALLERGEN_FREE
  }
}

export const fromConditionalType = (
  conditionalType: ConditionalType
): ConditionalTypeApiResponse => {
  switch (conditionalType) {
    case ConditionalType.TARGET:
      return ConditionalTypeApiResponse.TARGET
    case ConditionalType.MEAL_AND_MAIN_DISH:
      return ConditionalTypeApiResponse.MEAL_AND_MAIN_DISH
    case ConditionalType.SMALL_RACC:
      return ConditionalTypeApiResponse.SMALL_RACC
    case ConditionalType.ALLERGEN_FREE:
      return ConditionalTypeApiResponse.ALLERGEN_FREE
  }
}

export const toComparisonUnit = (
  comparisonUnit: ComparisonUnitApiResponse
): ComparisonUnit => {
  switch (comparisonUnit) {
    case ComparisonUnitApiResponse.GRAM:
      return ComparisonUnit.GRAM
    case ComparisonUnitApiResponse.MILLIGRAM:
      return ComparisonUnit.MILLIGRAM
    case ComparisonUnitApiResponse.TABLESPOON:
      return ComparisonUnit.TABLESPOON
    case ComparisonUnitApiResponse.PERCENTAGE:
      return ComparisonUnit.PERCENTAGE
    case ComparisonUnitApiResponse.CALORIE:
      return ComparisonUnit.CALORIE
    case ComparisonUnitApiResponse.TAG:
      return ComparisonUnit.TAG
  }
}

export const fromComparisonUnit = (
  comparisonUnit: ComparisonUnit
): ComparisonUnitApiResponse => {
  switch (comparisonUnit) {
    case ComparisonUnit.GRAM:
      return ComparisonUnitApiResponse.GRAM
    case ComparisonUnit.MILLIGRAM:
      return ComparisonUnitApiResponse.MILLIGRAM
    case ComparisonUnit.TABLESPOON:
      return ComparisonUnitApiResponse.TABLESPOON
    case ComparisonUnit.PERCENTAGE:
      return ComparisonUnitApiResponse.PERCENTAGE
    case ComparisonUnit.CALORIE:
      return ComparisonUnitApiResponse.CALORIE
    case ComparisonUnit.TAG:
      return ComparisonUnitApiResponse.TAG
  }
}

const toExtendedNutrientUnitForTargets = (
  response: ExtendedNutrientUnitForTargetsApiResponse
): ExtendedNutrientUnitForTargets => {
  switch (response) {
    case ExtendedNutrientUnitForTargetsApiResponse.PERCENTAGE:
      return ExtendedNutrientUnitForTargets.PERCENTAGE
    case ExtendedNutrientUnitForTargetsApiResponse.TAG:
      return ExtendedNutrientUnitForTargets.TAG
  }
}

const fromExtendedNutrientUnitForTargets = (
  response: ExtendedNutrientUnitForTargets
): ExtendedNutrientUnitForTargetsApiResponse => {
  switch (response) {
    case ExtendedNutrientUnitForTargets.PERCENTAGE:
      return ExtendedNutrientUnitForTargetsApiResponse.PERCENTAGE
    case ExtendedNutrientUnitForTargets.TAG:
      return ExtendedNutrientUnitForTargetsApiResponse.TAG
  }
}

export const toNutrientTargetUnit = (response: NutrientTargetUnitApiResponse): NutrientTargetUnit => {
  if (Object.values(NutrientUnitApiResponse).includes(response as any)) {
    return toNutrientUnit(response as NutrientUnitApiResponse)
  } else if (Object.values(ExtendedNutrientUnitForTargetsApiResponse).includes(response as any)) {
    return toExtendedNutrientUnitForTargets(response as ExtendedNutrientUnitForTargetsApiResponse)
  } else {
    throw new Error(`Unhandled NutrientTargetUnit: ${response}`)
  }
}

export const fromNutrientTargetUnit = (response: NutrientTargetUnit): NutrientTargetUnitApiResponse => {
  if (Object.values(NutrientUnit).includes(response as any)) {
    return fromNutrientUnit(response as NutrientUnit)
  } else if (Object.values(ExtendedNutrientUnitForTargets).includes(response as any)) {
    return fromExtendedNutrientUnitForTargets(response as ExtendedNutrientUnitForTargets)
  } else {
    throw new Error(`Unhandled NutrientTargetUnit: ${response}`)
  }
}

const toBasicOperation = (
  operation: BasicOperationApiResponse
): BasicOperation => {
  return {
    ...operation,
    operationType: toOperationType(operation.operationType)
  }
}

const fromBasicOperation = (
  operation: BasicOperation
): BasicOperationApiResponse => {
  return {
    ...operation,
    operationType: fromOperationType(operation.operationType)
  }
}

const toConditionalOperation = (
  conditionalOperation: ConditionalOperationApiResponse
): ConditionalOperation => {
  return {
    ...conditionalOperation,
    operationType: toOperationType(conditionalOperation.operationType),
    conditionalType: toConditionalType(conditionalOperation.conditionalType)
  }
}

const fromConditionalOperation = (
  conditionalOperation: ConditionalOperation
): ConditionalOperationApiResponse => {
  return {
    ...conditionalOperation,
    operationType: fromOperationType(conditionalOperation.operationType),
    conditionalType: fromConditionalType(conditionalOperation.conditionalType)
  }
}

const toTargetOperation = (
  targetOperation: TargetOperationApiResponse
): TargetOperation => {
  return {
    ...toBasicOperation(targetOperation),
    targetId: targetOperation.targetId,
    targetName: targetOperation.targetName
  }
}

const fromTargetOperation = (
  targetOperation: TargetOperation
): TargetOperationApiResponse => {
  return {
    ...fromBasicOperation(targetOperation),
    targetId: targetOperation.targetId,
    targetName: targetOperation.targetName,
    expectedExecutionReturn: targetOperation.expectedExecutionReturn,
    executeOnReferenceFormula: targetOperation.executeOnReferenceFormula
  }
}

const toComparisonOperation = (
  comparisonOperation: ComparisonOperationApiResponse
): ComparisonOperation => {
  const result: ComparisonOperation = {
    ...toBasicOperation(comparisonOperation),
    operator: toComparisonOperator(comparisonOperation.operator),
    comparisonUnit: toNutrientTargetUnit(comparisonOperation.comparisonUnit),
    value: comparisonOperation.value,
    comparisonType: toComparisonType(comparisonOperation.comparisonType),
    nutrient: comparisonOperation.nutrient ? toNutrientType(comparisonOperation.nutrient) : undefined,
    secondNutrient: comparisonOperation.secondNutrient ? toNutrientType(comparisonOperation.secondNutrient) : undefined,
    comparedToReferenceFormula: comparisonOperation.comparedToReferenceFormula,
    comparedToRdi: comparisonOperation.comparedToRdi
  }

  if (
    comparisonOperation.comparisonType ===
    ComparisonTypeApiResponse.PER_TARGET_VALUE &&
    comparisonOperation.targetUnit
  ) {
    result.targetValue = comparisonOperation.targetValue
    result.targetUnit = toComparisonUnit(comparisonOperation.targetUnit)
  }

  return result
}

const fromComparisonOperation = (
  comparisonOperation: ComparisonOperation
): ComparisonOperationApiResponse => {
  const result: ComparisonOperationApiResponse = {
    ...fromBasicOperation(comparisonOperation),
    operator: fromComparisonOperator(comparisonOperation.operator),
    comparisonUnit: fromNutrientTargetUnit(comparisonOperation.comparisonUnit),
    value: comparisonOperation.value,
    comparisonType: fromComparisonType(comparisonOperation.comparisonType),
    nutrient: comparisonOperation.nutrient ? fromNutrientType(comparisonOperation.nutrient) : undefined,
    secondNutrient: comparisonOperation.secondNutrient ? fromNutrientType(comparisonOperation.secondNutrient) : undefined,
    comparedToReferenceFormula: comparisonOperation.comparedToReferenceFormula,
    comparedToRdi: comparisonOperation.comparedToRdi
  }

  if (
    comparisonOperation.comparisonType === ComparisonType.PER_TARGET_VALUE &&
    comparisonOperation.targetUnit
  ) {
    result.targetValue = comparisonOperation.targetValue
    result.targetUnit = fromComparisonUnit(comparisonOperation.targetUnit)
  }

  return result
}

const toLogicalOperation = (
  logicalOperation: LogicalOperationApiResponse
): LogicalOperation => {
  return {
    ...toBasicOperation(logicalOperation),
    operator: toLogicalOperator(logicalOperation.operator),
    operations: logicalOperation.operations
      .map((operation) => {
        switch (operation.operationType) {
          case OperationTypeApiResponse.LOGICAL:
            return toLogicalOperation(operation as LogicalOperationApiResponse)
          case OperationTypeApiResponse.COMPARISON:
            return toComparisonOperation(
              operation as ComparisonOperationApiResponse
            )
          case OperationTypeApiResponse.TARGET:
            return toTargetOperation(operation as TargetOperationApiResponse)
          case OperationTypeApiResponse.CONDITIONAL:
            return toConditionalOperation(
              operation as ConditionalOperationApiResponse
            )
        }
      })
      .filter((operation) => operation !== undefined) as (
        | ComparisonOperation
        | LogicalOperation
        | TargetOperation
      )[]
  }
}

const fromLogicalOperation = (
  logicalOperation: LogicalOperation
): LogicalOperationApiResponse => {
  return {
    ...fromBasicOperation(logicalOperation),
    operator: fromLogicalOperator(logicalOperation.operator),
    operations: logicalOperation.operations.map((operation) => {
      switch (operation.operationType) {
        case OperationType.LOGICAL:
          return fromLogicalOperation(operation as LogicalOperation)
        case OperationType.COMPARISON:
          return fromComparisonOperation(operation as ComparisonOperation)
        case OperationType.TARGET:
          return fromTargetOperation(operation as TargetOperation)
        case OperationType.CONDITIONAL:
          return fromConditionalOperation(operation as ConditionalOperation)
        default:
          throw new Error('Invalid operation type')
      }
    })
  }
}

export const isNormalizedLogicalOperation = (
  operation: NormalizedOperation
): operation is NormalizedLogicalOperation => {
  return operation.operationType === OperationType.LOGICAL
}

export const isLogicalOperation = (
  operation: Operation
): operation is LogicalOperation => {
  return operation.operationType === OperationType.LOGICAL
}

export const isComparisonOperation = (
  operation: Operation | NormalizedOperation
): operation is ComparisonOperation | NormalizedComparisonOperation => {
  return operation.operationType === OperationType.COMPARISON
}

export const isTargetOperation = (
  operation: Operation | NormalizedOperation
): operation is TargetOperation | NormalizedTargetOperation => {
  return operation.operationType === OperationType.TARGET
}

export const isConditionalOperation = (
  operation: Operation | NormalizedOperation
): operation is ConditionalOperation => {
  return operation.operationType === OperationType.CONDITIONAL
}

export const addLevelsToLogicalOperations = (
  operation: LogicalOperation,
  currentLevel = 0
): LogicalOperation => {
  if (isLogicalOperation(operation)) {
    operation.level = currentLevel
    operation.operations = operation.operations.map((op) => {
      if (isLogicalOperation(op)) {
        return addLevelsToLogicalOperations(op, currentLevel + 1)
      }
      return op
    })
  }
  return operation
}

export const toOperation = (operation: OperationApiResponse): Operation => {
  switch (operation.operationType) {
    case OperationTypeApiResponse.LOGICAL:
      return toLogicalOperation(operation as LogicalOperationApiResponse)
    case OperationTypeApiResponse.COMPARISON:
      return toComparisonOperation(operation as ComparisonOperationApiResponse)
    case OperationTypeApiResponse.TARGET:
      return toTargetOperation(operation as TargetOperationApiResponse)
    case OperationTypeApiResponse.CONDITIONAL:
      return toConditionalOperation(
        operation as ConditionalOperationApiResponse
      )
    default:
      throw new Error('Invalid operation type')
  }
}

export const fromOperation = (operation: Operation): OperationApiResponse => {
  switch (operation.operationType) {
    case OperationType.LOGICAL:
      return fromLogicalOperation(operation as LogicalOperation)
    case OperationType.COMPARISON:
      return fromComparisonOperation(operation as ComparisonOperation)
    case OperationType.TARGET:
      return fromTargetOperation(operation as TargetOperation)
    case OperationType.CONDITIONAL:
      return fromConditionalOperation(operation as ConditionalOperation)
    default:
      throw new Error('Invalid operation type')
  }
}

const toTargetCategory = (
  targetCategory: BasicTargetCategoryApiResponse
): BasicTargetCategory => {
  return {
    ...targetCategory
  }
}

export const toTarget = (target: TargetApiResponse): Target => {
  const result: Target = {
    ...target,
    company: target.company ? toBasicCompany(target.company) : undefined,
    operation: target.operation ? toOperation(target.operation) : undefined,
    targetDefinitionCategory: target.category
      ? toTargetCategory(target.category)
      : undefined
  }

  if (result.operation) {
    if (isLogicalOperation(result.operation)) {
      result.operation = addLevelsToLogicalOperations(result.operation)
    }
  }

  return result
}

export const toTargets = (targets: TargetApiResponse[]): Target[] => {
  return targets.map((target) => toTarget(target))
}

export const toBasicTarget = (target: BasicTargetApiResponse): BasicTarget => {
  return {
    ...target,
    company: target.company ? toBasicCompany(target.company) : undefined,
    targetDefinitionCategory: target.category
      ? toTargetCategory(target.category)
      : undefined
  }
}

export const toBasicTargets = (
  targets: BasicTargetApiResponse[]
): BasicTarget[] => {
  return targets.map((target) => toBasicTarget(target))
}

export const toSortedBasicTargets = (
  res: SortedBasicTargetsApiResponse
): SortedBasicTargets => {
  return {
    ...res,
    items: toBasicTargets(res.items)
  }
}
