import { Box, IconButton, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { BoxColumnBasic, BoxRowBasic } from 'components/common'
import React from 'react'
import {
  CreateProofPinCommentRequestData,
  UpdateProofPinCommentRequestData
} from 'state/labelproof/proofer/ProoferRequest'
import { CommentText } from 'components/Comments/CommentText'
import { mentionParser } from 'components/Comments/TextParsers'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ProofCommentInput } from './ProofCommentInput'
import {
  mapStagedCommentToCreateProofCommentRequestData,
  mapStagedCommentToUpdateProofCommentRequestData
} from '../../ActivitiesMappers'
import { MentionItem } from 'react-mentions'
import { StagedPinComment } from 'state/labelproof/proofer/ProoferPinsSlice'

export interface ProofCommentFormProps {
  onSubmit: (comment: CreateProofPinCommentRequestData) => Promise<void>
  onUpdate: (
    commentId: string,
    comment: UpdateProofPinCommentRequestData
  ) => Promise<void>
  onRemoveReply: () => void
  disabled?: boolean
  notEnoughPermission?: boolean
  onChange: (newValue: string, mentions: MentionItem[]) => void
  stagedComment?: StagedPinComment
  editingComment: boolean
  setEditingComment: (editing: boolean) => void
  resetStagedComment: () => void
}

export const ProofCommentForm: React.FC<ProofCommentFormProps> = ({
  onSubmit,
  onUpdate,
  onRemoveReply,
  disabled,
  notEnoughPermission,
  onChange,
  stagedComment,
  editingComment,
  setEditingComment,
  resetStagedComment
}) => {
  const submitDisabled = React.useMemo(() => {
    return !stagedComment?.comment.trim() || disabled
  }, [stagedComment, disabled])

  const handleSend = () => {
    if (submitDisabled || !stagedComment) {
      return
    }
    onSubmit(mapStagedCommentToCreateProofCommentRequestData(stagedComment))
    resetStagedComment()
  }

  const handleUpdate = () => {
    if (submitDisabled) {
      return
    }
    if (!stagedComment?.id) {
      return
    }
    onUpdate(
      stagedComment.id,
      mapStagedCommentToUpdateProofCommentRequestData(stagedComment)
    )
    handleDiscard()
  }

  const handleSubmit = () => {
    if (editingComment) {
      handleUpdate()
    } else {
      handleSend()
    }
  }

  const handleDiscard = () => {
    resetStagedComment()
    setEditingComment(false)
  }

  return (
    <BoxColumnBasic>
      {stagedComment?.replyTo && (
        <>
          <BoxColumnBasic padding="0px 0px 15px 15px" gap={0.5}>
            <BoxRowBasic
              sx={{ justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Typography
                sx={{ fontStyle: 'italic', color: 'gray', fontSize: '13px' }}
              >
                Reply to {stagedComment?.replyTo.createdByName || ''}:
              </Typography>
              <IconButton color="inherit" onClick={onRemoveReply}>
                <CloseRoundedIcon
                  sx={{
                    width: '20px',
                    height: '20px',
                    color: '#233C5860'
                  }}
                />
              </IconButton>
            </BoxRowBasic>
            <CommentText
              text={stagedComment?.replyTo.comment}
              parsers={[mentionParser(stagedComment?.replyTo.mentionsMap)]}
              variant="staged-replied"
            />
          </BoxColumnBasic>
        </>
      )}
      <BoxColumnBasic>
        <BoxColumnBasic sx={{ gap: '5px' }}>
          <Box sx={{ maxHeight: '100px', overflowY: 'auto' }}>
            <ProofCommentInput
              disabled={disabled}
              notEnoughPermission={notEnoughPermission}
              onChange={onChange}
              onSubmit={handleSubmit}
              stagedComment={stagedComment}
            />
          </Box>
          <BoxRowBasic
            sx={{
              gap: '10px',
              alignItems: 'center',
              justifyContent: 'flex-end',
              padding: '5px 15px 15px 0'
            }}
          >
            {editingComment && (
              <Button color="secondary" onClick={(e) => handleDiscard()}>
                Discard
              </Button>
            )}
            <Button
              color="secondary"
              disabled={submitDisabled}
              onClick={() => handleSubmit()}
            >
              {editingComment ? 'Save' : 'Send'}
            </Button>
          </BoxRowBasic>
        </BoxColumnBasic>
      </BoxColumnBasic>
    </BoxColumnBasic>
  )
}
