export enum NutrientClassificationApiResponse {
  MICRONUTRIENT = 'micronutrient',
  MACRONUTRIENT = 'macronutrient',
  OTHER = 'other'
}

export enum NutrientRequirementApiResponse {
  OPTIONAL = 'optional',
  MANDATORY = 'mandatory',
  OTHER = 'other'
}

export enum NutrientUnitApiResponse {
  KILOCALORIE = 'kcal',
  GRAM = 'g',
  POWER_OF_HYDROGEN = 'pH',
  SPECIFIC_GRAVITY = 'sp. gr.',
  MILLIGRAM = 'mg',
  KILOJOULE = 'kJ',
  MICROGRAM = 'ug',
  INTERNATIONAL_UNIT = 'IU',
  MICROGRAM_RETINOL_EQUIVALENTS = 'ug RE',
  MILLIGRAM_ALPHA_TOCOPHEROL_EQUIVALENTS = 'mg alpha-TE',
  MICROMOLE_TROLOX_EQUIVALENTS = 'umol TE',
  MILLIGRAM_GALLIC_ACID_EQUIVALENTS = 'mg GAE',
  MICROGRAM_MCG = 'mcg'
}

export enum NutrientTypeApiResponse {
  ADDED_SUGAR = 'added_sugar',
  ALCOHOL = 'alcohol',
  ASH = 'ash',
  BETA_CAROTENE = 'beta_carotene',
  BIOTIN = 'biotin',
  CAFFEINE = 'caffeine',
  CALCIUM = 'calcium',
  CALORIES = 'calories',
  CARBOHYDRATES = 'carbohydrates',
  CAROTENOID_RE = 'carotenoid_re',
  CHOLESTEROL = 'cholesterol',
  CHOLINE = 'choline',
  CHROMIUM = 'chromium',
  COPPER = 'copper',
  DISACCHARIDES = 'disaccharides',
  TOTAL_FAT = 'total_fat',
  FLUORIDE = 'fluoride',
  FOLATE = 'folate',
  FOLATE_DFE = 'folate_dfe',
  FOLATE_FOOD = 'folate_food',
  FOLIC_ACID = 'folic_acid',
  IODINE = 'iodine',
  IRON = 'iron',
  MAGNESIUM = 'magnesium',
  MANGANESE = 'manganese',
  MOLYBDENUM = 'molybdenum',
  MONO_FAT = 'mono_fat',
  MONOSACCHARIDES = 'monosaccharides',
  OMEGA_3_FATTY_ACID = 'omega_3_fatty_acid',
  OMEGA_6_FATTY_ACID = 'omega_6_fatty_acid',
  OTHER_CARBS = 'other_carbs',
  PANTOTHENIC_ACID = 'pantothenic_acid',
  PHOSPHORUS = 'phosphorus',
  POLY_FAT = 'poly_fat',
  POTASSIUM = 'potassium',
  PROTEIN = 'protein',
  RETINOL_RE = 'retinol_re',
  SATURATED_FAT = 'saturated_fat',
  SELENIUM = 'selenium',
  SODIUM = 'sodium',
  DIETARY_FIBER = 'dietary_fiber',
  SOLUBLE_FIBER = 'soluble_fiber',
  INSOLUBLE_FIBER = 'insoluble_fiber',
  NON_DIETARY_FIBER = 'non_dietary_fiber',
  NON_DIETARY_SOLUBLE_FIBER = 'non_dietary_soluble_fiber',
  NON_DIETARY_INSOLUBLE_FIBER = 'non_dietary_insoluble_fiber',
  TOTAL_SUGARS = 'total_sugars',
  TRANS_FATTY_ACID = 'trans_fatty_acid',
  VITAMIN_A_IU = 'vitamin_a_iu',
  VITAMIN_A_RE = 'vitamin_a_re',
  VITAMIN_B1_THIAMIN = 'vitamin_b1_thiamin',
  VITAMIN_B12 = 'vitamin_b12',
  VITAMIN_B2_RIBOFLAVIN = 'vitamin_b2_riboflavin',
  VITAMIN_B3_NIACIN = 'vitamin_b3_niacin',
  VITAMIN_B3_NIACIN_EQUIV = 'vitamin_b3_niacin_equiv',
  VITAMIN_B6 = 'vitamin_b6',
  VITAMIN_C = 'vitamin_c',
  VITAMIN_D_IU = 'vitamin_d_iu',
  VITAMIN_D = 'vitamin_d',
  VITAMIN_E_ALPHA_TOCO = 'vitamin_e_alpha_toco',
  VITAMIN_E = 'vitamin_e',
  VITAMIN_E_IU = 'vitamin_e_iu',
  VITAMIN_K = 'vitamin_k',
  WATER = 'water',
  ZINC = 'zinc',
  VITAMIN_A_RAE = 'vitamin_a_rae',
  CHLORIDE = 'chloride',
  SUGAR_ALCOHOL = 'sugar_alcohol',
  STARCH = 'starch',
  ALANINE = 'alanine',
  ARGININE = 'arginine',
  ASPARAGINE = 'asparagine',
  ASPARTIC_ACID = 'aspartic_acid',
  CYSTEINE = 'cysteine',
  GLUTAMIC_ACID = 'glutamic_acid',
  GLUTAMINE = 'glutamine',
  GLYCINE = 'glycine',
  HISTIDINE = 'histidine',
  ISOLEUCINE = 'isoleucine',
  LEUCINE = 'leucine',
  LYSINE = 'lysine',
  METHIONINE = 'methionine',
  PHENYLALANINE = 'phenylalanine',
  PROLINE = 'proline',
  SERINE = 'serine',
  THREONINE = 'threonine',
  TRYPTOPHAN = 'tryptophan',
  TYROSINE = 'tyrosine',
  VALINE = 'valine',
  NET_CARBS = 'net_carbs'
}
export interface NutrientApiResponse {
  id: string
  name: string
  type: NutrientTypeApiResponse
  unit: NutrientUnitApiResponse
  requirement: NutrientRequirementApiResponse
  classification: NutrientClassificationApiResponse
  rank: number
}
