import { Option } from 'components/common'
import { ModalContext } from 'components/Modal/ModalContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import {
  AllergenDisplayNameLanguage,
  AllergensPerLanguage
} from 'models/Allergen'
import {
  FormulaNutritionFactLabelType,
  FormulaNutritionFactLanguage
} from 'models/FormulaLabel'
import React from 'react'
import { RegulationName } from 'services/apis/regulation/RegulationApiResponse'
import { getFormulaAllergens } from 'state/formulator/allergens/FormulaAllergensSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import {
  getAvailableRegulations,
  getNutritionFactLabel,
  getSupplementFacts
} from 'state/labels/nutritions/NutritionFactLabelsSlice'
import { getFormulaIngredientStatement } from 'state/labels/statements/IngredientStatementsSlice'
import { ImageDownloadModalContainer } from './components/DownloadModal/ImageDownloadModalContainer'
import { Label } from './Label'
import { ReportDownloadModalContainer } from './components/DownloadModal/ReportDownloadModalContainer'
import { getFormulaIngredients } from 'state/formulator/ingredients/FormulatorIngredientsSlice'

export interface LabelContainerProps {
  onEditClicked: () => void
  editorOpen: boolean
}

export const LabelContainer: React.FC<LabelContainerProps> = ({
  onEditClicked,
  editorOpen
}) => {
  const dispatch = useAppDispatch()
  const { showModal, showConfirmationModal } = React.useContext(ModalContext)

  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const format = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.type
  )
  const originalContainerWeight = useAppSelector(
    (state) => state.formulator.formula.containerWeight
  )
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const nutritionFactLabel = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel
  )
  const nutritionFactLabelEdits = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabelEdits
  )
  const ingredientStatement = useAppSelector(
    (state) => state.ingredientStatements.ingredientStatement
  )

  const formulaBreakdown = useAppSelector((state) => state.breakdown.breakdown)

  const formulaAllergens = useAppSelector(
    (state) => state.formulatorAllergens.formulaAllergens
  )

  const regulationId = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.regulationId
  )
  const availableRegulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )

  enum DownloadOption {
    IMAGE_PNG = 'image-png',
    IMAGE_SVG = 'image-svg',
    REPORT = 'report'
  }

  const downloadOptions: Option<DownloadOption>[] = [
    { id: 'image-png', label: 'Image (PNG)', value: DownloadOption.IMAGE_PNG },
    // {
    //   id: 'image-svg',
    //   label: (
    //     <>
    //       Image (SVG) <i>(Beta)</i>
    //     </>
    //   ),
    //   value: DownloadOption.IMAGE_SVG
    // },
    { id: 'report', label: 'Custom Report (PDF)', value: DownloadOption.REPORT }
  ]

  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (!availableRegulations) {
      void dispatch(getAvailableRegulations())
    }
  }, [])

  React.useEffect(() => {
    if (formulaId) {
      formulatorAnalytics.label.viewedLabelTab(formulaId)
    }
  }, [formulaId])

  React.useEffect(() => {
    void dispatch(
      getFormulaIngredients({
        companyId: currentCompany.id,
        formulaId
      })
    )
  }, [formulaBreakdown, formulaId, currentCompany.id])

  React.useEffect(() => {
    if (!editorOpen) {
      // Only perform updates if the editor is closed.
      if (!formulaIsSupplement) {
        void dispatch(
          getNutritionFactLabel({
            companyId: currentCompany.id,
            formulaId: formulaId
          })
        )
      } else {
        void dispatch(
          getSupplementFacts({
            companyId: currentCompany.id,
            formulaId: formulaId
          })
        )
      }
    }
  }, [formulaId, formulaIsSupplement, editorOpen, formulaBreakdown])

  React.useEffect(() => {
    void dispatch(
      getFormulaIngredientStatement({
        companyId: currentCompany.id,
        formulaId: formulaId,
        withSuggestions: true,
        isSupplement: formulaIsSupplement
      })
    )
  }, [formulaIsSupplement, editorOpen, formulaBreakdown])

  React.useEffect(() => {
    void dispatch(
      getFormulaAllergens({
        companyId: currentCompany.id,
        formulaId: formulaId,
        regulationId: regulationId
      })
    )
  }, [regulationId, formulaBreakdown])

  const ingredientStatementStatement = React.useMemo(() => {
    return (
      nutritionFactLabel?.ingredientStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [nutritionFactLabel?.ingredientStatement])

  const allergenStatementOverride = React.useMemo(() => {
    return (
      nutritionFactLabel?.allergenStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [nutritionFactLabel?.allergenStatement])

  const suggestedAllergenStatement = React.useMemo(() => {
    return (
      nutritionFactLabel?.suggestedAllergenStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [nutritionFactLabel?.suggestedAllergenStatement])

  const allergenStatementPrefix = React.useMemo(() => {
    return (
      nutritionFactLabel?.allergenStatementPrefix || {
        [FormulaNutritionFactLanguage.ENGLISH]: 'Contains: ',
        [FormulaNutritionFactLanguage.FRENCH]: 'Contient: ',
        [FormulaNutritionFactLanguage.SPANISH]: 'Contiene: '
      }
    )
  }, [nutritionFactLabel?.allergenStatementPrefix])

  const suggestedIngredientsStatement = React.useMemo(() => {
    return (
      ingredientStatement?.suggestedIngredientStatement || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [ingredientStatement?.suggestedIngredientStatement])

  const description = React.useMemo(() => {
    return (
      nutritionFactLabel?.description || {
        [FormulaNutritionFactLanguage.ENGLISH]: ''
      }
    )
  }, [nutritionFactLabel?.description])

  const allergens: AllergensPerLanguage = React.useMemo(() => {
    const getLanguageList = (
      language: AllergenDisplayNameLanguage
    ): string[] => {
      return formulaAllergens.allergens
        .flatMap((a) => {
          if (a.displayNames && a.displayNames[language]) {
            return a.displayNames[language]
          }
          return []
        })
        .filter((name): name is string => name !== undefined)
    }

    return {
      [AllergenDisplayNameLanguage.ENGLISH]: getLanguageList(
        AllergenDisplayNameLanguage.ENGLISH
      ),
      [AllergenDisplayNameLanguage.FRENCH]: getLanguageList(
        AllergenDisplayNameLanguage.FRENCH
      ),
      [AllergenDisplayNameLanguage.SPANISH]: getLanguageList(
        AllergenDisplayNameLanguage.SPANISH
      )
    }
  }, [formulaAllergens])

  const regulationName = React.useMemo(() => {
    return (
      (availableRegulations?.find((r) => r.id === regulationId)
        ?.name as RegulationName) || RegulationName.FDA
    )
  }, [regulationId])

  const download = (downloadOption: DownloadOption) => {
    if (
      downloadOption === DownloadOption.IMAGE_PNG ||
      downloadOption === DownloadOption.IMAGE_SVG
    ) {
      showModal({
        content: (
          <ImageDownloadModalContainer
            format={downloadOption === DownloadOption.IMAGE_SVG ? 'svg' : 'png'}
          />
        )
      })
    } else {
      showModal({
        content: <ReportDownloadModalContainer />
      })
    }
  }

  const handleDownload = (downloadOption: Option<string>) => {
    if (
      format === FormulaNutritionFactLabelType.DUAL &&
      !originalContainerWeight
    ) {
      showConfirmationModal({
        title: 'Missing Container Weight',
        message:
          'Are you sure you want to download the label without a container weight?',
        yesText: 'Download',
        noText: 'Cancel',
        onYesClicked: () => {
          download(downloadOption.value as DownloadOption)
        }
      })
    } else {
      download(downloadOption.value as DownloadOption)
    }
  }

  return (
    <Label
      noPreview={
        formulaBreakdown?.root.ingredientCount === 0 || !nutritionFactLabel
      }
      showServingSizeWarning={!formulaBreakdown?.root.servingSize?.value}
      isSupplement={formulaIsSupplement}
      onEditClicked={onEditClicked}
      factsLabel={{
        ingredientStatement: {
          statement: ingredientStatementStatement,
          suggestedStatement: suggestedIngredientsStatement,
          prefix: ingredientStatement?.ingredientStatementPrefix || {
            [FormulaNutritionFactLanguage.ENGLISH]: 'Ingredients: ',
            [FormulaNutritionFactLanguage.FRENCH]: 'Ingrédients: ',
            [FormulaNutritionFactLanguage.SPANISH]: 'Ingredientes: '
          }
        },
        allergenStatement: {
          allergens: allergens,
          statementsOverride: allergenStatementOverride,
          suggestedAllergenStatement: suggestedAllergenStatement,
          allergenStatementPrefix: allergenStatementPrefix
        },
        labelDescription: {
          description: description
        },
        facts: nutritionFactLabel,
        regulationName: regulationName
      }}
      download={{
        downloadOptions: downloadOptions,
        onDownload: handleDownload
      }}
    />
  )
}
