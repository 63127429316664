import React from 'react'
import { Box, Typography } from '@mui/material'
import styled from 'styled-components'

export const DocumentsMainViewBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  flex: '1 0 0',
  alignSelf: 'stretch',
  background: '#FFF',
  position: 'relative',
  height: '100%',
  minHeight: '250px'
})

export const DocumentsHeaderViewBoxStyled = styled(Box)({
  display: 'flex',
  padding: '16px 24px',
  alignItems: 'flex-start',
  gap: '16px',
  alignSelf: 'stretch',
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)'
})

export const FileNameTitleTypographyStyled = styled(Typography)({
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width: '100%'
})

export const UploadDateTitleTypographyStyled = styled(Typography)({
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '13px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width: '40%'
})

export const DocumentsViewBoxStyled = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
  flex: '1 0 0',
  alignSelf: 'stretch'
})

export const DocumentRowViewBoxStyled = styled(Box)({
  display: 'flex',
  padding: '0px 8px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
  cursor: 'pointer'
})

export const DocumentRowContentBoxStyled = styled(Box)({
  display: 'flex',
  padding: '6px 16px',
  alignItems: 'center',
  gap: '8px',
  alignSelf: 'stretch',
  borderRadius: '5px',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
})

export const DocumentTypeViewBoxStyled = styled(Box)({
  width: '40px',
  height: '40px'
})

export const DocumentTypeFrameViewBoxStyled = styled(Box)({
  width: '40px',
  height: '40px',
  flexShrink: 0,
  borderRadius: '8px',
  border: '1px solid var(--Highlight, rgba(0, 0, 0, 0.06))',
  background: '#FFF',
  boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.08)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
})

export const FileTypeTypographyStyled = styled(Typography)({
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  textAlign: 'center',
  fontFamily: 'Poppins',
  fontSize: '11px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal'
})

export const FileNameTypographyStyled = styled(Typography)({
  color: 'var(--Body, #14202E)',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: 'normal',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '100%'
})

export const uploadDateStyle: React.CSSProperties = {
  color: 'var(--Tertiary, rgba(35, 60, 88, 0.43))',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: 'normal',
  width: '250px'
}

export const uploadDropZoneStyles: React.CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  zIndex: 4
}

export const uploadDropZoneActiveStyles: React.CSSProperties = {
  border: '2px dashed #cccccc',
  borderRadius: '4px',
  cursor: 'pointer',
  backgroundColor: 'rgba(0, 0, 0, 0.05)'
}

export const uploadIconStyles: React.CSSProperties = {
  fontSize: 96,
  color: '#CCC',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 3
}
