import { Box, ClickAwayListener, SxProps } from '@mui/material'
import { Button } from 'components/Button/Button'
import React from 'react'
import { AssignerPopper, AssignerTableRow } from './AssignerPopper'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'

export interface AssignerProps {
  options: AssignerTableRow[]
  onSelect: (id: string) => void
  disabled?: boolean
  buttonSx?: SxProps
}

export const Assigner: React.FC<AssignerProps> = ({
  options,
  onSelect,
  buttonSx,
  disabled = false
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [open, setOpen] = React.useState(false)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
    setOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box>
        <Button
          color="secondary"
          variant="circle"
          onClick={!open ? handleOpen : handleClose}
          size="large"
          sx={buttonSx}
          disabled={disabled}
        >
          <PersonAddAltIcon fontSize="small" />
        </Button>
        <AssignerPopper
          data={options}
          onRowClick={onSelect}
          anchorEl={anchorEl}
          open={open}
        />
      </Box>
    </ClickAwayListener>
  )
}
