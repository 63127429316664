import { SortOrder } from 'components/common'
import { SupplierAllergen } from './Allergen'
import { BasicCompany } from './Company'
import { BasicIngredient } from './Ingredient'
import { BasicTag } from './Tags'
import { BasicUser } from './User'


export enum SuppliersSortField {
  NAME = 'name',
  TOTAL_INGREDIENTS = 'total_ingredients',
  LAST_ACCESSED = 'last_accessed'
}

export interface BasicSupplier {
  id: string
  name: string
}

export interface SimpleSupplier extends BasicSupplier {
  company: BasicCompany
  description?: string
  verified: boolean
  createdBy?: BasicUser
  createdAt: string
  updatedAt: string
}

export interface DetailedSupplier extends SimpleSupplier {
  tags: BasicTag[]
  totalFormulas: number
  totalIngredients: number
  allergens?: SupplierAllergen[]
}

export interface DetailedSupplierPaginated {
  items: DetailedSupplier[]
  page: number
  total: number
  size: number
  pages: number
  sortField: SuppliersSortField
  sortOrder: SortOrder
}

export interface BasicSupplierIngredientsPaginated {
  items: BasicIngredient[]
  page: number
  total: number
  size: number
  pages: number
}

export interface SupplierSimpleIngredient {
  id: string
  ingredient: BasicIngredient
  supplier: BasicSupplier
}
