import { SortOrder } from 'components/common'
import { BasicIngredient, DetailedIngredient } from './Ingredient'
import { Measurement } from './Measurement'
import { BasicTag } from './Tags'


export enum SimpleIngredientsSortField {
  NAME = 'name',
  FRIENDLY_ID = 'friendly_id'
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BasicSimpleIngredient extends BasicIngredient {}

export interface DetailedSimpleIngredient extends DetailedIngredient {
  manufacturer?: string
  verified: boolean
  tags: BasicTag[]
  costs: SimpleIngredientCost[]
  densityOverride?: number
  calculatedDensity?: number
  measurementPreference?: Measurement
  scalePreference?: number
  assay?: string
  botanicalName?: string
  source?: string
}

export interface DetailedSimpleIngredientsPaginated {
  items: DetailedSimpleIngredient[]
  page: number
  pages: number
  total: number
  size: number
  sortField: SimpleIngredientsSortField
  sortOrder: SortOrder
}

export interface SimpleIngredientCost {
  id: string
  simpleIngredientId: string
  measurement: Measurement
  cost: number
  note?: string
  createdAt: string
  updatedAt: string
}
