import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SimpleIngredientAllergen } from 'models/Allergen'
import { IngredientNutrient } from 'models/Ingredient'
import {
  DetailedSimpleIngredient,
  DetailedSimpleIngredientsPaginated,
  SimpleIngredientCost,
  SimpleIngredientsSortField
} from 'models/SimpleIngredient'
import {
  BasicSupplier,
  DetailedSupplierPaginated,
  SupplierSimpleIngredient
} from 'models/Supplier'
import { SimpleIngredientTag } from 'models/Tags'
import { SimpleIngredientApi } from 'services/apis/simple_ingredient/SimpleIngredientApi'
import { SupplierApi } from 'services/apis/supplier/SupplierApi'
import {
  AddingState,
  CreatingState,
  DeletingState,
  LoadingState,
  RemovingState,
  UpdatingState
} from '../CommonState'
import {
  AddSimpleIngredientAllergenRequest,
  AddSimpleIngredientTagRequest,
  AddSimpleIngredientToSupplierRequest,
  CreateSimpleIngredientCostRequest,
  CreateSimpleIngredientRequest,
  DeleteSimpleIngredientAllergenRequest,
  DeleteSimpleIngredientCostRequest,
  DeleteSimpleIngredientDensityOverrideRequest,
  DeleteSimpleIngredientFromSupplierRequest,
  DeleteSimpleIngredientRequest,
  DeleteSimpleIngredientTagRequest,
  DuplicateSimpleIngredientRequest,
  GetSimpleIngredientAllergensRequest,
  GetSimpleIngredientNutrientsRequest,
  GetSimpleIngredientRequest,
  GetSimpleIngredientsRequest,
  GetSimpleIngredientSuppliersRequest,
  GetSupplierOptionsRequest,
  UpdateSimpleIngredientCostRequest,
  UpdateSimpleIngredientNutrientsRequest,
  UpdateSimpleIngredientRequest
} from './SimpleIngredientsRequest'
import {
  DeleteSimpleIngredientAllergenResponse,
  DeleteSimpleIngredientFromSupplierResponse,
  DeleteSimpleIngredientTagResponse
} from './SimpleIngredientsResponse'
import { SortOrder } from 'components/common'

export interface SelectedIngredient extends DetailedSimpleIngredient {
  new?: boolean
}

interface SimpleIngredientState
  extends LoadingState,
    CreatingState,
    UpdatingState,
    DeletingState,
    AddingState,
    RemovingState {
  simpleIngredients: DetailedSimpleIngredientsPaginated
  currentSupplierSimpleIngredients: SupplierSimpleIngredient[]
  currentSimpleIngredientAllergens: SimpleIngredientAllergen[]
  currentSimpleIngredientNutrients: IngredientNutrient[][]
  selectedSimpleIngredient?: SelectedIngredient
  supplierOptions: BasicSupplier[]
  loadingAllergens: boolean
}

const initialState: SimpleIngredientState = {
  simpleIngredients: {
    items: [],
    total: 0,
    page: 0,
    size: 0,
    pages: 1,
    sortField: SimpleIngredientsSortField.NAME,
    sortOrder: SortOrder.ASC
  },
  currentSupplierSimpleIngredients: [],
  currentSimpleIngredientAllergens: [],
  currentSimpleIngredientNutrients: [],
  selectedSimpleIngredient: undefined,
  supplierOptions: [],
  loading: false,
  creating: false,
  deleting: false,
  updating: false,
  adding: false,
  removing: false,
  error: false,
  loadingAllergens: false
}

export const getSimpleIngredients = createAsyncThunk(
  'simple_ingredients/all/get',
  async ({
    companyId,
    fuzzyName,
    withSimplePublicIngredients = false,
    page = 1,
    size = 50,
    suppliers,
    tags,
    allergens,
    sortBy,
    sortOrder
  }: GetSimpleIngredientsRequest) => {
    return await SimpleIngredientApi.getSimpleIngredients(companyId, {
      simpleIngredientFuzzyName: fuzzyName,
      withSimplePublicIngredients,
      page,
      size,
      supplierIds: suppliers,
      tags,
      allergenTypes: allergens,
      sortBy,
      sortOrder
    })
  }
)

export const getSimpleIngredient = createAsyncThunk(
  'simple_ingredients/get',
  async ({ companyId, simpleIngredientId }: GetSimpleIngredientRequest) => {
    return await SimpleIngredientApi.getSimpleIngredient(
      companyId,
      simpleIngredientId
    )
  }
)

export const createSimpleIngredient = createAsyncThunk(
  'simple_ingredients/create',
  async ({
    companyId,
    name,
    friendlyId,
    verified,
    statement,
    manufacturer,
    description,
    isSugar
  }: CreateSimpleIngredientRequest) => {
    return await SimpleIngredientApi.createSimpleIngredient(companyId, {
      name,
      friendlyId,
      verified,
      statement,
      manufacturer,
      description,
      isSugar
    })
  }
)

export const duplicateSimpleIngredient = createAsyncThunk(
  'simple_ingredients/duplicate',
  async ({
    companyId,
    simpleIngredientId,
    simpleIngredientCompanyId
  }: DuplicateSimpleIngredientRequest) => {
    return await SimpleIngredientApi.duplicateSimpleIngredient(
      companyId,
      simpleIngredientId,
      { simpleIngredientCompanyId }
    )
  }
)

export const updateSimpleIngredient = createAsyncThunk(
  'simple_ingredients/update',
  async ({
    companyId,
    simpleIngredientId: ingredientId,
    name,
    friendlyId,
    verified,
    statement,
    manufacturer,
    description,
    isSugar,
    densityOverride,
    measurementPreferenceId,
    scalePreference,
    assay,
    botanicalName,
    source,
    proteinDigestibility
  }: UpdateSimpleIngredientRequest) => {
    return await SimpleIngredientApi.updateSimpleIngredient(
      companyId,
      ingredientId,
      {
        name,
        friendlyId,
        verified,
        statement,
        manufacturer,
        description,
        isSugar,
        densityOverride,
        measurementPreferenceId,
        scalePreference,
        assay,
        botanicalName,
        source,
        proteinDigestibility
      }
    )
  }
)

export const deleteSimpleIngredient = createAsyncThunk(
  'simple_ingredients/delete',
  async ({ companyId, simpleIngredientId }: DeleteSimpleIngredientRequest) => {
    return await SimpleIngredientApi.deleteSimpleIngredient(
      companyId,
      simpleIngredientId
    )
  }
)

export const deleteSimpleIngredientDensityOverride = createAsyncThunk(
  'simple_ingredients/density_override/delete',
  async ({
    companyId,
    simpleIngredientId
  }: DeleteSimpleIngredientDensityOverrideRequest) => {
    return await SimpleIngredientApi.deleteSimpleIngredientDensityOverride(
      companyId,
      simpleIngredientId
    )
  }
)

export const getSimpleIngredientSuppliers = createAsyncThunk(
  'simple_ingredients/suppliers/get',
  async ({
    companyId,
    simpleIngredientId
  }: GetSimpleIngredientSuppliersRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientSuppliers(
      companyId,
      simpleIngredientId
    )
  }
)

export const addSimpleIngredientToSupplier = createAsyncThunk(
  'simple_ingredients/suppliers/add',
  async ({
    companyId,
    supplierId,
    simpleIngredientId
  }: AddSimpleIngredientToSupplierRequest) => {
    return await SimpleIngredientApi.addSimpleIngredientToSupplier(
      companyId,
      supplierId,
      simpleIngredientId
    )
  }
)

export const deleteSimpleIngredientFromSupplier = createAsyncThunk(
  'simple_ingredients/suppliers/delete',
  async ({
    companyId,
    supplierId,
    simpleIngredientId
  }: DeleteSimpleIngredientFromSupplierRequest): Promise<DeleteSimpleIngredientFromSupplierResponse> => {
    await SimpleIngredientApi.deleteSimpleIngredientFromSupplier(
      companyId,
      supplierId,
      simpleIngredientId
    )
    return { supplierId, simpleIngredientId }
  }
)

export const addSimpleIngredientTag = createAsyncThunk(
  'simple_ingredients/tags/add',
  async ({
    companyId,
    simpleIngredientId,
    name
  }: AddSimpleIngredientTagRequest) => {
    return await SimpleIngredientApi.addSimpleIngredientTag(
      companyId,
      simpleIngredientId,
      name
    )
  }
)

export const deleteSimpleIngredientTag = createAsyncThunk(
  'simple_ingredients/tags/delete',
  async ({
    companyId,
    simpleIngredientId,
    tagId
  }: DeleteSimpleIngredientTagRequest): Promise<DeleteSimpleIngredientTagResponse> => {
    await SimpleIngredientApi.deleteSimpleIngredientTag(
      companyId,
      simpleIngredientId,
      tagId
    )
    return { simpleIngredientId, tagId }
  }
)

export const getSimpleIngredientAllergens = createAsyncThunk(
  'simple_ingredients/allergens/get',
  async ({
    companyId,
    simpleIngredientId
  }: GetSimpleIngredientAllergensRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientAllergens(
      companyId,
      simpleIngredientId
    )
  }
)

export const addSimpleIngredientAllergen = createAsyncThunk(
  'simple_ingredients/allergens/add',
  async ({
    companyId,
    simpleIngredientId,
    allergenType
  }: AddSimpleIngredientAllergenRequest) => {
    return await SimpleIngredientApi.addSimpleIngredientAllergen(
      companyId,
      simpleIngredientId,
      allergenType
    )
  }
)

export const deleteSimpleIngredientAllergen = createAsyncThunk(
  'simple_ingredients/allergens/delete',
  async ({
    companyId,
    simpleIngredientId,
    allergenType
  }: DeleteSimpleIngredientAllergenRequest): Promise<DeleteSimpleIngredientAllergenResponse> => {
    await SimpleIngredientApi.deleteSimpleIngredientAllergen(
      companyId,
      simpleIngredientId,
      allergenType
    )
    return { simpleIngredientId, allergenType }
  }
)

export const getSimpleIngredientNutrients = createAsyncThunk(
  'simple_ingredients/nutrients/get',
  async ({
    companyId,
    simpleIngredientId
  }: GetSimpleIngredientNutrientsRequest) => {
    return await SimpleIngredientApi.getSimpleIngredientNutrients(
      companyId,
      simpleIngredientId
    )
  }
)

export const updateSimpleIngredientNutrient = createAsyncThunk(
  'simple_ingredients/nutrients/update',
  async ({
    companyId,
    simpleIngredientId,
    nutrientType,
    amount
  }: UpdateSimpleIngredientNutrientsRequest) => {
    return await SimpleIngredientApi.updateSimpleIngredientNutrient(
      companyId,
      simpleIngredientId,
      nutrientType,
      amount
    ).catch((error) => {
      const response = error.response
      if (response.status === 409) {
        return Promise.reject(response.data.message)
      }
      return Promise.reject('Failed to update ingredient nutrient.')
    }
    )
  }
)

export const createSimpleIngredientCost = createAsyncThunk(
  'simple_ingredients/costs/create',
  async ({
    companyId,
    simpleIngredientId,
    measurementId,
    cost,
    note
  }: CreateSimpleIngredientCostRequest) => {
    return await SimpleIngredientApi.createSimpleIngredientCost(
      companyId,
      simpleIngredientId,
      {
        measurementId,
        cost,
        note
      }
    )
  }
)
export const updateSimpleIngredientCost = createAsyncThunk(
  'simple_ingredients/costs/update',
  async ({
    companyId,
    simpleIngredientId,
    simpleIngredientCostId,
    measurementId,
    cost,
    note
  }: UpdateSimpleIngredientCostRequest) => {
    return await SimpleIngredientApi.updateSimpleIngredientCost(
      companyId,
      simpleIngredientId,
      simpleIngredientCostId,
      {
        measurementId,
        cost,
        note
      }
    )
  }
)
export const deleteSimpleIngredientCost = createAsyncThunk(
  'simple_ingredients/costs/delete',
  async ({
    companyId,
    simpleIngredientId,
    simpleIngredientCostId
  }: DeleteSimpleIngredientCostRequest) => {
    return await SimpleIngredientApi.deleteSimpleIngredientCost(
      companyId,
      simpleIngredientId,
      simpleIngredientCostId
    )
  }
)

export const getSupplierOptions = createAsyncThunk(
  'suppliers/all/get',
  async ({
    companyId,
    page = 1,
    size = 50,
    fuzzyName,
    tags,
    withPublicSuppliers = false
  }: GetSupplierOptionsRequest) => {
    return await SupplierApi.getSuppliers(companyId, {
      page,
      size,
      simpleSupplierFuzzyName: fuzzyName,
      simpleSupplierTags: tags,
      withPublicSuppliers: withPublicSuppliers
    })
  }
)

export const clearSelectedSimpleIngredient = createAsyncThunk(
  'simpleIngredients/clearSelectedSimpleIngredient',
  (_, { dispatch }) => {
    return dispatch(setSelectedSimpleIngredient(undefined))
  }
)

const simpleIngredientSlice = createSlice({
  name: 'simpleIngredientSlice',
  initialState,
  reducers: {
    setSelectedSimpleIngredient: (
      state,
      action: PayloadAction<SelectedIngredient | undefined>
    ) => {
      state.selectedSimpleIngredient = action.payload
      if (action.payload === undefined) {
        // Reset all current values.
        state.currentSupplierSimpleIngredients = []
        state.currentSimpleIngredientAllergens = []
        state.currentSimpleIngredientNutrients = []
      }
    }
  },
  extraReducers(builder) {
    builder
      // get Ingredients
      .addCase(getSimpleIngredients.pending, (state: SimpleIngredientState) => {
        state.loading = true
      })
      .addCase(
        getSimpleIngredients.fulfilled,
        (state, action: PayloadAction<DetailedSimpleIngredientsPaginated>) => {
          if (action.payload.page === 1) {
            state.simpleIngredients = {
              ...action.payload
            }
          } else {
            const arr =
              action.payload.page > 1 ? state.simpleIngredients.items : []
            const all = [...arr, ...action.payload.items]
            const ids = [...new Set([...all.map((o) => o.id)])]
            const updatedItems = ids.map(
              (id) =>
                all
                  .reverse()
                  .find((o) => o.id === id) as DetailedSimpleIngredient
            )
            state.simpleIngredients = {
              ...action.payload,
              items: updatedItems
            }
            state.loading = false
          }
        }
      )
      .addCase(
        getSimpleIngredients.rejected,
        (state: SimpleIngredientState) => {
          state.loading = false
        }
      )
      // create Ingredient
      .addCase(createSimpleIngredient.pending, (state) => {
        state.creating = true
        state.error = false
      })
      .addCase(
        createSimpleIngredient.fulfilled,
        (state, action: PayloadAction<DetailedSimpleIngredient>) => {
          state.simpleIngredients.items = [
            ...state.simpleIngredients.items,
            { ...action.payload, tags: [], formulas: [], suppliers: [] }
          ]
          state.creating = false
        }
      )
      .addCase(createSimpleIngredient.rejected, (state) => {
        state.creating = false
        state.error = true
      })
      // duplicate Ingredient
      .addCase(duplicateSimpleIngredient.pending, (state) => {
        state.creating = true
        state.error = false
      })
      .addCase(
        duplicateSimpleIngredient.fulfilled,
        (state, action: PayloadAction<DetailedSimpleIngredient>) => {
          state.simpleIngredients.items = [
            ...state.simpleIngredients.items,
            { ...action.payload }
          ]
          state.selectedSimpleIngredient = { ...action.payload, new: true }
          state.creating = false
        }
      )
      .addCase(duplicateSimpleIngredient.rejected, (state) => {
        state.creating = false
        state.error = true
      })
      // update Ingredient
      .addCase(updateSimpleIngredient.pending, (state) => {
        state.updating = true
        state.error = false
      })
      .addCase(
        updateSimpleIngredient.fulfilled,
        (state, action: PayloadAction<DetailedSimpleIngredient>) => {
          const updatedProperties = {
            name: action.payload.name,
            friendlyId: action.payload.friendlyId,
            verified: action.payload.verified,
            ingredientStatement: action.payload.ingredientStatement,
            manufacturer: action.payload.manufacturer,
            description: action.payload.description,
            isSugar: action.payload.isSugar,
            densityOverride: action.payload.densityOverride,
            measurementPreference: action.payload.measurementPreference,
            scalePreference: action.payload.scalePreference,
            calculatedDensity: action.payload.calculatedDensity,
            assay: action.payload.assay,
            botanicalName: action.payload.botanicalName,
            source: action.payload.source,
            proteinDigestibility: action.payload.proteinDigestibility
          }

          // Update the ingredient in the list.
          state.simpleIngredients.items = [
            ...state.simpleIngredients.items.map((i) => {
              return i.id === action.payload.id
                ? {
                    ...i,
                    ...updatedProperties
                  }
                : i
            })
          ]
          state.selectedSimpleIngredient = {
            ...action.payload,
            ...state.selectedSimpleIngredient,
            ...updatedProperties
          }

          state.updating = false
        }
      )
      .addCase(updateSimpleIngredient.rejected, (state) => {
        state.updating = false
        state.error = true
      })
      // delete ingredient
      .addCase(deleteSimpleIngredient.pending, (state) => {
        state.deleting = true
        state.error = false
      })
      .addCase(
        deleteSimpleIngredient.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.simpleIngredients.items = state.simpleIngredients.items.filter(
            (s) => s.id !== action.payload
          )
          state.deleting = false
        }
      )
      .addCase(deleteSimpleIngredient.rejected, (state) => {
        state.deleting = false
        state.error = true
      })
      // delete ingredient density override
      .addCase(deleteSimpleIngredientDensityOverride.pending, (state) => {
        state.updating = true
        state.error = false
      })
      .addCase(
        deleteSimpleIngredientDensityOverride.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.simpleIngredients.items = state.simpleIngredients.items.map(
            (s) =>
              s.id === action.payload ? { ...s, densityOverride: undefined } : s
          )

          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient = {
              ...state.selectedSimpleIngredient,
              densityOverride: undefined
            }
          }
          state.updating = false
        }
      )
      .addCase(deleteSimpleIngredientDensityOverride.rejected, (state) => {
        state.updating = false
        state.error = true
      })
      // get Ingredient Suppliers
      .addCase(
        getSimpleIngredientSuppliers.pending,
        (state: SimpleIngredientState) => {
          state.loading = true
          state.error = false
        }
      )
      .addCase(
        getSimpleIngredientSuppliers.fulfilled,
        (state, action: PayloadAction<SupplierSimpleIngredient[]>) => {
          action.payload.forEach((supplierIngredient) => {
            if (
              !state.currentSupplierSimpleIngredients.some(
                (si) => si.id === supplierIngredient.id
              )
            ) {
              state.currentSupplierSimpleIngredients.push(supplierIngredient)
            }
          })
          state.currentSupplierSimpleIngredients = [
            ...state.currentSupplierSimpleIngredients
          ]
          state.loading = false
        }
      )
      .addCase(
        getSimpleIngredientSuppliers.rejected,
        (state: SimpleIngredientState) => {
          state.loading = false
          state.error = true
        }
      )
      // add Ingredient Supplier
      .addCase(
        addSimpleIngredientToSupplier.pending,
        (state: SimpleIngredientState) => {
          state.adding = true
          state.error = false
        }
      )
      .addCase(
        addSimpleIngredientToSupplier.fulfilled,
        (state, action: PayloadAction<SupplierSimpleIngredient>) => {
          state.currentSupplierSimpleIngredients = [
            ...state.currentSupplierSimpleIngredients,
            action.payload
          ]
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient.suppliers.push(
              action.payload.supplier
            )
          }

          state.simpleIngredients.items = state.simpleIngredients.items.map(
            (i) => {
              if (i.id === action.payload.ingredient.id) {
                return {
                  ...i,
                  suppliers: [...i.suppliers, action.payload.supplier]
                }
              } else {
                return i
              }
            }
          )
          state.adding = false
        }
      )
      .addCase(
        addSimpleIngredientToSupplier.rejected,
        (state: SimpleIngredientState) => {
          state.adding = false
          state.error = true
        }
      )
      // delete Ingredient Supplier
      .addCase(
        deleteSimpleIngredientFromSupplier.pending,
        (state: SimpleIngredientState) => {
          state.removing = true
          state.error = false
        }
      )
      .addCase(
        deleteSimpleIngredientFromSupplier.fulfilled,
        (
          state,
          action: PayloadAction<DeleteSimpleIngredientFromSupplierResponse>
        ) => {
          state.currentSupplierSimpleIngredients =
            state.currentSupplierSimpleIngredients.filter(
              (si) => si.supplier.id !== action.payload.supplierId
            )

          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient.suppliers =
              state.selectedSimpleIngredient.suppliers.filter(
                (s) => s.id !== action.payload.supplierId
              )
          }

          state.simpleIngredients.items = state.simpleIngredients.items.map(
            (i) => {
              if (i.id === action.payload.simpleIngredientId) {
                return {
                  ...i,
                  suppliers: i.suppliers.filter(
                    (s) => s.id !== action.payload.supplierId
                  )
                }
              } else {
                return i
              }
            }
          )
          state.removing = false
        }
      )
      .addCase(
        deleteSimpleIngredientFromSupplier.rejected,
        (state: SimpleIngredientState) => {
          state.removing = false
          state.error = true
        }
      )
      // add Ingredient Tag
      .addCase(
        addSimpleIngredientTag.pending,
        (state: SimpleIngredientState) => {
          state.adding = true
          state.error = false
        }
      )
      .addCase(
        addSimpleIngredientTag.fulfilled,
        (state, action: PayloadAction<SimpleIngredientTag>) => {
          // Update the tag in the ingredient list.
          state.simpleIngredients.items = state.simpleIngredients.items.map(
            (i) => {
              return i.id === action.payload.ingredient.id
                ? { ...i, tags: [...i.tags, action.payload.tag] }
                : i
            }
          )
          // Update the tag in the current ingredient.
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient.tags = [
              ...state.selectedSimpleIngredient.tags,
              action.payload.tag
            ]
          }
          state.adding = false
        }
      )
      .addCase(
        addSimpleIngredientTag.rejected,
        (state: SimpleIngredientState) => {
          state.adding = false
          state.error = true
        }
      )
      // delete Ingredient Tag
      .addCase(
        deleteSimpleIngredientTag.pending,
        (state: SimpleIngredientState) => {
          state.removing = true
          state.error = false
        }
      )
      .addCase(
        deleteSimpleIngredientTag.fulfilled,
        (state, action: PayloadAction<DeleteSimpleIngredientTagResponse>) => {
          // Update the tag in the ingredient list.
          state.simpleIngredients.items = state.simpleIngredients.items.map(
            (i) =>
              i.id === action.payload.simpleIngredientId
                ? {
                    ...i,
                    tags: i.tags.filter((t) => t.id !== action.payload.tagId)
                  }
                : i
          )
          // Update the tag in the current ingredient.
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient.tags =
              state.selectedSimpleIngredient.tags.filter(
                (t) => t.id !== action.payload.tagId
              )
          }
          state.removing = false
        }
      )
      .addCase(
        deleteSimpleIngredientTag.rejected,
        (state: SimpleIngredientState) => {
          state.removing = false
          state.error = true
        }
      )
      .addCase(getSupplierOptions.pending, (state: SimpleIngredientState) => {
        state.loading = true
        state.error = false
      })
      .addCase(
        getSupplierOptions.fulfilled,
        (state, action: PayloadAction<DetailedSupplierPaginated>) => {
          state.supplierOptions = action.payload.items
          state.loading = false
        }
      )
      .addCase(getSupplierOptions.rejected, (state: SimpleIngredientState) => {
        state.loading = false
        state.error = true
      })
      // get Ingredient Allergens
      .addCase(
        getSimpleIngredientAllergens.pending,
        (state: SimpleIngredientState) => {
          state.loading = true
          state.loadingAllergens = true
          state.error = false
        }
      )
      .addCase(
        getSimpleIngredientAllergens.fulfilled,
        (state, action: PayloadAction<SimpleIngredientAllergen[]>) => {
          state.currentSimpleIngredientAllergens = [...action.payload]
          state.loading = false
          state.loadingAllergens = false
        }
      )
      .addCase(
        getSimpleIngredientAllergens.rejected,
        (state: SimpleIngredientState) => {
          state.loading = false
          state.error = true
          state.loadingAllergens = false
        }
      )
      // add Ingredient Allergen
      .addCase(
        addSimpleIngredientAllergen.pending,
        (state: SimpleIngredientState) => {
          state.adding = true
          state.error = false
        }
      )
      .addCase(
        addSimpleIngredientAllergen.fulfilled,
        (state, action: PayloadAction<SimpleIngredientAllergen>) => {
          state.currentSimpleIngredientAllergens = [
            ...state.currentSimpleIngredientAllergens,
            action.payload
          ]
          state.adding = false
        }
      )
      .addCase(
        addSimpleIngredientAllergen.rejected,
        (state: SimpleIngredientState) => {
          state.adding = false
          state.error = true
        }
      )
      // delete Ingredient Allergen
      .addCase(
        deleteSimpleIngredientAllergen.pending,
        (state: SimpleIngredientState) => {
          state.removing = true
          state.error = false
        }
      )
      .addCase(
        deleteSimpleIngredientAllergen.fulfilled,
        (
          state,
          action: PayloadAction<DeleteSimpleIngredientAllergenResponse>
        ) => {
          state.currentSimpleIngredientAllergens =
            state.currentSimpleIngredientAllergens.filter(
              (ia) =>
                ia.ingredient.id !== action.payload.simpleIngredientId &&
                ia.allergen.type !== action.payload.allergenType
            )
          state.removing = false
        }
      )
      .addCase(
        deleteSimpleIngredientAllergen.rejected,
        (state: SimpleIngredientState) => {
          state.removing = false
          state.error = true
        }
      )
      // get Ingredient Nutrients
      .addCase(
        getSimpleIngredientNutrients.pending,
        (state: SimpleIngredientState) => {
          state.currentSimpleIngredientNutrients = []
          state.loading = true
          state.error = false
        }
      )
      .addCase(
        getSimpleIngredientNutrients.fulfilled,
        (state, action: PayloadAction<IngredientNutrient[][]>) => {
          state.currentSimpleIngredientNutrients = action.payload
          state.loading = false
        }
      )
      .addCase(
        getSimpleIngredientNutrients.rejected,
        (state: SimpleIngredientState) => {
          state.loading = false
          state.error = true
        }
      )
      // update Ingredient Nutrient
      .addCase(
        updateSimpleIngredientNutrient.pending,
        (state: SimpleIngredientState) => {
          state.updating = true
          state.error = false
        }
      )
      .addCase(
        updateSimpleIngredientNutrient.fulfilled,
        (state, action: PayloadAction<IngredientNutrient[]>) => {
          state.updating = false
          state.currentSimpleIngredientNutrients =
            state.currentSimpleIngredientNutrients.map((ng) =>
              ng.map((ingn) => {
                const foundNutrient = action.payload.find(
                  (n) => n.nutrient.type === ingn.nutrient.type
                )
                if (foundNutrient) {
                  return {
                    ...ingn,
                    amount: foundNutrient.amount,
                    isCalculated: foundNutrient.isCalculated
                  }
                }
                return ingn
              })
            )
        }
      )
      .addCase(
        updateSimpleIngredientNutrient.rejected,
        (state: SimpleIngredientState) => {
          state.updating = false
          state.error = true
        }
      )

      // create Ingredient Cost
      .addCase(
        createSimpleIngredientCost.pending,
        (state: SimpleIngredientState) => {
          state.creating = true
          state.error = false
        }
      )
      .addCase(
        createSimpleIngredientCost.fulfilled,
        (state, action: PayloadAction<SimpleIngredientCost>) => {
          state.creating = false
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient = {
              ...state.selectedSimpleIngredient,
              costs: [...state.selectedSimpleIngredient.costs, action.payload]
            }
            state.simpleIngredients.items = state.simpleIngredients.items.map(
              (simpleIngredient) => {
                if (simpleIngredient.id === action.payload.simpleIngredientId) {
                  return {
                    ...simpleIngredient,
                    costs: [...simpleIngredient.costs, action.payload]
                  }
                } else {
                  return simpleIngredient
                }
              }
            )
          }
        }
      )
      .addCase(
        createSimpleIngredientCost.rejected,
        (state: SimpleIngredientState) => {
          state.creating = false
          state.error = true
        }
      )
      // update Ingredient Cost
      .addCase(
        updateSimpleIngredientCost.pending,
        (state: SimpleIngredientState) => {
          state.updating = true
          state.error = false
        }
      )
      .addCase(
        updateSimpleIngredientCost.fulfilled,
        (state, action: PayloadAction<SimpleIngredientCost>) => {
          state.updating = false
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient = {
              ...state.selectedSimpleIngredient,
              costs: state.selectedSimpleIngredient.costs.map(
                (simpleIngredientCost) =>
                  simpleIngredientCost.id === action.payload.id
                    ? action.payload
                    : simpleIngredientCost
              )
            }
            state.simpleIngredients.items = state.simpleIngredients.items.map(
              (simpleIngredient) => {
                if (simpleIngredient.id === action.payload.simpleIngredientId) {
                  return {
                    ...simpleIngredient,
                    costs: simpleIngredient.costs.map((simpleIngredientCost) =>
                      simpleIngredientCost.id === action.payload.id
                        ? action.payload
                        : simpleIngredientCost
                    )
                  }
                } else {
                  return simpleIngredient
                }
              }
            )
          }
        }
      )
      .addCase(
        updateSimpleIngredientCost.rejected,
        (state: SimpleIngredientState) => {
          state.updating = false
          state.error = true
        }
      )
      // delete Ingredient Cost
      .addCase(
        deleteSimpleIngredientCost.pending,
        (state: SimpleIngredientState) => {
          state.error = false
        }
      )
      .addCase(
        deleteSimpleIngredientCost.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.deleting = false
          if (state.selectedSimpleIngredient) {
            state.selectedSimpleIngredient = {
              ...state.selectedSimpleIngredient,
              costs: state.selectedSimpleIngredient.costs.filter(
                (simpleIngredientCost) =>
                  simpleIngredientCost.id !== action.payload
              )
            }
            state.simpleIngredients.items = state.simpleIngredients.items.map(
              (simpleIngredient) => {
                if (
                  simpleIngredient.id === state.selectedSimpleIngredient?.id
                ) {
                  return {
                    ...simpleIngredient,
                    costs: simpleIngredient.costs.filter(
                      (simpleIngredientCost) =>
                        simpleIngredientCost.id !== action.payload
                    )
                  }
                } else {
                  return simpleIngredient
                }
              }
            )
          }
        }
      )
      .addCase(
        deleteSimpleIngredientCost.rejected,
        (state: SimpleIngredientState) => {
          state.deleting = false
          state.error = true
        }
      )
  }
})

export const { setSelectedSimpleIngredient } = simpleIngredientSlice.actions
export default simpleIngredientSlice.reducer
