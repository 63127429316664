import { ArrowOutward } from '@mui/icons-material'
import { Box } from '@mui/material'
import { Button } from 'components/Button/Button'
import { NameAvatarCell } from 'components/Cells/NameAvatarCell'
import { BoxColumnBasic, BoxRow } from 'components/common'
import { ComponentPreview } from 'components/ComponentPreview/ComponentPreview'
import {
  FactsLabel,
  FactsLabelProps
} from 'pages/Formulator/components/FormulatorPanel/Label/components/FactsLabel'
import React from 'react'

export interface LabelProps {
  formula: {
    id: string
    name: string
    imageUrl?: string
    onClick: (id: string) => void
    loading?: boolean
  }
  factsLabel: FactsLabelProps
}

export const Label: React.FC<LabelProps> = ({ formula, factsLabel }) => {
  return (
    <BoxColumnBasic sx={{ height: '100%' }}>
      <BoxRow
        sx={{
          justifyContent: 'space-between',
          padding: '15px 20px',
          borderBottom: '1px solid #0000000D'
        }}
      >
        {!formula.loading && (
          <>
            <NameAvatarCell name={formula.name} avatarSrc={formula.imageUrl} />
            <Button
              color="secondary"
              size="small"
              onClick={() => formula.onClick(formula.id)}
            >
              Open Formula{' '}
              <ArrowOutward style={{ fontSize: '12px', marginLeft: '5px' }} />
            </Button>
          </>
        )}
      </BoxRow>

      <BoxColumnBasic overflow={'hidden'} flexGrow="1" marginBottom={'0px'}>
        <Box
          padding={'20px'}
          sx={{
            height: '100%'
          }}
        >
          <ComponentPreview loading={formula.loading || false}>
            <FactsLabel {...factsLabel} />
          </ComponentPreview>
        </Box>
      </BoxColumnBasic>
    </BoxColumnBasic>
  )
}
