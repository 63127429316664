import { Box } from '@mui/material'
import { BoxRowBasic } from 'components/common'
import { LoadingAnimation } from 'components/LoadingAnimation/LoadingAmination'
import { ZoomButtons } from 'components/ZoomButtons/ZoomButtons'
import React, {
  useCallback,
  useImperativeHandle,
  useRef,
  useState
} from 'react'
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper
} from 'react-zoom-pan-pinch'

interface ComponentPreviewProps {
  loading: boolean
  children?: React.ReactNode
  loadingText?: string
  onTransformChange?: (ref: ReactZoomPanPinchRef) => void
}

export interface ComponentPreviewRef {
  resetTransform: (onComplete?: () => void) => void
}

export const ComponentPreview = React.forwardRef<
  ComponentPreviewRef,
  ComponentPreviewProps
>(({ loading, children, onTransformChange, loadingText = 'Loading' }, ref) => {
  const MAX_SCALE = 5
  const MIN_SCALE = 0.5
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)
  const [currentScale, setCurrentScale] = useState<number>(1)

  const handleOnTransformed = useCallback(
    (ref: ReactZoomPanPinchRef, s: { scale: number }) => {
      setCurrentScale(s.scale)
      if (onTransformChange) {
        onTransformChange(ref)
      }
    },
    [setCurrentScale, onTransformChange]
  )

  useImperativeHandle(ref, () => ({
    resetTransform: (onComplete?: () => void) => {
      if (transformWrapperRef.current) {
        transformWrapperRef.current.resetTransform()
        if (onComplete) {
          onComplete() // Notify when resetTransform is completed
        }
      }
    }
  }))

  React.useEffect(() => {
    // Reset the scale only once when new children are loaded.
    if (transformWrapperRef.current && !loading) {
      // Slight delay to ensure the children are loaded before resetting the scale.
      const timeout = setTimeout(() => {
        transformWrapperRef.current?.resetTransform(0)
      }, 0)

      // Cleanup timeout on unmount or dependency change
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [loading])

  return (
    <BoxRowBasic
      sx={{
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
        height: '100%'
      }}
    >
      {loading && <LoadingAnimation text={loadingText} />}
      {!loading && (
        <TransformWrapper
          ref={transformWrapperRef}
          centerOnInit={true}
          minScale={MIN_SCALE}
          maxScale={MAX_SCALE}
          centerZoomedOut={true}
          onTransformed={handleOnTransformed}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, resetTransform }) => (
            <>
              <TransformComponent
                wrapperStyle={{
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  overflow: 'hidden'
                }}
                contentStyle={{
                  width: 'fit-content',
                  height: 'fit-content',
                  minWidth: '100%',
                  minHeight: '100%',
                  padding: '20px'
                }}
              >
                {children}
              </TransformComponent>
              <ZoomButtons
                sx={{ bottom: '12px', left: '12px' }}
                onZoomInClicked={() => zoomIn()}
                onZoomOutClicked={() => zoomOut()}
                onZoomResetClicked={() => {
                  resetTransform()
                }}
                zoomInDisabled={currentScale >= MAX_SCALE || loading}
                zoomOutDisabled={currentScale <= MIN_SCALE || loading}
              />
            </>
          )}
        </TransformWrapper>
      )}
    </BoxRowBasic>
  )
})
