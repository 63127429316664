import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  FormulaYieldAdjustmentApiResponse,
  YieldAdjustmentType
} from 'services/apis/formula/FormulaApiResponse'
import { LoadingState } from '../../CommonState'
import {
  AddFormulaYieldAdjustmentsRequest,
  GetFormulaYieldAdjustmentsRequest,
  RemoveFormulaYieldAdjustmentsRequest,
  UpdateFormulaYieldAdjustmentsRequest
} from './FormulaYieldAdjustmentsRequest'

export interface FormulaYieldAdjustmentsState extends LoadingState {
  formulaYieldAdjustments: FormulaYieldAdjustmentApiResponse[]
}

const initialState: FormulaYieldAdjustmentsState = {
  formulaYieldAdjustments: [],
  loading: false,
  error: false
}

export const getFormulaYieldAdjustments = createAsyncThunk(
  'formulas/yieldAdjustments/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaYieldAdjustmentsRequest): Promise<
    FormulaYieldAdjustmentApiResponse[]
  > => {
    return await FormulaApi.getFormulaYieldAdjustments(companyId, formulaId)
  }
)

export const addFormulaYieldAdjustment = createAsyncThunk(
  'formulas/yieldAdjustments/add',
  async ({
    companyId,
    formulaId,
    yieldAdjustmentId
  }: AddFormulaYieldAdjustmentsRequest): Promise<FormulaYieldAdjustmentApiResponse> => {
    return await FormulaApi.addFormulaYieldAdjustment(
      companyId,
      formulaId,
      yieldAdjustmentId,
      {
        adjustmentType: YieldAdjustmentType.LOSS
      }
    )
  }
)

export const updateFormulaYieldAdjustment = createAsyncThunk(
  'formulas/yieldAdjustments/update',
  async ({
    companyId,
    formulaId,
    yieldAdjustmentId,
    yieldAmount,
    yieldPercentage,
    adjustmentType
  }: UpdateFormulaYieldAdjustmentsRequest): Promise<FormulaYieldAdjustmentApiResponse> => {
    return await FormulaApi.updateFormulaYieldAdjustment(
      companyId,
      formulaId,
      yieldAdjustmentId,
      {
        adjustmentType,
        yieldAmount,
        yieldPercentage
      }
    )
  }
)

export const removeFormulaYieldAdjustment = createAsyncThunk(
  'formulas/yieldAdjustments/remove',
  async ({
    companyId,
    formulaId,
    yieldAdjustmentId
  }: RemoveFormulaYieldAdjustmentsRequest): Promise<string> => {
    return await FormulaApi.removeFormulaYieldAdjustment(
      companyId,
      formulaId,
      yieldAdjustmentId
    )
  }
)

const formulaYieldAdjustmentsSlice = createSlice({
  name: 'formulaYieldAdjustmentsSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    builder.addCase(getFormulaYieldAdjustments.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getFormulaYieldAdjustments.fulfilled, (state, action) => {
      state.formulaYieldAdjustments = action.payload
      state.loading = false
      state.error = false
    })
    builder.addCase(getFormulaYieldAdjustments.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(addFormulaYieldAdjustment.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(addFormulaYieldAdjustment.fulfilled, (state, action) => {
      state.formulaYieldAdjustments = [
        ...state.formulaYieldAdjustments,
        action.payload
      ]
      state.loading = false
      state.error = false
    })
    builder.addCase(addFormulaYieldAdjustment.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(updateFormulaYieldAdjustment.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(updateFormulaYieldAdjustment.fulfilled, (state) => {
      // We're recomputing the formula yield adjustments by doing a separate get call.
      state.loading = false
      state.error = false
    })
    builder.addCase(updateFormulaYieldAdjustment.rejected, (state) => {
      state.loading = false
      state.error = true
    })
    builder.addCase(removeFormulaYieldAdjustment.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(removeFormulaYieldAdjustment.fulfilled, (state, action) => {
      state.formulaYieldAdjustments = state.formulaYieldAdjustments.filter(
        (fya) => fya.yieldAdjustment.id !== action.payload
      )
      state.loading = false
      state.error = false
    })
    builder.addCase(removeFormulaYieldAdjustment.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export const { resetState } = formulaYieldAdjustmentsSlice.actions

export default formulaYieldAdjustmentsSlice.reducer
