import { Box, TableCell, TableRow } from '@mui/material'
import React from 'react'
import { calculateLevelPx } from '../common/helper'

export interface YieldAdjustmentRowProps {
  yieldAdjustment: React.ReactNode
  visibleWidth?: string
  span?: number
  level?: number
}

export const YieldAdjustmentRow: React.FC<YieldAdjustmentRowProps> = ({
  yieldAdjustment,
  visibleWidth: width,
  level = 0,
  span = 5
}) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          padding: '4px',
          position: 'sticky',
          background: 'white',
          left: 0,
          zIndex: 1
        }}
      />
      {/* Empty cell for the checkbox */}
      <TableCell
        colSpan={span}
        sx={{
          padding: '4px'
        }}
      >
        <Box
          sx={{
            width: width,
            position: 'sticky',
            background: 'white',
            zIndex: 1,
            paddingLeft: `${calculateLevelPx(level)}px`,
            left: '32px'
          }}
        >
          {yieldAdjustment}
        </Box>
      </TableCell>
    </TableRow>
  )
}
