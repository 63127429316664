import { BasicUser } from 'models/User'
import {
  ReviewerApiResponse,
  TaskApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { ActionStatus, AvatarAction } from '../../../../components/Avatar'
import {
  Reviewer,
  ReviewerOption
} from './ReviewItem/components/Reviewers/Reviewers'
import { ReviewItem } from './ReviewList'
import { Owner } from './ReviewItem/components/Owner/Owner'
import { BasicUserApiResponse } from 'services/apis/user/UserApiResponse'

const isTaskChecked = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string
): boolean => {
  // If the current user is a reviewer and approved the task or if all reviewers approved, it is checked.
  if (reviewers.length === 0) {
    return false
  }
  return (
    reviewers.some(
      (reviewer) => reviewer.reviewer.id === currentUserId && reviewer.approved
    ) || reviewers.every((reviewer) => reviewer.approved)
  )
}

const isTaskCompleted = (reviewers: ReviewerApiResponse[]): boolean => {
  return (
    reviewers.every((reviewer) => reviewer.approved) && reviewers.length > 0
  )
}

const getAvatarAction = (
  reviewer: ReviewerApiResponse
): AvatarAction | undefined => {
  return reviewer.approved
    ? { status: ActionStatus.APPROVED, date: reviewer.approvedAt || '' }
    : undefined
}

const getReviewersFromTask = (reviewers: ReviewerApiResponse[]): Reviewer[] => {
  const otherReviewers: Reviewer[] = reviewers.map((reviewer) => ({
    id: reviewer.reviewer.id,
    name: `${reviewer.reviewer.firstName} ${reviewer.reviewer.lastName}`,
    imageUrl: reviewer.reviewer.imagePath,
    removable: reviewer.permissions.removeReviewer,
    action: getAvatarAction(reviewer)
  }))

  return otherReviewers
}

const getOwner = (owner: BasicUserApiResponse): Owner => {
  // Find the owner in the reviewer and remove them.
  return {
    id: owner.id,
    name: `${owner.firstName} ${owner.lastName}`,
    imageUrl: owner.imagePath
  }
}

const getReviewerOptions = (
  allReviewerOptions: BasicUser[]
): ReviewerOption[] => {
  return allReviewerOptions.map((reviewer) => ({
    id: reviewer.id,
    name: reviewer.firstName + ' ' + reviewer.lastName,
    imageUrl: reviewer.imagePath
  }))
}

const hasEditReview = (
  reviewers: ReviewerApiResponse[],
  currentUserId: string
): boolean => {
  return reviewers.some(
    (reviewer) =>
      reviewer.reviewer.id === currentUserId && reviewer.permissions.editReview
  )
}

export const getReviewItemsFromTasks = (
  tasks: TaskApiResponse[],
  allReviewerOptions: BasicUser[],
  currentUserId: string
): ReviewItem[] => {
  return tasks.map((task: TaskApiResponse) => ({
    id: task.id,
    name: task.name,
    checked: isTaskChecked(task.reviewers, currentUserId),
    completed: isTaskCompleted(task.reviewers),
    reviewerOptions: getReviewerOptions(allReviewerOptions),
    reviewers: getReviewersFromTask(task.reviewers),
    owner: task.createdBy ? getOwner(task.createdBy) : undefined,
    date: task.dueDate,
    checkable:
      hasEditReview(task.reviewers, currentUserId) ||
      task.permissions.addReviewer,
    editable: task.permissions.editTask,
    deletable: task.permissions.deleteTask,
    addReviewerDisabled: !task.permissions.addReviewer
  }))
}
