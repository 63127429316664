import { Typography, useTheme } from '@mui/material'
import { toCh } from 'components/InputFields/helper'
import React from 'react'
import { UnstyledInput } from '../../../../../../../FormulatorStyles'

export interface IngredientCostProps {
  totalCost: number | null
}

export const IngredientCost: React.FC<IngredientCostProps> = ({
  totalCost
}) => {
  const theme = useTheme()

  return (
    <UnstyledInput
      disabled={true}
      value={totalCost}
      sx={{
        borderRadius: '8px',
        minWidth: toCh(totalCost || 0, 1, 3),
        width: '100%'
      }}
      startAdornment={
        <Typography marginLeft={'0.25em'} color={theme.palette.tertiary.main}>
          $
        </Typography>
      }
      inputProps={{ style: { textAlign: 'right' } }}
    />
  )
}
