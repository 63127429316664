import {
  Box,
  ClickAwayListener,
  MenuItem,
  Paper,
  Popper,
  Typography
} from '@mui/material'
import { Option } from 'components/common'
import React from 'react'
import { InView } from 'react-intersection-observer'
import { AutocompleteInputField } from './components/AutcompleteInputField'
import { TagOption } from './components/Tag'
import { useDebouncedCallback } from 'use-debounce'
import { ZIndex } from 'styles/zIndex'

export enum AutocompleteChangeReason {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
  CREATE = 'CREATE'
}

export interface AutocompleteProps {
  label?: string
  labelIcon?: React.ReactNode
  options: Option<boolean>[]
  allPagesLoaded?: boolean
  onLoadNextPage?: () => void
  search: {
    value: string
    onChange: (search: string) => void
    placeholder?: string
  }
  filteredOptions?: Option<boolean>[] | null
  onChange: (option: Option<boolean>, reason: AutocompleteChangeReason) => void
  maxTagsDisplay?: number
  disabled?: boolean
  freeSolo?: boolean
  debouncedTime?: number
}

export const Autocomplete: React.FC<AutocompleteProps> = ({
  label,
  labelIcon,
  options,
  allPagesLoaded = true,
  onLoadNextPage,
  search,
  filteredOptions,
  onChange,
  maxTagsDisplay,
  disabled,
  freeSolo,
  debouncedTime = 1000
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const [popperWidth, setPopperWidth] = React.useState<number | undefined>(
    undefined
  )
  const popoverContentRef = React.useRef<HTMLDivElement | null>(null) // Create a ref for the popover content.
  const scrollPositionRef = React.useRef<number>(0) // Create a ref to store the scroll position.
  const boxRef = React.useRef<HTMLDivElement | null>(null) // Create a ref for the Box element.
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const [pendingOptions, setPendingOptions] = React.useState<Option<boolean>[]>(
    []
  )
  const [internalOptions, setInternalOptions] = React.useState<
    Option<boolean>[]
  >([])
  const [internalFilteredOptions, setInternalFilteredOptions] = React.useState<
    Option<boolean>[] | null | undefined
  >([])

  const displayedOptions = internalFilteredOptions ?? internalOptions
  const selectedTags = internalOptions.filter((o) => o.value)

  React.useEffect(() => {
    // If there are no more pending options, load the new options.
    if (pendingOptions.length === 0) {
      setInternalOptions(options)
      setInternalFilteredOptions(filteredOptions)
    }
  }, [options, filteredOptions])

  React.useEffect(() => {
    if (pendingOptions.length > 0) {
      handleSubmissionPendingOptionsDebounced()
    }
  }, [pendingOptions])

  const handleInViewChange = (inView: boolean) => {
    if (inView && displayedOptions.length > 0 && onLoadNextPage) {
      scrollPositionRef.current = popoverContentRef.current?.scrollTop || 0 // Save scroll position before loading new data
      onLoadNextPage()
    }
  }

  const handleFocus = () => {
    search.onChange('')
    setAnchorEl(boxRef.current) // Set the Box element as the anchor element
  }

  const handleBlur = () => {
    setAnchorEl(null)
  }

  const updatePendingOptions = (option: Option<boolean>) => {
    setInternalOptions(
      internalOptions.map((o) => (o.id === option.id ? option : o))
    )
    setInternalFilteredOptions(
      internalFilteredOptions?.map((o) => (o.id === option.id ? option : o))
    )
    setPendingOptions((prev) => {
      const isOptionPresent = prev.some((o) => o.id === option.id)
      if (isOptionPresent) {
        return prev.filter(
          (o) => !(o.id === option.id && o.value !== option.value)
        )
      }
      return [...prev, option]
    })
  }

  const handleSubmissionPendingOptionsDebounced = useDebouncedCallback(() => {
    pendingOptions.forEach((option) => {
      onChange(
        option,
        option.value
          ? AutocompleteChangeReason.ADD
          : AutocompleteChangeReason.REMOVE
      )
    })
    setPendingOptions([])
  }, debouncedTime)

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: Option<boolean>
  ) => {
    e.stopPropagation()
    scrollPositionRef.current = popoverContentRef.current?.scrollTop || 0 // Save the scroll position before updating
    updatePendingOptions(option)
  }

  React.useEffect(() => {
    if (popoverContentRef.current) {
      popoverContentRef.current.scrollTop = scrollPositionRef.current // Restore the scroll position after updating
    }
  }, [displayedOptions]) // Run when displayedOptions changes.

  React.useEffect(() => {
    if (anchorEl) {
      const resizeObserver = new ResizeObserver(() => {
        setPopperWidth(anchorEl.clientWidth)
      })
      resizeObserver.observe(anchorEl)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [anchorEl])

  const handleDelete = (tag: TagOption) => {
    updatePendingOptions({ ...tag, value: false })
  }

  const handleCreate = (newTag: string) => {
    onChange(
      { id: `temp-${newTag}`, label: newTag, value: true },
      AutocompleteChangeReason.CREATE
    )
  }

  return (
    <ClickAwayListener onClickAway={handleBlur}>
      <Box ref={boxRef}>
        <AutocompleteInputField
          onChange={search.onChange}
          onFocus={handleFocus}
          value={search.value}
          tags={selectedTags}
          onDelete={handleDelete}
          onCreate={freeSolo ? handleCreate : undefined}
          debounceTime={300}
          label={label}
          labelIcon={labelIcon}
          maxDisplay={open ? undefined : maxTagsDisplay}
          disabled={disabled}
          scale="small"
        />

        <Popper
          id={id}
          open={open && !disabled}
          anchorEl={anchorEl}
          placement="bottom"
          sx={{
            borderRadius: '8px',
            width: popperWidth,
            zIndex: ZIndex.popper
          }}
        >
          <Paper
            ref={popoverContentRef}
            sx={{
              padding: '6px',
              maxHeight: '30vh',
              flexWrap: 'nowrap',
              overflowY: 'auto'
            }}
          >
            {displayedOptions
              .sort((a, b) => {
                if (a.value && !b.value) {
                  return -1
                }
                if (!a.value && b.value) {
                  return 1
                }
                return 0
              })
              .map((o) => (
                <MenuItem
                  key={o.id}
                  title={o.label as string}
                  sx={{
                    padding: '6px'
                  }}
                  onClick={(e) => handleClick(e, { ...o, value: !o.value })}
                  selected={o.value}
                >
                  <Typography
                    sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                  >
                    {o.label}
                  </Typography>
                </MenuItem>
              ))}
            {!allPagesLoaded && (
              <InView
                as="div"
                key={`${search.value}-${displayedOptions.length}`}
                onChange={handleInViewChange}
                trackVisibility={true}
                delay={300}
              >
                <div
                  style={{
                    height: '1px',
                    display: 'block'
                  }}
                ></div>
              </InView>
            )}
            {displayedOptions.length === 0 &&
              label &&
              (!freeSolo || !search.value) && (
                <MenuItem sx={{ padding: '6px' }} disabled={true}>
                  No {label.toLowerCase()} found
                </MenuItem>
              )}
            {freeSolo && displayedOptions.length === 0 && search.value && (
              <MenuItem
                sx={{ padding: '6px' }}
                onClick={(e) => {
                  e.stopPropagation()
                  handleCreate(search.value)
                }}
              >
                Add "{search.value}"
              </MenuItem>
            )}
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}
