import { Box, TableCell, TableRow } from '@mui/material'
import React from 'react'

interface IngredientSearchRowProps {
  ingredientSearch: React.ReactNode
  width?: string
  span?: number
}

export const IngredientSearchRow: React.FC<IngredientSearchRowProps> = ({
  ingredientSearch,
  width = '100%',
  span = 5
}) => {
  return (
    <TableRow>
      <TableCell
        sx={{
          padding: '4px',
          position: 'sticky',
          background: 'white',
          left: 0,
          zIndex: 1
        }}
      />
      {/* Empty cell for the checkbox */}
      <TableCell
        colSpan={span}
        sx={{
          padding: '4px'
        }}
      >
        <Box
          sx={{
            width: width,
            position: 'sticky',
            background: 'white',
            left: '32px',
            zIndex: 1
          }}
        >
          {ingredientSearch}
        </Box>
      </TableCell>
    </TableRow>
  )
}
