import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FormulaIngredient } from 'models/FormulaIngredient'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import { LoadingState } from '../../CommonState'
import { GetFormulaIngredientsRequest } from './FormulatorIngredientsRequest'

export interface FormulatorIngredientsState extends LoadingState {
  formulaIngredients?: FormulaIngredient[]
}

const initialState: FormulatorIngredientsState = {
  loading: false,
  error: false,
  formulaIngredients: undefined
}

export const getFormulaIngredients = createAsyncThunk(
  'formulas/ingredients/all/get',
  async ({ companyId, formulaId }: GetFormulaIngredientsRequest) => {
    return await FormulaApi.getIngredients(companyId, formulaId)
  }
)

const formulatorIngredientsSlice = createSlice({
  name: 'breakdownSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    // Get ingredients of formula.
    builder.addCase(getFormulaIngredients.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(getFormulaIngredients.fulfilled, (state, action) => {
      state.formulaIngredients = action.payload
      state.loading = false
    })
    builder.addCase(getFormulaIngredients.rejected, (state) => {
      state.loading = false
      state.error = true
    })
  }
})

export const { resetState } = formulatorIngredientsSlice.actions
export default formulatorIngredientsSlice.reducer
