import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoadingState } from '../../CommonState'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  CreateFormulaTargetRequest,
  DeleteFormulaTargetRequest,
  GetFormulaTargetsRequest
} from './FormulaTargetsRequest'
import { FormulaTarget } from 'models/FormulaTargets'

export interface FormulaTargetsState extends LoadingState {
  formulaTargets: FormulaTarget[]
  executedCompanyTargets: FormulaTarget[]
  loadingFormulaTargets: boolean
}

const initialState: FormulaTargetsState = {
  formulaTargets: [],
  executedCompanyTargets: [],
  loadingFormulaTargets: false,
  loading: false,
  error: false
}

export const getExecutedCompanyTargets = createAsyncThunk(
  'formulas/targets/executed/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaTargetsRequest): Promise<FormulaTarget[]> => {
    return await FormulaApi.getFormulaTargets(companyId, formulaId, true)
  }
)

export const getFormulaTargets = createAsyncThunk(
  'formulas/targets/get',
  async ({
    companyId,
    formulaId
  }: GetFormulaTargetsRequest): Promise<FormulaTarget[]> => {
    return await FormulaApi.getFormulaTargets(companyId, formulaId, false)
  }
)

export const createFormulaTarget = createAsyncThunk(
  'formulas/targets/create',
  async ({
    companyId,
    formulaId,
    targetId
  }: CreateFormulaTargetRequest): Promise<FormulaTarget> => {
    return await FormulaApi.createFormulaTarget(companyId, formulaId, {
      targetDefinitionId: targetId
    })
  }
)

export const deleteFormulaTarget = createAsyncThunk(
  'formulas/targets/delete',
  async ({
    companyId,
    formulaId,
    targetId
  }: DeleteFormulaTargetRequest): Promise<{
    formulaId: string
    targetId: string
  }> => {
    return await FormulaApi.deleteFormulaTarget(companyId, formulaId, targetId)
  }
)

const formulaTargetsSlice = createSlice({
  name: 'formulaTargetsSlice',
  initialState,
  reducers: {
    resetFormulaTargetsState: () => {
      return initialState
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getExecutedCompanyTargets.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getExecutedCompanyTargets.fulfilled, (state, action) => {
        state.loading = false
        state.error = false
        state.executedCompanyTargets = action.payload
      })
      .addCase(getExecutedCompanyTargets.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(getFormulaTargets.pending, (state) => {
        state.loadingFormulaTargets = true
        state.error = false
      })
      .addCase(getFormulaTargets.fulfilled, (state, action) => {
        state.loadingFormulaTargets = false
        state.error = false
        state.formulaTargets = action.payload
      })
      .addCase(getFormulaTargets.rejected, (state) => {
        state.loadingFormulaTargets = false
        state.error = true
      })

    builder
      .addCase(createFormulaTarget.pending, (state) => {
        state.error = false
      })
      .addCase(createFormulaTarget.fulfilled, (state, action) => {
        state.error = false
        state.formulaTargets = [...state.formulaTargets, action.payload]
      })
      .addCase(createFormulaTarget.rejected, (state) => {
        state.error = true
      })

    builder
      .addCase(deleteFormulaTarget.pending, (state) => {
        state.error = false
      })
      .addCase(deleteFormulaTarget.fulfilled, (state, action) => {
        state.error = false
        state.formulaTargets = state.formulaTargets.filter(
          (formulaTarget) =>
            formulaTarget.targetDefinition.id !== action.payload.targetId
        )
      })
      .addCase(deleteFormulaTarget.rejected, (state) => {
        state.error = true
      })
  }
})

export const { resetFormulaTargetsState } = formulaTargetsSlice.actions

export default formulaTargetsSlice.reducer
