import { Box, Typography } from '@mui/material'
import { DateWithTooltip } from 'components/DateWithTooltip/DateWithTooltip'
import { BoxRowBasic } from 'components/common'
import React from 'react'
import {
  ActivityDetailsApiResponse,
  LabelProofActionApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import {
  ActionStatus,
  Avatar,
  AvatarAction
} from '../../../../components/Avatar'
import { BoldText } from './styles'

export interface ProofLogProps {
  occurredAt: string
  user?: {
    name: string
    avatarSrc: string
  }
  details: ActivityDetailsApiResponse
}

const renderLogDetails = (
  userName: string,
  details: ActivityDetailsApiResponse
) => {
  switch (details.action) {
    case LabelProofActionApiResponse.LABEL_PROOF_CREATED:
      return (
        <>
          {userName} created the label proof{' '}
          <BoldText>{details.labelProofName}</BoldText> for{' '}
          <BoldText>{details.formulaName}</BoldText> formula
        </>
      )
    case LabelProofActionApiResponse.LABEL_PROOF_RENAMED:
      return (
        <>
          {userName} renamed the label proof from{' '}
          <BoldText>{details.oldLabelProofName}</BoldText> to{' '}
          <BoldText>{details.newLabelProofName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.LABEL_PROOF_ARCHIVED:
      return (
        <>
          {userName} archived the label proof{' '}
          <BoldText>{details.labelProofName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.LABEL_PROOF_RESTORED:
      return (
        <>
          {userName} restored the label proof{' '}
          <BoldText>{details.labelProofName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.DOCUMENT_UPLOADED:
      return (
        <>
          {userName} uploaded a document to label proof{' '}
          <BoldText>{details.labelProofName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.DOCUMENT_DELETED:
      return (
        <>
          {userName} deleted a document from label proof{' '}
          <BoldText>{details.labelProofName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.REVIEWER_ADDED:
      return (
        <>
          {userName} added reviewer{' '}
          <BoldText>{`${details.reviewer.firstName} ${details.reviewer.lastName}`}</BoldText>{' '}
          to task <BoldText>{details.taskName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.REVIEWER_REMOVED:
      return (
        <>
          {userName} removed reviewer{' '}
          <BoldText>{`${details.reviewer.firstName} ${details.reviewer.lastName}`}</BoldText>{' '}
          from the task
        </>
      )
    case LabelProofActionApiResponse.TASK_CREATED:
      return (
        <>
          {userName} created the task <BoldText>{details.taskName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.TASK_RENAMED:
      return (
        <>
          {userName} renamed the task from{' '}
          <BoldText>{details.oldTaskName}</BoldText> to{' '}
          <BoldText>{details.newTaskName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.TASK_DUE_DATE_CHANGED:
      return (
        <>
          {userName}{' '}
          {details.oldTaskDueDate && details.newTaskDueDate ? (
            <>
              changed the due date of the task{' '}
              <BoldText>{details.taskName}</BoldText> from{' '}
              <BoldText>{details.oldTaskDueDate}</BoldText> to{' '}
              <BoldText>{details.newTaskDueDate}</BoldText>
            </>
          ) : details.oldTaskDueDate ? (
            <>
              removed the due date of the task{' '}
              <BoldText>{details.taskName}</BoldText>
            </>
          ) : (
            <>
              added a due date to the task{' '}
              <BoldText>{details.taskName}</BoldText> of{' '}
              <BoldText>{details.newTaskDueDate}</BoldText>
            </>
          )}
        </>
      )

    case LabelProofActionApiResponse.TASK_APPROVED:
      return (
        <>
          {userName} approved the task <BoldText>{details.taskName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.TASK_UNAPPROVED:
      return (
        <>
          {userName} unapproved the task <BoldText>{details.taskName}</BoldText>
        </>
      )
    case LabelProofActionApiResponse.TASK_DELETED:
      return (
        <>
          {userName} deleted the task <BoldText>{details.taskName}</BoldText>
        </>
      )
    default:
      return <>{userName} performed an unknown activity</>
  }
}

export const ProofLog: React.FC<ProofLogProps> = ({
  occurredAt,
  user,
  details
}) => {
  const userName = React.useMemo(() => user?.name || 'Someone', [user?.name])

  const avatarAction = React.useMemo((): AvatarAction | undefined => {
    switch (details.action) {
      case LabelProofActionApiResponse.TASK_APPROVED:
        return { status: ActionStatus.APPROVED, date: occurredAt }
      case LabelProofActionApiResponse.TASK_CREATED:
        return { status: ActionStatus.ADDED, date: occurredAt }
      default:
        return undefined
    }
  }, [details.action, occurredAt])

  return (
    <BoxRowBasic
      gap="1rem"
      sx={{
        width: '100%',
        borderRadius: '8px',
        backgroundColor: 'transparent',
        padding: '10px',
        alignItems: 'center'
      }}
    >
      <Avatar
        id={user?.name || ''}
        name={user?.name || ''}
        imageUrl={user?.avatarSrc}
        action={avatarAction}
        size={40}
      />

      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            color: 'rgba(20, 32, 46, 0.9)'
          }}
          component="span"
        >
          {renderLogDetails(userName, details)}
        </Typography>
        <DateWithTooltip
          date={occurredAt}
          style={{ marginLeft: '5px', display: 'inline' }}
        />
      </Box>
    </BoxRowBasic>
  )
}
