import { Box } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import { Tab } from 'components/Tabs/Tab/Tab'
import { TabPanel } from 'components/Tabs/TabPanel/TabPanel'
import { Tabs } from 'components/Tabs/Tabs'
import React from 'react'
import { LabelProoferTab } from './PanelContainer'

export interface PanelProps {
  reviewTabContent: React.ReactNode
  activityTabContent: React.ReactNode
  labelTabContent: React.ReactNode
  documentsTabContent: React.ReactNode
  activeTab?: LabelProoferTab
  onTabChange: (newTab: LabelProoferTab) => void
}

export const Panel: React.FC<PanelProps> = ({
  reviewTabContent,
  activityTabContent: commentsTabContent,
  labelTabContent,
  documentsTabContent,
  activeTab = LabelProoferTab.REVIEW,
  onTabChange
}) => {
  return (
    <>
      <Box height="100%">
        <BoxColumnBasic height="100%" width="100%" minWidth="36.8vw">
          <Tabs
            sx={{
              padding: '20px',
              alignItems: 'center',
              overflow: 'hidden',
              boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.08)',
              flex: '0 0 auto',
              zIndex: '1',
              borderBottom: '2px solid rgba(0, 0, 0, 0.06)'
            }}
            value={activeTab}
            onChange={(_, newValue) => onTabChange(newValue)}
          >
            <Tab label="Review" />
            <Tab label="Activity" />
            <Tab label="Label" />
            <Tab label="Documents" />
          </Tabs>
          <Box flex="1 1 auto" zIndex={1} overflow="hidden">
            <TabPanel value={activeTab} index={LabelProoferTab.REVIEW}>
              {reviewTabContent}
            </TabPanel>
            <TabPanel value={activeTab} index={LabelProoferTab.ACTIVITY}>
              {commentsTabContent}
            </TabPanel>
            <TabPanel value={activeTab} index={LabelProoferTab.LABEL}>
              {labelTabContent}
            </TabPanel>
            <TabPanel value={activeTab} index={LabelProoferTab.DOCUMENTS}>
              {documentsTabContent}
            </TabPanel>
          </Box>
        </BoxColumnBasic>
      </Box>
    </>
  )
}
