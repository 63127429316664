import React from 'react'
import { getExecutedCompanyTargets } from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { TargetCategoryProps } from './components/TargetCategory'
import { TargetsResults } from './TargetsResults'
import { deepCompareMemo } from 'common/utils'

export const TargetsResultsContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const executedCompanyTargets = useAppSelector(
    (state) => state.formulaTargets.executedCompanyTargets
  )
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const companyId = useAppSelector((state) => state.companies.currentCompany.id)

  const formulaBreakdown = useAppSelector((state) => state.breakdown.breakdown)

  const formulaBreakdownDependency = deepCompareMemo(() => {
    // When yield changes.
    if (formulaBreakdown) {
      return formulaBreakdown.result.items.map((item) => {
        return {
          formulaIngredientId: item.formulaIngredientId,
          yield: item.quantities.yieldQuantity
        }
      })
    }
    return []
  })

  const formulaDependency = deepCompareMemo(() => {
    if (formulaBreakdown) {
      return {
        inputAdjustments: formulaBreakdown.root.inputAdjustments,
        servingSize: formulaBreakdown.root.servingSize
      }
    }
  })

  React.useEffect(() => {
    if (formulaId) {
      void dispatch(
        getExecutedCompanyTargets({
          companyId: companyId,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, companyId, formulaBreakdownDependency, formulaDependency])

  const targetCategoriesProps = React.useMemo(() => {
    // Store each target in its relevant category.
    const targetCategories: Record<string, TargetCategoryProps> = {}
    const targetCategoriesRank: Record<string, number> = {}
    executedCompanyTargets.forEach((target) => {
      const categoryId = target.targetDefinitionCategory?.id ?? ''
      if (!targetCategories[categoryId]) {
        targetCategories[categoryId] = {
          categoryName:
            target.targetDefinitionCategory?.name ?? 'Custom Targets',
          targetsResultsProps: []
        }
        targetCategoriesRank[categoryId] = target.targetDefinitionCategory?.id
          ? target.targetDefinitionCategory?.rank ?? Number.MAX_VALUE
          : -1
      }
      targetCategories[categoryId].targetsResultsProps.push({
        description: target.targetDefinition.description,
        active: target.isTargetSelectedForFormula,
        matched: target.matched,
        name: target.targetDefinition.name,
        progressPercentage: target.averagePercentScore,
        executionTracePerTruthSet: target.targetExecutionTracePerTruthSet
      })
    })

    // Sorting priority in each category is Selected Targets, Percentage complete, Alphabetical.
    Object.values(targetCategories).forEach((category) => {
      category.targetsResultsProps.sort((a, b) => {
        if (a.active && !b.active) {
          return -1
        }
        if (!a.active && b.active) {
          return 1
        }
        if (
          a.progressPercentage !== undefined &&
          b.progressPercentage !== undefined
        ) {
          const diff = b.progressPercentage - a.progressPercentage
          if (diff !== 0) {
            return diff
          }
        }
        return a.name.localeCompare(b.name)
      })
    })

    return Object.keys(targetCategories)
      .sort((a, b) => {
        if (targetCategoriesRank[a] === targetCategoriesRank[b]) {
          return targetCategories[a].categoryName.localeCompare(
            targetCategories[b].categoryName
          )
        }
        return targetCategoriesRank[a] - targetCategoriesRank[b]
      })
      .map((key) => targetCategories[key])
  }, [executedCompanyTargets])

  return <TargetsResults targetCategoriesProps={targetCategoriesProps} />
}
