import { BasicCompanyApiResponse } from '../company/CompanyApiResponse'
import { SortedListing } from '../config/Sorting'
import { NutrientTypeApiResponse, NutrientUnitApiResponse } from '../nutrient/NutrientApiResponse'
import { RegulationApiResponse } from '../regulation/RegulationApiResponse'

export enum LogicalOperatorApiResponse {
  AND = 'and',
  OR = 'or'
}

export enum ComparisonOperatorApiResponse {
  EQUAL = '=',
  LESS_THAN = '<',
  BIGGER_THAN = '>',
  LESS_THAN_OR_EQUAL = '<=',
  BIGGER_THAN_OR_EQUAL = '>=',
  NOT_EQUAL = '!='
}

export enum OperationTypeApiResponse {
  LOGICAL = 'logical',
  COMPARISON = 'comparison',
  TARGET = 'target',
  CONDITIONAL = 'conditional'
}

export enum ComparisonTypeApiResponse {
  RACC = 'RACC',
  PER_RACC = 'per_RACC',
  PER_TARGET_VALUE = 'per_target_value',
  PER_LABELED_SERVING = 'per_labeled_serving',
  CALORIES_FROM_NUTRIENT = 'calories_from_nutrient',
  TAGS_IN_FORMULA = 'tags_in_formula',
  TEXT_IN_FORMULA = "text_in_formula"
}

export enum ConditionalTypeApiResponse {
  TARGET = 'target',
  MEAL_AND_MAIN_DISH = 'meal_and_main_dish',
  SMALL_RACC = 'small_racc',
  ALLERGEN_FREE = "allergen_free"
}

export enum ComparisonUnitApiResponse {
  GRAM = 'g',
  MILLIGRAM = 'mg',
  TABLESPOON = 'tbsp',
  PERCENTAGE = '%',
  CALORIE = 'calorie',
  TAG = 'tag'
}

export enum ExtendedNutrientUnitForTargetsApiResponse {
  PERCENTAGE = '%',
  TAG = 'tag'
}

export enum TargetsSortField {
  NAME = 'name',
  UPDATED_AT = 'updated_at',
  CATEGORY_NAME = 'category_name'
}

export type NutrientTargetUnitApiResponse = NutrientUnitApiResponse | ExtendedNutrientUnitForTargetsApiResponse

export interface BasicOperationApiResponse {
  id: string
  operationType: OperationTypeApiResponse
}

export interface ConditionalOperationApiResponse
  extends BasicOperationApiResponse {
  conditionalType: ConditionalTypeApiResponse
  targetId?: string
  isMealOrMainDish?: boolean
  isSmallRacc?: boolean
  allergenType?: string
}

export interface TargetOperationApiResponse extends BasicOperationApiResponse {
  targetId: string
  targetName: string
  expectedExecutionReturn?: boolean
  executeOnReferenceFormula?: boolean
}

export interface ComparisonOperationApiResponse
  extends BasicOperationApiResponse {
  operator: ComparisonOperatorApiResponse
  comparisonUnit: NutrientTargetUnitApiResponse
  value: number
  comparisonType: ComparisonTypeApiResponse
  comparedToReferenceFormula: boolean
  comparedToRdi?: boolean
  nutrient?: NutrientTypeApiResponse
  secondNutrient?: NutrientTypeApiResponse
  targetValue?: number
  targetUnit?: ComparisonUnitApiResponse
  tagId?: string
  text?: string
}

export interface LogicalOperationApiResponse extends BasicOperationApiResponse {
  operator: LogicalOperatorApiResponse
  operations: Array<OperationApiResponse>
}

export type OperationApiResponse =
  | LogicalOperationApiResponse
  | ComparisonOperationApiResponse
  | TargetOperationApiResponse
  | ConditionalOperationApiResponse

export interface BasicTargetCategoryApiResponse {
  id: string
  name: string
}

export interface BasicTargetApiResponse {
  id: string
  name: string
  updatedAt: string
  formulaCount: number
  isEditable: boolean
  company?: BasicCompanyApiResponse
  category?: BasicTargetCategoryApiResponse
  description?: string
  regulation?: RegulationApiResponse
}

export interface TargetApiResponse extends BasicTargetApiResponse {
  operation?: OperationApiResponse
}

export interface SortedBasicTargetsApiResponse
  extends SortedListing<BasicTargetApiResponse, TargetsSortField> { }
