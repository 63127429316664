import { MentionApiResponse } from 'services/apis/label_proofs/LabelProofApiResponse'

export const getMentionedUsersMap = (
  mentions: MentionApiResponse[]
): Record<string, string> => {
  return mentions.reduce((acc, mention) => {
    acc[mention.mentionedUser.id as string] =
      mention.mentionedUser.firstName + ' ' + mention.mentionedUser.lastName
    return acc
  }, {} as Record<string, string>)
}

export const addPinToText = (text: string, pinId?: string) => {
  return pinId ? `@@[${pinId}] ${text}` : text
}
