import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LabelProofActionApiResponse,
  LabelProofActivityApiResponse,
  LabelProofPinCommentApiResponse,
  PaginatedLabelProofActivityApiResponse
} from 'services/apis/label_proofs/LabelProofApiResponse'
import { LabelProofsApi } from 'services/apis/label_proofs/LabelProofsApi'
import {
  GetProofActivitiesRequest,
} from './ProoferRequest'
import { BaseApiPaginatedResponse } from 'services/apis/config/BaseApiPaginatedResponse'

export type Activity =
  | LabelProofPinCommentApiResponse
  | LabelProofActivityApiResponse
export type PaginatedActivities = BaseApiPaginatedResponse<Activity>
export const isLog = (
  item: Activity
): item is LabelProofActivityApiResponse => {
  return 'details' in item
}
export const isComment = (
  item: Activity
): item is LabelProofPinCommentApiResponse => {
  return 'comment' in item
}

export interface ActivitiesState {
  activities: PaginatedActivities
  loadingActivities: boolean
}

const initialState: ActivitiesState = {
  activities: {
    items: [],
    page: 0,
    total: 0,
    size: 0,
    pages: 0
  },
  loadingActivities: false
}

export const getProofActivities = createAsyncThunk(
  'labelproof/proofer/getActivities',
  async ({
    companyId,
    proofId,
    page = 1,
    size = 50
  }: GetProofActivitiesRequest) => {
    return await LabelProofsApi.getLabelProofActivities(
      companyId,
      proofId,
      page,
      size
    )
  }
)

const prooferActivitiesSlice = createSlice({
  name: 'prooferActivitiesSlice',
  initialState,
  reducers: {
    resetProoferActivitiesState: () => {
      return {
        ...initialState
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(getProofActivities.pending, (state) => {
      state.loadingActivities = true
    })
    builder.addCase(
      getProofActivities.fulfilled,
      (
        state,
        action: PayloadAction<PaginatedLabelProofActivityApiResponse>
      ) => {
        // Parse the activities properly.
        const newActivitiesAndComments = action.payload.items.map((item) => {
          if (
            item.details.action ===
              LabelProofActionApiResponse.COMMENT_CREATED &&
            item.linkedComment
          ) {
            return item.linkedComment
          }
          return item
        })

        if (action.payload.page === 1) {
          state.activities = {
            ...action.payload,
            items: newActivitiesAndComments
          }
        } else {
          state.activities = {
            ...action.payload,
            items: [...newActivitiesAndComments, ...state.activities.items]
          }
        }
        state.loadingActivities = false
      }
    )
    builder.addCase(getProofActivities.rejected, (state) => {
      state.loadingActivities = false
    })
  }
})

export const { resetProoferActivitiesState } = prooferActivitiesSlice.actions
export default prooferActivitiesSlice.reducer
