import React from 'react'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { BasicDocument } from 'models/Document'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { DocumentsPanel } from 'components/DocumentsPanel/DocumentsPanel'
import {
  deleteProofDocument,
  getProofDocuments,
  getProofDocument,
  uploadProofDocument
} from 'state/labelproof/proofer/ProoferSlice'


export const ProoferDocuments: React.FC<{}> = ({}) => {
  const dispatch = useAppDispatch()

  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )
  const proofId = useAppSelector((state) => state.proofer.proof?.id)

  const prooferDocuments = useAppSelector((state) => state.proofer.documents)

  const { showError } = React.useContext(SnackbarContext)

  const getDocuments = () => {
    if (!proofId) {
      return
    }
    void dispatch(
      getProofDocuments({
        companyId: currentCompany.id,
        proofId: proofId
      })
    ).then((action) => {
      if (getProofDocuments.rejected.match(action)) {
        showError('There was a problem loading the documents')
      }
    })
  }

  const handleUploadDocument = (
    file: File,
    progressListener: (progress: number | undefined) => void
  ) => {
    if (!proofId) {
      return Promise.reject()
    }
    return dispatch(
      uploadProofDocument({
        companyId: currentCompany.id,
        proofId: proofId,
        file: file,
        uploadProgressListener: (progressEvent) => {
          progressListener(progressEvent.progress)
        }
      })
    ).then(() => {})
  }

  const getDocumentUrl = (file: BasicDocument): Promise<string> => {
    if (!proofId) {
      return Promise.reject('')
    }

    return dispatch(
      getProofDocument({
        companyId: currentCompany.id,
        proofId,
        fileName: file.name,
        download: false
      })
    )
      .unwrap()
      .then((url) => {
        return url
      })
  }

  const handleDownloadDocument = (file: BasicDocument): Promise<void> => {
    if (!proofId) {
      return Promise.reject()
    }

    return dispatch(
      getProofDocument({
        companyId: currentCompany.id,
        proofId,
        fileName: file.name,
        download: true
      })
    ).then(() => {})
  }

  const handleDeleteDocument = (file: BasicDocument): Promise<void> => {
    if (!proofId) {
      return Promise.reject()
    }
    return dispatch(
      deleteProofDocument({
        companyId: currentCompany.id,
        proofId: proofId,
        fileName: file.name
      })
    ).then(() => {})
  }

  React.useEffect(() => {
    getDocuments()
  }, [])

  return (
    <DocumentsPanel
      documents={prooferDocuments}
      onDeleteDocument={handleDeleteDocument}
      onDownloadDocument={handleDownloadDocument}
      onUploadDocument={handleUploadDocument}
      getDocumentUrl={getDocumentUrl}
      openInNewTab={true}
      refresh={getDocuments}
    />
  )
}
