import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ConversionsApi } from 'services/apis/conversions/ConversionApi'
import { FormulaBreakdown } from 'services/apis/formula/breakdown/BreakdownResponse'
import { FormulaApi } from 'services/apis/formula/FormulaApi'
import {
  AddingState,
  LoadingState,
  RemovingState,
  UpdatingState
} from 'state/CommonState'
import {
  AddFormulaIngredientRequest,
  GetFormulaBreakdownRequest,
  GetIngredientConversionsRequest,
  RemoveFormulaIngredientsRequest,
  ScaleFormulaRequest,
  UpdateFormulaIngredientActiveRequest,
  UpdateFormulaIngredientAmountRequest,
  UpdateFormulaIngredientMeasurementRequest,
  UpdateFormulaIngredientSupplierRequest,
  UpdateFormulaIngredientWastePercentageRequest
} from './BreakdownRequest'
import { RootState } from 'state/store'

export interface BreakdownState
  extends LoadingState,
    UpdatingState,
    AddingState,
    RemovingState {
  breakdown?: FormulaBreakdown
}

const initialState: BreakdownState = {
  loading: false,
  updating: false,
  adding: false,
  removing: false,
  error: false,
  breakdown: undefined
}

export const getFormulaIngredientBreakdown = createAsyncThunk(
  'formulas/breakdown/get',
  async ({ companyId, formulaId, params }: GetFormulaBreakdownRequest) => {
    return FormulaApi.getFormulaBreakdown(companyId, formulaId, {
      roundingPrecision: 6,
      proportionRoundingPrecision: 4,
      ...params
    })
  }
)

export const scaleFormula = createAsyncThunk(
  'formulas/scale',
  async ({ companyId, formulaId, scale }: ScaleFormulaRequest) => {
    return await FormulaApi.scaleFormula(companyId, formulaId, { scale }).then(
      () => {
        return scale
      }
    )
  }
)

export const updateFormulaIngredientMeasurement = createAsyncThunk(
  'formulas/ingredients/measurements/update',
  async (
    {
      companyId,
      formulaId,
      formulaIngredientId,
      measurementId
    }: UpdateFormulaIngredientMeasurementRequest,
    { getState }
  ) => {
    const state = getState() as RootState
    const fi = state.breakdown.breakdown?.result.items.find(
      (v) => v.formulaIngredientId === formulaIngredientId
    )
    const oldMeasurementType = fi?.inputMeasurement.type
    const oldAmountInMeasurement =
      fi?.quantities.inputQuantity.convertedValues[oldMeasurementType!]?.value

    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      {
        measurementId: measurementId,
        amountInMeasurement: oldAmountInMeasurement
      }
    )
  }
)

export const removeFormulaIngredients = createAsyncThunk(
  'formulas/ingredients/remove',
  async ({
    companyId,
    formulaId,
    formulaIngredientIds = []
  }: RemoveFormulaIngredientsRequest) => {
    return await FormulaApi.deleteIngredients(
      companyId,
      formulaId,
      formulaIngredientIds
    )
  }
)

export const getFormulaIngredientMeasurements = createAsyncThunk(
  'formulas/ingredients/measurements/get',
  async ({ companyId, ingredientId }: GetIngredientConversionsRequest) => {
    return await ConversionsApi.getConversions(companyId, ingredientId).then(
      (res) => {
        return [
          ...res.conversions.map((c) => c.measurement),
          ...res.suggestedConversions.map((sc) => sc.measurement)
        ]
      }
    )
  }
)

export const updateIngredientAmount = createAsyncThunk(
  'formulas/ingredients/amount/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    amountInMeasurement,
    measurementId
  }: UpdateFormulaIngredientAmountRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { amountInMeasurement, measurementId }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const updateIngredientWastePercentage = createAsyncThunk(
  'formulas/ingredients/wastePercentage/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    wastePercentage
  }: UpdateFormulaIngredientWastePercentageRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { wastePercentage }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const updateFormulaIngredientSupplier = createAsyncThunk(
  'formulas/ingredients/supplier/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    supplierId
  }: UpdateFormulaIngredientSupplierRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { supplierId }
    )
  }
)

export const updateFormulaIngredientActive = createAsyncThunk(
  'formulas/ingredients/active/update',
  async ({
    companyId,
    formulaId,
    formulaIngredientId,
    isActive
  }: UpdateFormulaIngredientActiveRequest) => {
    return await FormulaApi.updateIngredient(
      companyId,
      formulaId,
      formulaIngredientId,
      { isActive }
    )
  },
  {
    getPendingMeta: (action) => {
      return {
        requestId: action.requestId,
        sequentialThunk: true,
        arguments: [
          action.arg.companyId,
          action.arg.formulaId,
          action.arg.formulaIngredientId
        ]
      }
    }
  }
)

export const addIngredientToFormula = createAsyncThunk(
  'formulas/ingredients/add',
  async ({
    companyId,
    formulaId,
    supplierId,
    ingredientId,
    amount = 0,
    wastePercentage = 0
  }: AddFormulaIngredientRequest) => {
    return await FormulaApi.addIngredient(companyId, formulaId, {
      supplierId,
      ingredientId,
      amount,
      wastePercentage
    })
  }
)

const breakdownSlice = createSlice({
  name: 'breakdownSlice',
  initialState,
  reducers: {
    resetState: (state) => {
      Object.assign(state, initialState)
    }
  },
  extraReducers(builder) {
    // Get ingredients of formula.
    builder.addCase(getFormulaIngredientBreakdown.pending, (state) => {
      state.loading = true
      state.error = false
    })
    builder.addCase(
      getFormulaIngredientBreakdown.fulfilled,
      (state, action) => {
        state.breakdown = action.payload
        state.loading = false
      }
    )
    builder.addCase(getFormulaIngredientBreakdown.rejected, (state) => {
      state.loading = false
      state.error = true
    })

    // Update ingredient amount.
    builder.addCase(updateIngredientAmount.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateIngredientAmount.fulfilled, (state) => {
      // Do nothing with the response since a refetch will be made.
      state.updating = false
    })
    builder.addCase(updateIngredientAmount.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update ingredient waste percentage.
    builder.addCase(updateIngredientWastePercentage.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateIngredientWastePercentage.fulfilled, (state) => {
      // Do nothing with the response since a refetch will be made.
      state.updating = false
    })
    builder.addCase(updateIngredientWastePercentage.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update formula supplier ingredient.
    builder.addCase(updateFormulaIngredientSupplier.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateFormulaIngredientSupplier.fulfilled, (state) => {
      // Do nothing with the response since a refetch will be made.
      state.updating = false
    })
    builder.addCase(updateFormulaIngredientSupplier.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Update ingredient active.
    builder.addCase(updateFormulaIngredientActive.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateFormulaIngredientActive.fulfilled, (state) => {
      // Do nothing with the response since a refetch will be made.
      state.updating = false
    })
    builder.addCase(updateFormulaIngredientActive.rejected, (state) => {
      state.updating = false
      state.error = true
    })

    // Add ingredient to formula.
    builder.addCase(addIngredientToFormula.pending, (state) => {
      state.adding = true
      state.error = false
    })
    builder.addCase(addIngredientToFormula.fulfilled, (state) => {
      // Do nothing with the response since a refetch will be made.
      state.adding = false
    })
    builder.addCase(addIngredientToFormula.rejected, (state) => {
      state.adding = false
      state.error = true
    })

    // Remove ingredient from formula.
    builder.addCase(removeFormulaIngredients.pending, (state) => {
      state.removing = true
      state.error = false
    })
    builder.addCase(removeFormulaIngredients.fulfilled, (state, action) => {
      if (state.breakdown) {
        const removedFormulaIngredientIds = action.payload
        const remaining = state.breakdown.result.items.filter(
          (ingredient) =>
            !removedFormulaIngredientIds.includes(
              ingredient.formulaIngredientId
            )
        )
        state.breakdown.result.items = remaining
        state.removing = false
      }
    })
    builder.addCase(removeFormulaIngredients.rejected, (state) => {
      state.removing = false
      state.error = true
    })

    // Update ingredient measurement.
    builder.addCase(updateFormulaIngredientMeasurement.pending, (state) => {
      state.updating = true
      state.error = false
    })
    builder.addCase(updateFormulaIngredientMeasurement.fulfilled, (state) => {
      state.updating = false
    })
    builder.addCase(updateFormulaIngredientMeasurement.rejected, (state) => {
      state.updating = false
      state.error = true
    })
  }
})

export const { resetState } = breakdownSlice.actions
export default breakdownSlice.reducer
