import { Box, ClickAwayListener, Fade, Popper } from '@mui/material'
import { BoxColumnBasic } from 'components/common'
import React from 'react'
import { DesignViewingState } from 'state/labelproof/proofer/ProoferPinsSlice'
import { ZIndex } from 'styles/zIndex'

const containerStyles = {
  width: '432px',
  maxHeight: '450px',
  background: '#FFFFFF',
  boxShadow:
    '0px 6px 10px 4px rgba(0, 0, 0, 0.15), 0px 2px 3px rgba(0, 0, 0, 0.3)',
  borderRadius: '8px'
}
interface PinDetailsPositionProps {
  activePinId: string | null
  currentDesignViewingState: DesignViewingState
}

export interface PinDetailsProps {
  actions: React.ReactNode
  thread: React.ReactNode
  onDismiss: (event: MouseEvent | TouchEvent) => void // TODO: Can be done better.
  position: PinDetailsPositionProps
  newPin?: boolean
}

export const PinDetails: React.FC<PinDetailsProps> = ({
  actions,
  thread,
  onDismiss,
  position,
  newPin = false
}) => {
  const [pinElement, setPinElement] = React.useState<HTMLElement | null>(null)
  const popperRef = React.useRef<any>(null)

  React.useEffect(() => {
    if (!position.activePinId) {
      setPinElement(null)
      return
    }

    const element = document.getElementById(`pin-${position.activePinId}`)
    setPinElement(element)
  }, [position.activePinId])

  React.useEffect(() => {
    if (!pinElement) {
      return
    }

    const observer = new MutationObserver(() => {
      if (popperRef.current?.update) {
        popperRef.current.update()
      }
    })

    observer.observe(pinElement, {
      attributeFilter: ['style', 'class']
    })

    return () => observer.disconnect()
  }, [pinElement])

  const shouldOpen = React.useMemo(() => !!pinElement, [pinElement])

  return (
    <>
      <Popper
        open={shouldOpen}
        anchorEl={pinElement}
        placement="right-start"
        disablePortal={false}
        popperRef={popperRef}
        modifiers={[
          {
            name: 'offset',
            options: {
              // Scale the offset based on zoom level
              offset: [
                25 * position.currentDesignViewingState.scale,
                5 * position.currentDesignViewingState.scale
              ]
            }
          },
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport',
              padding: 8
            }
          },
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false
            }
          },
          {
            name: 'eventListeners',
            enabled: true,
            options: {
              scroll: true,
              resize: true
            }
          }
        ]}
        sx={{ zIndex: ZIndex.popper }}
      >
        <Fade in={shouldOpen} timeout={200}>
          <Box>
            <ClickAwayListener onClickAway={onDismiss} mouseEvent="onMouseDown">
              <Box>
                <BoxColumnBasic sx={containerStyles}>
                  {newPin ? <Box sx={{ padding: '3px' }} /> : actions}
                  {thread}
                </BoxColumnBasic>
              </Box>
            </ClickAwayListener>
          </Box>
        </Fade>
      </Popper>
    </>
  )
}
