import { PATHS } from 'common/constants'
import { useSorterByField } from 'common/hooks'
import { SortOrder } from 'components/common'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { IngredientType } from 'models/Ingredient'
import { FormulatorContext } from 'pages/Formulator/components/FormulatorIngredients/FormulatorProvider'
import React from 'react'
import {
  getFormulaIngredientBreakdown,
  getFormulaIngredientMeasurements,
  updateFormulaIngredientActive,
  updateFormulaIngredientMeasurement,
  updateFormulaIngredientSupplier,
  updateIngredientAmount,
  updateIngredientWastePercentage
} from 'state/formulator/breakdown/BreakdownSlice'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { getIngredientSuppliers } from 'state/ingredients/IngredientsSlice'
import { mapNodeToRow } from './IngredientRowMapper'
import { IngredientsTable } from './IngredientsTable'
import { IngredientRowGroupProps } from './components/row/IngredientRowGroup'
import { IngredientSearchContainer } from './components/search/IngredientSearchContainer'

export interface IngredientsTableContainerProps {
  visibileWidth: string
}

export const IngredientsTableContainer: React.FC<
  IngredientsTableContainerProps
> = ({ visibileWidth }) => {
  const { viewOptions, updateIngredientIdSelection, selectedIngredientIdsMap } =
    React.useContext(FormulatorContext)

  const dispatch = useAppDispatch()
  const formulaId = useAppSelector((state) => state.formulator.formula.id)

  const formulaBreakdown = useAppSelector((state) => state.breakdown.breakdown)

  const formulaIsSupplement = useAppSelector(
    (state) => state.formulator.formula.isSupplement
  )
  const currentCompany = useAppSelector(
    (state) => state.companies.currentCompany
  )

  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const refreshIngredientBreakdown = React.useCallback(() => {
    if (formulaId) {
      void dispatch(
        getFormulaIngredientBreakdown({
          companyId: currentCompany.id,
          formulaId: formulaId
        })
      )
    }
  }, [formulaId, currentCompany.id])

  React.useEffect(() => {
    refreshIngredientBreakdown()
  }, [formulaId, currentCompany.id])

  const handleRowCheckedChange = React.useCallback(
    (checked: boolean, formulaIngredientId: string) => {
      updateIngredientIdSelection(formulaIngredientId, checked)
    },
    [selectedIngredientIdsMap]
  )

  const handleOnDetailNavigationClick = React.useCallback(
    (id: string, type: IngredientType) => {
      const windowPath =
        type === IngredientType.FORMULA
          ? `${PATHS.FORMULAS}/${id}`
          : `${PATHS.INGREDIENTS}?ingredientId=${id}`
      window.open(windowPath, '_blank', 'noreferrer')
    },
    []
  )

  const handleUpdateWastePercentage = React.useCallback(
    (formulaIngredientId: string, wastePercentage: number) => {
      formulatorAnalytics.ingredients.updatedWastePercentage(formulaId)
      void dispatch(
        updateIngredientWastePercentage({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          wastePercentage: wastePercentage
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleGetIngredientSuppliers = React.useCallback(
    (ingredientId: string) => {
      return dispatch(
        getIngredientSuppliers({
          companyId: currentCompany.id,
          ingredientId: ingredientId
        })
      )
        .unwrap()
        .then((res) =>
          res.map((ssi) => {
            return {
              id: ssi.supplier.id,
              name: ssi.supplier.name
            }
          })
        )
    },
    [currentCompany.id]
  )

  const handleSelectSupplier = React.useCallback(
    (formulaIngredientId: string, supplierId: string) => {
      formulatorAnalytics.ingredients.modifiedIngredientSupplier(formulaId)
      void dispatch(
        updateFormulaIngredientSupplier({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          supplierId: supplierId
        })
      )
    },
    [formulaId, currentCompany.id]
  )

  const handleIngredientAmountChange = React.useCallback(
    (formulaIngredientId: string, amount: number, measurementId: string) => {
      formulatorAnalytics.ingredients.modifiedIngredientWeight(formulaId)
      void dispatch(
        updateIngredientAmount({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          amountInMeasurement: amount || 0,
          measurementId: measurementId
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleUpdateIngredientMeasurement = React.useCallback(
    (formulaIngredientId: string, measurementId: string) => {
      formulatorAnalytics.ingredients.modifiedIngredientMeasurement(formulaId)
      void dispatch(
        updateFormulaIngredientMeasurement({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          measurementId: measurementId
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleUpdateActiveIngredient = React.useCallback(
    (formulaIngredientId: string, active: boolean) => {
      void dispatch(
        updateFormulaIngredientActive({
          companyId: currentCompany.id,
          formulaId: formulaId,
          formulaIngredientId: formulaIngredientId,
          isActive: active
        })
      ).then(() => {
        refreshIngredientBreakdown()
      })
    },
    [currentCompany.id, formulaId]
  )

  const handleGetAvailableMeasurements = React.useCallback(
    (ingredientId: string) => {
      return dispatch(
        getFormulaIngredientMeasurements({
          companyId: currentCompany.id,
          ingredientId: ingredientId
        })
      )
        .unwrap()
        .then((response) => {
          return response.map((r) => {
            return {
              id: r.id,
              unit: r.unit
            }
          })
        })
    },
    [currentCompany.id]
  )

  const visibility = React.useMemo(() => {
    return {
      showSupplier: viewOptions[0].value,
      showWastePercentage: viewOptions[1].value,
      showCost: viewOptions[2].value,
      showQuantityPercentage: viewOptions[3].value,
      showYield: viewOptions[4].value,
      showActive: formulaIsSupplement
    }
  }, [viewOptions, formulaIsSupplement])

  const rows = React.useMemo(() => {
    const output: IngredientRowGroupProps[] = []
    if (formulaBreakdown === undefined) {
      return output
    }

    formulaBreakdown.result.items.forEach((node) => {
      output.push(
        mapNodeToRow({
          node: node,
          showPercentages: visibility.showQuantityPercentage,
          navigationClick: handleOnDetailNavigationClick,
          getIngredientSuppliers: handleGetIngredientSuppliers,
          onSelectSupplier: handleSelectSupplier,
          toggleActive: handleUpdateActiveIngredient,
          onWastePercentageChange: handleUpdateWastePercentage,
          onAmountChange: handleIngredientAmountChange,
          onMeasurementChange: handleUpdateIngredientMeasurement,
          getAvailableMeasurements: handleGetAvailableMeasurements,
          checked:
            selectedIngredientIdsMap.get(node.formulaIngredientId) || false,
          onRowCheckedChange: handleRowCheckedChange,
          visibility: {
            supplier: visibility.showSupplier,
            cost: visibility.showCost,
            wastePercentage: visibility.showWastePercentage,
            yield: visibility.showYield,
            active: visibility.showActive
          },
          disabled: false,
          level: 0
        })
      )
    })
    return output
  }, [
    formulaBreakdown,
    visibility,
    selectedIngredientIdsMap,
    handleOnDetailNavigationClick,
    handleGetIngredientSuppliers,
    handleSelectSupplier,
    handleUpdateWastePercentage,
    handleIngredientAmountChange,
    handleUpdateIngredientMeasurement,
    handleGetAvailableMeasurements,
    handleRowCheckedChange
  ])

  const {
    items: sortedRows,
    sortingFieldId,
    sortingOrder,
    updateSortingFieldPath,
    sortingFieldIdGen
  } = useSorterByField({
    items: rows,
    idProperty: 'formulaIngredientId',
    sortingOrderCycle: [SortOrder.DESC, SortOrder.ASC, undefined]
  })

  return (
    <IngredientsTable
      {...visibility}
      ingredientRows={sortedRows}
      ingredientSearch={<IngredientSearchContainer />}
      loading={formulaBreakdown === undefined}
      visibleWidth={visibileWidth}
      sortingSettings={{
        sortingFieldId,
        sortingOrder,
        updateSortingFieldPath,
        sortingFieldIdGen
      }}
    />
  )
}
