import { Box, Input, InputProps, SxProps, Theme } from '@mui/material'
import React from 'react'
import { LabelTypography } from '../common'

export interface GrowProps {
  extraChars?: number
  mode?: 'strict' | 'minimum'
}

export interface InputFieldProps extends Omit<InputProps, 'onChange' | 'type'> {
  label?: string
  labelIcon?: React.ReactNode
  onChange?: (value: string) => void
  value?: string
  scale?: 'small' | 'medium' | 'large'
  boxSx?: SxProps
  grow?: boolean
  growProps?: GrowProps
}

export const InputField: React.FC<InputFieldProps> = ({
  label = '',
  onChange,
  value,
  scale = 'medium',
  labelIcon,
  boxSx,
  ...rest
}) => {
  const { sx, ...restProps } = rest

  return (
    <Box sx={boxSx}>
      {label && (
        <LabelTypography variant="subtitle2">
          {label} {rest.required && '*'} {labelIcon}
        </LabelTypography>
      )}
      <Input
        fullWidth
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        {...restProps}
        sx={{
          boxSizing: 'border-box' as const,
          background: '#FDFDFD' as const,
          border: '1px solid rgba(161, 171, 183, 0.3)' as const,
          boxShadow: 'inset 0px 2px 2px rgba(0, 0, 0, 0.05)' as const,
          borderRadius: '8px' as const,
          position: 'relative' as const,
          display: 'flex' as const,
          overflow: 'hidden' as const,
          // Customize input field styling based on scale prop.
          '& input': {
            width: '100%',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize:
              scale === 'large' ? '20px' : scale === 'small' ? '14px' : '16px',
            lineHeight: '20px',
            color: '#16191C',
            padding: '0px'
          },
          ...sx
        }}
      />
    </Box>
  )
}
