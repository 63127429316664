import {
  ArrowOutward,
  ChevronRightRounded,
  ExpandMoreRounded
} from '@mui/icons-material'
import { InputAdornment, Tooltip, Typography } from '@mui/material'
import { Button } from 'components/Button/Button'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { UnstyledInput } from '../../../../../../../FormulatorStyles'

export interface IngredientNameProps {
  name: string
  friendlyId?: string
  detailsNavigation: {
    hoverMessage: string
    onClick: () => void
  }
  verified?: boolean
  expanded?: boolean
  expandable?: boolean
  onExpandClick?: () => void
  disabled?: boolean
}

export const IngredientName: React.FC<IngredientNameProps> = ({
  name,
  friendlyId,
  detailsNavigation,
  verified = true,
  expandable = false,
  expanded = false,
  onExpandClick,
  disabled = false
}) => {
  const [hovered, setHovered] = React.useState(false)

  return (
    <UnstyledInput
      fullWidth
      inputProps={{
        style: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontFamily: 'Inter',
          fontSize: 16,
          fontWeight: disabled ? 400 : 600
        }
      }}
      disabled={disabled}
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onFocus={(e) => e.target.blur()}
      value={name}
      startAdornment={
        <InputAdornment position="start">
          {!verified && (
            <InfoBubble
              type="warning"
              text="This ingredient is sourced from the USDA database and may have incomplete data or allergen information."
              width="300px"
            />
          )}
          {expandable && (
            <Button
              variant="square"
              color="secondary"
              onClick={onExpandClick}
              size="small"
            >
              {expanded && <ExpandMoreRounded style={{ fontSize: '16' }} />}
              {!expanded && <ChevronRightRounded style={{ fontSize: '16' }} />}
            </Button>
          )}
        </InputAdornment>
      }
      endAdornment={
        <>
          {!hovered && friendlyId && (
            <InputAdornment position="end">
              <Typography variant="subtitle2" marginRight="4px">
                #{friendlyId}
              </Typography>
            </InputAdornment>
          )}

          {hovered && (
            <Button
              variant="square"
              color="secondary"
              onClick={detailsNavigation.onClick}
              size="medium"
            >
              <Tooltip title={detailsNavigation.hoverMessage}>
                <ArrowOutward style={{ fontSize: '16' }} />
              </Tooltip>
            </Button>
          )}
        </>
      }
    />
  )
}
