import { BoxRowBasic } from 'components/common'
import { ComponentPreview } from 'components/ComponentPreview/ComponentPreview'
import {
  FactsLabel,
  FactsLabelProps
} from 'pages/Formulator/components/FormulatorPanel/Label/components/FactsLabel'
import React, { useRef } from 'react'
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch'

interface EditorPreviewProps {
  loading: boolean
  factsLabel: FactsLabelProps
}

export const EditorPreview: React.FC<EditorPreviewProps> = ({
  loading,
  factsLabel: nfpLabel
}) => {
  const transformWrapperRef = useRef<ReactZoomPanPinchRef>(null)

  React.useEffect(() => {
    if (transformWrapperRef.current && nfpLabel.facts?.type) {
      transformWrapperRef.current.resetTransform()
    }
  }, [nfpLabel.facts?.type, transformWrapperRef.current])

  return (
    <BoxRowBasic
      sx={{ overflow: 'hidden', padding: '0 16px', position: 'relative' }}
    >
      <ComponentPreview loading={loading}>
        <FactsLabel {...nfpLabel} />
      </ComponentPreview>
    </BoxRowBasic>
  )
}
