import React from 'react'
import { EditorSection } from '../../../common/EditorSection'
import { OverrideTextField } from '../../../common/OverrideTextField'

interface LabelOverridesProps {
  servingSize: {
    value: string
    originalValue?: string
    onChange: (value: string) => void
  }
}

export const LabelOverrides: React.FC<LabelOverridesProps> = ({
  servingSize
}) => {
  return (
    <EditorSection title="Label">
      <OverrideTextField
        label={`Serving Size`}
        originalValue={servingSize.originalValue}
        placeholder={'e.g. 200mL'}
        value={servingSize.value}
        onChange={(value) => servingSize.onChange(value)}
      />
    </EditorSection>
  )
}
