import { BoxColumnBasic, LabelTypography } from 'components/common'
import React from 'react'
import { EditorSwitch } from '../../../common/EditorSwitch'
import { InfoBubble } from 'components/InfoBubble/InfoBubble'
import { MarkdownRenderer } from 'components/MarkdownRenderer/MarkdownRenderer'
import { infoMessages } from 'common/constants'
import { EditorSection } from '../../../common/EditorSection'
import { validateRange } from 'components/InputFields/validation'
import { OverrideNumberField } from 'components/InputFields/OverrideNumberField'
import { InvalidPDIngredients, InvalidPDIngredientsProps } from './components/InvalidPDIngredients/InvalidPDIngredients'
import { Banner } from 'components/Banner/Banner'


export interface NutrientsSettingsProps {
  showProteinPercentage: {
    label: string
    value: boolean
    hidden: boolean
    disabled: boolean
    onChange: (value: boolean) => void
  }
  pdcaas: {
    applyPdcaas: {
      label: string
      value: boolean
      hidden: boolean
      disabled: boolean
      onChange: (value: boolean) => void
    }
    pdcaasScore: {
      label: string
      value: number | null
      suggestedValue: number | null
      hidden: boolean
      disabled: boolean
      onChange: (value: number | null) => void
    }
    invalidPDIngredients: InvalidPDIngredientsProps
  }
  dvBasedOnRounded: {
    label: string
    value: boolean
    hidden: boolean
    disabled: boolean
    onChange: (value: boolean) => void
  }
}

export const NutrientsSettings: React.FC<NutrientsSettingsProps> = ({
  showProteinPercentage,
  pdcaas,
  dvBasedOnRounded
}) => {
  const showInvalidPDIngredientWarning = React.useMemo(() =>
    pdcaas.invalidPDIngredients.ingredients.length > 0
    && pdcaas.pdcaasScore.value === null
    && pdcaas.pdcaasScore.suggestedValue === null
    , [
      pdcaas.invalidPDIngredients.ingredients,
      pdcaas.pdcaasScore.value,
      pdcaas.pdcaasScore.suggestedValue
    ])
  return (
    <EditorSection title="Nutrients">
      {/* dv based on rounded settings */}
      {!dvBasedOnRounded.hidden && (
        <EditorSwitch
        label={dvBasedOnRounded.label}
        checked={dvBasedOnRounded.value}
        onChange={dvBasedOnRounded.onChange}
        disabled={dvBasedOnRounded.disabled}
        labelIcon={
          <InfoBubble
          text={
            <MarkdownRenderer
            markdownText={infoMessages.formula.label.DV_BASED_ON_ROUNDED}
            />
          }
          />
        }
        />
      )}

      {/* show protein percentage settings */}
      {!showProteinPercentage.hidden && (
        <EditorSwitch
          label={showProteinPercentage.label}
          checked={showProteinPercentage.value}
          onChange={showProteinPercentage.onChange}
          disabled={showProteinPercentage.disabled}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={
                    infoMessages.formula.label.SHOW_PROTEIN_PERCENTAGE
                  }
                />
              }
            />
          }
        />
      )}

      {/* pdcaas settings */}
      {/* apply pdcaas */}
      {!pdcaas.applyPdcaas.hidden && (
        <EditorSwitch
          label={pdcaas.applyPdcaas.label}
          checked={pdcaas.applyPdcaas.value}
          onChange={pdcaas.applyPdcaas.onChange}
          disabled={pdcaas.applyPdcaas.disabled}
          labelIcon={
            <InfoBubble
              text={
                <MarkdownRenderer
                  markdownText={infoMessages.formula.label.PDCAAS}
                />
              }
            />
          }
        />
      )}
      {/* pdcaas score */}
      {!pdcaas.pdcaasScore.hidden && pdcaas.applyPdcaas.value && (
        <BoxColumnBasic>
          <LabelTypography variant="subtitle2">
            {pdcaas.pdcaasScore.label}
          </LabelTypography>
          {
            showInvalidPDIngredientWarning &&
            <Banner type="warning" sx={{ marginBottom: '6px' }}>
              <InvalidPDIngredients {...pdcaas.invalidPDIngredients} />
            </Banner>
          }
          <OverrideNumberField
            value={pdcaas.pdcaasScore.value}
            originalValue={pdcaas.pdcaasScore.suggestedValue}
            allowEmptyValue={true}
            onChange={pdcaas.pdcaasScore.onChange}
            debounceTime={500}
            disabled={pdcaas.pdcaasScore.disabled}
            maxDecimals={6}
            additionalValidators={[(value) => validateRange(value, 0, 1)]}
            validation=">=0"
          />
        </BoxColumnBasic>
      )}
    </EditorSection>
  )
}
