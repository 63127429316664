import { Typography, useTheme } from '@mui/material'
import { convertToFixedFloat } from 'common/utils'
import { BoxRow } from 'components/common'
import { toCh } from 'components/InputFields/helper'
import { UnstyledInput } from 'pages/Formulator/FormulatorStyles'
import React from 'react'

export interface IngredientYieldQuantityProps {
  amount: number
  amountInGrams: number
  percentage: number
  showPercentages?: boolean
  unit: string
}

export const IngredientYieldQuantity: React.FC<
  IngredientYieldQuantityProps
> = ({ amount, percentage, showPercentages = true, unit }) => {
  const theme = useTheme()
  return (
    <BoxRow>
      {showPercentages && (
        <UnstyledInput
          disabled={true}
          value={convertToFixedFloat(percentage * 100, 2, true)}
          sx={{
            borderRadius: '8px 0px 0px 8px',
            width: '9ch',
            minWidth: '9ch'
          }}
          endAdornment={
            <Typography
              marginLeft={'0.25em'}
              color={theme.palette.tertiary.main}
            >
              %
            </Typography>
          }
          inputProps={{ style: { textAlign: 'right' } }}
        />
      )}
      <UnstyledInput
        value={amount}
        sx={{
          borderLeft: showPercentages ? '2px solid rgba(0, 0, 0, 0.06)' : '0px',
          borderRadius: showPercentages ? '0px 8px 8px 0px' : '8px 8px 8px 8px',
          minWidth: toCh(amount, 1, unit.length + 2),
          width: '100%'
        }}
        inputProps={{ style: { textAlign: 'right' } }}
        endAdornment={
          <Typography
            marginLeft={'0.25em'}
            whiteSpace={'nowrap'}
            color={theme.palette.tertiary.main}
          >
            {unit}
          </Typography>
        }
        fullWidth={false}
        disabled={true}
      />
    </BoxRow>
  )
}
