import html2canvas from '@cedar-kr/html2canvas'
import { Option } from 'components/common'
import { LoadingStateContext } from 'components/LoadingUI/LoadingUIContext'
import { ModalContext } from 'components/Modal/ModalContext'
import { SnackbarContext } from 'components/Snackbar/SnackbarContext'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { FormulaNutritionFactLabelType } from 'models/FormulaLabel'
import React, { useState } from 'react'
import { useAppSelector } from 'state/hooks'
import { DownloadModalContent } from './DownloadModal'

export interface ImageDownloadModalContainerProps {
  format: 'png' | 'svg'
}

export const ImageDownloadModalContainer: React.FC<
  ImageDownloadModalContainerProps
> = ({ format }) => {
  const formulaName = useAppSelector((state) => state.formulator.formula.name)
  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const formulaRegulationId = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.regulationId
  )
  const formulaNutritionFactsType = useAppSelector(
    (state) => state.nutritionFactLabels.nutritionFactLabel?.type
  )
  const { showError } = React.useContext(SnackbarContext)
  const { closeModal } = React.useContext(ModalContext)
  const { showLoading, hideLoading } = React.useContext(LoadingStateContext)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  enum OptionId {
    NUTRITION_FACTS = 'nutrition-facts',
    INGREDIENT_STATEMENT = 'ingredient-statement',
    ALLERGEN_STATEMENT = 'allergen-statement',
    LABEL_DESCRIPTION = 'description',
    TRANSPARENT_BACKGROUND = 'transparent-background',
    STATEMENTS = 'statements',
    GAP = 'gap'
  }

  const [options, setOptions] = useState([
    {
      id: OptionId.NUTRITION_FACTS,
      label: 'Nutrition Facts Panel',
      value: true,
      disabled: format === 'svg'
    },
    {
      id: OptionId.INGREDIENT_STATEMENT,
      label: 'Ingredient Statement',
      value: format !== 'svg',
      disabled: format === 'svg'
    },
    {
      id: OptionId.ALLERGEN_STATEMENT,
      label: 'Allergen Statement',
      value: format !== 'svg',
      disabled: format === 'svg'
    },
    {
      id: OptionId.LABEL_DESCRIPTION,
      label: 'Other Information',
      value: format !== 'svg',
      disabled: format === 'svg'
    },
    {
      id: OptionId.TRANSPARENT_BACKGROUND,
      label: 'Transparent Background',
      value: format === 'svg',
      disabled: format === 'svg'
    }
  ])

  const handleChange = (updatedOption: Option<boolean>) => {
    setOptions((prevOptions) => {
      const updatedOptions = prevOptions.map((option) => {
        if (option.id === updatedOption.id) {
          return {
            ...option,
            value: updatedOption.value
          }
        }
        return option
      })
      return updatedOptions
    })
  }

  const handleSVGDownload = (options: Option<boolean>[]) => {
    showLoading({ blockUI: true, message: 'Downloading image...' })

    const element = document.querySelector('#nutrition-fact-label')
    const ignoredElements = options.filter((o) => !o.value).map((o) => o.id)

    formulatorAnalytics.label.downloadedLabelImage(
      formulaId,
      formulaNutritionFactsType || FormulaNutritionFactLabelType.VERTICAL,
      format,
      formulaRegulationId || ''
    )

    if (element !== null && element instanceof HTMLElement) {
      const svgDocument = elementToSVG(element)
      // Remove any element that is not selected for download.
      ignoredElements.forEach((id) => {
        const el = svgDocument.querySelector(`[id$='${id}']`)
        if (el) {
          el.remove()
        }
      })

      // Throws a typehint error but doesn't cause issues for now.
      void inlineResources(svgDocument.documentElement)
        .then(() => {
          const svgString = new XMLSerializer().serializeToString(svgDocument)
          const blob = new Blob([svgString], { type: 'image/svg+xml' })
          const url = URL.createObjectURL(blob)

          const a = document.createElement('a')
          a.download = `${formulaName} - Nutrition Fact Label.svg`
          a.href = url
          a.click()
          a.remove()

          closeModal()
        })
        .catch(() => {
          showError('Could not download nutrition facts label')
        })
        .finally(() => {
          hideLoading()
        })
    } else {
      showError('Could not download nutrition fact label')
      hideLoading()
    }
  }

  const handlePNGDownload = (options: Option<boolean>[]) => {
    showLoading({ blockUI: true, message: 'Downloading image...' })
    const element = document.querySelector('#nutrition-fact-label')
    const ignoredElements = options.filter((o) => !o.value).map((o) => o.id)

    // If all 3 statements are hidden, hide the space between them.
    if (
      ignoredElements.includes(OptionId.INGREDIENT_STATEMENT) &&
      ignoredElements.includes(OptionId.ALLERGEN_STATEMENT) &&
      ignoredElements.includes(OptionId.LABEL_DESCRIPTION)
    ) {
      ignoredElements.push(OptionId.STATEMENTS)
      ignoredElements.push(OptionId.GAP)
    } else if (ignoredElements.includes(OptionId.NUTRITION_FACTS)) {
      ignoredElements.push(OptionId.GAP)
    }

    formulatorAnalytics.label.downloadedLabelImage(
      formulaId,
      formulaNutritionFactsType || FormulaNutritionFactLabelType.VERTICAL,
      format,
      formulaRegulationId || ''
    )

    if (element !== null && element instanceof HTMLElement) {
      void html2canvas(element, {
        scale: 10,
        width: element.getBoundingClientRect().width, // This avoids having rounded pixels and blank spaces.
        backgroundColor: options.find(
          (o) => o.id === OptionId.TRANSPARENT_BACKGROUND
        )?.value
          ? 'transparent'
          : 'white',
        ignoreElements: (el) => {
          return ignoredElements.some((id) => {
            if (id === OptionId.ALLERGEN_STATEMENT) {
              return el.id.includes(OptionId.ALLERGEN_STATEMENT)
            }
            if (id === OptionId.INGREDIENT_STATEMENT) {
              return el.id.includes(OptionId.INGREDIENT_STATEMENT)
            }
            return el.id === id
          })
        }
      })
        .then((canvas) => {
          const a = document.createElement('a')
          a.download = `${formulaName} - Nutrition Fact Label.png`
          a.href = canvas.toDataURL('image/png')
          a.click()
          a.remove()
          closeModal()
        })
        .catch(() => {
          showError('Could not download nutrition facts label')
        })
        .finally(() => {
          hideLoading()
        })
    } else {
      showError('Could not download nutrition facts label')
      hideLoading()
    }
  }

  const handleDownload = (options: Option<boolean>[]) => {
    if (format === 'svg') {
      handleSVGDownload(options)
    } else {
      handlePNGDownload(options)
    }
  }

  const downloadDisabled = React.useMemo(() => {
    // Disable download if all the options are off excluding the transparent background option.
    return options
      .filter((o) => o.id !== OptionId.TRANSPARENT_BACKGROUND)
      .every((o) => !o.value)
  }, [options])

  React.useEffect(() => {
    // In the event of an svg, download immediately.
    // Currently we do not support statements in the svg download due to the limitation of wrapping text in svg.
    if (format === 'svg') {
      handleDownload(options)
    }
  }, [])

  return (
    <DownloadModalContent
      title="Image Download"
      options={options}
      onChange={handleChange}
      onDownloadClick={handleDownload}
      onCancelClick={closeModal}
      downloadDisabled={downloadDisabled}
    />
  )
}
