import { Input } from '@mui/material'
import React from 'react'

export interface YieldAdjustmentProps {
  name: 'moisture' | 'fat'
  type: 'target' | 'loss'
  percentage: number
  amount: number
}

export const YieldAdjustment: React.FC<YieldAdjustmentProps> = ({
  name,
  type,
  percentage,
  amount
}) => {
  const value = React.useMemo(() => {
    return `${name === 'moisture' ? 'Moisture' : 'Fat'} ${
      type === 'target' ? 'Target' : 'Loss'
    } ${percentage * 100}%`
  }, [type, percentage, amount])

  return (
    <Input
      fullWidth
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        fontFamily: 'Inter',
        fontSize: 16,
        '& .MuiInputBase-input': {
          padding: '0px'
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: 'rgba(0, 0, 0, 0.38)'
        }
      }}
      value={value}
      disabled={true}
    />
  )
}
