import { BoxColumnBasic, BoxRow } from 'components/common'
import React from 'react'
import { Owner, OwnerProps } from './components/Owner/Owner'
import { Reviewers, ReviewersProps } from './components/Reviewers/Reviewers'
import { Task, TaskProps } from './components/Task/Task'
import { Typography } from '@mui/material'
import { Date, DateProps } from 'components/Date/Date'

export type DueDateProps = Omit<DateProps, 'due' | 'completed'>
export interface ReviewItemProps {
  taskProps: TaskProps
  ownerProps?: OwnerProps
  reviewersProps: ReviewersProps
  dueDateProps: DueDateProps
}

export const ReviewItem: React.FC<ReviewItemProps> = ({
  taskProps,
  ownerProps,
  reviewersProps,
  dueDateProps
}) => {
  return (
    <BoxColumnBasic>
      <Task {...taskProps} />
      <BoxRow sx={{ gap: '10px', paddingLeft: '30px' }}>
        <BoxRow>
          {ownerProps && <Owner {...ownerProps} />}
          <Reviewers {...reviewersProps} />
        </BoxRow>
        {(!taskProps.completed || dueDateProps.date) && (
          <>
            <Typography variant="subtitle2">·</Typography>
            <Date
              {...dueDateProps}
              due={true}
              completed={taskProps.completed}
            />
          </>
        )}
      </BoxRow>
    </BoxColumnBasic>
  )
}
