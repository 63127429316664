import { Box, InputAdornment, Typography } from '@mui/material'
import { pluralize } from 'common/utils'
import { NumberField } from 'components/InputFields/NumberField'
import { SubmitNumberField } from 'components/InputFields/SubmitNumberField'
import { BoxRow, BoxRowBasic } from 'components/common'

export interface TotalsBarProps {
  totalIngredients: number
  servingWeight: {
    value: number
    onChange: (value: number) => void
  }
  totalWeight: {
    value: number
    onChange: (value: number) => void
  }
  cost: {
    perServing: number | null
    perTotal: number | null
    visibile: boolean
  }
}

export const TotalsBar: React.FC<TotalsBarProps> = ({
  totalIngredients,
  servingWeight,
  totalWeight,
  cost
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: 'white'
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Typography color="secondary">
          {totalIngredients} {pluralize(totalIngredients, 'ingredient')}
        </Typography>
      </Box>
      <BoxRow
        gap="10px"
        sx={{ flex: 7, justifyContent: 'flex-end', flexWrap: 'wrap' }}
      >
        {cost.visibile && (
          <BoxRowBasic gap="10px">
            <BoxRowBasic gap="5px">
              <Typography color="secondary" style={{ whiteSpace: 'nowrap' }}>
                Total Cost
              </Typography>
              <Typography>${cost.perTotal}</Typography>
            </BoxRowBasic>
            <BoxRowBasic gap="5px">
              <Typography color="secondary" style={{ whiteSpace: 'nowrap' }}>
                Serving Cost
              </Typography>
              <Typography>${cost.perServing}</Typography>
            </BoxRowBasic>
          </BoxRowBasic>
        )}
        <BoxRowBasic gap="10px">
          <NumberField
            onChange={servingWeight.onChange}
            value={servingWeight.value}
            maxDecimals={6}
            debounceTime={500}
            validation=">=0"
            startAdornment={
              <InputAdornment position="start">Serving Size</InputAdornment>
            }
            endAdornment={<InputAdornment position="end">g</InputAdornment>}
            scale="small"
            grow
            growProps={{
              extraChars: 'Serving Size'.length,
              mode: 'strict'
            }}
            sx={{
              padding: '6px 10px'
            }}
            inputProps={{
              style: { textAlign: 'right' }
            }}
          />

          <SubmitNumberField
            onSubmit={totalWeight.onChange}
            value={totalWeight.value}
            maxDecimals={6}
            validation=">=0"
            startAdornment={
              <InputAdornment position="start">Total</InputAdornment>
            }
            submitText="Update"
            endAdornment={<InputAdornment position="end">g</InputAdornment>}
            scale="small"
            grow
            growProps={{
              extraChars: 'Total'.length + 2,
              mode: 'strict'
            }}
            sx={{
              padding: '6px 10px'
            }}
            inputProps={{
              style: { textAlign: 'right' }
            }}
          />
        </BoxRowBasic>
      </BoxRow>
    </Box>
  )
}
