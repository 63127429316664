import React from 'react'
import { TargetsSettings } from './TargetsSettings'
import { useAppDispatch, useAppSelector } from 'state/hooks'
import { Option, OptionGroup } from 'components/common'
import {
  createFormulaTarget,
  deleteFormulaTarget
} from 'state/formulator/formulaTargets/FormulaTargetsSlice'
import {
  addFormulaRegulationIsMealOrMainDish,
  addFormulaRegulationRacc,
  addFormulaRegulationReferenceFormula
} from 'state/formulator/FormulatorSlice'
import { searchIngredients } from 'state/ingredients/search/IngredientSearchSlice'
import { SearchResult } from 'pages/Formulator/components/FormulatorIngredients/components/Ingredients/components/search/IngredientSearch'
import { ModalContext } from 'components/Modal/ModalContext'
import { AdvancedIngredientSearchContainer } from 'pages/Formulator/components/FormulatorIngredients/components/Ingredients/components/search/advanced/AdvancedIngredientSearchContainer'
import { AnalyticsContext } from 'core/Analytics/AnalyticsContext'
import { FormulaRegulationTargetParametersProps } from './components/TargetParameters'

export const TargetsSettingsContainer: React.FC = () => {
  const dispatch = useAppDispatch()

  const formulaId = useAppSelector((state) => state.formulator.formula.id)
  const currentCompanyId = useAppSelector(
    (state) => state.companies.currentCompany.id
  )
  const selectedTargets = useAppSelector(
    (state) => state.formulaTargets.formulaTargets
  )
  const availableTargets = useAppSelector(
    (state) => state.targets.targets.items
  )

  const suggestions = useAppSelector(
    (state) => state.ingredientSearch.ingredientSuggestions
  )
  const searchLoading = useAppSelector(
    (state) => state.ingredientSearch.loading
  )

  const formulaRegulations = useAppSelector(
    (state) => state.formulator.formula.formulaRegulations
  )

  const regulations = useAppSelector(
    (state) => state.nutritionFactLabels.availableRegulations
  )

  const { showModal, closeModal } = React.useContext(ModalContext)
  const { formulatorAnalytics } = React.useContext(AnalyticsContext)

  const handleSearchChange = React.useCallback(
    (newSearchTerm: string, regulationId: string) => {
      void dispatch(
        searchIngredients({
          companyId: currentCompanyId,
          query: newSearchTerm,
          withPublicIngredients: true,
          requireAllTerms: true,
          size: 20
        })
      )

      const referenceFood = formulaRegulations.find(
        (regulation) => regulation.regulationId === regulationId
      )?.referenceFormula

      if (newSearchTerm !== referenceFood?.name && newSearchTerm === '') {
        formulatorAnalytics.targets.updatedReferenceFood(formulaId)
        // Clear the reference food.
        void dispatch(
          addFormulaRegulationReferenceFormula({
            companyId: currentCompanyId,
            formulaId: formulaId,
            regulationId: regulationId,
            referenceFormulaId: null
          })
        )
      }
    },
    [currentCompanyId]
  )

  const handleRACCChange = React.useCallback(
    (newRACC: number, regulationId: string) => {
      const oldRACC = formulaRegulations.find(
        (regulation) => regulation.regulationId === regulationId
      )?.racc
      if (newRACC !== oldRACC) {
        formulatorAnalytics.targets.updatedRACC(formulaId)
        void dispatch(
          addFormulaRegulationRacc({
            companyId: currentCompanyId,
            formulaId: formulaId,
            regulationId: regulationId,
            racc: newRACC
          })
        )
      }
    },
    [currentCompanyId, formulaId, formulaRegulations]
  )

  const handleMainAndMealDishChange = React.useCallback(
    (value: boolean, regulationId: string) => {
      formulatorAnalytics.targets.updatedMealOrMainDish(formulaId)
      void dispatch(
        addFormulaRegulationIsMealOrMainDish({
          companyId: currentCompanyId,
          formulaId: formulaId,
          regulationId: regulationId,
          isMealOrMainDish: value
        })
      )
    },
    [currentCompanyId, formulaId]
  )

  const handleReferenceFoodClick = React.useCallback(
    (ingredientId: string, regulationId: string) => {
      formulatorAnalytics.targets.updatedReferenceFood(formulaId)
      void dispatch(
        addFormulaRegulationReferenceFormula({
          companyId: currentCompanyId,
          formulaId: formulaId,
          regulationId: regulationId,
          referenceFormulaId: ingredientId
        })
      )
    },
    [currentCompanyId, formulaId]
  )

  const handleTargetSelectionChange = React.useCallback(
    (selected: Option<boolean>) => {
      if (selected.value) {
        formulatorAnalytics.targets.selectedTarget(formulaId)
        // Add target to formula.
        void dispatch(
          createFormulaTarget({
            companyId: currentCompanyId,
            formulaId: formulaId,
            targetId: selected.id
          })
        )
      } else {
        formulatorAnalytics.targets.deselectedTarget(formulaId)
        // Remove target from formula.
        void dispatch(
          deleteFormulaTarget({
            companyId: currentCompanyId,
            formulaId: formulaId,
            targetId: selected.id
          })
        )
      }
    },
    [currentCompanyId, formulaId]
  )

  const targetOptionGroups = React.useMemo(() => {
    // Turn selectedTargets into a set of ids for faster access.
    const selectedTargetIds = new Set(
      selectedTargets.map((target) => target.targetDefinition.id)
    )

    // Group the available targets by category.
    const targetGroupOptionsMap = new Map<string, OptionGroup<boolean>>()
    availableTargets.forEach((target) => {
      const categoryName =
        target.targetDefinitionCategory?.name ?? 'Custom Targets'
      if (targetGroupOptionsMap.has(categoryName)) {
        const category = targetGroupOptionsMap.get(categoryName)
        category?.options.push({
          value: selectedTargetIds.has(target.id),
          label: target.name,
          id: target.id
        })
      } else {
        targetGroupOptionsMap.set(categoryName, {
          label: categoryName,
          options: [
            {
              value: selectedTargetIds.has(target.id),
              label: target.name,
              id: target.id
            }
          ]
        })
      }
    })

    // Sort the options by label.
    targetGroupOptionsMap.forEach((group) => {
      group.options.sort((a, b) =>
        (a.label as string).localeCompare(b.label as string)
      )
    })

    return Array.from(targetGroupOptionsMap.values())
  }, [availableTargets, selectedTargets])

  const searchResults: SearchResult[] = React.useMemo(() => {
    return suggestions.items.map((sug) => {
      return {
        ingredient: {
          id: sug.id,
          friendlyId: sug.friendlyId,
          name: sug.name,
          highlightedName: sug.highlightedName
        },
        suppliers: sug.suppliers
      }
    })
  }, [suggestions])

  const handleAdvancedSearchShowClick = React.useCallback(
    (searchTerm: string, regulationId: string) => {
      showModal({
        content: (
          <AdvancedIngredientSearchContainer
            initialSearchTerm={searchTerm}
            onIngredientClick={(ingredientId: string) => {
              handleReferenceFoodClick(ingredientId, regulationId)
              closeModal()
            }}
          />
        )
      })
    },
    [formulaId, currentCompanyId]
  )

  const formulaRegulationsProps = regulations.map((regulation) => {
    const formulaRegulation = formulaRegulations.find(
      (formulaRegulation) => formulaRegulation.regulationId === regulation.id
    )

    return {
      regulationId: regulation.id,
      regulationName: regulation.name,
      racc: {
        value: formulaRegulation?.racc || 0,
        onChange: (value: number) => handleRACCChange(value, regulation.id)
      },
      mealMainDish: {
        value: formulaRegulation?.isMealOrMainDish || false,
        onChange: (value: boolean) =>
          handleMainAndMealDishChange(value, regulation.id)
      },
      referenceIngredient: {
        searchProps: {
          onAdvancedSearchClick: (searchTerm: string) =>
            handleAdvancedSearchShowClick(searchTerm, regulation.id),
          state: searchLoading ? 'searching' : 'ready',
          searchResults: searchResults,
          onSearchChange: (newSearchTerm: string) =>
            handleSearchChange(newSearchTerm, regulation.id),
          onClick: (ingredientId: string) =>
            handleReferenceFoodClick(ingredientId, regulation.id),
          placeholder: 'Search ingredients...',
          value: formulaRegulation?.referenceFormula?.name ?? undefined
        },
        ingredient: formulaRegulation?.referenceFormula
          ? {
              id: formulaRegulation.referenceFormula.id,
              name: formulaRegulation.referenceFormula?.name
            }
          : undefined
      }
    }
  })

  return (
    <TargetsSettings
      targetParametersProps={{
        formulaRegulations:
          formulaRegulationsProps as FormulaRegulationTargetParametersProps[]
      }}
      targetSelectionProps={{
        targetOptionGroups: targetOptionGroups,
        onChange: handleTargetSelectionChange
      }}
    />
  )
}
