import { BasicCompanyApiResponse } from '../company/CompanyApiResponse'
import { BaseApiPaginatedResponse } from '../config/BaseApiPaginatedResponse'
import { FilterApiResponse } from '../filter/FilterApiResponse'
import { BasicFormulaApiResponse } from '../formula/FormulaApiResponse'
import { BasicUserApiResponse } from '../user/UserApiResponse'

// Permissions interfaces
export interface CompanyScopePermissionsApiResponse {
  listLabelProofs: boolean
  createLabelProof: boolean
}

export interface LabelProofScopePermissionsApiResponse {
  editLabelProof: boolean
  archiveLabelProof: boolean
  deleteLabelProof: boolean
  uploadDesignFile: boolean
  deleteDesignFile: boolean
  listTasks: boolean
  createTask: boolean
  listComments: boolean
  createComment: boolean
  listActivity: boolean
}

export interface LabelProofCommentScopePermissionsApiResponse {
  editComment: boolean
  deleteComment: boolean
}

export interface LabelProofPinScopePermissionsApiResponse {
  repositionPin: boolean
  resolvePin: boolean
  deletePin: boolean
}

export interface LabelProofTaskScopePermissionsApiResponse {
  editTask: boolean
  deleteTask: boolean
  addReviewer: boolean
}

export interface LabelProofTaskReviewerScopePermissionsApiResponse {
  editReview: boolean
  removeReviewer: boolean
}

export enum LabelProofStatusApiResponse {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed'
}

export interface LabelProofProgressApiResponse {
  totalTasks: number
  completedTasks: number
  status: LabelProofStatusApiResponse
}

export enum LabelProofTaskStatusApiResponse {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed'
}

export enum LabelProofActionApiResponse {
  LABEL_PROOF_CREATED = 'label_proof_created',
  LABEL_PROOF_RENAMED = 'label_proof_renamed',
  LABEL_PROOF_ARCHIVED = 'label_proof_archived',
  LABEL_PROOF_RESTORED = 'label_proof_restored',
  DOCUMENT_UPLOADED = 'document_uploaded',
  DOCUMENT_DELETED = 'document_deleted',
  TASK_CREATED = 'task_created',
  TASK_RENAMED = 'task_renamed',
  TASK_DELETED = 'task_deleted',
  TASK_APPROVED = 'task_approved',
  TASK_UNAPPROVED = 'task_unapproved',
  REVIEWER_ADDED = 'reviewer_added',
  REVIEWER_REMOVED = 'reviewer_removed',
  COMMENT_CREATED = 'comment_created',
  COMMENT_EDITED = 'comment_edited',
  COMMENT_DELETED = 'comment_deleted',
  TASK_DUE_DATE_CHANGED = 'task_due_date_changed',
  PIN_CREATED = 'pin_created',
  PIN_REPOSITIONED = 'pin_repositioned',
  PIN_RESOLVED = 'pin_resolved',
  PIN_DELETED = 'pin_deleted'
}

export interface LabelProofProgressApiResponse {
  totalTasks: number
  completedTasks: number
  status: LabelProofStatusApiResponse
}

export interface LabelProofProgressUpdateApiResponse {
  oldStatus: LabelProofStatusApiResponse
  newProgress: LabelProofProgressApiResponse
}

export interface LabelProofTaskProgressUpdateApiResponse {
  oldStatus: LabelProofTaskStatusApiResponse
  newStatus: LabelProofTaskStatusApiResponse
}

export interface ProgressUpdateApiResponse {
  labelProofProgressUpdate?: LabelProofProgressUpdateApiResponse
  taskProgressUpdate?: LabelProofTaskProgressUpdateApiResponse
}

export interface LabelProofApiResponse {
  id: string
  company: BasicCompanyApiResponse
  formula: BasicFormulaApiResponse
  name: string
  createdBy: BasicUserApiResponse
  createdAt: string
  updatedAt: string
  progress: LabelProofProgressApiResponse
  permissions: LabelProofScopePermissionsApiResponse
}

export type LabelProofsApiPaginatedResponse =
  BaseApiPaginatedResponse<LabelProofApiResponse>

export type LabelProofsCreatedByApiResponse =
  FilterApiResponse<BasicUserApiResponse>

export interface LabelProofPinCommentApiResponse {
  id: string
  comment: string
  createdBy?: BasicUserApiResponse
  replyTo?: LabelProofPinCommentApiResponse
  createdAt: string
  lastEditedAt?: string
  pinId: string
  mentions: MentionApiResponse[]
  isDeleted: boolean
  permissions: LabelProofCommentScopePermissionsApiResponse
}

export interface LabelProofPinApiResponse {
  id: string
  createdBy?: BasicUserApiResponse
  widthRatio: number
  heightRatio: number
  resolved: boolean
  resolvedBy?: BasicUserApiResponse
  resolvedAt?: string
  createdAt: string
  comments: LabelProofPinCommentApiResponse[]
  permissions: LabelProofPinScopePermissionsApiResponse
}

export interface MentionApiResponse {
  mentionedUser: BasicUserApiResponse
  createdAt: string
}

export interface ReviewerApiResponse {
  taskId: string
  reviewer: BasicUserApiResponse
  assignedAt: string
  approved: boolean
  approvedAt?: string
  permissions: LabelProofTaskReviewerScopePermissionsApiResponse
}

export enum TaskStatusApiResponse {
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed'
}

export interface BasicTaskApiResponse {
  id: string
  name: string
}
export interface TaskApiResponse extends BasicTaskApiResponse {
  createdBy?: BasicUserApiResponse
  createdAt: string
  updatedAt: string
  status: TaskStatusApiResponse
  reviewers: ReviewerApiResponse[]
  permissions: LabelProofTaskScopePermissionsApiResponse
  dueDate: string | null
}

export interface AddReviewerApiResponse {
  taskId: string
  reviewer: BasicUserApiResponse
  assignedAt: string
  approved: boolean
  approvedAt?: string
  permissions: LabelProofTaskReviewerScopePermissionsApiResponse
}

export interface LabelProofCreatedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.LABEL_PROOF_CREATED
  labelProofName: string
  formulaId: string
  formulaName: string
}

export interface LabelProofRenamedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.LABEL_PROOF_RENAMED
  oldLabelProofName: string
  newLabelProofName: string
}

export interface LabelProofArchivedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.LABEL_PROOF_ARCHIVED
  labelProofName: string
}

export interface LabelProofRestoredActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.LABEL_PROOF_RESTORED
  labelProofName: string
}

export interface DocumentUploadedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.DOCUMENT_UPLOADED
  labelProofName: string
}

export interface DocumentDeletedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.DOCUMENT_DELETED
  labelProofName: string
}

export interface TaskCreatedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_CREATED
  taskName: string
  progressUpdate: ProgressUpdateApiResponse
}

export interface TaskRenamedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_RENAMED
  oldTaskName: string
  newTaskName: string
}

export interface TaskDueDateChangedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_DUE_DATE_CHANGED
  taskName: string
  oldTaskDueDate?: string
  newTaskDueDate?: string
}

export interface TaskDeletedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_DELETED
  taskName: string
  progressUpdate: ProgressUpdateApiResponse
}

export interface TaskApprovedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_APPROVED
  taskName: string
  progressUpdate: ProgressUpdateApiResponse
}

export interface TaskUnapprovedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.TASK_UNAPPROVED
  taskName: string
  progressUpdate: ProgressUpdateApiResponse
}

export interface ReviewerAddedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.REVIEWER_ADDED
  taskName: string
  reviewer: BasicUserApiResponse
  progressUpdate: ProgressUpdateApiResponse
}

export interface ReviewerRemovedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.REVIEWER_REMOVED
  reviewer: BasicUserApiResponse
  progressUpdate: ProgressUpdateApiResponse
}

export interface CommentCreatedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.COMMENT_CREATED
}

export interface CommentEditedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.COMMENT_EDITED
}

export interface CommentDeletedActivityDetailsApiResponse {
  action: LabelProofActionApiResponse.COMMENT_DELETED
}

export type ActivityDetailsApiResponse =
  | LabelProofCreatedActivityDetailsApiResponse
  | LabelProofRenamedActivityDetailsApiResponse
  | LabelProofArchivedActivityDetailsApiResponse
  | LabelProofRestoredActivityDetailsApiResponse
  | DocumentUploadedActivityDetailsApiResponse
  | DocumentDeletedActivityDetailsApiResponse
  | TaskCreatedActivityDetailsApiResponse
  | TaskRenamedActivityDetailsApiResponse
  | TaskDeletedActivityDetailsApiResponse
  | TaskApprovedActivityDetailsApiResponse
  | TaskUnapprovedActivityDetailsApiResponse
  | ReviewerAddedActivityDetailsApiResponse
  | ReviewerRemovedActivityDetailsApiResponse
  | CommentCreatedActivityDetailsApiResponse
  | CommentEditedActivityDetailsApiResponse
  | CommentDeletedActivityDetailsApiResponse
  | TaskDueDateChangedActivityDetailsApiResponse

export interface LabelProofActivityApiResponse {
  id: string
  occurredAt: string
  linkedUser?: BasicUserApiResponse
  linkedTask?: BasicTaskApiResponse
  linkedComment?: LabelProofPinCommentApiResponse
  details: ActivityDetailsApiResponse
}

export type PaginatedLabelProofActivityApiResponse =
  BaseApiPaginatedResponse<LabelProofActivityApiResponse>
